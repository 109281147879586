import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section9-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    padding: '2% 5%',
  },
  descTitle: {
    fontWeight: 'bold',
    marginTop: '5px',
    fontSize: '1rem',
    lineHeight: '1.4',
  },
  descInfo: {
    fontSize: '1rem',
    lineHeight: '1.4',
  },
});

function PerformanceSection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Performance"
      imageWidth={34}
      imageRatio="150%"
      imageSrc={ImageBg}
      descMargin="-15%"
      desc={() => (
        <div className={classes.desc}>
          <Typography variant="body2" className={classes.descTitle}>
            Optimise workouts and recovery
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Through maximising nutrient dense, bio-available, whole foods, lean protein supplies and accurate levels of
            the best slow release complex cards.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Intermittent fasting – the true fountain of youth
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Gain natural enhancement by safely releasing Human Growth Hormone, through planned eating windows, to power
            the growth of every tissue in the body. By increasing HGH you reduce shortening of the telomeres of your
            chromosomes, responsible for aging. IF enables sharper cognitive function, better mood and a natural high
            feeling, better sex drive and a higher metabolism, stimulates the healing and repair of cells, while
            preserving protein in the body for muscle retention and leanness. *Engage one of our Health Professionals
            for the most appropriate and correct method of IF and utilisation with this App.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Scheduling
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Structure eating patterns to best: combine meals, maximise hydration, plan slow release carbohydrates and
            proteins, plan workouts, avoid nutrition loss from coffee and alcohol, and schedule eating windows, liquids
            and foods for IF.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Add our Weight Shifter App
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Our Weight Shift App seamlessly integrates with Health Creator to assist you in your goals of fat loss
            and/or muscle gain, in the healthiest manner possible for the body.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Add our Treasure Box App
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Utilise the Sanctuaries powerful ‘Articulate Visualisation’ process, mediation and affirmation programs to
            catalysis; perfect health, exceptional performance, and long term anti-aging.
          </Typography>
        </div>
      )}
    />
  );
}

export default withStyles(styles)(PerformanceSection);
