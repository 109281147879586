import React, {useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography, Button} from '@material-ui/core';
import ImageBg from '~/assets/images/page/healthGuides/banner_bg.jpg';
import AnimationWraper from '~/components/ui/AnimationWrapper';
import {useFetchRelatedProductsForPage} from 'shared/react-query/hooks';
import {useHistory} from 'react-router-dom';

const styles = (theme) => ({
  root: {
    color: '#000',
    marginBottom:'25px'
  },
  mainContainer: {
    display: 'flex',
    flexBasis: '100%',
    // border: '1px red solid',
    padding: '10px',
    // height: '450px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // height: 'auto',
    },
  },
  itemsContainerLeft: {
    display: 'flex',
    // border: '1px blue solid',
    padding: '10px',
    flexBasis: '60%',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  itemsContainerRight: {
    display: 'flex',
    // border: '1px silver solid',
    padding: '10px',
    flexBasis: '40%',
    justifyContent: 'center',
    '& >img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  productContainer: {
    // border: '1px yellow solid',
    padding: '10px',
    display: 'flex',
    flexBasis: '50%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      boxSizing: 'content-box',
    },
  },
  productContainerLeft: {
    width: '30%',
    // border: '1px pink solid',
    // alignSelf: 'flex-end', // with this one the alignment is going. hence commented
    '& >img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  descriptionContainerRight: {
    // border: '1px grey solid',
    display: 'flex',
    flexBasis: '70%',
    flexDirection: 'column',
    paddingLeft: '20px',
    '& >p:first-child': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    '& >p:last-child': {
      fontSize: '0.7rem',
      alignSelf: 'flex-end',
    },
  },
  titleNew: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    color: '#595959',
  },
  itemWrapper: {
    padding: '30px 0px',
  },
  gridTitleRight: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-100px',
    },
  },
  buttonNomal: {
    color: '#9aa200',
    borderRadius: '0',
    // border: 'solid 1px #9aa200',
    fontWeight: 'bold',
    backgroundColor: 'white',
    '&:active': {
      backgroundColor: 'white',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

function RelevantProducts(props) {
  const {classes} = props;
  const relatedProductsForPage = useFetchRelatedProductsForPage();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container className={classes.itemWrapper} justify="center" alignItems="center">
            <Typography variant="h5" align="center" className={classes.titleNew}>
              Related Products
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridTitleRight}>
          <Grid container className={classes.itemWrapper} justify="center" alignItems="center"></Grid>
        </Grid>
      </Grid>

      <div className={classes.mainContainer}>
        <div className={classes.itemsContainerLeft}>
          {relatedProductsForPage.data?.metaobject?.products?.references?.edges?.map(({node}, index) => {
            const item = node;
            return (
              <React.Fragment key={index}>
                <AnimationWraper key={item.title} index={index} timeOut={400}>
                  <div
                    onClick={() => {
                      if (node.collections.edges[0].node.handle === 'raw-organic-ingredients') {
                        history.push(`/shop-by-product-and-services/raw-organic-ingredients/${item.handle}`);
                        return 
                      }
                      if (node.collections.edges[0].node.handle === 'life-force-food') {
                        history.push(`/shop-by-product-and-services/life-force-food/${item.handle}`);
                        return 
                      }
                    }}
                    key={index}
                    style={{cursor:'pointer'}}
                    className={classes.productContainer}
                  >
                    <div className={classes.productContainerLeft}>
                      <img src={item.variants.nodes[0].image.src} alt={item.title} />
                    </div>
                    <div className={classes.descriptionContainerRight}>
                      <p>{item.title}</p>
                      <div>
                        {item?.text_above_product_title?.value && (
                          <Typography style={{color: '#9aa200', fontSize: 13, fontFamily: 'Lato'}}>
                            {item.text_above_product_title?.value}
                          </Typography>
                        )}
                        {item?.text_below_product_title?.value && (
                          <Typography style={{color: '#9aa200', fontSize: 13, fontFamily: 'Lato'}}>
                            {item.text_below_product_title?.value}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                </AnimationWraper>
              </React.Fragment>
            );
          })}
        </div>
        <div className={classes.itemsContainerRight}>
          <img style={{objectFit: 'cover'}} src={ImageBg} alt="Our Scanner" />
        </div>
      </div>
        <Grid container justify='center'>
          <Grid item >
            <Button
              variant="contained"
              className={classes.buttonNomal}
              onClick={() => {
                history.push('/shop-by-product-and-services/raw-organic-ingredients');
              }}
            >
              {'SEE MORE PRODUCTS'}
            </Button>
          </Grid>
        </Grid>
    </div>
  );
}

export default withStyles(styles)(RelevantProducts);
