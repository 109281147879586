import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    background: '#8DBF4C',
    borderRadius: '1000px',
    color: 'white',
    padding: '5px 40px',
    cursor: 'pointer',
    userSelect: 'none',
    width: 'fit-content',
    '&.hollow': {
      background: 'white',
      border: '1px solid #8DBF4C',
      color: '#8DBF4C',
    },
    '&.icon': {
      paddingLeft: 10,
    },
  },
  icon: {
    marginRight: 10,
    width: 18,
    height: 18,
  },
});

function Button(props) {
  const {classes, text, hollow, icon, style} = props;

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={clsx(classes.root, {
        hollow: hollow,
        icon: !!icon,
      })}
      style={style}
    >
      {icon && <img src={icon} className={classes.icon} alt="Icon" />}
      <Typography variant="subtitle2" style={{fontSize: 14}}>
        {text}
      </Typography>
    </Grid>
  );
}

export default withStyles(styles)(Button);
