import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageHeader from '~/components/ui/PageHeader';
import {FormControl, InputLabel, Button, Select, MenuItem} from '@material-ui/core';
import ImageMap from '~/assets/images/page/about-us/map.png';
import ImageStore from '~/assets/images/page/about-us/imageStore.png';
import icon1 from '~/assets/images/page/about-us/ic_share_facebook.png';
import icon2 from '~/assets/images/page/about-us/ic_share_instagram.png';
import icon3 from '~/assets/images/page/about-us/ic_share_twitter.png';
import icon4 from '~/assets/images/page/about-us/ic_share_web.png';
import ImageHead1 from '~/assets/images/page/prosper/barista.jpg';
import ImageHead2 from '~/assets/images/page/prosper/platting-4282016_1280.jpg';
import ImageHead3 from '~/assets/images/page/prosper/Gemini_Generated_Image (1).jpg';
import ImageHead4 from '~/assets/images/page/prosper/Gemini_Generated_Image.png';
import ImageHead5 from '~/assets/images/page/prosper/front of house.jpg';

import OuterContainer from '~/components/ui/OuterContainer';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {getPath} from '~/static/routes';

const TeamMembers = [
  {
    id: 1,
    // name: 'Cindy Smith',
    image: ImageHead1,
    position: 'Barista/barperson',
    // quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
    intro: 'Coffees, teas, smoothies and tonics',
  },
  {
    id: 2,
    // name: 'John Bill',
    image: ImageStore,
    position: 'Team Leader',
    quote: 'Separate team leader or appointed from one of the accompanying roles.',
    intro: `Team management, relations and promotion.`,
  },
  {
    id: 3,
    // name: 'John Bill',
    image: ImageHead2,
    position: 'Health chef',
    // quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
    intro: 'Health foods, desserts, dressings and condiments',
  },

  {
    id: 4,
    // name: 'Roy Sun',
    image: ImageHead3,
    position: 'Beauty technician',
    // quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
    intro: 'Natural skin and hair care products and services',
  },
  {
    id: 5,
    // name: 'Allen',
    image: ImageHead4,
    position: 'Health facilitator',
    // quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
    intro: 'Health testing, Apps, and literature',
  },
  {
    id: 6,
    // name: 'Taylor',
    image: ImageHead5,
    position: 'Front of house',
    // quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
    intro: 'Customer service and waitering',
  },
];

const styles = (theme) => ({
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    width: '100%',
    zIndex: 2,
    backgroundColor: 'white',
    fontFamily: 'montserrat',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    flex: 1,
    display: 'flex',
    margin: '1.5rem 0',
  },
  backgroundColor: {
    backgroundColor: 'white',
  },
  class3: {
    flexBasis: '35%',
    padding: '1em',
    color: 'rgba(38, 38, 38, 0.8)',
    '&>h2': {
      fontSize: '1.5rem',
      color: '#9aa200',
    },
    '&>h3': {
      fontSize: '1.2rem',
    },
    '&>p': {
      fontSize: '1rem',
    },
  },
  class4: {
    flexBasis: '65%',
    flexDirection: 'column',
  },
  class5: {
    textAlign: 'center',
    fontSize: '2rem',
    color: '#8c8c8c',
    fontWeight: 500,
    margin: '1.5rem 0',
  },
  class6: {
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  class7: {
    width: '30%',
    position: 'relative',
    marginBottom: '1em',
    [theme.breakpoints.down(768)]: {
      width: '45%',
    },
    [theme.breakpoints.down(500)]: {
      width: '95%',
    },
    '&>img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  class9: {
    textAlign: 'center',
    '&>img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  select: {
    minWidth: '40ch',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  class10: {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1.5rem 0',
    [theme.breakpoints.down(1690)]: {
      flexDirection: 'column',
      height: '100px',
    },
  },
  class11: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'absolute',
    textAlign: 'center',
    opacity: 0,
    padding: 10,
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(41, 41, 41,30%)',
      backdropFilter: 'blur(2.3px)',
    },
  },
  class12: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > img': {
      // width: '1rem',
      height: 'auto',
    },
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9AA200',
    fontSize: '14px',
    padding: '5px 30px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const CustomeSelect = withStyles((theme) => ({
  root: {
    padding: '10px 20px',
  },
}))(Select);

const Section1 = (props) => {
  const {classes} = props;
  const [selectValue, setSelectValue] = useState(0);

  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1)}>
        <div className={classes.class3}>
          <div className={clsx(classes.class0, classes.class10)}>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="demo-simple-select-outlined-label">Locality</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value=""
                // onChange={handleChange}
                label="Locality"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Avondale</MenuItem>
                <MenuItem value={20}>Devonport</MenuItem>
                <MenuItem value={30}>Onehunga</MenuItem>
              </Select>
            </FormControl>
            <ColorButton variant="contained" color="primary" className={classes.margin}>
              <Link style={{color: 'white', textDecoration: 'none'}} to={getPath('Find a store')}>
                FIND A STORE
              </Link>{' '}
            </ColorButton>
          </div>
          <h3>Innovative health retail and hospitality</h3>
          <p>
            Without question, the greatest reward of running a Sanctuary Store is the ability to offer a diverse range
            of specialty health; foods, drinks, literature, products, methods, and services to help people experience
            their full health and vibrancy. Be involved in the first physical Sanctuary Store, view our{' '}
            <Link to={'/prosper'}>Prosper page</Link> to see how to be a part of the wellness industry's most advanced
            retail innovation.
          </p>
          <h2>Servicing the following areas</h2>
          <div className={classes.class9}>
            <img src={ImageMap} />
          </div>
        </div>
        <div className={classes.class4}>
          <h2 className={classes.class5}>Store coming soon!</h2>
          <div className={clsx(classes.class0, classes.class6)}>
            {TeamMembers.map((element) => {
              return (
                <div key={element.id} className={classes.class7}>
                  <div className={clsx(classes.class0, classes.class11)}>
                    <p style={{fontWeight: 'bold'}}>{element.name}</p>
                    <p style={{fontWeight: 'bold', fontSize: 14, color: 'white'}}>{element.position}</p>
                    <p style={{fontSize: 14, color: 'white', whiteSpace: 'break-spaces'}}>{element.intro}</p>
                    <p style={{color: 'white'}}>{element.quote}</p>
                    <div className={classes.class12}>
                      <img src={icon1} />
                      <img src={icon2} />
                      <img src={icon3} />
                      <img src={icon4} />
                    </div>
                  </div>
                  <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={element.image} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(Section1);
