import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import LoadingIcon from '~/assets/images/layout/loadingGreen.svg';
import clsx from 'clsx';

const styles = (theme) => ({
class1:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize:'1rem',
    color:'grey'
},
error:{
    color:'red',
    fontSize:'1rem'
}
});

const Status= (props) =>{
const {classes,statusObj} = props;

if(statusObj.status == 'loading')
{return (
<div className={classes.class1}><img src={LoadingIcon}/>
</div>
)} else if (statusObj.status == 'error'){
return (<div className={clsx(classes.class1,classes.error)} >Error : {statusObj.message}
</div>)
}else if(statusObj.status == 'info'){
    return (<div className={classes.class1} >{statusObj.message}
        </div>)
}else {
    return (<>{props.children}</>)
}

}

export default withStyles(styles)(Status);
