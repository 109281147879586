import React from 'react';
import {withStyles} from '@material-ui/core/styles';
// import {Grid} from '@material-ui/core';
import {Grid, Typography, Box} from '@material-ui/core';
import Card from '~/components/ui/Card';

const styles = (theme) => ({
  root: {
    color: 'white',
  },
  // image: {
  //   width: '100%',
  //   backgroundColor: '#ffffff',
  //   [theme.breakpoints.down('sm')]: {
  //     maxWidth: '100%',
  //   },
  // },
  subtitle: {
    color: 'rgba(255, 255, 255, 0.49)',
    fontSize: '16px',
  },
  title: {
    margin: '10px 0 15px 0',
    fontWeight: 'bold',
  },
  card: {
    margin: '20% 60px 20% 20px',
    border: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      margin: '20px',
    },
  },
  guidesubtitle: {fontSize: '20px'},

  cardContent: {
    height: '100%',
    paddingTop: '60%',
    paddingBottom: '15px',
  },
  cardQuote: {
    background: 'rgba(255, 255, 255, 0.7)',
    width: '60%',
    padding: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  cardDesc: {
    fontWeight: 'bold',
  },
});

function HeaderLeft(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.root}>
    <Typography>{pageData.subText}</Typography>
      <Box className={classes.card}>
        <Card imageSrc={pageData.heroThumbnail} ratio="66%">
          <Grid
            className={classes.cardContent}
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          />
        </Card>
      </Box>
    </div>
  );
}

export default withStyles(styles)(HeaderLeft);
