import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageHeader from '~/components/ui/PageHeader';
import LifeForceComponent from '~/components/ui/LifeForceComponent';
import {LifeForceSectionDataList} from '~/data';

const styles = () => ({
  root: {},
  sectionContainer: {
    backgroundColor: 'white',
  },
});

function ShopByProduct(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <div className={classes.sectionContainer}>
        {LifeForceSectionDataList.map((section, index) => (
          <LifeForceComponent key={index} section={section} lastOne={index === LifeForceSectionDataList.length - 1} />
        ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(ShopByProduct);
