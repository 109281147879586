import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Fade} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));

const SubmenuAnimationWrapper = (props) => {
  const classes = useStyles();
  return (
    <Fade in={props.showFade} {...(props.showFade ? {timeout: 1000} : {})}>
      <div style={{width: '100%', background: 'white', borderBottom: '1px solid #c0c0c0'}}>{props.children}</div>
    </Fade>
  );
};

export default SubmenuAnimationWrapper;
