import React, {useState} from 'react';
import {Typography, withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import background from '~/assets/images/layout/SanctuaryStore_home.png';
import {getPath} from '~/static/routes';
import {useHistory} from 'react-router-dom';

const styles = (theme) => ({
  bannerWrapper: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 'calc(97vh - 10px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  },
  class1: {
    display: 'flex',
    flex: 1.5,
    [theme.breakpoints.down(1221)]: {
      flexDirection: 'column',
    },
  },
  class3: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '45vh',
    justifyContent: 'space-between',
  },
  class4: {
    display: 'flex',
    flex: 0.5,
    justifyContent: 'flex-end',
    [theme.breakpoints.down(1221)]: {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '2vh',
      justifyContent: 'center',
    },
    '& > div:nth-child(1)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '21em',
      [theme.breakpoints.down(1221)]: {
        alignItems: 'flex-start',
      },
    },
  },
  class5: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down(1221)]: {
      flexDirection: 'row-reverse',
    },
  },
  class6: {
    fontFamily: 'roboto',
    color: '#3a2222',
    backgroundColor: '#ffffff',
    fontSize: '0.8rem',
    opacity: '0.5',
    padding: ' 0.5em 1em ',
    borderRadius: 5,
    width: '18ch',
  },

  class7: {
    border: '0.2em solid white',
    borderRadius: '2em',
    margin: '1em',
  },
  class8: {
    backgroundColor: 'white',
    width: '1.5em',
    height: '1.5em',
    borderRadius: '2em',
    margin: '0.5em',
  },
  class9: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  class10: {
    flex: 0.5,
    [theme.breakpoints.down(1221)]: {
      flex: 0,
    },
  },
  class11: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  discover: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'italic',
    letterSpacing: '0.72px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  title: {
    opacity: 0.8,
    fontFamily: 'Montserrat',
    color: 'rgb(255, 255, 255)',
    fontSize: '35px',
    lineHeight: '42px',
    fontWeight: '500',
    fontStretch: 'normal',
    letterSpacing: '2.24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '42px',
    },
  },
  lifeForce: {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '400',
    color: 'rgba(255, 255, 255, 0.698)',
    fontStretch: 'normal',
    letterSpacing: '2.24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight:'27px',
      padding: '15px 0',
    },
  },
  button: {
    backgroundColor: '#ffffff',
    color: '#A6A838',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    width: '',
  },
  removeLine: {
    textDecoration: 'none',
  },
  downArrow: {
    padding: 20,
  },
  websiteButtonsContainer:{
    [theme.breakpoints.down('sm')]: {
      display:'none !important'
    },
  }
});

const SiteOption = withStyles(styles)((props) => {
  const {classes} = props;
  return (
    <div className={classes.class5} style={{opacity: props.active && 1}}>
      <div className={classes.class6}>{props.name}</div>
      <div className={classes.class7}>
        <div className={classes.class8}></div>
      </div>
    </div>
  );
});

function BannerCard(props) {
  const {classes} = props;
  const websites = [
    {title: 'SANCTUARY STORE', url: ''},
    {title: 'URBAN SANCTUARY', url: 'https://vfhurbansanctuary.com/'},
    {title: 'MASTER SANCTUARY', url: 'https://vfhurbansanctuary.com/master-sanctuary'},
  ];
  const currentWebsite = 0;
  const [highlightedWebsite, setHighlightedWebsite] = useState(currentWebsite);
  let history = useHistory();

  return (
    <div className={classes.bannerWrapper}>
      <div style={{flex: 1}}></div>
      <div className={classes.class1}>
        <div style={{flex: 0.5}}></div>
        <div className={classes.class3}>
          <Box textAlign="center">
            <Typography variant='h1' className={classes.title} style={{marginBottom: 26}}>SANCTUARY STORE 
            <br/>WELL-BEING SUPPLIES
            </Typography>
            <Typography className={classes.lifeForce}>
              Life Force Food, Biospherically Correct Vision Products,<br/>
              Well-being Apps, Holistic Health Testing, Literature &amp; Protocols{' '}
            </Typography>
          </Box>
          <Box sx={{display:"flex", flexWrap:"wrap", gap:10, justifyContent:"center", marginTop:10}} >
                  <Button onClick={()=>{history.push("/shop-by-product-and-services/raw-organic-ingredients?tag1=specialisedOrganicCerealMixes")}} variant="contained">Organic Cereal Mixes</Button>
                  <Button onClick={()=>{history.push("/shop-by-product-and-services/raw-organic-ingredients")}} variant="contained">Organic Ingredients</Button>
                  <Button onClick={()=>{history.push("/shop-by-product-and-services/raw-organic-ingredients?tag1=functionalPowdersHerbsAndSpices")}} variant="contained">Functional Powders</Button>
                  </Box>
          <Box textAlign="center" marginTop={"20px"}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                history.push(getPath('About us'));
              }}
            >
              Learn about us
            </Button>
          </Box>
        </div>
        <div className={classes.class4}>
          <div className={classes.websiteButtonsContainer}>
            {websites.map((element, index) => {
              return (
                <a
                  style={{textDecoration: 'none'}}
                  href={element.url}
                  onMouseEnter={() => setHighlightedWebsite(index)}
                  onMouseLeave={() => setHighlightedWebsite(currentWebsite)}
                >
                  <SiteOption name={element.title} active={index === highlightedWebsite} />
                </a>
              );
            })}
          </div>
          <div className={classes.class10}></div>
        </div>
      </div>
      <div className={classes.class11}>
        <ArrowDownwardIcon onClick={props.scrollDown} className={classes.downArrow} alt="sanctuary store" />
      </div>
    </div>
  );
}

export default withStyles(styles)(BannerCard);
