import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Box, Card, CardMedia, Button} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
  root: {},
  flexGrow: {
    flex: 1,
  },
  content: {
    lineHeight: '25px',
    overflow: 'hidden',
  },
  contentFolded: {
    maxHeight: '100px',
  },
  buttonNomal: {
    height: '30px',
    color: '#9aa200',
    borderRadius: '0',
    border: 'solid 1px #9aa200',
    fontSize: '13px',
    fontWeight: 'bold',
    paddingLeft: '12px',
    paddingRight: '12px',
    backgroundColor: 'white',
    '&:active': {
      backgroundColor: 'white',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  buttonActive: {
    color: 'white',
    backgroundColor: '#9aa200',
    '&:active': {
      backgroundColor: '#9aa200',
    },
    '&:hover': {
      backgroundColor: '#9aa200',
    },
  },
  clamp:{
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',  
    overflow: 'hidden'
  }
});

function CardItem(props) {
  const {classes, cardData, clamp,isExpand, onClickExpand} = props;
  const history = useHistory();

  return (
    <Card className={classes.root}>
      <Box px="20px" py="18px">
        <Grid container direction="row" wrap="nowrap">
          <Grid item style={{width:clamp?"82%":"auto"}}>
            <Grid item style={{height: '100%',width:'auto'}}>
              <Box fontWeight="fontWeightBold" fontSize="subtitle1.fontSize" color="#434343">
                {cardData.name.value}
              </Box>
              <Box fontWeight="fontWeightBold" fontSize="body2.fontSize" color="#959595">
                <p className={clamp?classes.clamp:''}>
                {cardData.description.value}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="60px" height="60px">
              <CardMedia
                style={{borderRadius:99, width:60, height:60}}
                component="img"
                image={cardData.image?.reference?.image?.url}
              />
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          fontSize="body2.fontSize"
          color="#575757"
          mt="20px"
          className={clsx(classes.content, {
            [classes.contentFolded]: !isExpand,
          })}
        >
          {/* {cardData.content} 
        </Box> */}
       {clamp && <Box mt="30px">
          <Grid container alignItems="center">
            <Box flex={1}>
              <Button
                variant="contained"
                className={clsx(classes.buttonActive)}
                onClick={() => {
                  history.push(`life-force-food-ingredients#${cardData.name.value.charAt(0)}`);
                }}
              >
               LEARN MORE
              </Button>
            </Box>

            {/* <Box color="#9AA200" fontWeight="fontWeightBold" fontSize="body2.fontSize">
              {'>>'} RECIPES WITH ALC
            </Box> */}
          </Grid>
        </Box>}
      </Box>
    </Card>
  );
}

export default withStyles(styles)(CardItem);
