import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '~/components/ui/OuterContainer';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import Service1 from '~/assets/images/page/about-us/IMG_8056.jpg';
import Service2 from '~/assets/images/page/about-us/slide-image-1.jpg';
import Service3 from '~/assets/images/page/about-us/pieChart.179c29c6d6d27532ebce.png';
import Service4 from '~/assets/images/page/giftpacks/gfbg.jpg';
import Service5 from '~/assets/images/page/about-us/Screenshot 2024-02-22 at 1.10.59 PM.png';
import { getPath } from '~/static/routes';



const styles = (theme) => ({
  backgroundColor: {
    backgroundColor: '#F3F3F3',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    fontFamily: 'Montserrat',
    margin: '3em 0em',
    flexDirection: 'column',
    '&>h2': {
      color: '#595959',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '1em',
    },
  },
  class2: {
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    margin: '3em 0em',
    '&::after': {
      content: '""',
      width: '32%',
    },
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
    },
  },
  class3: {
    width: '18%',
    color: 'white',
    border: '1px solid #8c8c8c',
    marginBottom: '2em',
    display:'flex',
    flexDirection:'column',
    [theme.breakpoints.down(1024)]: {
      width: '31.66%',
    },
    [theme.breakpoints.down(500)]: {
      width: '95%',
    },
    '&>img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      flex:1,
      objectFit: "cover"
    },
    '&>h3': {
      backgroundColor: '#9aa200',
      textAlign: 'center',
      margin: 0,
      padding: '1rem',
    },
  },
});

const ServiceCard = withStyles(styles)((props) => {

  const { classes } = props;

  const isExternalLink = /^https?:\/\//.test(props.path);

  const content = (
    <>
      <img src={props.image} />
      <h3>{props.title}</h3>
    </>
  );

  return isExternalLink ? (
    <a
      className={classes.class3}
      href={props.path}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      {content}
    </a>
  ) : (
    <Link className={classes.class3} to={props.path} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  );
});


const Section3 = (props) => {
  const {classes} = props;

    const ServiceData = [
  {
    image: Service1,
    title: 'Life Force Food',
    path: getPath('Life Force Food'),
  },
  {
    image: Service2,
    title: 'Vision Product',
    path: 'https://visionproductsdirect.com/',
  },
  {
    image: Service3,
    title: 'Tech Testing',
    path: 'https://vfhurbansanctuary.com/holistic-system'
  },
  {
    image: Service4,
    title: 'Giftpacks',
    path: getPath('Gift Packs'),
  },
  {
    image: Service5,
    title: 'Health Creator App',
    path: 'https://vfhurbansanctuary.com/discover#healthcreator',
  },
];

  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1)}>
        <h2>Select Services</h2>
        <div className={clsx(classes.class0, classes.class2)}>
          {ServiceData.map((element) => {
            return <ServiceCard key={element.title} {...element} />;
          })}
        </div>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(Section3);
