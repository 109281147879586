import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    zIndex: '3',
    padding: '50px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px auto',
    },
  },
  title: {
    opacity: '0.8',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  immuneSystemImg: {
    maxWidth: '400px',
    margin: '0px auto',
    zIndex: '3',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      marginBottom: '20px',
    },
  },
  immuLinkContainer: {
    position: 'absolute',
    bottom: '30px',
    textAlign: 'left',
    zIndex: '5',
    left: '5%',
    right: '5%',
    fontSize: '16px',
    lineHeight: 1.2,
    letterSpacing: '0.2px',
    color: '#757575',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      bottom: '16px',
    },
  },
  exerciseImageWrapper: {
    maxWidth: '500px',
    position: 'relative',
  },
  exerciseImage: {
    margin: '0px auto',
    width: '100%',
    zIndex: '3',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

function Section3(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.exerciseWrapper}>
      <Container maxWidth="lg">
        <div className={classes.root}>
          <Grid container>
            <Grid item md={6} className={classes.leftContent}>
              <Typography variant="h5" className={classes.title}>
                {pageData.section3Title}
              </Typography>
              <div className={classes.imageWrapper}>
                <img src={pageData.section3Image} className={classes.immuneSystemImg} alt="Our Scanner" />
              </div>
            </Grid>
            <Grid item md={6} className={classes.imageWrapper}>
              <div className={classes.exerciseImageWrapper}>
                <img src={pageData.section3RightImage} className={classes.exerciseImage} alt="Our Scanner" />
                {pageData.title === 'IMMUNE SYSTEM' && (
                  <div className={classes.immuLinkContainer}>
                    <div>
                      <div>
                        <a href="#">Click here</a> for a free protocol - related to Covid 19, influenza and other
                        respiratory infections
                        <br /> <br />
                      </div>
                      <div>
                        <a href="#">Click here</a> for full Protocol
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(Section3);
