import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

import ImageBg from '~/assets/images/page/discover/section1-background.png';
import ImageMobile from '~/assets/images/page/discover/section1-mobile.png';
import IconPlayVideo from '~/assets/images/page/discover/section1-video-play.png';
import IconArrow from '~/assets/images/page/discover/section1-arrow.png';
import BorderRadiusButton from '~/components/ui/BorderRadiusButton';
import NavigationList from '~/components/ui/NavigationList';
const styles = (theme) => ({
  root: {
    padding: '0 6%',
    backgroundImage: `url(${ImageBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      background: 'white',
    },
  },
  leftSection: {
    padding: '90px 15% 40px 15%',
    [theme.breakpoints.down('sm')]: {
      padding: '5%',
    },
  },
  imageMobile: {
    width: '100%',
    marginBottom: '10px',
  },
  bottomText: {
    fontFamily: 'Georgia',
    fontSize: '0.9rem',
    marginTop: '20px',
    textAlign: 'center',
    color: '#434343',
  },
  rightSection: {
    padding: '70px 15% 50px 5%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5%',
    },
  },
  title: {
    color: '#8C8C8C',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      color: '#fff',
    },
  },
  desc: {
    marginTop: '10px',
    color: '#5499C7',
    fontWeight: 'normal',
  },
  list: {
    marginTop: '50px',
  },
  listItem: {
    marginTop: '15px',
    cursor: 'pointer',
  },
  iconArrow: {
    height: '16px',
    marginRight: '10px',
  },
  itemDesc: {
    color: '#9AA200',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      fontSize: '1rem',
    },
  },
  playVideoBox: {
    marginTop: '30px',
  },
  iconPlayVideo: {
    width: '20px',
    marginRight: '10px',
  },
  playVideoDesc: {
    fontWeight: 'normal',
    color: '#5499C7',
  },
});

const titleList = [
  'Perfect Health',
  'Precise nutritional analysis',
  'Nutrition Calculator',
  'Nutrient and Toxin profiling',
  'Food Science Depository',
  'Purity',
  'Performance',
  'Create Health',
];

function BannerSection(props) {
  const {classes, handleClick} = props;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={6}>
          <Grid container className={classes.leftSection} direction="column" alignItems="center">
            <img src={ImageMobile} className={classes.imageMobile} alt="Mobile phone" />
            <BorderRadiusButton text="DOWNLOAD" />
            <Typography variant="body1" className={classes.bottomText}>
              Dynamically support performance, healing and anti-aging. Interlock our food science, Nutritional
              Optimisation and the purity of body and mind to create your own perfect health. Sew the physiological
              conditions for achieving natural fat loss, muscle gain, and a state of vibrant preventative health.
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <div className={classes.rightSection}>
            <Typography variant="h4" className={classes.title}>
              Health Creator App
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              Optimum nutritional design & Cellular Purity
            </Typography>
            <NavigationList handleClick={handleClick} color={'#9AA200'} titleList={titleList} icon={IconArrow} />
            <Grid container alignItems="center" className={classes.playVideoBox}>
              <img src={IconPlayVideo} className={classes.iconPlayVideo} alt="Icon play video" />
              <Typography variant="h6" className={classes.playVideoDesc}>
                View Live App Demo
              </Typography>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(BannerSection);
