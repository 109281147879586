import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import OuterContainer from '~/components/ui/OuterContainer';
import SubmenuAnimationWrapper from './SubmenuAnimationWrapper';

const useStyles = makeStyles((theme) => ({
  class0: {
    margin: 0,
    width: '100%',
    fontFamily: 'Roboto',
  },
  class1: {
    color: '#595959',
    fontSize: '14px',
    cursor: 'pointer',
  },
  class5: {padding: '10px 0'},

  class2: {
    color: '#8C8C8C',
    fontSize: '13px',
    cursor: 'pointer',
  },
  class3: {
    color: '#9AA200',
    cursor: 'pointer',
    fontSize: 13,
  },
}));

const Submenu2 = (props) => {
  const {submenulvl2Obj, historyPush, showFade} = props;
  const classes = useStyles();
  const handleOnClick = (element) => {
    if (element.hasOwnProperty('url')) {
      window.location.assign(element.url);
    } else {
      historyPush(element.path);
    }
  };
  const renderMenu = (submenu) => {
    return (
      <>
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          className={classes.class0}
          style={{height: '100%'}}
          justify="center"
        >
          {submenu.map((element, index) => {
            return (
              <React.Fragment key={element.name + index}>
                {element.submenu.map((ele2, index1) => {
                  if (ele2.head) {
                    return (
                      <Grid
                        container
                        item
                        alignItems="flex-start"
                        xs
                        spacing={1}
                        key={ele2.name + index1}
                        className={classes.class0}
                        style={{height: '100%'}}
                      >
                        <Grid item xs={12}>
                          <Typography onClick={() => handleOnClick(ele2)} className={classes.class1}>
                            {ele2.name}
                          </Typography>
                        </Grid>
                        {ele2.hasOwnProperty('submenu') &&
                          ele2.submenu.map((ele) => (
                            <Grid item xs={12} key={ele.name}>
                              <Typography onClick={() => handleOnClick(ele)} className={classes.class2}>
                                {ele.name}
                              </Typography>
                            </Grid>
                          ))}
                      </Grid>
                    );
                  }
                })}
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid container item xs={12} className={classes.class0}>
          {submenu.map((element) => {
            if (element.head == true) {
              return (
                <Grid container item xs justify="center" className={classes.class0} key={element.name}>
                  <Typography
                    className={classes.class3}
                    onClick={() => {
                      handleOnClick(element);
                    }}
                  >
                    &gt;&gt; See all {element.name.toLowerCase()}
                  </Typography>
                </Grid>
              );
            }
          })}
        </Grid>
      </>
    );
  };

  return (
    <SubmenuAnimationWrapper showFade={showFade}>
      <OuterContainer className={classes.class5}>
        <Grid container spacing={3} className={classes.class0}>
          {submenulvl2Obj !== null && renderMenu(submenulvl2Obj.submenu)}
        </Grid>
      </OuterContainer>
    </SubmenuAnimationWrapper>
  );
};

export default Submenu2;
