import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Grid, Container} from '@material-ui/core';

import ImageBg from '~/assets/images/page/discover/section12-bg.png';
import OuterContainer from '../OuterContainer';

const styles = (theme) => ({
  root: {
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 10%',
    },
  },
  contentContainer: {
    background: `url(${ImageBg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  title: {
    textAlign: 'center',
    color: '#8DBF4C',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    marginBottom: '20px',
  },
  desc: {
    color: '#707070',
    fontSize: '1rem',
  },
});

function GlobalMetawheel(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <OuterContainer>
        <Grid container className={classes.contentContainer}>
          <Grid item md={6} sm={false} xs={false}></Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="h5" className={classes.title}>
              Global Metawheel
            </Typography>
            <Typography variant="body1" className={classes.desc}>
              Global Advancement Architecture
              <br />
              <br />
              Your magnificence can help others. The Global Metawheel illustrates how your growth ultimately benefits
              the planet. Further, there is significant research revealing how helping others, or contributing to
              something meaningful, can provide you even greater happiness and wellbeing. Through little effort, but
              greater understanding, your advancement seamlessly Elevates Global Consciousness bringing more Increase to
              others.
              <br />
              <br />
              We believe in first helping the individual in order that they may, from a position of strength and
              compassion, help others. The second ring of the Global Metawheel focuses on global advancement. The VFH
              Visions, Initiatives and Charter surrounds the wheel supporting and interlocking the blueprint of a 2nd
              Genome of Humanity.
              <br />
              <br />
              The Sanctuary interlocks your development and advancement into planetary wellbeing. Through its greater
              purpose and ethos, the Sanctuary shows the path of greater people for better planet.
              <br />
              <br />
              This may be something you wish to explore at a later date, or if of interest, take a look now at the
              interactive diagram and discover how, at the right time, you can engage a higher purpose to participate in
              creating something much greater than your magnificent self: “A Successful Planet.”
            </Typography>
          </Grid>
        </Grid>
      </OuterContainer>
    </div>
  );
}

export default withStyles(styles)(GlobalMetawheel);
