import React, {useEffect} from 'react';

import HeroSection from './Sections/HeroSection';
import CellWellBeingSection from './Sections/CellWellBeingSection';
import ReportingSection from './Sections/ReportingSection';
import BannercardSection from './Sections/BannercardSection';
import ThumbNailCardSection from './Sections/ThumbNailCardSection';
import OurCustomerSection from './Sections/OurCustomerSection';
import FindStoreSection from './Sections/FindStoreSection';

export default function Scanning() {

useEffect(() => {
  window.scrollTo(0,0);
},[])

  return (
    <div>
      <HeroSection />
      <CellWellBeingSection />
      <ReportingSection />
      <BannercardSection />
      <ThumbNailCardSection />
      <OurCustomerSection />
      <FindStoreSection />
    </div>
  );
}
