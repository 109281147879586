import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import HtmlContent from '~/components/ui/HtmlContent';

const styles = (theme) => ({
  title: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },

  
});

function lifeforceContent({props, lifeforce}) {
  const {lifeforcecontent} = lifeforce  
  return (
    <>
      <HtmlContent 
          content={lifeforcecontent} 
        />
    </>
  );
}

export default withStyles(styles)(lifeforceContent);
