import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Modal, Typography} from '@material-ui/core';
import BiosphericallyCorrectIcon from '~/assets/images/page/lifeforcefood/biosphericallyCorrectIconWithRating.png';

const styles = (theme) => ({
  sectionContainer: {
    backgroundColor: 'white',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    top: '10%',
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  modalTitleBar: {
    backgroundColor: '#5FA6D0',
    borderBottom: '6px solid #83B45A',
  },
  modalTitleBarText: {
    fontSize: '1.4rem',
    color: 'white',
    textAlign: 'center',
  },
  ratingText: {
    color: 'white',
    paddingTop: 5,
  },
  tabContainer: {
    padding: '0 5px',
  },
  tab: {
    display: 'flex',
    height: '2em',
    color: '#707070',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 700,
    backgroundColor: 'white',
    border: '5px white solid',
    padding: '5px 0',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    justifyContent: 'center',
  },
  tabText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    textAlign: 'center',
  },
  quickViewTab: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    // height: '50vh',
    [theme.breakpoints.down(1007)]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
});

function BioModal(props) {
  const {open, close, classes} = props;

  const bioData = [
    {
      title: 'Quick View',
      activeState: true,
      content: (
        <div className={classes.quickViewTab}>
          <div>
            <img
              alt="group"
              src="//cdn.shopify.com/s/files/1/0032/0492/files/Group_1360.jpg?v=1587683217"
              style={{maxHeight: '100%', maxWidth: '100%'}}
            />
          </div>
          <div style={{minWidth: 290, padding: '10px 0'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f8f8f8',
                width: 250,
                maxHeight: '100%',
                alignItems: 'center',
                padding: '5%',
                borderRadius: 10,
              }}
            >
              <div style={{fontFamily: 'Lato', fontWeight: 900, fontSize: '1.2rem', textAlign: 'center'}}>
                Analysis of ecological and societal harmony
              </div>
              <div style={{width: '30%', margin: '20px 0', borderBottom: '2px black solid'}}></div>
              <div style={{fontFamily: 'Lato', fontSize: '1rem', textAlign: 'center'}}>
                <p style={{fontWeight: 900, margin: '5px 0'}}>Profile Product: </p>LIFE FORCE FOOD
                <p style={{fontWeight: 900, margin: '5px 0'}}> Biospherically Rating:</p>
              </div>
              <img
                src={BiosphericallyCorrectIcon}
                style={{width: '60%', maxHeight: '100%', margin: '15px 0'}}
                alt="Biospherically Correct"
              />
              <div style={{fontFamily: 'Lato', fontSize: '1rem', textAlign: 'center', color: '#8c8c8c'}}>
                <p style={{margin: '7px 0'}}>Biosphercially Correct</p>
                <p style={{margin: '7px 0', color: '#3e789e', fontSize: '1.7rem', fontWeight: 500}}>
                  Its time to reconnect
                </p>
                <p style={{margin: '7px 0'}}>www.biosphericallycorrect.org</p>
              </div>
            </div>
            {/* <img
              alt=""
              src="//cdn.shopify.com/s/files/1/0032/0492/files/Group_1194_967a079a-99e9-426b-bcfc-7e6eb8d893fd_large.jpg?v=1587600168"
              style={{maxHeight: '100%', maxWidth: '100%'}}
            /> */}
          </div>
        </div>
      ),
      icon: '',
    },
    {
      title: 'Diversity Protection',
      activeState: false,
      content: (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'space-evenly',
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/wild-harvest_small.png?101431"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000006933171Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/sea_turtle_small.png?101410"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/orangutan_small.png?101410"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/polar_bear_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
          </div>
          <div style={{padding: 15}}>
            <h1>Diversity Protection</h1>
            TO WHAT EXTENT DOES THE CULTIVATION AND EXTRACTION OF THE RAW MATERIALS NECESSARY TO PRODUCE THIS PRODUCT;
            ENHANCE THE DIVERSITY OF LIFE, THAT IS, AIDS THE GREATERBIOSPHERIC SERVICESOF THE PLANET, THE LOCALISED ECO
            SYSTEMS (SPECIES AND HABITAT PROTECTION) AND PRESERVES THE ASSOCIATED KNOWLEDGE, CUSTOMS AND PROSPERITY OF
            THE INDIGENOUS CULTURES OF THE TERRITORY?
            <h2>Biospherically Correct</h2>
            <i>
              The ingredients in our skin care are all natural, some organic and some wild harvested. However, we prize
              most of all the unique ingredients: acai, camu camu, copibia and tamanu oil which are authenticially
              organic and wild harvested.
            </i>
            <p>
              Acai harvest provides villagers with income. Wages are higher for harvesting acai than in other industries
              such as logging. Families who harvest acai keep some of the fruit to eat or use as garden fertilizer. Acai
              palms are preserved. It is more beneficial to harvest the berries than to cut the palm down.
            </p>
            Approximately 30% of fruit harvested falls to the ground or is eaten by birds or wild animals, protecting
            diversity. In Peru, camu camu seedlings are available free of charge from the government to native peoples
            for them to plant. Our coconut oil comes from a model farm in Fiji that utilizes natural resources to create
            work for local people. We do not use palm oil.
            <p>
              SUMMARY: OUR SKIN CARE RANGE IS RESPECTFUL OF THE DIVERSITY OF LIFE, HONOURING NATURE AND THE VALUING OF
              INDIGENOUS CULTURE.
            </p>
          </div>
        </div>
      ),
      icon: '//cdn.shopify.com/s/files/1/0032/0492/t/3/assets/ico-diversity.png?v=1092055600561399744',
    },
    {
      title: 'Green Ingredients',
      activeState: false,
      content: (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'space-evenly',
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            <img
              alt="Bamboo"
              src="//cdn.shopify.com/s/files/1/0032/0492/files/iStock_000000449501Small_small.jpg?100966"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/rosehip_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000004053391XSmall_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/acai_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000014918529Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
          </div>
          <div style={{padding: 15}}>
            <h1>Green Ingredients</h1>
            DO THE INGREDIENTS IN THE PRODUCT MEET THE CRITERIA FOR BEING 'GREEN INGREDIENTS', THAT IS, ORGANIC AND/OR
            NATURAL, HIGH YIELDING AND SUSTAINABLE?
            <h2>Biospherically Correct</h2>
            <i>
              All of the ingredients in our skin care products are natural - i.e. non synthesisied and/or organic. Our
              nutrient rich ingredients have undergone minimal processing and resonate well within the body. We use a
              unique natural preservative system combining the anti aging, healing and preservative properties of citrus
              bioflavanoids and Totarol, extracted from old recycled bark from the NZ native totara tree.
            </i>
            <p>
              Acai, our premier ingredient throughout the range, is wild harvested from the Amazon Rain Forest and cold
              pressed to yield the purest oil. It is a high yield, sustainable ingredient as the acai palm is not
              damaged during harvest and the seeds are used to propagate new trees.{' '}
            </p>
            Virtually all of our skin care is crafted in New Zealand on a specialised herbal farm where essential oils
            are used for scents, colours are derived from nature and herbal extracts are used for enhancing health and
            well-being. Many of these are supplied directly from our manufacturiers own garden. We use 100% pure New
            Zealand sea salt in our exfoliant, while our pure coconut oil is made naturally in Fiji.
            <p>
              SUMMARY: OUR NATURAL, ORGANIC, WILD HAVESTED INGREDIENTS ARE SUSTAINABLE IN NATURE MAKING THEM IDEAL FOR
              OUR SKIN CARE.
            </p>
          </div>
        </div>
      ),
      icon: '//cdn.shopify.com/s/files/1/0032/0492/t/3/assets/ico-green.png?v=18078277334629179923',
    },
    {
      title: 'Positive Functionality',
      activeState: false,
      content: (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'space-evenly',
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000008233744Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/bicycle_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000009511846Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="http://cdn.shopify.com/s/files/1/0032/0492/files/tshirt_small.jpg?101342"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000004455199Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
          </div>
          <div style={{padding: 15}}>
            <h1>Positive Functionality</h1>
            HOW POSITIVE FOR THE INDIVIDUAL, SOCIETY AND THE ENVIRONMENT IS THE FUNCTION OF THE PRODUCT IN ITS FINAL
            FORM?
            <h2>Biospherically Correct</h2>
            <i>
              It is deeply satisfying to create, use and then share products which have come from Mother Nature's
              basket, celebrating our connection with all living things. Ingredients are chosen for their nutritional
              and anti aging properties, as well for their fragrance and performance as gentle cleansers and
              moisturisers e.g, our body wash contains an incredible 10% of honey. Honey is known for its ability to
              fight infection, and boosting immunity. The two super fruits, acai and camu camu, used in our skin care
              items are unsurpassed in terms of anti-oxidant content and have unmatched healing and nutritional
              properties.
            </i>
            <p>
              The skin preparations are made by herbalists with an intimate knowledge of the plant world, ensuring the
              very best outcomes in terms of quality and product integrity. Soaps are also are hand made and coconut oil
              cold pressed to retain natural properties.
            </p>
            The words love, forgiveness, compassion, tolerance and integrity are meaningfully printed on our labels to
            inspire and promote a sense of connection with others. Positive function is further enhanced by proceeds
            going towards Vision for Humanity Charitable Trust initiatives. Marketing and product sales are conducted
            via our website, keeping advertising and retail over heads to a minimum.
            <p>
              SUMMARY: OUR NATURAL SKIN CARE RANGE IS A GREAT EXAMPLE OF A MUTALLY BENEFICIAL RELATIONSHIP BETWEEN THE
              INDIVIDUAL AND THE ENVIRONMENT.
            </p>
          </div>
        </div>
      ),
      icon: '//cdn.shopify.com/s/files/1/0032/0492/t/3/assets/ico-functionality.png?v=15410481981913093478',
    },
    {
      title: 'Eco-Processes',
      activeState: false,
      content: (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'space-evenly',
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            <img
              alt="Bamboo"
              src="//cdn.shopify.com/s/files/1/0032/0492/files/iStock_000000449501Small_small.jpg?100966"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="http://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000009258498Small_small.jpg?101087"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000013053281Small_grande.jpg?101087"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000012598012Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000014377944Small_grande.jpg?100697"
              style={{height: 150, padding: 20}}
            />
          </div>
          <div style={{padding: 15}}>
            <h1>Eco-Processes</h1>
            HOW WELL DO THE PROCESSES INHERENT IN THE MANUFACTURING, PACKAGING AND DISTRIBUTION OF THIS PRODUCT SUSTAIN
            NATURE?
            <h2>Biospherically Correct</h2>
            <i>
              Our skin care is supplied to you in eco packaging which is kept minimal and incorporates vegetable inks,
              biodegradable labels, recycled paper and comes in a recyclable glass jar, PP or PLA bottle. Our bottles
              come with a pump for re use as part of a refill purchase option, helping to reduce waste. Our self
              adhesive labels are made to be compostable and our printers participate in the Enviro Mark NZ Programme.
            </i>
            <p>
              The supplier of our skin formulations is energy and environmentally conscious, winning a regional business
              sustainability award in 2009. All of their 'green' initiatives come under the umbrella of carefully
              written policies reflecting a commitment to recycling,energy conservation, emission free transport, toxin
              free products and a sustainable supply of raw ingredients.
            </p>
            A lack of Eco Processes surrounding everyday logistical issues and the reliance on fossil fuels, represents
            an area for improvement. We look for such improvement to help raise our skin care range to a 2nd Generation
            Biospherically Correct product in the future.
            <p>
              SUMMARY: AT VISION PRODUCTS WE EMBRACE THE ETHOS OF REDUCE, REUSE,RECYCLE, USING MATERIALS FROM NATURAL
              RESOURCES AS PACKAGING AND LABELLING WHICH BIO-INTEGRATES BACK INTO THE LIVING ENVIRONMENT AS PART OF OUR
              'CRADLE TO CRADLE' PHILOSOPHY.
            </p>
          </div>
        </div>
      ),
      icon: '//cdn.shopify.com/s/files/1/0032/0492/t/3/assets/ico-eco.png?v=1686030779642900193',
    },
    {
      title: 'Trade Integrity',
      activeState: false,
      content: (
        <div
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'space-evenly',
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {' '}
            <img
              alt="Bamboo"
              src="//cdn.shopify.com/s/files/1/0032/0492/files/iStock_000000449501Small_small.jpg?100966"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000004419540Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000006854733Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/iStock_000012426789Small_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
            <img
              alt="Bamboo"
              src="https://cdn.shopify.com/s/files/1/0032/0492/files/delivery_grande.jpg?100697"
              style={{height: 150, margin: 10}}
            />
          </div>
          <div style={{padding: 15}}>
            <h1>Eco-Processes</h1>
            HOW WELL DO THE PROCESSES INHERENT IN THE MANUFACTURING, PACKAGING AND DISTRIBUTION OF THIS PRODUCT SUSTAIN
            NATURE?
            <h2>Biospherically Correct</h2>
            <i>
              Our skin care is supplied to you in eco packaging which is kept minimal and incorporates vegetable inks,
              biodegradable labels, recycled paper and comes in a recyclable glass jar, PP or PLA bottle. Our bottles
              come with a pump for re use as part of a refill purchase option, helping to reduce waste. Our self
              adhesive labels are made to be compostable and our printers participate in the Enviro Mark NZ Programme.
            </i>
            <p>
              The supplier of our skin formulations is energy and environmentally conscious, winning a regional business
              sustainability award in 2009. All of their 'green' initiatives come under the umbrella of carefully
              written policies reflecting a commitment to recycling,energy conservation, emission free transport, toxin
              free products and a sustainable supply of raw ingredients.
            </p>
            A lack of Eco Processes surrounding everyday logistical issues and the reliance on fossil fuels, represents
            an area for improvement. We look for such improvement to help raise our skin care range to a 2nd Generation
            Biospherically Correct product in the future.
            <p>
              SUMMARY: AT VISION PRODUCTS WE EMBRACE THE ETHOS OF REDUCE, REUSE,RECYCLE, USING MATERIALS FROM NATURAL
              RESOURCES AS PACKAGING AND LABELLING WHICH BIO-INTEGRATES BACK INTO THE LIVING ENVIRONMENT AS PART OF OUR
              'CRADLE TO CRADLE' PHILOSOPHY.
            </p>
          </div>
        </div>
      ),
      icon: '//cdn.shopify.com/s/files/1/0032/0492/t/3/assets/ico-trade.png?v=1548973488879919851',
    },
  ];
  const [tabs, setActiveTab] = useState(bioData);
  const changeActiveTab = (index) => {
    const arr = tabs.slice();
    arr.forEach((element) => (element.activeState = false));
    arr[index].activeState = true;
    setActiveTab(arr);
  };

  const body = (
    <div className={classes.paper}>
      <Grid container>
        <Grid item container xs={12} className={classes.modalTitleBar}>
          <Grid item xs={false} sm={3}></Grid>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.modalTitleBarText}>
              Biospherically Correct <span style={{fontSize: '0.8rem'}}>&nbsp; in harmony with our biosphere.</span>
            </Typography>
          </Grid>
          <Grid item sm={3} xs={12} style={{textAlign: 'right'}}>
            <Typography className={classes.ratingText}>
              Rating :&nbsp;
              <span
                style={{
                  borderRadius: 18,
                  border: '5 white solid',
                  backgroundColor: 'white',
                  width: 18,
                  height: 18,
                  textAlign: 'center',
                  color: '#707070',
                }}
              >
                &nbsp;1&nbsp;
              </span>
              &nbsp;2&nbsp; 3&nbsp;
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} item style={{backgroundColor: '#E6E6E6', paddingTop: 10}}>
          {tabs.map((element, index) => {
            return (
              <Grid
                onClick={() => {
                  changeActiveTab(index);
                }}
                item
                className={classes.tabContainer}
                xs={2}
              >
                <div
                  className={classes.tab}
                  style={{
                    backgroundColor: element.activeState ? 'white' : 'transparent',
                    border: element.activeState ? '5px white solid' : '5px transparent solid',
                  }}
                >
                  <img
                    src={element.icon}
                    alt="icons"
                    style={{width: 35, height: 35, display: index === 0 ? 'none' : 'block'}}
                  />
                  <span className={classes.tabText} style={{display: index === 0 && 'block'}}>
                    {element.title}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            height: '50vh',
            overflow: 'overlay',
          }}
        >
          {tabs.find((element) => element.activeState).content}
        </Grid>
      </Grid>
    </div>
  );
  return (
    <Modal open={open} onClose={close} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      {body}
    </Modal>
  );
}

export default withStyles(styles)(BioModal);
