import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {MuiThemeProvider, useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Grid, Typography, Box, Container} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import worldMap from '~/assets/images/layout/worldmap.png';
import auckMap from '~/assets/images/layout/Aucklandmap.png';

import aus from '~/assets/images/layout/au&nz.png';
import OuterContainer from '../OuterContainer';

const styles = (theme) => ({
  class1: {
    color: '#000',
    textAlign: 'center',
    marginTop:20,
  },
  tabsWrapper: {
    maxWidth: '95%',
    margin: '50px auto',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '2.3rem',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#9aa200',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      letterSpacing: '5px',
      lineHeight: '25px',
    },
  },
  appBarWrapper: {
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  worldMap: {
    width: '80%',
  },
  tabButton: {
    textTransform: 'capitalize',
  },

  customButton: {
    border: 'none',
  },
  header: {
    boxShadow: '0px 1px 2px -1px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 8px 0px rgba(0,0,0,0.08)',
  },
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function MapCard(props) {
  const {classes} = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <OuterContainer className={classes.class1}>
      <Grid item md={12} sm={12} xs={12} className={classes.rightColumn}>
        <Typography variant="h4" className={classes.title}>
          PROSPER - THE WORLD IS YOUR OYSTER
        </Typography>
        <Typography variant="body2">
          Join the higher consciousness revolution, and utilize the
          <b> Plato Franchise System</b> <br />
          to establish a meaningful livelihood.
        </Typography>
        <div className={classes.tabsWrapper}>
          <MuiThemeProvider theme={theme}>
            <AppBar position="static" color="default" className={classes.header}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{style: {background: '#f5f5f5'}}}
                variant="fullWidth"
              >
                <Tab label="World" {...a11yProps(0)} className={classes.tabButton} />
                <Tab label="Australia & New Zealand" {...a11yProps(1)} className={classes.tabButton} />
                <Tab label="Auckland" {...a11yProps(2)} className={classes.tabButton} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <img src={worldMap} alt="Map" className={classes.worldMap} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <img src={aus} alt="Map" className={classes.worldMap} />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <img src={auckMap} alt="Map" className={classes.worldMap} />
              </TabPanel>
            </SwipeableViews>
          </MuiThemeProvider>
        </div>
      </Grid>
    </OuterContainer>
  );
}

export default withStyles(styles)(MapCard);
