import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core';
import {Grid} from '@material-ui/core';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import OuterContainer from '~/components/ui/OuterContainer';
import ImageBg from '~/assets/images/page/healthGuides/banner_bg.jpg';
import {useLocation} from 'react-router';
import {PageData} from './pageData';

const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '6% 12%',
    background: 'rgba(27, 27, 27, 0.64)',
    [theme.breakpoints.down('sm')]: {
      padding: '2% 4%',
    },
    height: (props) => props.minHeight,
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  class6: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.20)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  },
});
const useStyles = makeStyles(styles);
const routes = [{title: 'Products and Services'}, {title: 'Health Protocols', to: 'immunesystem'}];

function HeroSection(props) {
  // const {pageData} = props;
  const location = useLocation();
  const [minHeight, setMinHeight] = useState(0);
  const divRef = useRef(null);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    if (divRef) {
      console.log(divRef.current.scrollHeight);
      setMinHeight(divRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    PageData.forEach((onePage) => {
      if (location && location.pathname.includes(onePage.path)) {
        setPageData(onePage);
      }
    });
  }, location);

  const classes = useStyles({minHeight});
  return (
    <div ref={divRef}>
      <Grid container>
        <Grid item sm={6} xs={12}>
          {pageData && <HeaderLeft pageData={pageData} />}
        </Grid>
        <Grid item sm={6} xs={12}>
          {pageData && <HeaderRight pageData={pageData} />}
        </Grid>
      </Grid>

      <div className={classes.shadow}></div>
    </div>
  );
}

export default HeroSection;
