import React, {useEffect, useCallback, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {checkout, removeFromCart, setBackground} from '~/store/actions';
import {withStyles} from '@material-ui/core';
import headerBackground from '~/assets/images/page/healthGuides/banner_bg.jpg';
import Cart from '~/components/layout/Header/Cart';
import AllHealthGuides from './AllHealthGuides';
import HealthProtocols from '~/components/ui/HealthProtocols';
import Disclaimer from './Disclaimer';

const styles = (theme) => ({
  class6: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.20)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

let minHeight = 0;

function GuidesDetailPage(props) {
  const {classes, pageData, setBackground} = props;
  const dispatch = useDispatch();
  const [cartOpen, setCartOpen] = useState(false);
  const cartArr = useSelector(({cart}) => cart.cart);
  const [showTitle, setShowTitle] = useState(true);
  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    const background = {
      background: `url(${headerBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
    };

    if (minHeight > 0) {
      background.height = minHeight + 'px';
      background.minHeight = minHeight + 'px';
    }
    setBackground(background);
    return () => {
      setBackground({
        background: `url(${headerBackground})`,
      });
    };
  }, [setBackground]);

  const setRef = useCallback((node) => {
    if (node) {
      minHeight = node.getBoundingClientRect().bottom + window.scrollY;
    }
  }, []);


  const handleClick = () => {
    window.scroll({top:0, left:0, behaviour: 'smooth'});
  }
  
  return (
    <div className={classes.root}>
      <div ref={setRef}>
         {/* <Header  pageData={pageData} setCartOpen={setCartOpen}/> */}
         {/* <HeaderLeft  pageData={pageData} setCartOpen={setCartOpen}/>
         <HeaderRight  pageData={pageData} setCartOpen={setCartOpen}/> */}

        {/* <OuterContainer
          style={{
            backgroundImage: `url(${ImageBg})`,
          }}
          className={classes.class6}
        >
          <Header  pageData={pageData} setCartOpen={setCartOpen}/>
        </OuterContainer> */}
      </div>

      <AllHealthGuides 
        handleClick={handleClick}
        showTitle={() => setShowTitle(false)}
        showButtons={() => setShowButtons(false)}
      />
      <HealthProtocols />
      <Disclaimer />
    </div>
  );
}

export default connect(null, {setBackground})(withStyles(styles)(GuidesDetailPage));
