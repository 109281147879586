import {CART, EMPTYCART} from '../action-types';

const INITIAL_STATE = {
  cart: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CART:
      return {...state, cart: action.payload};
    case EMPTYCART:
      return INITIAL_STATE;
    default:
      return state;
  }
};
