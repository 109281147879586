import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = (props) => {
  const {routes, checkoutId} = props;
  return (
    <div style={{position: 'relative'}}>
      <Header
        checkoutId={checkoutId}
        topMenuRoutes={routes.filter((route) => route.head)}
        sideMenuRoutes={routes.filter((route) => route.side)}
      />
      {props.children}
      <Footer routes={[...routes.filter((route) => route.footer),{name: 'Services',
        path: '/shop-by-product-and-services',}]} />
    </div>
  );
};

export default Layout;
