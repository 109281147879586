import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import NavLeft from '~/assets/images/page/healthProtocols/immuneSystem/ic_guides_nav_left.png';
import NavRight from '~/assets/images/page/healthProtocols/immuneSystem/ic_guides_nav_right.png';
import { productsData } from '~/pages/healthProtocols/Sections/RelevantProductsData';
import CardItem from '~/pages/lifeIngredients/Sections/CardItem';
import { useHistory } from 'react-router';
import { getPath } from '~/static/routes';

const useStyles = makeStyles((theme) => ({
    btnRight: {
        width: '20px',
        cursor: 'pointer',
    },
    btnLeft: {
        width: '20px',
        cursor: 'pointer',
    },  
    cardRow: {
        // border: '1px red solid',
        flex: '1',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        margin: '0 30px',
        // padding: '10px', // when padding given no carousel scrolling working. might be the values inside navItem is changing
        position: 'relative', // for carousel scrolling this should be relative
    },
    cardRowItem: {
        // border: '1px blue solid',
        border: '1px white solid', // if this 1px is removed then one by one side scroll is not working 
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: '10px',
        width: 'calc(25% - 10px)',
        whiteSpace: 'break-spaces', // only with this property the inside contents are able to bring to wrap
        [theme.breakpoints.down('md')]: {
          width: 'calc(50% - 10px)',
        },
        [theme.breakpoints.down('xs')]: {
          width: 'calc(100% - 0px)',
        }
    },
    cardRowBox: {
    marginTop: '15px',
    marginBottom: '20px',
    },
    prodImage: {
        width: '80%',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: '0.32px',
      textAlign: 'center',
      color: '#9aa200',
      textTransform: 'uppercase',
      margin: '20px',
    },
    seeAll: {
      fontSize: '1.0rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: '0.32px',
      textAlign: 'right',
      color: '#9aa200',
      marginRight: '150px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        marginRight: '100px',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: '50px',
      },
    }
}));

const createRandomObj = (c) => {
    const times = Math.floor(Math.random() * 4 + 3);
    const result = [];
  
    for (let i = 0; i < times; i++) {
      result.push({
        id: c + i,
        title:
          c +
          Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5),
        desc: 'The Ultimate Anti-Oxidant',
        content:
          'Adenosine triphosphate (ATP) is chemically classified as a nucleoside triphosphate. Its primary biological use is as a coenzyme. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.',
      });
    }
  
    return result;
  };
  
  const createBaseList = () => {
    const Data = ['0-9', ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')];
    const result = [];
    Data.forEach((d) => {
      if (d !== '0-9') {
        result.push(...createRandomObj(d));
      }
    });
    return result;
  };
  
const DataList = createBaseList().map((item, index) => ({...item, id: index, itemId: item.id}));

const ReleventIngredients = (props) => {
    
    const classes = useStyles();
    const prodDataList = productsData.filter(item => item);
    const scrollEle = useRef(null);
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState('');
    
    const navItem = (isLeft) => {
        const parent = scrollEle.current;
        let nextIndex = -1;
    
        [...parent.children].forEach((child, index) => {
          if (Math.abs(parent.scrollLeft - child.offsetLeft) < 5) {
            if (isLeft && nextIndex < 0) {
              if (index > 0) {
                nextIndex = index - 1;
              } else {
                nextIndex = parent.children.length - 1;
              }
            }
          } else if (Math.abs(parent.scrollLeft + parent.clientWidth - child.offsetLeft - child.offsetWidth) < 5) {
            if (!isLeft && nextIndex < 0) {
              if (index >= parent.children.length - 1) {
                nextIndex = 0;
              } else {
                nextIndex = index + 1;
              }
            }
          }
        });
    
        if (nextIndex >= 0) {
          parent.children[nextIndex].scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
      };

    const onClickSeeAll = () => {
      history.push(getPath('Food ingredients'));
    }

    const handleClickRedirectIngredient = (item) => {
      history.push(getPath('Food ingredients') + `/${item.itemId}` + `@${item.id}`);
    }

    return (
       <div>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.title}>Relevant Ingredients</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.seeAll}><span onClick={() => onClickSeeAll()}>{'>> See all'}</span></Typography>
              </Grid>
            </Grid>
           <Grid container className={classes.cardRowBox} alignItems="center">
            <img
              className={classes.btnLeft}
              src={NavLeft}
              alt="NavLeft"
              onClick={() => {
                navItem(true);
              }}
            />
            <div className={classes.cardRow} ref={scrollEle}>
              {DataList.map((item, index) => (
                    <div
                        key={item.id}
                        className={classes.cardRowItem}
                        // onClick={() => {
                        //   setSelectItem(item);
                        // }}
                        >
                        <CardItem 
                            cardData={item}
                            onClickExpand={() => {}}
                            handleClickRedirectIngredient={() => handleClickRedirectIngredient(item)}
                        />
                    </div>
                ))}
            </div>
            <img
              className={classes.btnRight}
              src={NavRight}
              alt="NavRight"
              onClick={() => {
                navItem(false);
              }}
            />
          </Grid>
       </div>
    );
}

export default ReleventIngredients;