import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Box} from '@material-ui/core';
import Card from '~/components/ui/Card';


const styles = (theme) => ({
  root: {
    color: 'white',
  },
  subtitle: {
    color: 'rgba(255, 255, 255, 0.49)',
    fontSize: '16px',
  },
  title: {
    margin: '10px 0 15px 0',
    fontWeight: 'bold',
  },
  card: {
    margin: '20px 60px 0 20px',
    border: '1px solid white',
    [theme.breakpoints.down('sm')]: {
      margin: '20px',
    },
  },
  guidesubtitle: {fontSize: '20px'},

  cardContent: {
    height: '100%',
    paddingTop: '60%',
    paddingBottom: '15px',
  },
  
  cardQuote: {
    background: 'rgba(255, 255, 255, 0.7)',
    width: '60%',
    padding: '20px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  cardDesc: {
    fontWeight: 'bold',
  },

});

function HeaderLeft(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.root}>
      <Box className={classes.card}>
        <Card imageSrc={pageData.cardBg} ratio="128%">
          <Grid
            className={classes.cardContent}
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Typography variant="h5" className={classes.guidesubtitle}>
              {pageData.subTitle}
            </Typography>
            <Box className={classes.cardQuote}>
              <Typography variant="body2">{pageData.cardDesc}</Typography>
             </Box>
              <Typography variant="subtitle1">
                  {pageData.title}
              </Typography>
          </Grid>
        </Card>
      </Box>
    </div>
  );
}

export default withStyles(styles)(HeaderLeft);
