const {mealPlanNameValidation} = require('../utils/inputValidation');

const createNewMealPlan = [
  {
    inputFieldProps: {
      label: 'Meal plan name',
      key: 'name',
      type: 'text',
    },
    initialValue: '',
    gridItemProps: {
      xs: 11,
      sm: 10,
    },
    validationSchema: mealPlanNameValidation('Meal plan name'),
  },
  {
    inputFieldProps: {
      label: 'Day',
      key: 'day',
      type: 'select',
    },
    options: [
      {
        label: 'Monday',
        value: 1,
      },
      {
        label: 'Tuesday',
        value: 2,
      },
      {
        label: 'Wednesday',
        value: 3,
      },
      {
        label: 'Thursday',
        value: 4,
      },
      {
        label: 'Friday',
        value: 5,
      },
      {
        label: 'Saturday',
        value: 6,
      },
      {
        label: 'Sunday',
        value: 7,
      },
    ],
    initialValue: '',
    gridItemProps: {
      xs: 11,
      sm: 10,
    },
  },
  {
    inputFieldProps: {
      label: 'Create',
      type: 'submit',
      fullWidth: true,
      variant: 'contained',
    },
    gridItemProps: {
      xs: 6,
      sm: 5,
    },
  },
];

const selectExistingMealPlan = [
  {
    inputFieldProps: {
      label: 'Meal plan',
      key: 'selectedMealPlan',
      type: 'select',
    },
    options: [],
    initialValue: '',
    gridItemProps: {
      xs: 11,
      sm: 10,
    },
  },
  {
    inputFieldProps: {
      label: 'Submit',
      type: 'submit',
      fullWidth: true,
      variant: 'contained',
    },
    gridItemProps: {
      xs: 6,
      sm: 5,
    },
  },
];

const createdMealPlan = (onClick) => [
  ...this.createNewMealPlan,
  {
    inputFieldProps: {
      label: 'Switch Meal Plan',
      type: 'button',
      fullWidth: true,
      variant: 'contained',
      flex: 1,
      onClick,
    },
    gridItemProps: {
      xs: 6,
      sm: 5,
      container: true,
      alignItems: 'flex-end',
    },
  },
];

export {createNewMealPlan, selectExistingMealPlan, createdMealPlan};
