import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Container, Typography} from '@material-ui/core';
import oppImage from '~/assets/images/page/prosper/oppImage.png';
import teamEffort from '~/assets/images/page/prosper/team.png';

import CommonSection from './CommonSection';
import OuterContainer from '../../../components/ui/OuterContainer';

const styles = (theme) => ({
  prosperWrapper: {
    background: 'white',
    paddingTop: '60px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  countryInfoWrapper: {
    backgroundColor: '#ffffff',
    margin: '20px 0px 0px 0px',
  },
  countryInfo: {
    fontSize: '16px',
    color: '#434343',
    padding: '10px 20px 20px 20px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
  },
  countries: {
    fontWeight: '400',
    margin: '10px 0px 5px 0px',
  },
  subTitle: {
    textAlign: 'center',
    color: '#9aa300',
    fontSize: '16px',
    paddingTop: '10px',
  },
});

function TopSection(props) {
  const {classes} = props;
  return (
    <OuterContainer className={classes.prosperWrapper}>
      <CommonSection
        imageWidth={25}
        reverse={false}
        title="THE OPPORTUNITY"
        imageSrc={oppImage}
        info={
          <>
            After years of research, and development the Sanctuary Store opportunity is ready to serve store operators
            and customers alike. The first trillion dollar industry in history is upon us; ‘Increasing Health Span’. As
            the global population suffers from the results of a systemic human health miss-management crisis. A valuable
            need exists to Enrich, Empower and Inspire people back to their full health potential. There could not be
            any more rewarding livelihood to dedicate yourself to.
            <div className={classes.countryInfoWrapper}>
              <Typography variant="h6" className={classes.subTitle}>
                New Zealand & Australia
              </Typography>
              <Typography component={'div'} variant="body2" className={classes.countryInfo}>
                We are looking for skilled 'Conscious Creatures' who are interested in operating a Sanctuary Store in
                your local area. The first opportunities exist in these territories:
                <Box textAlign="center" className={classes.countries}>
                  <span>
                    <u>A. Auckland, Hamilton and Tauranga</u>
                  </span>
                  <br />
                  <span>
                    <u>B. Sydney, The Gold Coast, Melbourne</u>
                  </span>
                </Box>
                We intend to only franchise several Sanctuary Stores per city, limiting the number of stores to a
                density of approx. one per 100,000 people, to ensure your greatest success.
              </Typography>
            </div>
          </>
        }
      ></CommonSection>
      <CommonSection
        imageWidth={25}
        title="IT'S TEAM EFFORT"
        imageSrc={teamEffort}
        info="Participate in our Plato Franchise opportunity and prosper while being instrumental in helping
                    create “A Successful Planet”. Following Plato’s philosophy; that a person’s earnings should not be
                    more than 5 times that of another’s – in order to help establish more equality in the world, we have
                    created a franchise structure which prohibits an individual accumulating multiple stores. In fact
                    this system requires you to be a part of a team of equal contributors and shareholders who together
                    own and operate the store. In this way we believe our Sanctuaries are the way to a more financially
                    balanced and sustainable future with more ethical wealth distribution. If this resonates with you-
                    keep reading."
      />
    </OuterContainer>
  );
}

export default withStyles(styles)(TopSection);
