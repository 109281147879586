import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import exerciseImage from '~/assets/images/page/3dscanning/exercise.png';
import reportImage from '~/assets/images/page/3dscanning/report.png';

const styles = (theme) => ({
  root: {
    zIndex: '2',
    padding: '50px 0px',
  },
  title: {
    opacity: '0.8',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: '500',
    fontstretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#8c8c8c',
  },
  leftContent: {
    textAlign: 'center',
  },
  imageWrapper: {
    textAlign: 'center',
  },
  reportingImage: {
    maxWidth: '500px',
    textAlign: 'center',
    margin: '0px auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  exerciseImage: {
    margin: '0px auto',
    maxWidth: '500px',
    zIndex: '3',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

function ExerciseSection(props) {
  const {classes} = props;

  return (
    <div className={classes.exerciseWrapper}>
      <Container>
        <div className={classes.root}>
          <Grid container>
            <Grid item md={6} className={classes.leftContent}>
              <Typography variant="h5" className={classes.title}>
                Accurate & Trusted Reporting
              </Typography>
              <img src={reportImage} className={classes.reportingImage} alt="Our Scanner" />
            </Grid>
            <Grid item md={6} className={classes.imageWrapper}>
              <img src={exerciseImage} className={classes.exerciseImage} alt="Our Scanner" />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(ExerciseSection);
