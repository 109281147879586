import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '../ui/OuterContainer';
import People from '../assets/people.png';
import clsx from 'clsx';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { IconButton } from '@material-ui/core';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${People})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5)',
    padding: '5em 1em',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    color: 'white',
    fontFamily: 'Montserrat',
    maxWidth: '70%',
    [theme.breakpoints.down(450)]: {
      maxWidth: '98%',
    },
    '&>h2': {
      fontSize: '2rem',
      fontWeight: 500,
      marginBottom: '0em',
    },
    '&>p': {
      fontSize: '1rem',
    },
  },
  class2: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  class3: {
    border: '1px white solid',
    fontSize: '0.8rem',
    padding: '0.3em 0.5em',
    borderRadius: '1em',
    marginLeft: '1em',
    marginTop: '1em',
    cursor: 'pointer',
  },
  class4: {
    fontStyle: 'italic',
    fontSize: '1.4rem',
  },
  class5: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '3em',
    marginTop: '2em',
  },
  socialIcons: {
    width: '1rem',
    height: '1rem',
    color: '#9aa200',
    marginLeft: '1rem',
    float: 'right',
  },
  class6: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>p': {
      fontWeight: 600,
      fontSize: '1.2rem',
      color: '#9aa300',
    },
  },
  class7: {
    color: '#12041a',
    fontSize: '1rem',
    textAlign: 'justify',
  },
  class8: {
    fontSize: '2rem',
    justifyContent: 'center',
    '&>p': {
      padding: '1em',
      margin: 0,
    },
  },
});

const Option = withStyles(styles)((props) => {
  const {classes} = props;
  return (
    <div
      onClick={props.onClick}
      className={classes.class3}
      style={props.selected ? {background: 'white', color: 'black'} : {}}
    >
      {props.title}
    </div>
  );
});

const PeopleSection = (props) => {
  const {classes, peopleData} = props;
  const [selected, setSelected] = useState(0);
  return (
    <OuterContainer className={classes.background}>
      <div className={clsx(classes.class0, classes.class1)}>
        <h2>GREATOR PEOPLE FOR BETTER PLANET</h2>
        <p>Join our growing number of Visionary Sanctuary Store customers</p>
        <div className={clsx(classes.class0, classes.class2)}>
          {peopleData.map((element, index) => {
            return (
              <Option
                {...element}
                selected={selected === index}
                onClick={() => {
                  setSelected(index);
                }}
              />
            );
          })}
        </div>
        <div className={clsx(classes.class0, classes.class5)}>
          <div className={clsx(classes.class0, classes.class6)}>
            <p>{peopleData[selected].name}</p>
            <div className={clsx(classes.class0)}>
              {/* <FacebookIcon className={classes.socialIcons} />
              <TwitterIcon className={classes.socialIcons} /> */}
               <IconButton onClick={()=>{
                window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
              }}>
              <InstagramIcon className={classes.socialIcons} />
              </IconButton>
            </div>
          </div>
          <p className={classes.class7}>{peopleData[selected].text}</p>
        </div>
        <div className={clsx(classes.class0, classes.class8)}>
          <p
            style={{cursor: 'pointer'}}
            onClick={() => {
              if (selected > 0) {
                setSelected(selected - 1);
              }
            }}
          >
            &lt;
          </p>
          <p
            style={{cursor: 'pointer'}}
            onClick={() => {
              if (selected < peopleData.length - 1) {
                setSelected(selected + 1);
              }
            }}
          >
            &gt;
          </p>
        </div>
        <p className={classes.class4}>Increasing Health Span
</p>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(PeopleSection);

// import React, {useState} from 'react';

// import {makeStyles} from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
// import People from '~/assets/images/page/home/people.png';
// import PeopleColumn from '~/assets/images/page/home/peoplecolumn.png';
// import PeopleMd from '~/assets/images/page/home/peoplemd.png';
// import peopleData from './peopleData';
// import SocialIcons from '~/components/ui/SocialIcons';
// import Grow from '@material-ui/core/Grow';
// import useWindowWidth from '~/hooks/useWindowWidth';
// const styles = (theme) => ({
//   root: {
//     width: (props) => props.width * 0.988,
//     height: (props) => (props.width * 5.3) / 12,
//     backgroundImage: `url(${People})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     textAlign: 'center',

//     [theme.breakpoints.down('sm')]: {
//       backgroundImage: `url(${PeopleMd})`,
//       width: (props) => props.width * 0.988,
//       height: (props) => (props.width * 5) / 8,
//     },
//     [theme.breakpoints.down('xs')]: {
//       backgroundImage: `url(${PeopleColumn})`,
//       width: (props) => props.width,
//       height: (props) => (props.width * 1491) / 851,
//     },
//   },
//   shadow: {
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0,0,0,.5)',
//     padding: '50px 0',
//   },
//   arrowButton: {
//     borderRadius: '28px',
//     padding: '2px 20px',
//     backgroundColor: 'rgba(0, 0, 0, 0)',
//     fontFamily: 'Lato',
//     fontSize: '22px',
//     color: '#fff',
//     margin: 6,
//     boxShadow: 'none',
//     '&:hover': {
//       backgroundColor: 'rgba(0, 0, 0, 0)',
//       boxShadow: 'none',
//       fontWeight: '900',
//     },
//   },
//   oneButton: {
//     borderRadius: '28px',
//     boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
//     border: 'solid 1px #fff',
//     padding: '2px 20px',
//     backgroundColor: 'rgba(0, 0, 0, 0.01)',
//     fontFamily: 'Lato',
//     fontSize: '14px',
//     color: '#fff',
//     margin: 6,
//     [theme.breakpoints.down('xs')]: {
//       fontSize: '9px',
//       margin: 3,
//     },
//   },
//   activeButton: {
//     borderRadius: '28px',
//     boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
//     border: 'solid 1px #fff',
//     padding: '2px 20px',
//     backgroundColor: '#fff',
//     fontFamily: 'Lato',
//     fontSize: '14px',
//     color: '#000',
//     margin: 6,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '9px',
//     },
//   },
//   btnsContainer: {
//     padding: '10px 25%',
//     [theme.breakpoints.down('sm')]: {
//       padding: '5px 2%',
//     },
//   },
//   infoContainer: {
//     borderRadius: '4px',
//     margin: '10px 25%',
//     backgroundColor: '#fff',
//     padding: '40px 80px',
//     [theme.breakpoints.down('sm')]: {
//       margin: '10px 5%',
//       padding: '10px 20px',
//     },
//   },
//   title: {
//     fontFamily: 'Montserrat',
//     fontSize: 40,
//     fontWeight: 500,
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: 0.98,
//     letterSpacing: 'normal',
//     color: '#ffffff',
//     padding: 20,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 23,
//     },
//   },
//   text: {
//     fontSize: 20,
//     [theme.breakpoints.down('sm')]: {
//       fontSize: 16,
//     },
//   },
//   name: {
//     fontFamily: 'Montserrat',
//     fontSize: '16px',
//     fontWeight: '500',
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     letterSpacing: 'normal',
//     color: '#9aa200',
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '14px',
//       lineHeight: '14px',
//     },
//   },
//   discoverContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   info: {
//     marginTop: 20,
//     fontFamily: 'Lato',
//     fontWeight: '300',
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: '1.33',
//     letterSpacing: '0.72px',
//     textAlign: 'left',
//     color: '#434343',
//     [theme.breakpoints.down('sm')]: {
//       marginTop: 10,
//     },
//   },
// });

// const useStyles = makeStyles(styles);

// export default function PeopleSection(props) {
//   const width = useWindowWidth();
//   const classes = useStyles({width});

//   const [show, setShow] = useState(true);
//   const [active, setActive] = useState(0);
//   const activeInfo = (index) => {
//     if (show) {
//       setShow(false);
//       setTimeout(() => {
//         if (index < 0) {
//           index = peopleData.length - 1;
//         }
//         if (index > peopleData.length - 1) {
//           index = 0;
//         }
//         setActive(index);
//         setShow(true);
//       }, 400);
//     }
//   };
//   return (
//     <div className={classes.root}>
//       <div className={classes.shadow}>
//         <Typography className={classes.title}>GREATER PEOPLE FOR BETTER PLANET</Typography>
//         <Typography className={classes.title + ' ' + classes.text}>
//           Join our growing number of Visionary Sanctuary Store customers
//         </Typography>
//         <div className={classes.btnsContainer}>
//           {peopleData.map((item, index) => (
//             <Button
//               variant="contained"
//               key={index}
//               onClick={() => {
//                 activeInfo(index);
//               }}
//               className={active === index ? classes.activeButton : classes.oneButton}
//             >
//               {item.title}
//             </Button>
//           ))}
//         </div>
//         <Grow in={show} timeout={400}>
//           <div className={classes.infoContainer}>
//             <Grid container className={classes.discoverContainer}>
//               <Typography variant="h4" className={classes.name}>
//                 {peopleData[active].name}
//               </Typography>
//               <SocialIcons />
//             </Grid>
//             <Typography variant="body2" className={classes.info}>
//               {peopleData[active].text}
//             </Typography>
//           </div>
//         </Grow>
//         <div>
//           <Button
//             variant="contained"
//             onClick={() => {
//               activeInfo(active - 1);
//             }}
//             className={classes.arrowButton}
//           >
//             {'<'}
//           </Button>
//           <Button
//             variant="contained"
//             onClick={() => {
//               activeInfo(active + 1);
//             }}
//             className={classes.arrowButton}
//           >
//             {'>'}
//           </Button>
//         </div>
//         <Typography className={classes.title + ' ' + classes.text}>Discover your Magnificence</Typography>
//       </div>
//     </div>
//   );
// }
