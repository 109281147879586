import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography, Button, Container, Divider} from '@material-ui/core/';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useCheckoutId} from '~/hooks/uselocalForage';
import {useAddProductToCart} from 'shared/react-query/hooks';

const styles = (theme) => ({
  root: {
    color: '#343434',
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mainTitle: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  atcBtn: {
    margin: '8px ',
    width: '160px',
    color: 'white',
    backgroundColor: '#9aa200',
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    '&:hover': {
      color: '#9aa200',
    },
    [theme.breakpoints.down('xs')]: {
      height: '32px',
    },
  },
  subtitle: {
    color: '#8c8c8c',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    cursor: 'pointer',
    margin: '5px',
  },
  greenBox: {
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    height: '100%',
  },

  itemName: {
    color: '#595959',
    fontWeight: '500',
  },
  itemData: {
    color: '#595959',
    fontWeight: '300',
  },
  desc: {
    color: '#8c8c8c',
    fontWeight: '300',
  },
});

function NutritionalData(props) {
  const {classes} = props;
  const addItemToCartMutation = useAddProductToCart();
  const {checkoutId} = useCheckoutId();
  return (
    <>
      <Container maxWidth="lg" className={classes.root}>
        <Box display="flex" justifyContent="space-between" alignItems="center" m={2} className={classes.header}>
          <Box
            display="flex"
            alignItems="center"
            color="#9aa300"
            style={{cursor: 'pointer'}}
            onClick={() => {
              window.history.back();
            }}
          >
            <ChevronLeftIcon />
            <Typography>Back To Products</Typography>
          </Box>
          <Box>
            <Typography className={classes.mainTitle}>Nutritional Data</Typography>
          </Box>
          <Box>
            <Button
              className={classes.atcBtn}
              onClick={() => {
                addItemToCartMutation.mutate({
                  cartId: checkoutId,
                  lineItems: {merchandiseId: props.data?.productByHandle?.variants?.edges?.[0]?.node?.id, quantity: 1},
                });
              }}
            >
              Add to cart
            </Button>
          </Box>
        </Box>
        <Grid container spacing={1} display="flex" alignItems="stretch">
          <Grid item md={4} sm={6} xs={12}>
            <Box className={classes.greenBox}>
              <div ref={props.nutritionalData}></div>
              <Box textAlign="center">
                <Typography className={classes.subtitle}>Nutritional Conditions</Typography>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="column" p={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Brix levels:</Typography>
                  <Typography className={classes.itemData}>5</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Cooking method:</Typography>
                  <Typography className={classes.itemData}>5</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Permitted max temp:</Typography>
                  <Typography className={classes.itemData}>5</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Organic content:</Typography>
                  <Typography className={classes.itemData}>5</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Date harvest:</Typography>
                  <Typography className={classes.itemData}>5</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <Box className={classes.greenBox}>
              <Box textAlign="center">
                <Typography className={classes.subtitle}>Food Science</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Typography className={classes.desc}>{props.data?.productByHandle?.food_science?.value}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withStyles(styles)(NutritionalData);
