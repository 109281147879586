import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import OuterContainer from '~/components/ui/OuterContainer';

const styles = (theme) => ({
  backgroundColor: {
    backgroundColor: '#F9F9EF',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    fontFamily: 'Montserrat',
    '&>h1, p': {
      flex: 1,
      padding: '1rem',
      color: '#9aa200',
    },
    '&>h1': {
      textAlign: 'center',
    },
    '&>p': {
      flex: 1,
      fontSize: '0.7rem',
      color: 'rgba(38, 38, 38, 0.8)',
    },
    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
    },
  },
});

const Section2 = (props) => {
  const {classes} = props;
  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1)}>
        <h1>Biospherically Correct Shopping Experience</h1>
        <p>
        Our Sanctuary Stores are designed to operate with eco processes to aid our planet, and to be run by skilled staff focused on enriching you, our customers, by offering a combination of advanced well-being services and supplies to increase your Health Span. See Prosper to view the Plato Franchise opportunity.
        </p>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(Section2);
