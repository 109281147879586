import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import {Grid, IconButton, Typography} from '@material-ui/core';
const styles = (theme) => ({
  class1: {
    color: 'black',
    fontSize: '1rem',
  },
  class2: {
    color: '#9aa200',
    fontSize: '1rem',
  },
  class3: {
    fontWeight: 600,
  },
  socialIcons: {
    width: '16px',
    height: '16px',
    color: '#9aa200',
  },
});

const Section3 = (props) => {
  const {classes} = props;

  return (
    <Grid container item style={{margin: 0, width: '100%'}} spacing={2} xs={12} sm={4} md={5}>
      <Grid item xs={9}>
        <Typography className={clsx(classes.class3, classes.class1)}>SANCTUARY STORE - VISION FOR HUMANITY</Typography>
      </Grid>
      {/* <Grid container item style={{margin: 0, width: '100%'}} xs={3}> */}
      {/* <Grid item xs={1}>
        <FacebookIcon className={classes.socialIcons} />
      </Grid> */}
      {/* <Grid item xs={1}>
        <TwitterIcon className={classes.socialIcons} />
      </Grid> */}
      <Grid item xs={1}>
      <IconButton onClick={()=>{
                window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
              }}>
        <InstagramIcon className={classes.socialIcons} />
        </IconButton>
      </Grid>
      {/* </Grid> */}
      <Grid item xs={12}>
        <Typography className={classes.class2}>Click here to integrate your Visionaire accounts</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.class1}>
          (09) 353-7890
          <br />
          Level 3, 2 St Martins Lane,
          <br />
          Grafton, Auckland 1010
          <br />
          New Zealand
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Section3);
