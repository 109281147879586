import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';
import SetBackgroundComponent from '~/components/ui/SetBackgroundComponent';
import {ProductCard} from '~/components/ui';
import GiftpacksBg from '~/assets/images/page/giftpacks/gfbg.jpg';
import mainImg from '~/assets/images/page/lifeforcefood/detail/blackricesushi.png';

const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '100px 12%',
    background: 'rgba(27,27,27,0.64)',

    [theme.breakpoints.down('sm')]: {
      padding: '80px 4%',
    },
  },
  prdCard: {
    width: '400px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    margin: '4% 2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },

  desc: {
    fontFamily: 'Lato',
    color: 'rgba(255,255,255,0.8)',
    margin: '4% 2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
});

const data = {
  id: 1,
  name: 'Black Rice Sushi',
  description:
    'A naturally chewy texture with beautiful favours of Soy sauce, Carrots and Cabbage, very filling and delicious. ',
  starReview:
    'I love this black rice sushi, you can tell it’s healthy but its tastes amazing!- love the dressing they use to make it extra delicious ',
  price: 13.99,
  image: [{id: 1, name: 'food', price: 8.99, imgsrc: mainImg}],
};

const routes = [
  {title: 'Products and Services', to: '/productandservice'},
  {title: 'Gift Packs', to: '/giftpacks'},
];
function Banner(props) {
  const {classes} = props;
  return (
    <div>
      <div className={classes.content}>
        {/* <SetBackgroundComponent headerBackground={GiftpacksBg} /> */}
        <Grid container justify="center" spacing={4}>
          <Grid item md={3} xs={10} sm={6}>
            <ProductCard data={data} />
          </Grid>
          <Grid item md={9} xs={12} sm={6}>
            <Typography className={classes.title}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
            <Typography className={classes.desc}>
              Phasellus imperdiet augue ac risus hendrerit, at ultricies enim fermentum. Nunc nec fringilla dolor. Duis
              id massa ut nisi commodo pulvinar. Integer turpis orci, eleifend quis gravida ut, imperdiet quis diam.
              Curabitur feugiat, neque quis mollis suscipit, mi nulla lobortis dui, et ultricies lacus augue id nunc.
              Mauris cursus tortor at eleifend ornare. Mauris feugiat pellentesque nibh vel mollis. Duis tincidunt lorem
              at turpis scelerisque, a pellentesque est scelerisque. Integer ac ligula in mauris lobortis finibus sed
              vitae nisi. Pellentesque vitae auctor mauris. Nunc hendrerit a diam at ultricies. Nam ex eros, dapibus
              egestas metus ultrices, ullamcorper euismod libero. Duis ac mi tempus, fringilla eros et, pulvinar lorem.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(Banner);
