import React, {useState, useEffect, useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Container, Typography} from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SetBackgroundComponent from '~/components/ui/SetBackgroundComponent';
import headerBackground from '~/assets/images/page/lifeforcefood/detail/detailbg.png';
import BreadcrumbsComponent from '~/components/ui/Breadcrumbs';
import {withRouter} from 'react-router-dom';
import {products} from '../lifeforcefood/productMockData';
import NutritionalData from './Sections/NutritionalData';
import NutritionalSummary from './Sections/NutritionalSummary';
import GlobalImpact from './Sections/GlobalImpact';
import OtherProducts from '../Detail/Sections/OtherProducts';
import ConsciousnessData from './Sections/ConsciousnessData';
import { useFetchProduct } from 'shared/react-query/hooks';
import Status from '~/components/ui/Status';
import { statusObj } from '~/utils/commonFunc';

const styles = (theme) => ({
  root: {
    color: '#343434',
    // paddingTop: '100px',
    background: 'rgba(24,24,24,0.5)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
    },
  },
  foodChart: {
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0px 20px 0px',
    },
  },
  foodChartTable: {
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
    },
  },
  mainContent: {
    backgroundColor: '#fff',
  },
  imageBox: {
    marginLeft: '25px',
  },
  prdImage: {
    border: '4px solid white',
    width: '120px',
    height: '120px',
    marginTop: '-50px',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: '80px',
      marginTop: '-30px',
    },
  },
  viewLink: {
    color: '#9aa200',
    font: 'Lato',
    fontSize: '1rem',
    textDecorationColor: '#9aa200',
  },
  
});
const routes = [
  {title: 'Products and Services', to: '/shopbyproduct'},
  {title: 'Life Force Food', to: '/productandservice/lifeforcefood'},
];

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);

function NutritionalDetail(props) {
  const {classes, location, history} = props;
  const [itemData, setItemData] = useState(null);
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);
  const myRef5 = useRef(null);
  const myRef6 = useRef(null);
  const myRef7 = useRef(null);
  const getSecondLastSegment = (pathname) => {
    const pathSegments = pathname.split('/');
    return pathSegments[pathSegments.length - 2];
  };

  const {data: productData, isLoading} = useFetchProduct({
    handle: getSecondLastSegment(location.pathname),
  });
  useEffect(() => {
    const id = 1;
    const item = products.find((item) => item.id === id);
    const hash = location.hash;

    switch (hash) {
      case '#NutritionalSummaryStatus':
        scrollToRef(myRef);
        break;
      case '#NutritionalAnalysis':
        scrollToRef(myRef2);
        break;
      case '#Micronutrients':
        scrollToRef(myRef3);
        break;
      case '#CalorieComposition':
        scrollToRef(myRef4);
        break;
      case '#SpecialFunctionality':
        scrollToRef(myRef5);
        break;
      case '#DeliverySI':
        scrollToRef(myRef6);
        break;
      case '#NutritionalData':
        scrollToRef(myRef7);
        break;
      // code block
    }
    if (item) {
      setItemData(products[0]);
    } else {
      history.push('/productandservice/lifeforcefood');
    }
  }, [history, location.pathname]);

  return (
    <Status statusObj={isLoading ? statusObj('loading') : statusObj('done')}>

      <Box className={classes.root}>
        {/* <Box pb={4}> */}
          {/* <SetBackgroundComponent headerBackground={headerBackground} className={classes.headerbg} /> */}
          {/* <Container maxWidth="lg"> */}
          {/* <BreadcrumbsComponent text={itemData && itemData.name} routes={routes} /> */}
          {/* </Container> */}
        {/* </Box> */}
        <Box className={classes.mainContent}>
          <Container maxWidth="lg">
            <Box className={classes.imageBox} display="flex" mb={2}>
              <img className={classes.prdImage} src={productData?.productByHandle?.variants?.edges?.[0]?.node?.image?.originalSrc} alt="product image" />
              <Box m={2}>
                {' '}
                {/* <Link to={null} className={classes.viewLink}> */}
                <Typography>View Today's Meals</Typography>
                {/* </Link> */}
                {/* <Link to={null} className={classes.viewLink}> */}
                <Typography>View Week's Meals</Typography>
                {/* </Link> */}
              </Box>
            </Box>
          </Container>
          <NutritionalData data={productData} nutritionalData={myRef7}/>
          <Box mt="30px">
            <div ref={myRef}></div>
            <NutritionalSummary analysisRef={myRef2} data={itemData} micronutrientsRef={myRef3} calorieCompositionRef={myRef4} specialFuncRef={myRef5} deliverySiRef={myRef6}/>
          </Box>
          <Box mt="30px">
            <GlobalImpact />
          </Box>
          <Container maxWidth="lg">
            <Box mt="30px" bgcolor="#F6F6F6" className={classes.foodChart} color="#8C8C8C">
              <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold" textAlign="center">
                CONSCIOUSNESS FOOD CHART
              </Box>
              <Box fontSize="body2.fontSize" mt="10px">
                <ConsciousnessData />
              </Box>
            </Box>
            <Box mt="30px">
              <OtherProducts />
            </Box>
          </Container>
        </Box>
      </Box>
    </Status>
  );
}

export default withRouter(withStyles(styles)(NutritionalDetail));
