import React, {useState} from 'react';
import { useEffect } from 'react';
import OuterContainer from '~/components/ui/OuterContainer';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';

function TechTesting(props) {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);


  return (
    <>
      <OuterContainer>
        <Section2 />
        <Section3 />
      </OuterContainer>
      <Section4 />

      <Section5 />
    </>
  );
}

export default TechTesting;
