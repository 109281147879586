let config = {};
if (process.env.REACT_APP_STAGE == 'dev') {
  config = {
    apiURL: 'https://us-central1-visionforhumanity-896fd.cloudfunctions.net',
    shopifyConfig: {
      storefrontToken: 'cacda4bfbfbc0c34f3f3ee547fdf2349',
      shopname: 'vfhdev',
    },
    algoliaConfig: {
      applicationID: '6SH40WEEM6',
      searchAPIKey: '0f5695e591e7fcac39840a89d0c5609e',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyB-eXWaWpKLuAqgk7HlLf7i-euuHtkSu_o',
      authDomain: 'visionforhumanity-896fd.firebaseapp.com',
      databaseURL: 'https://visionforhumanity-896fd.firebaseio.com',
      projectId: 'visionforhumanity-896fd',
      storageBucket: 'visionforhumanity-896fd.appspot.com',
      messagingSenderId: '825555070441',
      appId: '1:825555070441:web:77f6a447c44dc22112af78',
      measurementId: 'G-4KFWWPZXYZ',
    },
  };
} else if (process.env.REACT_APP_STAGE == 'prod') {
  config = {
    shopifyConfig: {
      storefrontToken: '1a957fc705cb79cead980782b36cdf71',
      shopname: 'vfhprod',
    },
    algoliaConfig: {
      searchAPIKey: 'No Key yet',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyAc_1ITERqziFAg3NP24lkSPgcnDOj2exU',
      authDomain: 'vfh-prod.firebaseapp.com',
      projectId: 'vfh-prod',
      storageBucket: 'vfh-prod.appspot.com',
      messagingSenderId: '325328054389',
      appId: '1:325328054389:web:58e5b4c8ebc9282fc7f03c',
      measurementId: 'G-MNLJ0RZ0YG',
    },
  };
}

if (process.env.REACT_APP_STAGE == 'prod') {
  console.log = () => {};
}

export default config;
