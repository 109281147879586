import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import HtmlContent from '~/components/ui/HtmlContent';


const styles = (theme) => ({})

const NutritionPopupContent = ({density, props}) => {
  const {densitycontent} = density;

  return (
    <div>
      <Box>
        <HtmlContent 
          content={densitycontent} 
        />
      
      
      </Box>
    </div>
  );
};

export default withStyles(styles)(NutritionPopupContent);
