import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: (props) => props.paddingTopBottom + ' 6%',
    display: 'flex',
    flexDirection: (props) => (props.reverse ? 'row-reverse' : 'row'),
    color: '#000',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: () => 'column',
      padding: () => '20px 6%',
    },
  },
  leftImage: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
      width: '100%',
    },
  },
});

const useStyles = makeStyles(styles);

export default function CommonComponent(props) {
  const {reverse, imageWidthInPercentage, imageSrc, children, paddingTopBottom = '60px'} = props;
  const classes = useStyles({reverse, paddingTopBottom});
  return (
    <div className={classes.root}>
      <div
        style={{
          flex: imageWidthInPercentage,
        }}
      >
        <img src={imageSrc} alt={'img'} className={classes.leftImage} />
      </div>
      <div
        style={{
          flex: 100 - imageWidthInPercentage,
        }}
      >
        {children}
      </div>
    </div>
  );
}
