import React from 'react';
import {withStyles} from '@material-ui/core';
import ScanningBg from '~/assets/images/page/3dscanning/3dscanning.png';
import HeroSectionData from './HeroSectionData';
import SetBackgroundComponent from '~/components/ui/SetBackgroundComponent';
const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '5% 12%',
    background: 'rgba(27,27,27,0.64)',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20% 4%',
    },
  },
});
const routes = [
  {title: 'Products and Services', to: '/productandservice'},
  {title: 'Tech Testing', to: '/productandservice/techtesting'},
  {title: '3D Scanning', to: '/productandservice/techtesting/3dscanning'},
];
function HeroSection(props) {
  const {classes} = props;

  return (
    <>
      <div className={classes.content}>
        {/* <SetBackgroundComponent headerBackground={ScanningBg} bgHeight="800px" overlayDisplay="block" /> */}
        <HeroSectionData />
      </div>
    </>
  );
}

export default withStyles(styles)(HeroSection);
