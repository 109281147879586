import React from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import { NaturePeopleOutlined } from '@material-ui/icons';

const styles = (theme) => ({
  rela: {top: '100%', transform: 'translateY(-100%)', position: 'relative'},
  root: {
    width: '100%',
    margin: 0,
    height: (props) => props.height,
    backgroundImage: (props) => `url(${props.imageSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 19,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 0.32,
    textAlign: 'right',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  subText: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 0.32,
    textAlign: 'right',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 0.32,
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  shadow: {
    width: '100%',
    height: '100%',
    width: '100%',
    margin: 0,
    backgroundColor: 'rgba(0,0,0,.45)',
  },
  image: {width: '100%', '-webkit-mask': 'rgba(0,0,0,1)'},
});
const useStyles = makeStyles(styles);
export default function ReadMore(props) {
  const {text, subText, imageSrc, height = 280, hasReadMore = true, path, additionText, additionTextPath, handleClickProtocols} = props;
  const classes = useStyles({imageSrc, height});
  const history = useHistory();
  
  return (
    <Grid container item className={classes.root} spacing={4} onClick={handleClickProtocols ? () => handleClickProtocols({text}) : null}>
      <Grid container item xs={12} direction="column" className={classes.shadow} justify="space-between" spacing={3}>
        <Grid item>
          <Typography className={classes.text}>{text}</Typography>
          <Typography className={classes.subText}>{subText}</Typography>

        </Grid>
        <Grid container item>
          <Grid item xs={6}>
            {additionText && (
              <Button
                onClick={() => history.push(additionTextPath)}
                className={classes.button}
                style={{border: '1px solid white'}}
              >
                {additionText}
              </Button>
            )}
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            {hasReadMore &&
              (path && path.includes('https://' || 'http://') ? (
                <Button onClick={() => window.open(path)} className={classes.button}>
                  Read More
                </Button>
              ) : (
                <Button className={classes.button} onClick={() => history.push(path)}>
                  Read More
                </Button>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
