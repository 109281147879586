import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography, Divider, Button} from '@material-ui/core';
import ProgressChart from '~/components/ui/ProgressChart';
import PieChart from '~/components/ui/PieChart';
import foodSciIcon from '~/assets/images/page/lifeforcefood/detail/foodSci.png';
import {withRouter} from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NutritionDensityContent from './nutritionDensityContent';
import nutritionDensityData from './nutritionDensityData';
import LifeforceContent from './lifeforceContent';
import lifeforceData from './lifeforceData';
import H320Content from './h320Content';
import h320Data from './h320Data';
import {getPath} from '~/static/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  title: {
    color: '#9aa300',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  subtitle: {
    color: '#8c8c8c',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    cursor: 'pointer',
  },
  itemtitle: {
    color: '#434343',
    fontSize: '18px',
    fontWeight: '750',
    letterSpacing: '1px',
    cursor: 'pointer',
  },

  // scititle: {
  //   color: '#9aa300',
  //   fontSize: '16px',
  //   fontWeight: '700',
  //   cursor: 'pointer',
  // },
  itemname: {
    color: '#434343',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: '300',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginBottom: '4px',
    },
  },

  statusBox: {
    backgroundColor: 'rgba(154,162,0, 0.1)',
    width: '100%',
    borderLeft: 'solid 5px #9aa200 ',
    padding: '10px 10px',
  },

  ingredients: {
    margin: '4px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  icon: {
    width: '32px',
    marginRight: '16px',
  },

  iconArrow: {
    color: '#8c8c8c',
    cursor: 'pointer',
  },
  viewBtn: {
    color: 'white',
    backgroundColor: '#9aa200',
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    '&:hover': {
      color: '#9aa200',
    },
  },
  blueprint: {
    // border: '1px red solid',
    // padding:'10px',
    margin: 0,
    width: '100%',
  },
  class1: {
    padding: '5px 0',
    borderLeft: 'solid 5px #9aa200',
    backgroundColor: 'rgba(154,162,0, 0.1)',
  },
}));

function Nutritional(props) {
  const {data, history} = props;
  const classes = useStyles();

  const density = {
    densitycontent: nutritionDensityData,
  };
  const lifeforce = {
    lifeforcecontent: lifeforceData,
  };
  const h320 = {
    h320content: h320Data,
  };

  return (
    <Grid container spacing={2} className={classes.blueprint}>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        md={12}
        alignItems="flex-start"
        style={{height: '100%'}}
        className={classes.blueprint}
      >
        <Grid item xs={12} className={classes.blueprint}>
          <Box textAlign="center">
            <Typography
              className={classes.title}
              onClick={() => {
                // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#NutritionalSummaryStatus`);
                history.push(getPath('Nutritional details') + `#NutritionalSummaryStatus`);
              }}
            >
              Nutritional Summary Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Box className={classes.class1}>
            <Grid container xs={12} spacing={1} className={classes.blueprint}>
              <Grid item xs={6} md={4} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Servings per package: </strong>
                  {data && data.nurtritionalSum.servePerPkg}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Serving Size: </strong> {data && data.nurtritionalSum.serveSize}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Per: </strong>100g
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Nutrient density: </strong> {data && data.nurtritionalSum.nutrientDensity}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Life Force:</strong>
                  {data && data.nurtritionalSum.lifeForce}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} className={classes.blueprint}>
                <Typography component="div">
                  <strong>H320: </strong> {data && data.nurtritionalSum.h320}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} className={classes.blueprint}>
                <Typography component="div">
                  <strong>Raw: </strong>
                  {data && data.nurtritionalSum.raw}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Box
            textAlign="center"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => {
              // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#NutritionalAnalysis`);
              history.push(getPath('Nutritional details') + `#NutritionalAnalysis`);
            }}
          >
            <Box></Box>
            <Typography className={classes.subtitle}>Nutritional ANALYSIS</Typography>
            <ChevronRightIcon className={classes.iconArrow} />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Divider />
        </Grid>

        {/* Micronutrients */}

        <Grid item xs={12} className={classes.blueprint}>
          <Box>
            <Typography
              onClick={() => {
                // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#Micronutrients`);
                history.push(getPath('Nutritional details') + `#Micronutrients`);
              }}
              className={classes.itemtitle}
            >
              1. Micronutrients
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.blueprint}>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#9aa200" radius={25} percentage={0.15} />
              <Typography className={classes.itemname}>vitamins</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#1f78b4" radius={25} percentage={0.65} />
              <Typography className={classes.itemname}>macrominerals</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#7e489c" radius={25} percentage={0.12} />
              <Typography className={classes.itemname}>microminerals</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#b9ad13" radius={25} percentage={0.23} />
              <Typography className={classes.itemname}>amino acids</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Divider />
        </Grid>

        {/* Carbohydrates */}
        <Grid item xs={12} className={classes.blueprint}>
          <Box>
            <Typography
              onClick={() => {
                // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#Micronutrients`);
                history.push(getPath('Nutritional details') + `#CalorieComposition`);
              }}
              className={classes.itemtitle}
            >
              2. Carbohydrates
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.blueprint}>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#ec5569" radius={25} percentage={0.25} />
              <Typography className={classes.itemname}>carbohydrates</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#7e489c" radius={25} percentage={0.35} />
              <Typography className={classes.itemname}>proteins</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#659bbf" radius={25} percentage={0.22} />
              <Typography className={classes.itemname}>fats</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#9dc05e" radius={25} percentage={0.33} />
              <Typography className={classes.itemname}>sugars</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Divider />
        </Grid>

        {/* Specialised Functionality */}
        <Grid item xs={12} className={classes.blueprint}>
          <Box>
            <Typography
              onClick={() => {
                // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#Micronutrients`);
                history.push(getPath('Nutritional details') + `#SpecialFunctionality`);
              }}
              className={classes.itemtitle}
            >
              3. Specialised Functionality
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.blueprint}>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <PieChart i={0} width={50} height={50} innerRadius={15} outerRadius={25} value={48} />
              <Typography className={classes.itemname}>Glycaemic Index</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <ProgressChart color="#9aa200" radius={25} percentage={0.62} />
                <Typography className={classes.itemname}>Arginine</Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <ProgressChart color="#9ab255" radius={25} percentage={0.35} />
                <Typography className={classes.itemname}>Lysine</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <PieChart i={1} width={50} height={50} innerRadius={15} outerRadius={25} value={72} />
              <Typography className={classes.itemname}>HDL</Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <PieChart i={2} width={50} height={50} innerRadius={15} outerRadius={25} value={6.8} />
              <Typography className={classes.itemname}>PH Level</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Divider />
        </Grid>

        {/* Delivery of specialised ingredients */}
        <Grid item xs={12} className={classes.blueprint}>
          <Box>
            <Typography
              onClick={() => {
                // history.push(`/productandservice/lifeforcefood/nutrition/${data && data.id}#Micronutrients`);
                history.push(getPath('Nutritional details') + `#DeliverySI`);
              }}
              className={classes.itemtitle}
            >
              4. Delivery of specialised ingredients
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} spacing={1} className={classes.blueprint}>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <PieChart i={3} width={50} height={50} innerRadius={15} outerRadius={25} value={null} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#9aa200" radius={25} percentage={0.15} />
              <Typography className={classes.itemname}>vitamins</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#9aa200" radius={25} percentage={0.15} />
              <Typography className={classes.itemname}>vitamins</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.blueprint}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ProgressChart color="#9aa200" radius={25} percentage={0.15} />
              <Typography className={classes.itemname}>vitamins</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        md={4}
        className={classes.blueprint}
        style={{alignItems: 'flex-start', height: '100%'}}
      >
        {/* <Grid item xs={12} className={classes.blueprint}>
          <Typography
            onClick={() => {
              // history.push(`/ingredients`);
              history.push(getPath('Food ingredients'));
            }}
            className={classes.title}
          >
            Ingredients
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          {['first Ingredient', 'Second Ingredient', 'Third Ingredient', 'Fourth Ingredient'].map((item, index) => (
            <Typography
              onClick={() => {
                // history.push(`/ingredients#${item.charAt(0)}`);
                // history.push(getPath('Food ingredients' + `#${item.charAt(0)}`));
                history.push(getPath('Food ingredients'));
              }}
              key={index}
              className={classes.ingredients}
            >
              {item}
            </Typography>
          ))}
        </Grid> */}
        <Grid item xs={12} className={classes.blueprint}>
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <img src={foodSciIcon} alt="food science icon" className={classes.icon} />
            <Typography
              className={classes.scititle}
              onClick={() => history.push(getPath('Nutritional details') + `#NutritionalData`)}
            >
              FOOD SCIENCE
            </Typography>
          </Box> */}
        </Grid>
        {/* <Grid item xs={12} className={classes.blueprint}>
          <Box display="flex" justifyContent="center">
            <Button className={classes.viewBtn}>View health creator app</Button>
          </Box>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default withRouter(Nutritional);
