import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, TextField, MenuItem, IconButton, InputAdornment} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import VideoChatIcon from '~/assets/images/page/lifeforcefood/videoChatIcon.svg';
import OuterContainer from '~/components/ui/OuterContainer';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const styles = (theme) => ({
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    margin: '2px 0px',
  },
  class2: {
    flexDirection: 'column',
  },
  class3: {
    justifyContent: 'space-between',
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
    },
  },
  class4: {
    [theme.breakpoints.down(960)]: {
      order: 1,
    },
    alignItems: 'center',
  },
  class5: {
    flexWrap: 'wrap',
    [theme.breakpoints.down(819)]: {
      justifyContent: 'center',
    },
  },
  class7: {
    margin: '0px 6px 11px 0px',
  },
  class8: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '11px',
  },
  backgroundColor: {
    backgroundColor: 'white',
    padding: '0px 10px',
  },
  category: {
    whiteSpace: 'nowrap',
    color: '#595959',
    fontSize: '1.2rem',
    lineHeight: '2',
    marginRight: '10px',
    fontWeight: '500',
    '& > svg': {
      fontSize: 18,
    },
    cursor: 'pointer',
  },
  divider: {
    width: 0,
    height: '28px',
    border: 'solid 1px #dfdfdf',
    marginRight: '10px',
  },
  tagBtn: {
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    border: 'solid 1px #9aa200',
    color: '#9aa200',
  },
  clearBtnTop: {
    [theme.breakpoints.up(426)]: {
      display: 'none',
    },
  },
  clearBtnBottom: {
    [theme.breakpoints.down(426)]: {
      display: 'none',
    },
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    border: 'solid 1px grey',
    color: 'grey',
    padding: 0,
    minWidth: 0,
    height: '32px',
    width: '32px',
  },
  class9: {
    minWidth: '20ch',
    width: '100%',
    margin: '0px 10px',
    [theme.breakpoints.down(960)]: {
      margin: 0,
    },
  },
  class10: {
    width: '26ch',
    [theme.breakpoints.down(426)]: {
      flex: 1,
    },
  },
  class11: {
    [theme.breakpoints.down(426)]: {
      display: 'flex',
      flexBasis: '100%',
    },
  },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#9aa200',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9aa200',
    },
    '& .MuiOutlinedInput-input': {
      padding: '6.5px 31.5px 6.5px 6.5px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9aa200',
      },
      '&:hover fieldset': {
        borderColor: '#9aa200',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9aa200',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 9px) scale(1)',
      pointerEvents: 'none',
    },
  },
})(TextField);

const AutocompleteModifed = withStyles({
  root: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: '6.5px 21.5px 6.5px 6.5px',
    },
    '& label.Mui-focused': {
      color: '#9aa200',
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9aa200',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9aa200',
      },
      '&:hover fieldset': {
        borderColor: '#9aa200',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9aa200',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(14px, 9px) scale(1)',
      pointerEvents: 'none',
    },
  },
})(Autocomplete);

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Filter = (props) => {
  const {
    classes,
    filters,
    setFilters,
    name,
    filterData1 = [],
    filterData2 = [],
    filterData3 = [],
    allergens = [],
    preferences = [],
    recipeSortAttributes = [],
  } = props;

  const getSubCategories = (arr) => {
    const subCategories = [];
    const seenKeys = new Set();

    arr.forEach((item) => {
      if (item.subCat && Array.isArray(item.subCat)) {
        item.subCat.forEach((subCatItem) => {
          if (!seenKeys.has(subCatItem.key)) {
            seenKeys.add(subCatItem.key);
            subCategories.push(subCatItem);
          }
        });
      }
    });

    return subCategories;
  };
  const subCatArray = getSubCategories(filterData1);
  const catToDisplay = (tag1) => {

    return filterData1.length
      ? filterData1.find((element) => element.key === tag1)
        ? filterData1.find((element) => element.key === tag1).subCat
        : filterData1.find((element) => element.key == null).subCat
      : [];
  };

  const handleChange = (tag, value) => {
    if (typeof value == 'string') {
      if (value.trim().length == 0) {
        value = null;
      }
    }
    setFilters({
      ...filters,
      [tag]: value,
    });
  };

  const tag6Value = (tag6) => {
    if (!tag6) {
      return null;
    } else {
      const val = recipeSortAttributes.find((e) => e.key == filters.tag6);
      return val ? val.name : null;
    }
  };
  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1, classes.class2)}>
        <div className={clsx(classes.class0, classes.class1, classes.class3)}>
          <div className={clsx(classes.class0, classes.class1, classes.class4, classes.class5)}>
            <div className={clsx(classes.class0, classes.class4, classes.class6)}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleChange('tag1', null);
                }}
                className={classes.category}
                style={{borderBottom: filters.tag1 == null ? '2px #595959 solid' : 0}}
              >
                All {name}
              </div>
              <div className={classes.divider}></div>
            </div>
            {filterData2.map((element, index) => {
              return (
                <div className={clsx(classes.class0, classes.class4)}>
                  <div
                    onClick={() => {
                      handleChange('tag1', element.key);
                    }}
                    className={classes.category}
                    style={{borderBottom: filters.tag1 == element.key ? '2px #595959 solid' : 0}}
                  >
                    {element.name}
                  </div>
                  {filterData2.length - 1 > index && <div className={classes.divider}></div>}
                </div>
              );
            })}
            <div className={clsx(classes.class0, classes.class4)}>
              {/* <div className={classes.category}>
                <BookmarksIcon /> My Favorites
              </div> */}
              {props.clearFilters ? <div className={clsx(classes.divider, classes.clearBtnTop)}></div> : null}
            </div>
            {props.clearFilters ? (
              <div className={clsx(classes.class0, classes.class4, classes.clearBtnTop)}>
                <div className={classes.category}>
                  <IconButton onClick={props.clearFilters} style={{padding: 4}}>
                    <ClearIcon />
                  </IconButton>
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={clsx(classes.class0, classes.class1)}
            style={{flex: 1, justifyContent: 'space-between', alignItems: 'center'}}
          >
            <CssTextField
              className={classes.class9}
              variant="outlined"
              value={filters.searchText ?? ''}
              onChange={(e) => {
                handleChange('searchText', e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{color: '#9aa200'}} />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton style={{padding: 4}}>
              <img src={VideoChatIcon} style={{height: '1.7rem'}} />
            </IconButton>
          </div>
        </div>
        <div className={clsx(classes.class0, classes.class1, classes.class3)}>
          <div className={clsx(classes.class0, classes.class1, classes.class5)}>
            {subCatArray.map((element, index) => (
              <div className={classes.class7}>
                <Button
                  onClick={() => {
                    handleChange('tag1', element.key);
                  }}
                  key={index}
                  className={classes.tagBtn}
                  size="small"
                  style={{background: filters.tag1 == element.key ? '#9aa20042' : 'none'}}
                >
                  {element.name}
                </Button>
              </div>
            ))}
            {/* <div className={clsx(classes.class7, classes.class11)}>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                label="Preferences"
                multiple
                value={filters.tag4}
                onChange={(e) => handleChange('tag4', e.target.value)}
                input={<CssTextField label="Preferences" select className={classes.class10} variant="outlined" />}
                renderValue={(selected) =>
                  selected.map((ele) => allergens.concat(preferences).find((e) => e.key == ele).name).join(', ')
                }
                MenuProps={MenuProps}
              >
                {preferences.map((element, index) => {
                  return <MenuItem value={element.key}>{element.name}</MenuItem>;
                })}
                <ListSubheader style={{background: 'white'}}>Allergies :</ListSubheader>
                {allergens.map((element, index) => (
                  <MenuItem style={{fontSize: 13}} value={element.key}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
            <div className={clsx(classes.class7, classes.class11)}>
              <CssTextField
                id="select"
                label="Health Protocols"
                select
                variant="outlined"
                className={classes.class10}
                value={filterData3.find((e) => e.key === filters.tag1) ? filters.tag1 : ''}
                onChange={(e) => handleChange('tag1', e.target.value)}
              >
                {filterData3.map((element) => (
                  <MenuItem value={element.key}>{element.name}</MenuItem>
                ))}
              </CssTextField>
            </div>
            {/* <div className={clsx(classes.class7, classes.class11)}>
              <AutocompleteModifed
                id="combo-box-demo"
                options={recipeSortAttributes.map((e) => e.name)}
                value={tag6Value(filters.tag6)}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  handleChange('tag6', !!newValue ? recipeSortAttributes.find((e) => e.name == newValue).key : null);
                }}
                className={classes.class10}
                renderInput={(params) => {
                  return <CssTextField {...params} label="Sort by" variant="outlined" />;
                }}
              />
            </div> */}
            {props.clearFilters ? (
              <div
                className={clsx(classes.class7, classes.class11)}
                style={{
                  marginBottom: 11,
                  marginRight: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button key={123} className={classes.clearBtnBottom} onClick={props.clearFilters} size="small">
                  <ClearIcon />
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(Filter);
