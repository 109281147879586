import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Container} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    background: 'white',
    padding: '40px 0 20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  itemWrapper: {
    height: '100%',
  },
  title: {
    color: '#595959',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  titleRight: {
    textAlign: 'right',
  },

  desc: {
    // color: '#434343',
    color: '#595959',
    width: '84%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

function SectionHeader(props) {
  const {classes, reverse, title, desc} = props;

  return (
    <Grid container className={classes.root} direction={reverse ? 'row-reverse' : 'row'}>
      {/* <Grid item md={6} xs={12}> */}
      <Grid item sm={6} xs={12}>
        <Grid container className={classes.itemWrapper} justify="center" alignItems="center">
          <Typography variant="h5" align="center"
           className={clsx(classes.title, {
              [classes.titleRight]: reverse,
            })}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item md={6} xs={12}> */}
      <Grid item sm={6} xs={12}>
        {/* <Grid
          container
          className={classes.itemWrapper}
          justify={reverse ? 'flex-end' : 'flex-start'}
          alignItems="center"
        > */}
          <Typography variant="body1" align="justify" className={classes.desc}>
            <Container maxWidth="lg">
              {typeof desc === 'function' ? desc() : desc}
            </Container>
          </Typography>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SectionHeader);
