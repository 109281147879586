import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 17.94 27.47"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 0 19.78)"
      >
        <tspan x={0} y={0}>
          {"P"}
        </tspan>
        <tspan
          x={3.47}
          y={0}
          style={{
            letterSpacing: 0,
          }}
        >
          {"r"}
        </tspan>
        <tspan x={5.28} y={0}>
          {"otein"}
        </tspan>
        <tspan x={0.23} y={6.2}>
          {"Sou"}
        </tspan>
        <tspan
          x={9.66}
          y={6.2}
          style={{
            letterSpacing: 0,
          }}
        >
          {"r"}
        </tspan>
        <tspan
          x={11.47}
          y={6.2}
          style={{
            letterSpacing: 0,
          }}
        >
          {"ce"}
        </tspan>
      </text>
      <path
        d="M9 14.61c-4.13 0-7.49-3.28-7.49-7.3S4.87 0 9 0s7.49 3.28 7.49 7.31-3.36 7.3-7.49 7.3ZM9 .34c-3.93 0-7.14 3.13-7.14 6.97s3.2 6.96 7.14 6.96 7.14-3.12 7.14-6.96S12.94.34 9 .34Z"
        className="cls-2"
      />
      <path
        d="m10.94 8.11-.32-.14c-.19.41-.53.74-.95.93l.14.31c.5-.22.9-.61 1.13-1.1Z"
        className="cls-2"
      />
      <path
        d="M12.34 5.33c-.58 0-1.13.22-1.56.61l-.18-.17c-.29-.28-.67-.44-1.08-.44-.65 0-1.18-.51-1.18-1.15 0-.4-.16-.77-.45-1.05l-.21-.2c-.43-.42-.99-.65-1.6-.65-1.25 0-2.26.99-2.26 2.2v.06c0 .2.03.39.08.58.15.52.36 1.02.64 1.49-.43.29-.72.78-.72 1.33v.1c0 .12.01.23.04.35.4 1.75 1.79 3.14 3.56 3.58.41.51 1.03.8 1.69.8.19 0 .37-.02.55-.07l.18-.05c2.25-.57 4.04-2.29 4.65-4.48.05-.19.08-.38.08-.58v-.06c0-1.21-1.01-2.2-2.26-2.2Zm-8.08-.29c-.04-.16-.07-.32-.07-.49v-.06c0-1.03.86-1.86 1.91-1.86.51 0 .99.19 1.35.55l.21.2c.22.22.34.5.34.81 0 .82.68 1.49 1.52 1.49.31 0 .61.12.83.34l.18.17c-.29.28-.45.66-.45 1.05 0 .63-.53 1.15-1.18 1.15-.35 0-.68.12-.95.32a.792.792 0 0 1-.65-.77c0-.3-.12-.58-.34-.8l-.32-.31a1.659 1.659 0 0 0-1.78-.35 6.15 6.15 0 0 1-.61-1.42Zm-.04 3.28c-.02-.09-.03-.18-.03-.27v-.1c0-.7.58-1.27 1.3-1.27.35 0 .67.13.92.37l.32.31c.15.15.24.35.24.56 0 .62.52 1.13 1.15 1.13.22 0 .42.08.57.23l.34.33c.23.23.36.53.36.86v.08c0 .67-.56 1.21-1.24 1.21-.09 0-.17 0-.26-.03l-.18-.04c-1.74-.36-3.11-1.68-3.5-3.37Zm10.03-.73c0 .17-.02.33-.07.49-.58 2.08-2.27 3.7-4.4 4.25l-.18.05a1.818 1.818 0 0 1-1.53-.28h.09c.88 0 1.59-.69 1.59-1.55v-.08c0-.41-.17-.8-.46-1.09l-.34-.33c-.12-.12-.27-.21-.42-.26.13-.04.26-.07.4-.07.84 0 1.52-.67 1.52-1.49 0-.31.12-.59.34-.81l.21-.2c.36-.35.84-.55 1.35-.55 1.05 0 1.91.84 1.91 1.86v.06Z"
        className="cls-2"
      />
      <path
        d="m4.62 5.04.12.36.33-.12c-.04-.11-.08-.23-.11-.34l-.33.09ZM6.1 2.96c-.86 0-1.56.69-1.56 1.53v.18l.35-.03v-.15c0-.65.55-1.19 1.21-1.19v-.34ZM12.1 10.29l.25.24c.11-.11.23-.23.33-.35l-.26-.22c-.1.11-.2.22-.31.33ZM9.61 11.68l-.08.02.09.33.08-.02a5.91 5.91 0 0 0 2.46-1.3l-.23-.25c-.66.58-1.46 1.01-2.31 1.23ZM8.63 6.16l.14-.31c-.42-.18-.76-.51-.95-.93l-.32.14c.22.49.62.88 1.13 1.1ZM4.54 7.95v.1c0 .07 0 .14.02.2.04.18.09.36.16.53l.33-.12c-.06-.16-.11-.32-.15-.49 0-.04-.01-.09-.01-.13v-.1c0-.3.23-.56.54-.59l-.04-.34c-.49.05-.85.45-.85.92ZM4.87 9.13c.06.12.13.25.2.36l.3-.18c-.07-.11-.13-.22-.19-.33l-.31.15ZM6.68 8.34l-.33.09c.18.59.67 1.06 1.27 1.23l.09-.33c-.49-.14-.89-.52-1.04-1Z"
        className="cls-2"
      />
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as ProteinSourceIcon }
