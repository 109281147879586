import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Container, Box, Typography} from '@material-ui/core';
import guideIntro from '~/assets/images/page/healthGuides/guideIntro.png';

const styles = (theme) => ({
  root: {
    color: '#343434',
    margin: '4% auto 2%',
  },
  leftSection: {
    background: '#f2f2f2',
    borderRadius: '8px',
  },
  imageBox: {
    width: '100%',
    height: 400,
    position: 'relative',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  guideTitle: {
    textTransform: 'uppercase',
    fontSize: '20px',
    color: '#595959',
  },
  content: {
    fontSize: '16px',
    color: '#343434',
    fontWeight: '300',
    margin: '2% auto',
  },
  imgTitle: {
    position: 'absolute',
    top: '40%',
    whiteSpace: 'nowrap',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'lavenderblush',
    fontSize: '24px',
    fontFamily: 'Georgia',
  },
  imgSubtitle: {
    position: 'absolute',
    top: '60%',
    whiteSpace: 'nowrap',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'rgba(255,255,255,1)',
    fontSize: '16px',
    fontFamily: 'Roboto',
  },
});

function GuideIntro(props) {
  const {classes} = props;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} className={classes.leftSection}>
          <Box p={3}>
            <Box textAlign="center" mb={1}>
              <Typography variant="h6" className={classes.guideTitle}>
                Vitality and Longevity Guides
              </Typography>
            </Box>
            <Typography className={classes.content}>
              Take control of your well-being by investing in these concise health guides, developed by the Sanctuary, to help provide you vital context to our health systems and protocols.
            </Typography>
            <Typography className={classes.content}>
              Compliment your well-being knowledge through the unique perspectives contained in this Vitality and Longevity series. Vital Guides to greater Health Span.
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} className={classes.rightSection}>
          <Box className={classes.imageBox}>
            <img src={guideIntro} alt="health guides" className={classes.imageContainer} />
            <Typography className={classes.imgTitle}>Metawheel Guides specific to your needs</Typography>
            <Typography className={classes.imgSubtitle}># take the Metawheel journey</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(GuideIntro);
