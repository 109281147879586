import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  bannerCardWrapper: {
    zIndex: '2',
    position: 'relative',
    marginTop: '-150px',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '30px 10px 10px',
    },
  },
  overlay: {
    background: 'rgba(27, 27, 27, 0.64)',
    paddingBottom: '20px',
  },
  videoImage: {
    width: '300px',
  },
  title: {
    color: '#ffffff',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: 'normal',
    margin: '50px 0px 15px 0px',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  subTitle: {
    fontSize: '11px',
  },
  description: {
    fontSize: '20px',
    fontFamily: 'AmazonEmberDisplay',
  },
  list: {
    marginTop: '30px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  listItem: {
    fontSize: '16px',
    fontFamily: 'Lato',
    lineHeight: 1.2,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
});

function BannerSection(props) {
  const {classes, pageData} = props;
  return (
    <div
      className={classes.bannerCardWrapper}
      style={{
        background: `url(${props.pageData.section4BannerCardbg}) no-repeat`,
        backgroundSize: '120%',
        backgroundPosition: 'center',
      }}
    >
      <div className={classes.overlay}>
        <Container>
          <div className={classes.root}>
            <Grid container className={classes.root}>
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h4" className={classes.title}>
                  {pageData.banner.bannerTitle}
                </Typography>
                <div className={classes.list}>
                  {pageData.banner.bannerContent.map((item) => (
                    <>
                      <Typography key={item} className={classes.listItem}>
                        {item}
                      </Typography>
                      <br />
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(BannerSection);
