import heroThumbnailIS from '~/assets/images/page/healthProtocols/immuneSystem/immune-hero-thumbnail.png';
import heroThumbnailBH from '~/assets/images/page/healthProtocols/brainHealth/brainhealth-hero-thumbnail.png';
import heroThumbnailHH from '~/assets/images/page/healthProtocols/heartHealth/pexels-photo-1988698.png';
import heroThumbnailCH from '~/assets/images/page/healthProtocols/cellularHealth/pexels-photo-1729931.png';
import heroThumbnailBCH from '~/assets/images/page/healthProtocols/boneCartilage/Rectangle-138802.png';
import heroThumbnailIHS from '~/assets/images/page/healthProtocols/increasedHealthSpan/photo-1434494878577-86c23bcb06b9.png';
import factorsImg1 from '~/assets/images/page/healthProtocols/immuneSystem/immu-factor.jpg';
import factorsImg2 from '~/assets/images/page/healthProtocols/brainHealth/photo-1559757175-0828e13882ed.png';
import factorsImg3 from '~/assets/images/page/healthProtocols/heartHealth/photo-1460672985063-6764ac8b9c74.png';

import factorsImg4 from '~/assets/images/page/healthProtocols/cellularHealth/photo-1460672985063-6764ac8b9c74.png';
import factorsImg5 from '~/assets/images/page/healthProtocols/boneCartilage/new-ways-grow-cartilage-osteoarthritis.png';
import factorsImg6 from '~/assets/images/page/healthProtocols/increasedHealthSpan/woman-pointing-at-sky-on-seashore-1117493.png';

import protocolelement from '~/assets/images/page/healthProtocols/protocolelement.png';

import section3Img1 from '~/assets/images/page/healthProtocols/immuneSystem/Optimize.png';
import section3Img2 from '~/assets/images/page/healthProtocols/brainHealth/a2ig_er6s_180617.png';
import section3Img3 from '~/assets/images/page/healthProtocols/heartHealth/human-heart-1700453_1280.png';

import section3Img4 from '~/assets/images/page/healthProtocols/cellularHealth/wi2m_j9j4_171126.png';
import section3Img5 from '~/assets/images/page/healthProtocols/boneCartilage/osteoporosis-illustration.png';
import section3Img6 from '~/assets/images/page/healthProtocols/increasedHealthSpan/QQ20200429-1.png';

import section3RightImg1 from '~/assets/images/page/healthProtocols/immuneSystem/immuSection3.png';
import section3RightImg2 from '~/assets/images/page/healthProtocols/brainHealth/pexels-photo-4034016.png';
import section3RightImg3 from '~/assets/images/page/healthProtocols/heartHealth/15590.png';

import section3RightImg4 from '~/assets/images/page/healthProtocols/cellularHealth/4r4e_olku_160620.png';
import section3RightImg5 from '~/assets/images/page/healthProtocols/boneCartilage/bone-health.jpg';
import section3RightImg6 from '~/assets/images/page/healthProtocols/increasedHealthSpan/woman-exercise.png';

import section4BannerCardBg1 from '~/assets/images/page/healthProtocols/immuneSystem/photo-1529386317747-0a2a51add902.png';
import section4BannerCardBg2 from '~/assets/images/page/healthProtocols/brainHealth/photo-1573511860302-28c524319d2a.png';
import section4BannerCardBg3 from '~/assets/images/page/healthProtocols/heartHealth/18576.png';

import section4BannerCardBg4 from '~/assets/images/page/healthProtocols/cellularHealth/photo-1557511560-d07d5f64fd59.png';
import section4BannerCardBg5 from '~/assets/images/page/healthProtocols/immuneSystem/Hair-Follicle-Main-Banner.png';
import section4BannerCardBg6 from '~/assets/images/page/healthProtocols/increasedHealthSpan/photo-1573511860302-28c524319d2a.png';

import section5ISThumbnailBg1 from '~/assets/images/page/healthProtocols/immuneSystem/Fit3d-compare-U2.png';
import section5ISThumbnailBg2 from '~/assets/images/page/healthProtocols/immuneSystem/Rectangle-138596.png';
import section5ISThumbnailBg3 from '~/assets/images/page/healthProtocols/immuneSystem/Fit3D-Posture-Analysis.png';

import section5BHThumbnailBg1 from '~/assets/images/page/healthProtocols/brainHealth/photo-1558021212-51b6ecfa0db9.png';
import section5BHThumbnailBg2 from '~/assets/images/page/healthProtocols/brainHealth/Group-66271.png';
import section5BHThumbnailBg3 from '~/assets/images/page/healthProtocols/brainHealth/photo-1549925245-ed00f728a961.png';

import section5HHThumbnailBg1 from '~/assets/images/page/healthProtocols/heartHealth/pexels-photo-887349.png';
import section5HHThumbnailBg2 from '~/assets/images/page/healthProtocols/heartHealth/Group-66271.png';
import section5HHThumbnailBg3 from '~/assets/images/page/healthProtocols/heartHealth/pexels-photo-867463.png';

import section5CHThumbnailBg1 from '~/assets/images/page/healthProtocols/cellularHealth/photo-1558021212-51b6ecfa0db9.png';
import section5CHThumbnailBg2 from '~/assets/images/page/healthProtocols/cellularHealth/Group-66271.png';
import section5CHThumbnailBg3 from '~/assets/images/page/healthProtocols/cellularHealth/photo-1549925245-ed00f728a961.png';

import section5BCTThumbnailBg1 from '~/assets/images/page/healthProtocols/boneCartilage/Fit3d-compare-U2.png';
import section5BCTThumbnailBg2 from '~/assets/images/page/healthProtocols/boneCartilage/Group-66271.png';
import section5BCTThumbnailBg3 from '~/assets/images/page/healthProtocols/boneCartilage/Fit3D-Posture-Analysis.png';

import section5IHSThumbnailBg1 from '~/assets/images/page/healthProtocols/increasedHealthSpan/photo-1558021212-51b6ecfa0db9.png';
import section5IHSThumbnailBg2 from '~/assets/images/page/healthProtocols/increasedHealthSpan/Group-66271.png';
import section5IHSThumbnailBg3 from '~/assets/images/page/healthProtocols/increasedHealthSpan/photo-1549925245-ed00f728a961.png';

export const PageData = [
  {
    id: 1,
    title: 'IMMUNE SYSTEM',
    subText: 'Health Protocol 1',
    mainTitle: 'Power Immune System Protocol',
    heroThumbnail: heroThumbnailIS,
    subTitle: 'Simple and fundamental daily health habits to benefit your Immune System',
    contents:
      'The Power Immune System Protocol focuses on the most fundamental aspects to naturally fortifying the body from infection and disease. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements, essential oils and elements of nature. ',
    factor: {
      factorsImage: factorsImg1,
      factorContents: [
        'Better wound healing, less sickness and days off, and more productivity',
        'Increased emotional vibrancy and vagal tone for effective stress and trauma processing',
        'Greater happiness, sense of connection and mental well-being through immune boosting natural remedies and life style factors',
        'Immune fitness through nutritional profiling, microbiome health and nutraceutical optimisation',
        'Increased cardiovascular and mitochondria function for greater   energy and resilience',
        'Potential for minimising risk of infection and disease',
      ],
      factorTitle: 'Establish enhancing immune system habits',
    },
    section3Title: 'Immune integrity Interactions and Factors',
    section3Image: section3Img1,
    section3RightImage: section3RightImg1,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with a fully functional and healthy immune system',
      bannerContent: [
        'Optimal foods for a healthy and more diverse microbiome profile to reduce inflammation and aid immune function',
        'Detoxification, gut repair and parasite elimination to release the hand brakes on the immune system',
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques to restore and boost immune function via a balanced nervous and endocrine system ',
        'Immune enhancing physical exercises, therapies and essential oils for stress and trauma processing to help enable better cardiorespiratory fitness, white blood cell count and T cells',
        'Beneficial herbs, vitamins, minerals and supplements as protectants, possessing properties or aiding antiviral, antioxidant, anti-inflammatory activities. ',
        'Outdoor activates to sync and regulate sleep, healing and mental / emotional balance for proper immune function',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and hence balance the sympathetic and parasympathetic nervous system, and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, creating the optimal awake alert/calm state for enjoyable living and restful sleep. Such a state helps enable homeostasis and full immune function.',
        'Our bodies need exercise and that exercise has a tremendous cleansing and anti-inflammatory effect on the brain. Likewise the food we eat, where it is from, the effects it has on the microbiome, and how this in turn shapes the quality of our microbiome, effects inflammation within the body and brain, while nutritional efficiencies or deficiencies play a large role in brain function.',
        'The purity of our biosphere, living environment and products used determines toxin inputs related to immune balance and function. ',
        'Looking at the whole person and considering all beneficial actions can have a powerful effect on human health. Establishing an immune boosting daily routine can enable rewarding benefits that pay off short and long term. ',
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg1,
    section5ThumbnailBg1: section5ISThumbnailBg1,
    section5ThumbnailBg2: section5ISThumbnailBg2,
    section5ThumbnailBg3: section5ISThumbnailBg3,
    bookConsultant: [
      'Impaired immune function and even autoimmune diseases can present themselves at the worst of times as a result of a range of compounding and unaddressed negative factors. Maintaining your full immune function over a life time requires a protocol of proactive, yet simple, measures which considers all of these influencing factors to create a powerful immune system capable of protecting against and combating the ever present sources of viruses and disease. Book in with us to work through a Self-Advancing Protocol to help improve and fortify your immune system to protect both brain and body.',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Immune system Protocol',
    thumbnailSectionSubTitle: 'Engage the other key factors to a Power Immune System',
    thumbnailCardText1: '3D Avatars & Reports',
    thumbnailCardText2: 'Track Weight Loss, Body Comp, & Posture',
    thumbnailCardText3: 'Posture & Balance',
    path: 'immune-system',
  },
  {
    id: 2,
    title: 'BRAIN HEALTH',
    subText: 'Health Protocol 2',
    mainTitle: 'Clear Mind, Healthy Brain and Cognition Protocol',
    heroThumbnail: heroThumbnailBH,
    subTitle: 'Simple and fundamental daily health habits to benefit you',
    contents:
      'The Clear Mind, Healthy Brain and Cognition Protocol focuses on the most fundamental aspects to mind, brain and nervous system health. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements, essential oils and elements of nature.',
    factor: {
      factorsImage: factorsImg2,
      factorContents: [
        'Better learning capability and processing',
        'Greater stress and trauma processing',
        'A balanced and highly enjoyable alert/calm state of living',
        'Increased emotional vibrancy and vagal tone',
        'Greater happiness, connection and mental well-being',
        'Potential for minimising neurodegenerative conditions',
      ],
      factorTitle: 'Establish enhancing mind, brain and nervous system habits',
    },
    section3Title: 'Neural integrity Interactions and Factors',
    section3Image: section3Img2,
    section3RightImage: section3RightImg2,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with a healthy mind, brain and nervous system function',
      bannerContent: [
        'Optimal cognition foods for clearer thinking, better concentration and mental processing',
        'Gut brain axis detoxification, gut repair and parasite elimination',
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques',
        'Mind enhancing physical exercises for stress, trauma processing and neuroplasticity',
        'Beneficial herbs, supplements and essential oils conducive to mental wellbeing',
        'Outdoor activates to sync and regulate sleep, healing and mental emotional balance',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and hence balance the sympathetic and parasympathetic nervous system, and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, creating the optimal awake alert/calm state for enjoyable living and restful sleep. Such a state helps enable homeostasis and full immune function.',
        'Our bodies need exercise and that exercise has a tremendous cleansing and anti-inflammatory effect on the brain. Likewise the food we eat, where it is from, the effects it has on the microbiome, and how this in turn shapes the quality of our microbiome, effects inflammation within the body and brain, while nutritional efficiencies or deficiencies play a large role in brain function.',
        'The purity of our biosphere, living environment and products used determines toxin inputs related to immune balance and function. ',
        'Looking at the whole person and considering all beneficial actions can have a powerful effect on human health. Establishing an immune boosting daily routine can enable rewarding benefits that pay off short and long term. ',
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg2,
    section5ThumbnailBg1: section5BHThumbnailBg1,
    section5ThumbnailBg2: section5BHThumbnailBg2,
    section5ThumbnailBg3: section5BHThumbnailBg3,
    bookConsultant: [
      'Anxiety, depression or neurodegeneration can seem to appear suddenly, or occur slowly over time as a result of a significant event or multiple unaddressed negative factors. Maintaining your mental health over a life time requires a protocol of proactive, yet simple, measures which considers all of these influencing factors. Book in with us to work through a Self-Advancing Protocol to help improve and fortify your mind, brain and nervous health and performance.',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Brain health Protocol',
    thumbnailSectionSubTitle: 'Engage the other key factors to a Power Immune System',
    thumbnailCardText1: '3D Avatars & Reports',
    thumbnailCardText2: 'Track Weight Loss, Body Comp, & Posture',
    thumbnailCardText3: 'Posture & Balance',
    path: 'brain-health',
  },
  {
    id: 3,
    title: 'HEART HEALTH',
    subText: 'Health Protocol 3',
    mainTitle: 'Healthy Heart, Blood and Lungs Protocol',
    heroThumbnail: heroThumbnailHH,
    subTitle: 'Simple and fundamental daily health habits to benefit your heart and lung health',
    contents:
      'The Healthy Heart, Blood and Lungs Protocol focuses on the most fundamental aspects to naturally fortifying the body from excess weight gain, arterial plague build and calcification to avoid associated heart and lung conditions and diseases. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements, essential oils and elements of nature.',
    factor: {
      factorsImage: factorsImg3,
      factorContents: [
        'Better cardiorespiratory and cardiovascular efficiency',
        'Greater calm, stress and trauma processing and mental well-being',
        'A balanced and highly enjoyable and balanced alert/calm state of living',
        'Increased emotional vibrancy, vagal tone, and general happiness',
        'An aid to reducing excess weight, obesity and type two diabetes',
        'Potential for minimising risk of associated heart and lung conditions',
      ],
      factorTitle: 'Establish enhancing heart and lung habits',
    },
    section3Title: 'Heart and lung integrity Interactions and Factors ',
    section3Image: section3Img3,
    section3RightImage: section3RightImg3,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with a fully functional cardiorespiratory and cardiovascular system ',
      bannerContent: [
        'Optimal foods for a healthy and more diverse microbiome profile to reduce inflammation, improve healthy fats, lower blood sugar levels, increase metabolic flexibility and fuel the body with the proper energy it needs to aid exercise',
        'Detoxification, gut repair and parasite elimination to release the hand brakes on the digestive system to enable more energy for increased activity ',
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques to restore and boost confidence associated with proactive behaviour to enable beneficial food and exercise habits',
        'Age related cardiorespiratory and cardiovascular fitness exercises to gradually improve heart and lung performance, metabolic flexibility and aid the endocannabinoid system',
        'Beneficial herbs, vitamins, minerals and supplements as heart and lung protectants, enhancing haemoglobin, healthy cholesterol levels and dissolving of calcification and arterial plague',
        'Outdoor activates to sync and regulate sleep, healing and mental / emotional balance to aid in the natural inclination to fully enjoy and participate in life, for proper heart and lung function',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and contribute to our attitudes, zest and approach to life – an important factor concerning all lifestyle choices. A balanced nervous and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, helps create the optimal awake alert/calm state for enjoyable living and restful sleep. Such a state enables general homeostasis influencing breathing, heart rate and desire to exercise and eat well. ',
        'Our bodies need exercise and that exercise has a tremendous cleansing and anti-inflammatory effect which aids in the performance of our lungs, heart and flushing of our arteries. Likewise the food we eat, determines the fatty deposits, visceral fat, cholesterol levels, and arterial capacity for efficient lung and heart function. ',
        'Nutritional efficiencies (or deficiencies) play a role in blood quality effecting haemoglobin, blood oxygen, energy and mitochondria function - all essential to our cardio systems.',
        'Certain foods, beneficial herbs, vitamins, minerals and supplements can act as heart, lung and artery protectants, processing properties or aiding antioxidant, anti-inflammatory, anticoagulant (blood thinning), bronchodilatory (aiding airways), and antitussive (relieving cough) activities.',
        'The purity of our biosphere, living environment and products used, determines toxin inputs directly related to lung and heart health.',
        'Looking at the whole person and considering all beneficial actions can have a powerful effect on human health. Establishing a heart and lung boosting daily routine can enable rewarding benefits that pay off short and long term for better quality of life.',
        ,
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg3,
    section5ThumbnailBg1: section5HHThumbnailBg1,
    section5ThumbnailBg2: section5HHThumbnailBg2,
    section5ThumbnailBg3: section5HHThumbnailBg3,
    bookConsultant: [
      'Inactivity, weight gain, lower cardio respiratory efficiency, and arterial plague build up can all slowly accumulate over time as a result of poor and unaddressed lifestyle choices. Making changes requires a clear protocol of proactive, yet simple, measures which considers all of these influencing factors. Book in with us to work through a Self-Advancing Protocol to help improve and fortify your heart, lung and cardio systems and enjoy life more.  ',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Brain health Protocol',
    thumbnailSectionSubTitle: 'Engage the other key factors to a Power Immune System',
    thumbnailCardText1: '3D Avatars & Reports',
    thumbnailCardText2: 'Track Weight Loss, Body Comp, & Posture',
    thumbnailCardText3: 'Posture & Balance',
    path: 'heart-health',
  },
  {
    id: 4,
    title: 'Cellular Health',
    subText: 'Health Protocol 4',
    mainTitle: 'Pure Cell Protocol   ',
    heroThumbnail: heroThumbnailCH,
    subTitle: 'Simple and fundamental daily health habits to benefit your cellular well-being',
    contents:
      'The Pure Cell Protocol focuses on the most fundamental aspects to naturally fortify the cells of the body and brain from cellular damage (genetic changes, cell mutation, abnormal cell division, necrosis and apoptosis). Excessive cell damage and compromised repair mechanisms can result in premature aging and the early onset of disease. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements and elements of nature',

    factor: {
      factorsImage: factorsImg4,
      factorContents: [
        'Better cardiorespiratory and cardiovascular efficiency through improved mitochondria function',
        'Greater calm, stress and trauma processing and mental well-being',
        'A balanced and highly enjoyable and balanced alert/calm state of living',
        'Increased emotional vibrancy, vagal tone, and general happiness',
        'Potential for minimising risk of associated disease conditions  - Consequat imperdiet velit odio eget dolor. ',
        'The encouragement of healthy cell regeneration for greater health, youthfulness and vitality.',
      ],
      factorTitle: 'Establish enhancing cellular health habits',
    },
    section3Title: 'Cellular Health',
    section3Image: section3Img4,
    section3RightImage: section3RightImg4,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with healthy cell regeneration ',
      bannerContent: [
        'Optimal foods for a healthy and more diverse microbiome profile to reduce inflammation and sugar loads - the base cause of most cell dysfunction and disease. Improve healthy fats, lower blood sugar levels, increase metabolic flexibility and fuel the body with the proper energy it needs to aid exercise',
        'Detoxification, gut repair and parasite elimination to release the hand brakes on the digestive system to enable more energy for increased cellular regeneration',
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques to restore and boost confidence associated with proactive behaviour to enable beneficial food and exercise habits conducive to cell health ',
        'Age related cardiorespiratory and cardiovascular fitness exercises to gradually improve cell health, metabolic flexibility and aid the endocannabinoid system',
        'Beneficial herbs, vitamins, minerals and supplements as cellular protectants, enhancing regulation of cellular energy homeostasis and aiding DNA repair and cell regeneration ',
        'Outdoor activates to sync and regulate sleep, healing and mental / emotional balance to aid in the natural inclination to fully enjoy and participate in life, for better cellular repair and function',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and contribute to our attitudes, beliefs, and emotional vibrancy which science has shown effects the very cells of our bodies through neurotransmitters, hormones and peptides. A balanced nervous and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, helps create the optimal awake alert/calm state for enjoyable living and restful sleep conducive to cell health. ',
        'Our trillions of cells thrive on exercise, as they are oxygenated for greater mitochondria function, this along with proper nutritional efficiencies supports energy production of the body. Certain beneficial herbs, vitamins, minerals and supplements can act as too promote cell health, while quality drinking water aids balanced pH, minerals and intracellular and extracellular hydration.',
        'The purity of our biosphere, living environment and products used, determines toxin inputs directly related to cellular integrity. Looking at the whole person, and considering all beneficial actions, can have a powerful effect on human health. Establishing a Cell health routine can enable rewarding benefits that pay off short and long term for a better quality of life.  ',
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg4,
    section5ThumbnailBg1: section5CHThumbnailBg1,
    section5ThumbnailBg2: section5CHThumbnailBg2,
    section5ThumbnailBg3: section5CHThumbnailBg3,
    bookConsultant: [
      'Cell damage and repair is happening in our bodies all the time. Cell regeneration and particular DNA repair is occurring at approx. 70,000 events per day all without our knowledge. Optimal cell repair and regeneration is vital to healthy overall function of the human body, homeostasis and to ward off biologically degenerating conditions such as various cancers and disease. Mitochondria efficiency within our cells provide the base for energy production, vitality and youthfulness. Ongoing efficiency of cell repair equates to both greater life expectancy and quality of life – health span. Making changes requires a clear protocol of proactive, yet simple, measures which considers all of these influencing factors. Book in with us to work through a Self-Advancing Protocol to help improve and fortify your cell health, energy levels, radiance and longevity.  ',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Cellular health Protocol',
    thumbnailSectionSubTitle: 'Morbi blandit nec est vitae dictum.',
    thumbnailCardText1: 'Commodo etiam maximus',
    thumbnailCardText2: 'Condimentum est posuere',
    thumbnailCardText3: 'Nisl consectetur pellentesque',
    path: 'cellular-health',
  },
  {
    id: 5,
    title: 'Bone and Cartilage',
    subText: 'Health Protocol 5',
    mainTitle: 'Strong Bone and Cartilage Protocol',
    heroThumbnail: heroThumbnailBCH,
    subTitle:
      'Simple and fundamental daily health habits to benefit your bone, cartilage and associated skeletal well-being',
    contents:
      'The Strong Bones and Cartilage Protocol focuses on the most fundamental aspects to naturally fortify, repair and maintain the bones, cartilage and skeletal system. \r\n \r\nExcessive wear and tear all at once through intense repetitive action, or more gradually over a life time, compromises the ability of skeletal cartilage to repair. This can be especially evident in high performance athletes, those that do high impact sports and the elderly. Bone strength and can also deteriorate over years of nutritional and life style abuse. \r\n \r\nAwareness and prevention in early stages of life might mean the difference of full mobility or not in later years. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements and elements of nature. ',
    factor: {
      factorsImage: factorsImg5,
      factorContents: [
        'Better cardiorespiratory and cardiovascular efficiency through improved mobility and activity',
        'A balanced and more enjoyable state of living',
        'Relieve aches and pains associated with cartilage and bone issues',
        'Better range of motion and participation in life',
        'Age appropriate, low impact exercises to maintain bone density and strength',
        'Long term potential to repair cartilage and improve osteoarthritis and associated skeletal conditions',
      ],
      factorTitle: 'Factors',
    },
    section3Title: 'Cellular integrity Interactions and Factors ',
    section3Image: section3Img5,
    section3RightImage: section3RightImg5,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with bone density, cartilage integrity and the skeletal system',
      bannerContent: [
        'Optimal foods, with the ideal nutrients, for a healthy and more diverse microbiome profile to aid skeletal health and reduce inflammation and sugar loads - the base cause of most disease including arthritis. Improve healthy fats, lower blood sugar levels, increase metabolic flexibility and fuel the body with the proper energy it needs to aid exercise, while reducing weight on cartilage and joints. Detoxification, gut repair and parasite elimination to improve absorption of nutrients vital for skeletal health.',
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques to restore and boost confidence associated with proactive behaviour to enable beneficial habits for better life participation and hence range of motion. Age related cardiorespiratory, cardiovascular and low impact resistance exercises to gradually improve bone density, relieve cartilage stress, and aid endocannabinoid production important to joints. ',
        'Beneficial herbs, vitamins, minerals and supplements as skeletal protectants, enhancing long term maintenance, repair and potential regeneration of nerves, cartilage, and bone density. Outdoor activates to sync and regulate sleep, healing and mental / emotional balance to aid in the natural inclination to fully enjoy and participate in life, so you “use it and don’t lose it!”',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and contribute to our attitudes, beliefs, and emotional vibrancy to keep us active and progressive.',
        'A balanced nervous and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, helps create the optimal awake alert/calm state for enjoyable living and restful sleep essential to all biological repair including skeletal.',
        'The skeletal system can repair but over a much longer time frame compared to the rest of the body. Proper nutritional efficiencies, certain beneficial herbs, vitamins, minerals and supplements are essential for this process, as are balanced blood levels of essential minerals as a result of drinking quality water, and avoiding alcohol.',
        'The purity of our biosphere, living environment and products used, determines toxin inputs potentially related to inflammatory markers associated with skeletal issues such as arthritis. Looking at the whole person, and considering all beneficial actions, can have a powerful effect on human health and provide rewarding benefits that pay off both short and long term for a better quality of life.  ',
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg5,
    section5ThumbnailBg1: section5BCTThumbnailBg1,
    section5ThumbnailBg2: section5BCTThumbnailBg2,
    section5ThumbnailBg3: section5BCTThumbnailBg3,
    bookConsultant: [
      'Skeletal wear and tear is happening in our bodies all the time, especially if we are inflicting over demanding pressures and impacts on our cartilage, and eating foods that promote inflammation; irritating joints and undermining bone density. Optimal repair and regeneration is vital from an early age to maintain cartilage, bone density along with nerve health. ',
      'Paying attention now equates to both better quality of life as we age; mobility, life participation and connection to others. Making changes requires a clear protocol of proactive, yet simple, measures which considers all of these influencing factors. Book in with us to work through a Self-Advancing Protocol to help improve and fortify your skeletal health over a life time. ',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Strong Bone and Cartilage Protocol',
    thumbnailSectionSubTitle: 'Engage the other key factors to Bone and Cartilage health',
    thumbnailCardText1: '3D Avatars & Reports',
    thumbnailCardText2: 'Track Weight Loss, Body Comp, & Posture',
    thumbnailCardText3: 'Posture & Balance',
    path: 'bone-and-cartilage-health',
  },
  {
    id: 6,
    title: 'Increased Health Span',
    subText: 'Health Protocol 6',
    mainTitle: 'Optimal Health Span Protocol',
    heroThumbnail: heroThumbnailIHS,
    subTitle: 'Simple and fundamental daily health habits to benefit your Vitality and Longevity – Health Span  ',
    contents:
      'Health span means living a long healthy life that is basically disease free and loaded with energy, mojo, and hence continued opportunity - as a result of being able to fully participate in life. The Okinawan Elders do it, so why can’t you. The Optimal Health Span Protocol focuses on the most fundamental aspects to live a naturally long, youthful life. We are what we think, feel, breath, eat, see and do. We consciously reside in a Self Protecting and Healing Mind – Body System (SPHMBS). A biological system that was designed to perform at its best in certain favourable conditions, if we respect those conditions we are capable of living in a vibrant and healthy state for more than 100 years. \r\n \r\n We do not want to just live, we want to live with vigour, excitement, joy an happiness, in a state of physical, mental, emotional and spiritual wellbeing that serves ourselves and others. Get the basics laid in stone by simply refining and regimenting; an ideal diet, age appropriate exercise, Health Psychology and social exercises, detoxification, beneficial herbs, supplements and elements of nature.',
    factor: {
      factorsImage: factorsImg6,
      factorContents: [
        'Refined homeostasis for all round nervous, endocrine, cardiorespiratory, cardiovascular, digestive and endocannabinoid efficiency',
        'Maintained and/or improved muscle strength, mobility, flexibility and range of motion enabling continued participation in life',
        'Age appropriate, low impact exercises to maintain bone density, joint and cartilage integrity',
        'The potential for a more youthful appearance, graceful aging and radiance of skin, hair, nails, eye colour and aura in general',
        'A balanced and more enjoyable state of ‘alert/calm’ living that is vibrant, vigorous, and has potential to be generally symptom and pain free',
        'Long term potential to avoid chronic western world diseases',
      ],
      factorTitle: 'Establish enhancing Health Span habits',
    },
    section3Title: 'Health Span Interactions and Factors',
    section3Image: section3Img6,
    section3RightImage: section3RightImg6,
    banner: {
      bannerTitle:
        'Follow a custom protocol to maximise the beneficial factors associated with a longer and more vibrant life',
      bannerContent: [
        'Positive psychology, mediation, and cognitive behavioural therapy Techniques to restore and boost confidence associated with proactive behaviour to enable beneficial habits for better life participation, health orientation, and happiness in general. ',
        'Optimal foods, with the ideal nutrients, for a healthy and more diverse microbiome profile to reduce inflammation, bad fats and sugar loads - the base cause of most western diseases: obesity, type 2 diabetes, cancers, neurodegenerative conditions, heart disease, arthritis, and autoimmune disease.',
        'Age related cardiorespiratory, cardiovascular and low impact resistance exercises to gradually improve bone density, relieve cartilage stress, and aid endocannabinoid production important to joints. Improve healthy fats, lower blood sugar levels, increase metabolic flexibility and contribute quality fuel for the body to enhance exercise, while reducing weight on cartilage and joints. ',
        'Detoxification, gut repair and parasite elimination to improve absorption of nutrients vital for all round health. Beneficial herbs, vitamins, minerals and supplements as protectants, enhancing long term maintenance, repair and potential regeneration of neurons, nerves, cells, and DNA. Compressive assortment of protective mechanisms for beneficial gene expression.',
        'Outdoor activates to sync and regulate sleep, healing and mental / emotional balance to aid in the optimal awake ‘alert /calm’ state, enhancing the inclination towards fully enjoying and participating in life and all it has to offer.',
      ],
    },
    protocolElementSection: {
      title: 'Health Protocol Elements',
      subTitle:
        'We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the whole you - your Self Protecting and Healing Mind – Body System (SPHMBS)',
      content: [
        'The mind itself can influence mental health and wellbeing and contribute to our attitudes, beliefs, and emotional vibrancy to keep us active, progressive, happy and well.',
        'A balanced nervous and endocrine system, especially if in sync with the natural cycles of our earth’s biosphere, helps create the optimal awake alert/calm state for enjoyable living and restful sleep essential to all biological repair.',
        'Our SPHMBS is a contiguous system which is continuously repairing and regenerating DNA at approx. 70,000 cellular events per day. Various components of our biological form regenerate at different rates –some weeks, some years. Proper nutritional efficiencies, certain beneficial herbs, vitamins, minerals and supplements are essential for this maintenance and repair process, as are balanced blood levels of essential minerals, and favourable blood chemistry of emotions induced peptides.',
        'The purity of our biosphere, living environment and products used, determines toxin inputs potentially related to inflammatory markers, fertility, and autoimmune conditions. Looking at the whole person, and considering all beneficial actions, can have a powerful effect on human health and provide rewarding benefits that pay off both short and long term for a longer and higher quality of life – health span.',
      ],
      imgSrc: protocolelement,
    },
    section4BannerCardbg: section4BannerCardBg6,
    section5ThumbnailBg1: section5IHSThumbnailBg1,
    section5ThumbnailBg2: section5IHSThumbnailBg2,
    section5ThumbnailBg3: section5IHSThumbnailBg3,
    bookConsultant: [
      'Gene expression is malleable. The human mind; our outlook and beliefs are able to determine the life style factors that affect our gene expression and hence biological outcomes.',
      'Wear and tear is happening in our bodies all the time, and at a greater rate if we choose to inflict ourselves to over demanding pressures and impacts as a result of little or high impact exercise, excess alcohol consumption, inflammatory / nutrient void foods, environmental and commercial toxins and damaging work, social and relationship conditions. ',
      'Making life changes requires a clear protocol of proactive, yet simple, measures which considers all of these influencing factors. Book in with us to work through a Self-Advancing Protocol to understand how to progressively work towards setting yourself up to experience the most favourable conditions to help improve and fortify your Immune function, cardio fitness, skeletal health, cellular integrity, cognition and neurogenesis over a life time.',
    ],
    protocolName: 'Understanding your immune system',
    thumbnailSectionTitle: 'Increased Health Span',
    thumbnailSectionSubTitle: 'Mauris ante nisl, consectetur et luctus et, porta ut dolor.',
    thumbnailCardText1: '3D Avatars & Reports',
    thumbnailCardText2: 'Track Weight Loss, Body Comp, & Posture',
    thumbnailCardText3: 'Posture & Balance',
    path: 'increased-health-span',
  },
];
