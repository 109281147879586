import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 20.92 27.57"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 0 19.88)"
      >
        <tspan
          x={0}
          y={0}
          style={{
            letterSpacing: 0,
          }}
        >
          {"P"}
        </tspan>
        <tspan x={3.44} y={0}>
          {"alm Oil"}
        </tspan>
        <tspan
          x={4.87}
          y={6.2}
          style={{
            letterSpacing: "-.01em",
          }}
        >
          {"F"}
        </tspan>
        <tspan x={7.84} y={6.2}>
          {"ree"}
        </tspan>
      </text>
      <path
        id="Ellipse_23"
        d="M10.47 14.47c-3.99 0-7.23-3.25-7.23-7.23S6.49 0 10.47 0s7.23 3.25 7.23 7.23-3.25 7.23-7.23 7.23Zm0-14.17C6.65.3 3.54 3.41 3.54 7.23s3.11 6.93 6.93 6.93 6.93-3.11 6.93-6.93S14.3.3 10.47.3Z"
        className="cls-1"
        data-name="Ellipse 23"
      />
      <g id="Group_679" data-name="Group 679">
        <g id="Group_678" data-name="Group 678">
          <path
            id="Path_596"
            d="M13.88 3.72c-.26 0-.51.06-.74.17a3.24 3.24 0 0 0-5.38-.03c-.88-.37-1.89.03-2.26.91A1.719 1.719 0 0 0 6.61 7.1c-.54 2.12.74 4.28 2.86 4.82s4.28-.74 4.82-2.86c.16-.63.16-1.3 0-1.93.93-.22 1.5-1.15 1.28-2.08-.18-.78-.88-1.32-1.68-1.33Zm-8.1 1.72c0-.72.58-1.3 1.3-1.3.16 0 .31.03.46.08-.23.45-.35.95-.34 1.45v.12c-.19.28-.35.57-.47.89-.56-.16-.94-.67-.94-1.25Zm4.65 6.17c-1.95 0-3.53-1.58-3.54-3.53 0-.75.24-1.49.69-2.1a.23.23 0 0 0 .04-.14v-.17a2.819 2.819 0 0 1 2.71-2.93 2.819 2.819 0 0 1 2.93 2.71V5.84c0 .05.01.1.04.14 1.16 1.57.82 3.78-.75 4.94-.61.45-1.34.69-2.1.69Zm3.72-4.9c-.12-.32-.28-.63-.48-.91v-.12c0-.49-.11-.98-.33-1.42.65-.29 1.42 0 1.72.65a1.304 1.304 0 0 1-.92 1.8Z"
            className="cls-1"
            data-name="Path 596"
          />
          <path
            id="Path_597"
            d="M14.13 4.8c-.1 0-.2.02-.28.06-.11.05-.16.19-.1.3.05.11.19.16.3.1.03-.01.06-.02.09-.02.11 0 .21.09.21.21 0 .1-.08.19-.18.21-.12.02-.21.13-.19.25.02.11.11.19.22.19h.03a.654.654 0 0 0-.09-1.3Z"
            className="cls-1"
            data-name="Path 597"
          />
          <path
            id="Path_598"
            d="M7.1 4.86a.568.568 0 0 0-.28-.06.649.649 0 0 0-.09 1.29h.03c.12 0 .22-.1.22-.22 0-.11-.08-.21-.19-.22-.11-.02-.19-.12-.17-.23.01-.1.1-.18.21-.18.03 0 .06 0 .09.02.11.05.24 0 .3-.1a.24.24 0 0 0-.1-.3Z"
            className="cls-1"
            data-name="Path 598"
          />
          <path
            id="Path_599"
            d="M9.46 6.83c.25 0 .45-.2.45-.45s-.2-.45-.45-.45-.45.2-.45.45.2.45.45.45Z"
            className="cls-1"
            data-name="Path 599"
          />
          <path
            id="Path_600"
            d="M11.36 6.83c.25 0 .45-.2.45-.45s-.2-.45-.45-.45-.45.2-.45.45.2.45.45.45Z"
            className="cls-1"
            data-name="Path 600"
          />
          <path
            id="Path_601"
            d="M12.55 8.45a.223.223 0 0 0-.31.05c-.35.61-.98 1.02-1.68 1.09h-.03c-1.07 0-1.94-1.1-1.96-1.12a.222.222 0 0 0-.31-.04c-.1.08-.11.21-.04.31.04.05 1.02 1.29 2.31 1.29h.04c.85-.07 1.61-.55 2.04-1.28.07-.1.05-.24-.05-.31Z"
            className="cls-1"
            data-name="Path 601"
          />
          <path
            id="Path_602"
            d="M9.73 7.74c0 .08.03.24.3.25h.03c.24 0 .28-.15.29-.21v-.02c0-.06-.02-.11-.05-.15a.302.302 0 0 0-.24-.09c-.28 0-.31.16-.32.22Z"
            className="cls-1"
            data-name="Path 602"
          />
          <path
            id="Path_603"
            d="M10.76 8.01h.02c.28 0 .3-.18.3-.23a.27.27 0 0 0-.05-.15.31.31 0 0 0-.25-.09h-.02c-.26 0-.29.16-.3.22 0 .08.03.24.3.25Z"
            className="cls-1"
            data-name="Path 603"
          />
          <path
            id="Path_604"
            d="M12.85 6.08c0-1.1-.73-2.07-1.79-2.37H10.92s-.02 0-.02.01-.01.01-.02.02-.01.02-.02.03l-.51.66-.46-.61h-.01v-.04h-.01v-.01h-.13a2.46 2.46 0 0 0-1.63 2.32v.07c-.34.5-.53 1.09-.53 1.7 0 .83.31 1.63.86 2.25a2.646 2.646 0 0 0 4.19 0c.54-.62.84-1.42.85-2.25 0-.61-.18-1.2-.53-1.7v-.08Zm.11 1.78c0 1.49-1.21 2.85-2.53 2.85S7.9 9.35 7.9 7.86c0-.55.17-1.08.49-1.52a.23.23 0 0 0 .04-.14v-.12c0-.81.48-1.54 1.22-1.87l.53.7s.07.07.12.08H10.41s.07-.04.09-.07l.59-.76c.81.29 1.36 1.06 1.36 1.92v.12s.01.1.04.14c.32.44.49.97.49 1.52Z"
            className="cls-1"
            data-name="Path 604"
          />
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as PalmOilFreeIcon }
