import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';
import BorderRadiusButton from '~/components/ui/BorderRadiusButton';

import CommonComponent from './CommonComponent';
import SectionTitle from '~/components/ui/SectionTitle';
import ImageSrc from '~/assets/images/page/weight-shifter/health_img.png';
import IconPlayVideo from '~/assets/images/page/discover/section2-video-play.png';

const Data = {
  heading: 'Healthy, long term, weight shifting',
  subHeading: 'Integrate all of the information required into one place to plan and create healthy, long term results:',
  bulletPoints: [
    'For elderly wishing to maintain muscle and add bone density',
    'For athletes and body contestants wishing to have exact data to achieve their goals',
    'For overweight people wishing to safely reduce stubborn fat',
    'For toning, improving strength, flexibly, enabling an enthusiasm for life and looking fantastic',
  ],
  benefits: ['Toning and strengthening', 'Toning and fat loss', 'Muscle gain and fat loss', 'Muscle and weight gain'],
};

const styles = (theme) => ({
  root: {
    marginLeft: '9%',
    padding: 5,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  dot: {
    width: 9,
    height: 9,
    display: 'inline-block',
    borderRadius: '5px',
    background: '#8DBF4C',
    marginRight: 8,
  },
  bulletItem: {
    display: 'inline',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '2.2',
    color: '#707070ff',
  },
  subHeading: {color: '#707070', paddingBottom: 10, paddingTop: 15, fontSize: 16},
  toningAndButton: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  benefits: {
    display: 'inline-block',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: 'rgb(5, 146, 255, 0.08)',
    padding: 10,
    textAlign: 'center',
    maxWidth: 260,
    marginBottom: 10,
  },
  benefitsAndButton: {
    marginTop: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      justifyContent: 'center',
    },
  },
});

function HealthyLongTerm(props) {
  const {classes} = props;

  return (
    <CommonComponent reverse={true} imageWidthInPercentage={46} imageSrc={ImageSrc}>
      <div className={classes.root}>
        <SectionTitle text={Data.heading} />
        <Typography variant="body2" className={classes.subHeading}>
          {Data.subHeading}
        </Typography>
        {/* create bullet list component */}
        <Grid container style={{paddingBottom: 18}}>
          {Data.bulletPoints.map((item, index) => (
            <div key={index}>
              <span className={classes.dot} />
              <Typography variant="body2" className={classes.bulletItem}>
                {item}
              </Typography>
            </div>
          ))}
        </Grid>
        {/* create list component */}
        <Grid container className={classes.benefitsAndButton}>
          <Grid item md={6} xs={12} className={classes.benefits}>
            {Data.benefits.map((item, index) => (
              <Typography key={index} style={{color: '#707070', fontSize: 14, marginBottom: 6}} variant="body2">
                {item}
              </Typography>
            ))}
          </Grid>

          <Grid item md={6} xs={12} container direction="column" justify="space-between" alignItems="center">
            <BorderRadiusButton
              text="DOWNLOAD"
              style={{marginTop: 5, width: '85%', marginBottom: 15, height: '44px', maxWidth: 200}}
            />
            <BorderRadiusButton
              hollow
              text="View Live Demo"
              style={{marginTop: 5, marginBottom: 15, width: '85%', height: '44px', maxWidth: 200}}
              icon={IconPlayVideo}
            />
          </Grid>
        </Grid>
      </div>
    </CommonComponent>
  );
}

export default withStyles(styles)(HealthyLongTerm);
