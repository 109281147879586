import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Banner from '../Sections/Banner';
import Filter from '../Sections/Filter';
import {ProductCard} from '~/components/ui';
import BioModal from '~/components/ui/BioModal';
import {Grid, Container, Avatar, Button} from '@material-ui/core';
import BiosphericallyCorrectIcon from '~/assets/images/page/lifeforcefood/biosphericallyCorrectIcon.png';
import {LifeForceSectionDataList} from '~/data';
import LifeForceComponent from '~/components/ui/LifeForceComponent';
import FoodQuestions from '../Detail/Sections/FoodQuestions';
import {useHistory, useLocation} from 'react-router';
import {statusObj} from '~/utils/commonFunc';
import OuterContainer from '~/components/ui/OuterContainer';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import Status from '~/components/ui/Status';
import _ from 'lodash';
import {getPath} from '~/static/routes';
import {useAddProductToCart, useFetchProducts} from 'shared/react-query/hooks';
import {useCheckoutId} from '~/hooks/uselocalForage';
import InfiniteScroll from 'react-infinite-scroll-component';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 440,
      md: 700,
      lg: 950,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  background: {
    background: 'white',
  },
  root: {},
  icon: {
    width: '64px',
    height: 'auto',
  },
}));

const CommonLayout = (props) => {
  const {
    collectionHandle,
    bannerFilterData,
    modifiedCat1ValsAndkeys,
    recipeSortAttributes,
    allergens,
    preferences,
    cat2ValsAndkeys,
    cat4ValsAndkeys,
    getProducts,
    pathname,
    onClickAddToMealPlan,
  } = props;
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const initialFilterState = {
    tag1: null,
    tag2: null,
    tag4: [],
    tag5: null,
    tag6: null,
    searchText: null,
  };
  const listRef = useRef(null);

  const [filters, setFilters] = useState({
    tag1: Object.fromEntries(new URLSearchParams(location.search)).tag1 ?? initialFilterState.tag1,
    tag2: Object.fromEntries(new URLSearchParams(location.search)).tag2 ?? initialFilterState.tag2,
    tag4: !!Object.fromEntries(new URLSearchParams(location.search)).tag4
      ? Object.fromEntries(new URLSearchParams(location.search)).tag4.split(',')
      : initialFilterState.tag4,
    tag5: Object.fromEntries(new URLSearchParams(location.search)).tag5 ?? initialFilterState.tag5,
    tag6: Object.fromEntries(new URLSearchParams(location.search)).tag6 ?? initialFilterState.tag6,
    searchText: Object.fromEntries(new URLSearchParams(location.search)).searchText ?? initialFilterState.searchText,
  });

  useEffect(() => {
    setFilters({
      tag1: Object.fromEntries(new URLSearchParams(location.search)).tag1 ?? initialFilterState.tag1,
      tag2: Object.fromEntries(new URLSearchParams(location.search)).tag2 ?? initialFilterState.tag2,
      tag4: !!Object.fromEntries(new URLSearchParams(location.search)).tag4
        ? Object.fromEntries(new URLSearchParams(location.search)).tag4.split(',')
        : initialFilterState.tag4,
      tag5: Object.fromEntries(new URLSearchParams(location.search)).tag5 ?? initialFilterState.tag5,
      tag6: Object.fromEntries(new URLSearchParams(location.search)).tag6 ?? initialFilterState.tag6,
      searchText: Object.fromEntries(new URLSearchParams(location.search)).searchText ?? initialFilterState.searchText,
    });

    if(location.search?.length){
  listRef.current.scrollIntoView({
    behavior: "smooth",
    block: "start"
  })

}
  
  }, [location.search]);

  const [status, setStatus] = useState(statusObj('done'));


  const filterArrAndIndex = () => {
    const filterArr = [];
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && key !== 'tag6' && key !== 'searchText') {
        if (Array.isArray(value)) {
          filterArr.push(
            ...value.map((e) => {
              if (preferences.map((el) => Object.keys(el)[0]).includes(e)) {
                return e;
              } else {
                return `-${e}`;
              }
            })
          );
        } else {
          filterArr.push(value);
        }
      }
    }
    const index = !!filters.tag6 ? `_${filters.tag6}_desc` : '';
    return {filterArr, index};
  };

  const handleChange = (obj) => {
    if (_.isEqual(filters, obj)) {
      return null;
    }
    const object = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value !== null) {
        if (Array.isArray(value) && !value.length) {
        } else {
          object[key] = value;
        }
      }
    }
    const searchObj = new URLSearchParams(object).toString();
    history.push({
      pathname: getPath(pathname),
      search: searchObj,
    });
    setFilters(obj);

    if(object.hasOwnProperty('searchText')){
      return
    }
    // listRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start"
    // })
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {data: productsData, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage} = useFetchProducts({
    first: 12,
    collectionHandle: collectionHandle,
    filters: [{tag: filters.tag1}],
  });

  const {checkoutId} = useCheckoutId();
  const addItemToCartMutation = useAddProductToCart();

  function combinedEdges(arr) {
    const combinedEdges = [];
    if (!arr) {
      return combinedEdges;
    }
    arr.forEach((element) => {
      if (
        element.collectionByHandle &&
        element.collectionByHandle.products &&
        element.collectionByHandle.products.edges
      ) {
        combinedEdges.push(...element.collectionByHandle.products.edges);
      }
    });

    return combinedEdges;
  }



  useEffect(() => {
    console.log(combinedEdges(productsData?.pages), 'productsData');
  }, [productsData]);
  return (
    <div>
      <div style={{backgroundColor: 'white'}}>
        <Banner setFilters={(obj) => handleChange(obj)} bannerFilterData={bannerFilterData} filters={filters} />
        <FoodQuestions />
        <div ref={listRef}  />
        <Filter
          name={pathname}
          filterData1={modifiedCat1ValsAndkeys}
          filterData2={cat2ValsAndkeys}
          filterData3={cat4ValsAndkeys}
          allergens={allergens}
          preferences={preferences}
          recipeSortAttributes={recipeSortAttributes}
          setFilters={(obj) => {
            handleChange(obj);
          }}
          filters={filters}
          clearFilters={
            _.isEqual(initialFilterState, filters)
              ? null
              : () => {
                  handleChange(initialFilterState);
                }
          }
        />
        <div
          style={{
            color: 'black',
            display: 'block',
            position: 'fixed',
            right: 5,
            top: '50%',
            zIndex: 100,
            transform: 'translateY(-50%)',
          }}
          onClick={handleOpen}
        >
          <Avatar src={BiosphericallyCorrectIcon} className={classes.icon} />
        </div>
        <OuterContainer className={classes.background}>
          <ThemeProvider theme={theme}>
            <Status statusObj={isLoading ? statusObj('loading') : statusObj('done')}>
              <InfiniteScroll
                dataLength={combinedEdges(productsData?.pages).length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={isFetchingNextPage && <Status statusObj={statusObj('loading')} />}
              >
                <Grid container xs={12} justify="center" spacing={2} style={{margin: 0}}>
                  {combinedEdges(productsData?.pages)?.map(({node}, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={node.id}>
                        <ProductCard
                          data={{
                            id: node.id,
                            name: node.title,
                            description: node.descriptionHtml,
                            price: node.variants.nodes?.[0]?.priceV2?.amount,
                            image: node.variants.nodes?.[0]?.image?.src,
                            text_above_product_title:node.text_above_product_title?.value,
                            text_below_product_title:node.text_below_product_title?.value,

                          }}
                          navigateTo={getPath(pathname) + `/${node.handle}`}
                          sortBy={
                            null
                            // filters.tag6
                            //   ? {
                            //       key: recipeSortAttributes.find((e) => Object.keys(e)[0] === filters.tag6)[filters.tag6],
                            //       value: item.attributes[filters.tag6],
                            //     }
                            //   : null
                          }
                          onClickAddToCart={() => {
                            addItemToCartMutation.mutate({
                              cartId: checkoutId,
                              lineItems: {merchandiseId: node.variants.nodes?.[0]?.id, quantity: 1},
                            });
                          }}
                          onClickAddToMealPlan={() => {}}
                        />
                      </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Status>
          </ThemeProvider>
        </OuterContainer>
      </div>
      <OuterContainer className={classes.background}>
        <LifeForceComponent section={LifeForceSectionDataList[0]} lastOne={false} />
      </OuterContainer>
      <BioModal open={open} close={handleClose} />
    </div>
  );
};

export default CommonLayout;
