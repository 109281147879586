import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';

const styles = (theme) => ({
  requirementsCardContentWrapper: {
    marginBottom: '20px',
    backgroundColor: '#ffffff',
  },
  cardImageWrapper: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cardImage: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardTextWrapper: {
    padding: '20px 30px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  requirementsCardTitle: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    marginBottom: '20px',

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.5',
    },
  },
  reqCardInfo: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    fontSize: '16px',
    letterSpacing: '0.72px',
    textAlign: 'left',
    color: '#434343',

    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
});

function ExperiencedSection(props) {
  const {classes, title, text, imageSrc} = props;

  return (
    <Grid container className={classes.requirementsCardContentWrapper}>
      <Grid item md={3} className={classes.cardImageWrapper}>
        <img src={imageSrc} alt="Map" className={classes.cardImage} />
      </Grid>
      <Grid item md={9} className={classes.cardTextWrapper}>
        <Typography variant="h4" className={classes.requirementsCardTitle}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.reqCardInfo}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ExperiencedSection);
