import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const styles = (theme) => ({
  title: {
    color: '#8DBF4C',
    padding: '2px 0 2px 20px',
    borderLeft: 'solid 6px #8DBF4C',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    fontSize: 23,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
});

function SectionTitle(props) {
  const {classes, text} = props;

  return (
    <Typography variant="h5" className={classes.title}>
      {text}
    </Typography>
  );
}

export default withStyles(styles)(SectionTitle);
