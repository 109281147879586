import React, {useRef, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageHeader from '~/components/ui/PageHeader';
import BannerSection from './Sections/BannerSection';
import PerfectHealthSection from './Sections/PerfectHealthSection';
import PreciseNutritionalSection from './Sections/PreciseNutritionalSection';
import NutritionCalculatorSection from './Sections/NutritionCalculatorSection';
import NutrientToxinSection from './Sections/NutrientToxinSection';
import FoodScienceSection from './Sections/FoodScienceSection';
import PuritySection from './Sections/PuritySection';

import Carousel from '~/components/ui/Carousel';
import PerformanceSection from './Sections/PerformanceSection';
import CreateHealthSection from './Sections/CreateHealthSection';
import SelectYourPlan from '~/components/ui/SelectYourPlan';

import GlobalMetawheel from '~/components/ui/GlobalMetawheel';
const styles = (theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
});

function Discover(props) {
  const {classes, location, history} = props;
  const parentRef = useRef(null);

  const scrollToRef = (y) => window.scroll({top: y, left: 0, behavior: 'smooth'});

  const handleClick = (index) => {
    if (!isNaN(index)) scrollToRef(parentRef.current.children[index].offsetTop);
  };

  useEffect(() => {
    if (location.state && location.state.index) {
      scrollToRef(parentRef.current.children[location.state.index].offsetTop);
    } else {
      scrollToRef(0);
    }
  }, [location.state, history]);

  return (
    <div className={classes.root}>
      <BannerSection handleClick={handleClick} />
      <div ref={parentRef}>
        <PerfectHealthSection />
        <PreciseNutritionalSection />
        <NutritionCalculatorSection />
        <NutrientToxinSection />
        <FoodScienceSection />
        <div>
          <PuritySection />
          <Carousel />
        </div>
        <PerformanceSection />
        <CreateHealthSection />
        <SelectYourPlan />
        <GlobalMetawheel />
      </div>
    </div>
  );
}

export default withStyles(styles)(Discover);
