import React, {useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

import {productsData} from './RelevantProductsData';

import NavLeft from '~/assets/images/page/healthProtocols/immuneSystem/ic_guides_nav_left.png';
import NavRight from '~/assets/images/page/healthProtocols/immuneSystem/ic_guides_nav_right.png';

const styles = (theme) => ({
  root: {
    color: '#000',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: '0.32px',
    textAlign: 'center',
    color: '#9aa200',
    textTransform: 'uppercase',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  btnLeft: {
    width: '20px',
    cursor: 'pointer',
  },
  btnRight: {
    width: '20px',
    cursor: 'pointer',
  },
  cardRowBox: {
    marginTop: '15px',
  },
  cardRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: '1',
    margin: '0 30px',
    position: 'relative',
  },
  cardRowItem: {
    display: 'inline-box',
    textAlign: 'center',
    marginRight: '10px',
    width: 'calc(25% - 8px)',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(25% - 7.5px)',
    },
    [theme.breakpoints.only('xs')]: {
      width: 'calc(33.33% - 6.66px)',
    },
  },
  prodImage: {
    width: '80%',
  },
  productTitle: {
    paddingTop: '5px',
    fontFamily: 'Avenir',
    fontSize: '11px',
    fontWeight: '300',
    [theme.breakpoints.only('xs')]: {
      whiteSpace: 'pre-wrap',
    },
  },
});

const prodDataList = productsData.filter((item) => item);

function RelevantProducts(props) {
  const {classes} = props;
  // const {itemData} = props.location;

  const scrollEle = useRef(null);
  // const [selectItem, setSelectItem] = useState(null);
  // let showItem = selectItem ? selectItem : itemData;
  // if (!showItem) {
  //   showItem = prodDataList[0];
  // }

  const navItem = (isLeft) => {
    const parent = scrollEle.current;
    let nextIndex = -1;

    [...parent.children].forEach((child, index) => {
      if (Math.abs(parent.scrollLeft - child.offsetLeft) < 5) {
        if (isLeft && nextIndex < 0) {
          if (index > 0) {
            nextIndex = index - 1;
          } else {
            nextIndex = parent.children.length - 1;
          }
        }
      } else if (Math.abs(parent.scrollLeft + parent.clientWidth - child.offsetLeft - child.offsetWidth) < 5) {
        if (!isLeft && nextIndex < 0) {
          if (index >= parent.children.length - 1) {
            nextIndex = 0;
          } else {
            nextIndex = index + 1;
          }
        }
      }
    });

    if (nextIndex >= 0) {
      parent.children[nextIndex].scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Relevant Products</Typography>
          </Grid>

          <Grid container className={classes.cardRowBox} alignItems="center">
            <img
              className={classes.btnLeft}
              src={NavLeft}
              alt="NavLeft"
              onClick={() => {
                navItem(true);
              }}
            />
            <div className={classes.cardRow} ref={scrollEle}>
              {prodDataList.map((item) => (
                <div
                  key={item.id}
                  className={classes.cardRowItem}
                  // onClick={() => {
                  //   setSelectItem(item);
                  // }}
                >
                  <Grid container direction="column" alignItems="center">
                    <img src={item.image} alt={item.title} className={classes.prodImage} />
                    <span className={classes.productTitle}>{item.title}</span>
                  </Grid>
                </div>
              ))}
            </div>

            <img
              className={classes.btnRight}
              src={NavRight}
              alt="NavRight"
              onClick={() => {
                navItem(false);
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(RelevantProducts);
