import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 21.41 27.76"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="M10.86 14.61c-4.13 0-7.49-3.28-7.49-7.3S6.73 0 10.86 0s7.49 3.28 7.49 7.31-3.36 7.3-7.49 7.3Zm0-14.27c-3.94 0-7.14 3.13-7.14 6.97s3.2 6.96 7.14 6.96S18 11.15 18 7.31 14.79.34 10.86.34Z"
        className="cls-2"
      />
      <g id="leaf">
        <g id="Group_673" data-name="Group 673">
          <g id="Group_672" data-name="Group 672">
            <path
              id="Path_583"
              d="M15.54 6.41h-.02c-.52.07-1.02.19-1.52.35-.39.12-.76.3-1.1.53-.17.12-.32.25-.45.41-.11.13-.2.29-.26.45-.05.14-.06.28-.06.42 0 .15.04.29.1.43.02.06.05.11.08.17.03.06.07.12.11.17.01.02.04.02.06 0a3.32 3.32 0 0 0 .56-.86c.11-.22.24-.44.39-.65.15-.21.34-.4.56-.55.08-.05.19-.02.24.06.04.07.03.17-.04.22-.36.29-.65.66-.83 1.08-.09.16-.18.33-.28.49-.08.13-.17.26-.27.38-.51.49-1.33.86-3.07.77-.12-.01-.23.08-.24.19-.01.12.08.22.2.23h.02c1.86.09 2.78-.3 3.35-.83.15.08.3.15.47.19.21.06.42.08.64.05.08 0 .16-.03.24-.05.08-.02.15-.06.22-.09.07-.04.13-.09.19-.14.06-.06.11-.12.15-.18.12-.18.2-.38.24-.59.04-.26.04-.52 0-.78-.04-.34-.01-.68.07-1.01.07-.28.16-.55.27-.81 0-.02 0-.04-.02-.05Z"
              className="cls-2"
              data-name="Path 583"
            />
          </g>
        </g>
        <g id="Group_675" data-name="Group 675">
          <g id="Group_674" data-name="Group 674">
            <path
              id="Path_584"
              d="M13.97 6.42c-.85-1.63-1.64-2.21-2.41-2.42 0-.17-.02-.33-.06-.49-.05-.2-.14-.4-.27-.56-.05-.06-.11-.12-.17-.18-.06-.05-.13-.1-.19-.14a.849.849 0 0 0-.22-.09c-.08-.02-.16-.04-.24-.05-.22-.01-.44.02-.65.1-.25.09-.48.22-.69.38-.28.2-.59.35-.92.44-.28.08-.56.14-.85.17-.02 0-.04.02-.04.05v.02c.32.4.68.77 1.07 1.11.31.27.65.5 1.03.67.19.08.38.14.58.17.18.03.36.02.53-.01.14-.03.28-.09.41-.16.13-.08.24-.18.33-.3.04-.05.07-.1.11-.15.03-.05.06-.11.09-.17 0-.02 0-.04-.02-.05-.18-.03-.35-.05-.53-.06h-.51c-.26.02-.51.02-.77 0-.27-.02-.53-.09-.77-.2a.17.17 0 0 1-.08-.23c.04-.08.14-.12.23-.08.44.16.91.22 1.37.17.19 0 .38-.01.57 0 .16 0 .32.02.48.04.69.18 1.42.7 2.22 2.2.05.1.19.15.29.09s.15-.18.1-.29Z"
              className="cls-2"
              data-name="Path 584"
            />
          </g>
        </g>
        <g id="Group_677" data-name="Group 677">
          <g id="Group_676" data-name="Group 676">
            <path
              id="Path_585"
              d="M9.4 9.43c-.02-.2-.06-.4-.13-.58-.06-.16-.16-.31-.28-.44a1.277 1.277 0 0 0-.78-.39c-.06 0-.13-.01-.19-.01s-.13 0-.19.01c-.02 0-.04.02-.04.04.06.16.13.32.22.48.08.15.17.29.26.43.14.21.27.42.38.65.11.24.19.49.21.75.02.09-.05.18-.14.2-.1.02-.19-.05-.21-.14v-.03c-.07-.45-.26-.88-.54-1.24-.1-.16-.2-.32-.29-.48-.08-.14-.15-.28-.2-.43-.19-.68-.09-1.55.85-2.98.06-.1.02-.23-.08-.29-.1-.05-.22-.03-.28.06-1.01 1.53-1.12 2.5-.93 3.24-.15.08-.28.18-.41.3-.16.15-.28.32-.37.51-.03.07-.06.15-.08.22-.02.08-.02.15-.02.23 0 .08 0 .16.03.24.02.07.05.15.08.22.1.2.24.37.41.5.21.16.44.29.69.39.32.14.61.33.86.56.21.19.41.41.58.63.01.02.04.02.06 0 0 0 .01-.01.01-.02.19-.47.35-.96.45-1.45.09-.39.11-.8.08-1.2Z"
              className="cls-2"
              data-name="Path 585"
            />
          </g>
        </g>
      </g>
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 0 20.07)"
      >
        <tspan x={0} y={0}>
          {"Ce"}
        </tspan>
        <tspan
          x={6.49}
          y={0}
          style={{
            letterSpacing: ".02em",
          }}
        >
          {"r"}
        </tspan>
        <tspan
          x={8.49}
          y={0}
          style={{
            letterSpacing: 0,
          }}
        >
          {"tified"}
        </tspan>
        <tspan x={0.96} y={6.2}>
          {"Organic"}
        </tspan>
      </text>
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as CertifiedOrganicIcon }
