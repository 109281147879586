import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

import ImageBg from '~/assets/images/page/discover/section2-bg.png';
import IconPlayVideo from '~/assets/images/page/discover/section2-video-play.png';

import Image1 from '~/assets/images/page/discover/section2-image-list1.png';
import Image2 from '~/assets/images/page/discover/section2-image-list2.png';
import Image3 from '~/assets/images/page/discover/section2-image-list3.png';

import BorderRadiusButton from '~/components/ui/BorderRadiusButton';
import OuterContainer from '~/components/ui/OuterContainer';

const styles = (theme) => ({
  class0: {
    width: '100%',
    margin: 0,
  },
  leftSection: {
    padding: '0 6%',
  },
  rightSection: {
    background: `url(${ImageBg})`,
    backgroundSize: 'cover',
  },
  titleBox: {
    padding: '5px 0 5px 20px',
    marginLeft: '-26px',
    borderLeft: '6px solid #8DBF4C',
  },
  title: {
    color: '#8DBF4C',
    fontWeight: 'bold',
    borderLeft: '6px solid #8DBF4C',
    paddingLeft: '15px',
  },
  desc: {
    color: '#707070',
    fontSize: '1rem',
  },
  list: {
    marginTop: '30px',
    color: '#5499C7',
  },
  listItem: {
    paddingTop: '3px',
    color: '#5499C7',
  },
  appsTitle: {
    color: '#707070',
  },
  appItemImage: {
    width: '60px',
    height: '80px',
    objectFit: 'contain',
  },
  appItemName: {
    color: '#8DBF4C',
    textDecoration: 'underline',
    marginTop: '10px',
  },
});

function PerfectHealthSection(props) {
  const {classes} = props;

  return (
    <OuterContainer>
      <Grid container className={classes.class0} spacing={4}>
        <Grid container item spacing={4} className={classes.class0} xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Perfect Health
              <Typography variant="body1" className={classes.desc}>
                At your Finger Tips
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.desc}>
              Empower yourself with ultimate wellbeing
            </Typography>
            {[
              '-  The healing powers of food',
              '-  The mindfulness of cellular purity',
              '-  The intelligence of food science',
              '-  The anti-aging enhancements of Intermittent Fasting',
            ].map((item) => (
              <Typography key={item} className={classes.listItem} variant="body1">
                {item}
              </Typography>
            ))}
          </Grid>
          <Grid container item justify="space-between" className={classes.class0} item xs={12}>
            <BorderRadiusButton text="DOWNLOAD" style={{marginTop: '2px'}} />
            <BorderRadiusButton hollow text="View Live Demo" style={{marginTop: '2px'}} icon={IconPlayVideo} />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.appsTitle}>
                Partner Apps
              </Typography>
            </Grid>
            {[
              {
                image: Image1,
                name: 'Meta Wheel',
              },
              {
                image: Image2,
                name: 'Weight Shifter',
              },
              {
                image: Image3,
                name: 'Treasure Box',
              },
            ].map((item) => (
              <Grid item key={item.name} xs>
                <Grid container direction="column" alignItems="center">
                  <img src={item.image} alt={item.name} className={classes.appItemImage} />
                  <Typography className={classes.appItemName}>{item.name}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <img src={ImageBg} style={{maxWidth: '100%', objectFit: 'cover'}} />
        </Grid>
      </Grid>
    </OuterContainer>
  );
}

export default withStyles(styles)(PerfectHealthSection);
