import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import config from './config';

// Replace this with your own config details
const firebaseConfig = config.firebaseConfig;
// console.log(firebaseConfig, 'firebase');

// firebase.initializeApp(firebaseConfig);

// const firebaseConfig = {
//   apiKey: 'AIzaSyB-eXWaWpKLuAqgk7HlLf7i-euuHtkSu_o',
//   authDomain: 'http://localhost:9099/',
//   databaseURL: 'http://localhost:4000?ns=visionforhumanity-896fd',
//   projectId: 'visionforhumanity-896fd',
// };

firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({timestampsInSnapshots: true});
// firebase.auth().useEmulator('http://localhost:9099/');
// firebase.firestore().useEmulator('localhost', 8080);
// firebase.functions().useEmulator('localhost', 5001);

export default firebase;
