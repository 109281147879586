export const styles = (theme) => ({
  backgroundWrapper: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundAttachment: 'fixed',
  },
  content: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    paddingBottom: '10%',
    fontSize: '0.8rem',
  },

  oneLink: {
    display: 'inline',
    fontSize: '1.5em',
    color: '#9aa200',
    fontWeight: 'bold',
    lineHeight: '2.4',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.2em',
      lineHeight: '2.5',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8',
    },
  },
});
