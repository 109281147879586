import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {Typography, withStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';

const styles = (theme) => ({
  content: {
    padding: '4% 20px',
    position: 'relative',
  },
  heading: {fontWeight: 'bold', letterSpacing: '5px', marginTop: '0.5em'},
  textBox: {
    marginTop: '1.5em',
    fontSize: '1em',
    fontWeight: 'normal',
    letterSpacing: '2px',
    lineHeight: '1.5',
    [`@media (max-width:768px)`]: {
      fontSize: '0.75em',
      lineHeight: '1.2',
    },
  },

  text: {
    fontSize: '0.8rem',
    lineHeight: '1.8',
    letterSpacing: '1px',
    opacity: '0.9',
    marginBottom: '2rem',
  },
  viewBtn: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '160px',
    height: '24px',
    border: 'solid 2px #9aa200',
    fontSize: '0.8em',
    lineHeight: '2.2',
    textAlign: 'center',
    letterSpacing: '1.5px',
    '&:hover': {
      backgroundColor: '#9aa200',
    },
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: 'bold',
  },
});

function GuideBox(props) {
  const {classes} = props;
  return (
    <div className={classes.content}>
      <Typography align="center" className={classes.heading}>
        {props.title ? props.title : intl.get(messageKeys.ORGANIZE_GUIDEBOX_TITLE)}
      </Typography>
      <div className={classes.textBox}>
        {' '}
        <Typography className={classes.text}>{props.guideContent}</Typography>
      </div>
      <div className={classes.viewBtn}>
        <Link to="#" className={classes.linkStyle}>
          {props.btnText ? props.btnText : intl.get(messageKeys.ORGANIZE_GUIDEBOX_TAKEME_BUTTON)}
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(GuideBox);
