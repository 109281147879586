import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import OuterContainer from '../../../components/ui/OuterContainer';
import clsx from 'clsx';
import { useHistory } from 'react-router';

const styles = (theme) => ({
  link: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 'normal',
    color: '#9aa200',
    marginLeft: '10px',
  },
  provideTitle: {
    fontFamily: 'Montserrat',
    fontSize: '50px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.45',
    letterSpacing: '10px',
    textAlign: 'left',
    color: '#8c8c8c',
    marginTop: '-1.7%',
    paddingLeft: '10px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      letterSpacing: '5px',
      lineHeight: '25px',
      marginBottom: '0px',
      textAlign: 'center',
    },
  },
  submitButton: {
    letterSpacing: '1.28px',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    color: '#9aa200',
    boxShadow: 'none',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: '20px',
    },
  },
  buttonWrapper: {
    margin: '10px auto 30px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: '1em',
    [theme.breakpoints.down(535)]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    width: '100%',
    fontFamily: 'Montserrat',
    flexDirection: 'column',
    marginBottom: '5rem',
    [theme.breakpoints.down('sm')]: {
      margin: '2rem 0',
    },
    backgroundColor: '#f6f6f6',
    zIndex: 2,
    padding: '2em',
    '& > h2': {
      // fontWeight: 'bold',
      fontWeight: 500,
      marginTop: '-1.4em',
      //fontSize: '3.8rem',
      fontSize: '2rem',
      color: '#8c8c8c',
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(598)]: {
        fontSize: '1.5rem',
        marginTop: '-1.4em',
        textAlign: 'center',
      },
    },
  },
  class3: {
    alignItems: 'center',
    [theme.breakpoints.down(768)]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '&>h3': {
      color: '#9aa300',
      fontWeight: 'bold',
      // fontSize: '2rem',
      fontSize: '1.5rem',
      flex: 1,
      textAlign: 'center',
    },
    '&>p': {
      flex: 1,
      color: '#595959',
      fontSize: '1rem',
    },
  },
  divider: {
    borderBottom: '1.5px rgba(140, 140, 140, 0.21) solid',
    width: '90%',
    alignSelf: 'center',
  },
});
const WhiteButton = withStyles((theme) => ({
  root: {
    color: '#9aa200',
    backgroundColor: '#ffffff',
    fontSize: '13px',
    padding: '10px 50px',
    border: '1px solid #9aa200',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#9AA200',
      color: '#ffffff',
    },
  },
}))(Button);

function ProvideInfo(props) {
  const {classes} = props;
  const history = useHistory();

  return (
    <OuterContainer>
      <div className={clsx(classes.class0, classes.class1)}>
        <h2>WHAT WE PROVIDE</h2>
        <div className={clsx(classes.class0, classes.class3)}>
          <h3>SUCCESS</h3>
          <p>
            We provide it all, you bring the skills, enthusiasm and commitment and we will provide everything else from
            the software and Apps, to the stock, branding, and systems set up - ready to run the store. Most of all we
            provide the encouragement, prosperity plan and strategy to ensure you run a successful Sanctuary Store.
          </p>
        </div>
        <div className={classes.divider}></div>
        <div className={clsx(classes.class0, classes.class3)}>
          <h3>BIOSPHERICALLY CORRECT STORE</h3>
          <p>
            Be assured we have though out all of the Biospherically Correct operating systems: ethical suppliers, ‘Food
            Efficiency Flow (FEF)’ and zero waste management, sustainable labelling and packaging systems,
            self-sufficient energy and water systems, our Intellectual Property Rights, and all supported by our
            technical back up and assistance concerning all arenas of operation.
          </p>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <WhiteButton variant="contained" color="primary" className={classes.submitButton} onClick={()=>{
          history.push('/volunteer')
        }}>
          Submit your application
        </WhiteButton>
        <a className={classes.link} href="mailto:kim@visionproducts.co.nz">
          Have a question ?
        </a>
      </div>
    </OuterContainer>
  );
}

export default withStyles(styles)(ProvideInfo);
