import axios from 'axios';

export const axiosPost = (endpoint, data, token) => {
  return axios({
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token ? `Bearer ${token}` : null,
    },
    url: `${endpoint}`,
    data: data,
  });
};

export const axiosGet = (endpoint, token, data) => {
  return axios({
    method: 'Get',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    url: `${endpoint}`,
    data: data ?? {},
  });
};
