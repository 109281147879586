// import React, {useState} from 'react';
// import {withStyles} from '@material-ui/core/styles';
// import {Link} from 'react-router-dom';
// import {Grid, Select, FormControl, InputLabel, Button, Typography, Container, Box} from '@material-ui/core';
// import PageHeader from '~/components/ui/PageHeader';
// import ShadableCard from '~/components/ui/ShadableCard';
// import StoreTypeBox from '~/components/ui/StoreTypeBox';

// import ImageMap from '~/assets/images/page/about-us/map.png';
// import ImageHead1 from '~/assets/images/page/about-us/head1.png';
// import ImageHead2 from '~/assets/images/page/about-us/head2.png';
// import ImageHead3 from '~/assets/images/page/about-us/head3.png';
// import ImageHead4 from '~/assets/images/page/about-us/head4.png';
// import ImageHead5 from '~/assets/images/page/about-us/head5.png';
// import ImageStore from '~/assets/images/page/about-us/imageStore.png';

// import ServiceList from './Sections/ServiceList';
// import OuterContainer from '../../components/ui/OuterContainer';
// import clsx from 'clsx';

// const TeamMembers = [
//   {
//     id: 1,
//     name: 'Cindy Smith',
//     image: ImageHead1,
//     position: 'Barista',
//     quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
//     intro: 'Cindy is an experienced barista, she has been in Ponsonby Sanctuary store for three years ',
//   },
//   {
//     id: 2,
//     name: null,
//     image: ImageStore,
//   },
//   {
//     id: 3,
//     name: 'John Bill',
//     image: ImageHead2,
//     position: 'Barista',
//     quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
//     intro: 'John is an experienced barista, she has been in Ponsonby Sanctuary store for three years ',
//   },

//   {
//     id: 4,
//     name: 'Roy Sun',
//     image: ImageHead3,
//     position: 'Barista',
//     quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
//     intro: 'Roy is an experienced barista, she has been in Ponsonby Sanctuary store for three years ',
//   },
//   {
//     id: 5,
//     name: 'Allen',
//     image: ImageHead4,
//     position: 'Barista',
//     quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
//     intro: 'Allen is an experienced barista, she has been in Ponsonby Sanctuary store for three years ',
//   },
//   {
//     id: 6,
//     name: 'Taylor',
//     image: ImageHead5,
//     position: 'Barista',
//     quote: ' "To live is the rarest thing in the world. Most people exist, that is all." ',
//     intro: 'Taylor is an experienced barista, she has been in Ponsonby Sanctuary store for three years ',
//   },
// ];

// const styles = (theme) => ({
//   root: {
//     color: 'black',
//   },
//   grow: {
//     flexGrow: 1,
//   },
//   topSectionContainer: {
//     paddingTop: '4%',
//     paddingBottom: '20px',
//     background: 'white',
//   },
//   topSectionLeft: {
//     marginBottom: '30px',
//   },
//   topSectionRight: {
//     marginBottom: '30px',
//     paddingLeft: '50px',
//     [theme.breakpoints.down('sm')]: {
//       paddingLeft: '0px',
//     },
//   },
//   formControl: {
//     flex: 1,
//     margin: '1.5rem 0',
//   },
//   enjoyTitle: {
//     color: '#262626',
//     fontWeight: 'bold',
//     margin: '10px auto',
//   },
//   enjoyInfo: {
//     color: 'rgba(38,38,38,0.8)',
//   },
//   mapTitle: {
//     textAlign: 'center',
//     color: '#9AA200',
//     marginTop: '10px',
//     fontWeight: 'bold',
//   },
//   map: {
//     marginTop: '20px',
//     width: '100%',
//   },
//   teamTitle: {
//     textAlign: 'center',
//     color: '#8C8C8C',
//     margin: '10px auto',
//   },
//   storeName: {
//     fontWeight: '500',
//     color: '#8C8C8C',
//   },
//   squareGridItemRoot: {
//     position: 'relative',
//     paddingTop: '100%',
//   },
//   squareGridItem: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//   },
//   teamMemberHead: {
//     width: '100%',
//     height: '100%',
//   },
//   teamMemberInfo: {
//     background: 'rgba(225,225,225,0.1)',
//     width: '100%',
//     height: '100%',
//     padding: '4%',
//     position: 'relative',
//     textAlign: 'center',
//   },
//   teamMemberNameLabel: {
//     color: 'rgb(12,74,96)',
//     fontWeight: 'bold',
//   },
//   teamMemberName: {
//     color: '#585858',
//   },
//   teamMemberQuote: {
//     fontStyle: 'italic',
//   },
//   iconRow: {
//     position: 'absolute',
//     bottom: '4%',
//     width: '50%',
//     left: '25%',
//   },
//   iconShare: {
//     height: '15px',
//   },
//   experienceBox: {
//     padding: '0 80px',
//     [theme.breakpoints.down('sm')]: {
//       padding: '0 30px',
//     },
//     background: 'rgba(154, 162, 0, 0.06)',
//   },
//   experienceTitle: {
//     color: '#9AA200',
//     fontWeight: 'bold',
//     textAlign: 'right',
//     padding: '10px 30px 10px 10px',
//     [theme.breakpoints.down('sm')]: {
//       padding: '10px',
//       textAlign: 'center',
//     },
//   },
//   experienceInfo: {
//     color: 'rgba(38,38,38,0.8)',
//     padding: '15px',
//   },
//   removeDecoration: {
//     color: '#9AA200',
//     fontWeight: '500',
//     textDecoration: 'none',
//   },

// });

// const ColorButton = withStyles((theme) => ({
//   root: {
//     color: 'white',
//     backgroundColor: '#9AA200',
//     fontSize: '14px',
//     padding: '5px 30px',
//     '&:hover': {
//       backgroundColor: '#686D03',
//     },
//   },
// }))(Button);

// const CustomeSelect = withStyles((theme) => ({
//   root: {
//     padding: '10px 20px',
//   },
// }))(Select);

// function Aboutus(props) {
//   const {classes} = props;
//   const [selectValue, setSelectValue] = useState(0);

//   return (
//     <>
//       <PageHeader text="ABOUT US" />
//       <OuterContainer className={classes.backgroundColor}>
//         <div className={clsx(classes.class0, classes.class1)}>
//           <div className={classes.class3}>
//             <FormControl variant="outlined" className={classes.formControl}>
//               <InputLabel htmlFor="outlined-age-native-simple" style={{transform: 'translate(14px, 12px) scale(1)'}}>
//                 Ponsonby - Express
//               </InputLabel>
//               <CustomeSelect
//                 native
//                 value={selectValue}
//                 onChange={(event) => {
//                   setSelectValue(event.target.value);
//                 }}
//                 label="Ponsonby"
//               >
//                 <option aria-label="None" value="" />
//                 <option value={10}>Newmarket</option>
//                 <option value={20}>Mt Roskill - Express</option>
//                 <option value={30}>Mt Eden</option>
//               </CustomeSelect>
//             </FormControl>
//             <h3>What we enjoy most</h3>
//             <p>
//               Without question the greatest reward of running a Sanctuary Store is the ability to offer a diverse range
//               of functional, yet simple tools to help people experience their full health and vibrancy. At the Ponsonby
//               store we are fortunate to have a great team who all possess outstanding abilities in their own right. Come
//               in and discover your Magnificence in our friendly Store.
//             </p>
//             <h2>Servicing the following areas</h2>
//             <div className={classes.class9}>
//               <img src={ImageMap} />
//             </div>
//           </div>
//           <div className={classes.class4}>
//             <h2 className={classes.class5}>PONSONBY TEAM</h2>
//             <div className={clsx(classes.class0, classes.class6)}>
//               <div className={classes.class7}>
//                 <img src={ImageHead1} />
//               </div>
//               <div className={classes.class7}>
//                 <img src={ImageHead1} />
//               </div>
//               <div className={classes.class7}>
//                 <img src={ImageHead1} />
//               </div>
//               <div className={classes.class7} style={{backgroundColor: 'grey'}}>
//                 {/* <img src={ImageHead1} /> */}
//               </div>
//               <div className={classes.class7}>
//                 <img src={ImageHead1} />
//               </div>
//               <div className={classes.class7}>
//                 <img src={ImageHead1} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </OuterContainer>
//     </>
//     // <div className={classes.root}>
//     //   <PageHeader text="ABOUT US" />

//     //   <div className={classes.topSectionContainer}>
//     //     {/* <div style={{boxSizing: 'border-box', border: '1px red solid', width: '100%'}}>
//     //       <div
//     //         style={{
//     //           display: 'flex',
//     //           boxSizing: 'border-box',
//     //           border: '2px blue solid',
//     //           padding: 20,
//     //           flex: 1,
//     //           width: '80%',
//     //           minWidth: 1024,
//     //           margin: '0 auto',
//     //         }}
//     //       >
//     //         <Grid container>
//     //           <Grid item md={4} sm={12} xs={12} className={classes.topSectionLeft}>
//     //             <Grid container>
//     //               <FormControl variant="outlined" className={classes.formControl}>
//     //                 <InputLabel
//     //                   htmlFor="outlined-age-native-simple"
//     //                   style={{transform: 'translate(14px, 12px) scale(1)'}}
//     //                 >
//     //                   Ponsonby - Express
//     //                 </InputLabel>
//     //                 <CustomeSelect
//     //                   native
//     //                   value={selectValue}
//     //                   onChange={(event) => {
//     //                     setSelectValue(event.target.value);
//     //                   }}
//     //                   label="Ponsonby"
//     //                 >
//     //                   <option aria-label="None" value="" />
//     //                   <option value={10}>Newmarket</option>
//     //                   <option value={20}>Mt Roskill - Express</option>
//     //                   <option value={30}>Mt Eden</option>
//     //                 </CustomeSelect>
//     //               </FormControl>
//     //             </Grid>
//     //             <Box mt={2} mb={2}>
//     //               {' '}
//     //               <Typography variant="subtitle1" className={classes.enjoyTitle}>
//     //                 What we enjoy most
//     //               </Typography>
//     //             </Box>
//     //             <Typography variant="body2" className={classes.enjoyInfo}>
//     //               Without question the greatest reward of running a Sanctuary Store is the ability to offer a diverse
//     //               range of functional, yet simple tools to help people experience their full health and vibrancy. At the
//     //               Ponsonby store we are fortunate to have a great team who all possess outstanding abilities in their
//     //               own right. Come in and discover your Magnificence in our friendly Store.
//     //             </Typography>
//     //             <Box mt={1} mb={1} display="flex" justifyContent="space-between">
//     //               <Typography variant="subtitle1" className={classes.mapTitle}>
//     //                 Servicing the following areas
//     //               </Typography>
//     //               <ColorButton variant="contained" color="primary" className={classes.margin}>
//     //                 <Link style={{color: 'white', textDecoration: 'none'}} to="/findastore">
//     //                   FIND A STORE
//     //                 </Link>
//     //               </ColorButton>
//     //             </Box>

//     //             <img src={ImageMap} alt="Map" className={classes.map} />
//     //           </Grid>
//     //           <Grid item md={8} sm={12} xs={12} className={classes.topSectionRight}>
//     //             <Box mb={2}>
//     //               <Typography variant="h6" className={classes.teamTitle}>
//     //                 PONSONBY TEAM
//     //               </Typography>
//     //             </Box>
//     //             <Grid container spacing={2} style={{marginTop: '10px'}}>
//     //               {TeamMembers.map((member, index) => (
//     //                 <Grid key={index} item md={4} sm={4} xs={6}>
//     //                   <div className={classes.squareGridItemRoot}>
//     //                     <div className={classes.squareGridItem}>
//     //                       {member.name === null ? (
//     //                         <img src={member.image} className={classes.teamMemberHead} alt="Team Member" />
//     //                       ) : (
//     //                         <  member={member} />
//     //                       )}
//     //                     </div>
//     //                   </div>
//     //                 </Grid>
//     //               ))}
//     //             </Grid>
//     //           </Grid>
//     //         </Grid>

//     //       </div> */}
//     //     </div>
//     //     <Container maxWidth="lg"></Container>
//     //   </div>
//     //   <StoreTypeBox />
//     //   <Box m={3} className={classes.viewBtn} textAlign="center">
//     //     <Button className={classes.viewText} size="large">
//     //       {'>> '} Find out more at Urban Sanctuary
//     //     </Button>
//     //   </Box>
//     //   <div className={classes.experienceBox}>
//     //     <Grid container alignItems="center">
//     //       <Grid item md={6} xs={12}>
//     //         <Typography variant="h5" className={classes.experienceTitle}>
//     //           Biospherically Correct Shopping Experience
//     //         </Typography>
//     //       </Grid>
//     //       <Grid item md={6} xs={12}>
//     //         <Typography variant="body2" className={classes.experienceInfo}>
//     //           Our Sanctuary Stores are designed to operate with eco processes to aid our planet, and are run by skilled
//     //           staff focused on enriching you, our customers, by offering a combination of advanced well-being services
//     //           and supplies to increase your Health Span. See Prosper to view the Plato Franchise opportunity.
//     //         </Typography>
//     //       </Grid>
//     //     </Grid>
//     //   </div>
//     //   <ServiceList />
//     // </div>
//   );
// }

// export default withStyles(styles)(Aboutus);

import React from 'react';
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';

const Aboutus = (props) => {
  return (
    <div>
      <Section1 /> 
      <div style={{height: '1rem', backgroundColor: 'white'}}></div> 
       <Section2 />
      <Section3 />
    </div>
  );
};

export default Aboutus;
