import React, {useState, useEffect} from 'react';
import ProtocolsDetailPage from './Sections/ProtocolsDetailPage';
import {PageData} from './Sections/pageData';

function Protocols(props) {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    PageData.forEach((onePage) => {
      if (props.location && props.location.pathname.includes(onePage.path)) {
        setPageData(onePage);
      }
    });
  }, [props.location]);
  return <div>{pageData && <ProtocolsDetailPage pageData={pageData} />}</div>;
}

export default Protocols;
