import React from 'react';
import {withStyles, Box} from '@material-ui/core';
import {Container} from '@material-ui/core';
import Coverflow from 'react-coverflow';

import useWindowWidth from '~/hooks/useWindowWidth';
import Image0 from '~/assets/images/page/register/image0.png';
import Image1 from '~/assets/images/page/register/image1.png';
import Image2 from '~/assets/images/page/register/image2.png';
import Image3 from '~/assets/images/page/register/image3.png';
import Image4 from '~/assets/images/page/register/image4.png';
import Image5 from '~/assets/images/page/register/image5.png';
import Image6 from '~/assets/images/page/register/image6.png';

import HealthProtocols from '~/components/ui/HealthProtocols';
import { useHistory } from 'react-router';
import { getPath } from '~/static/routes';
import AllHealthGuides from '../healthGuides/Sections/AllHealthGuides';
import Disclaimer from '../healthGuides/Sections/Disclaimer';
import ProtocolsCard from './Sections/ProtocolsCard';
import HealthProtocolsPhilosophy from './Sections/HealthProtocolsPhilosophy';
import GuideIntro from './Sections/GuideIntro';


const styles = (theme) => ({
  root: {
    // padding: '6% 0 3%',
    // [theme.breakpoints.down('sm')]: {
    //   padding: '0 30px',
    // },
  },

  coverFlow: {
    backgroundColor: '#0000001c',
  },
  mainContent: {
    background: 'white',
    marginTop: '20px',
  },
});
const routes = [{title: 'Products and Services', to: '/productandservice'}];

function AllHealthProtocols(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Box className={classes.mainContent}>
      <ProtocolsCard />
      <HealthProtocolsPhilosophy/>
        <GuideIntro />
        <AllHealthGuides />
        <HealthProtocols />
        <Disclaimer />
      </Box>
    </div>
  );
}

export default withStyles(styles)(AllHealthProtocols);
