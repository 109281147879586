import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import visionairebutton from '~/assets/images/layout/visionaire-btn.png';
import {Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  title: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {},
  },
  imgWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
});

const Section1 = (props) => {
  const {classes} = props;
  return (
    <Grid
      container
      item
      xs={12}
      sm={4} md={5}
      spacing={2}
      alignItems="flex-start"
      style={{height: '100%', width: '100%', margin: 0}}
    >
      <Grid item xs={12}>
        <Typography className={classes.title}>Sign up and become a Sanctuary Store Visionaire</Typography>
      </Grid>
      <Grid container spacing={3} item style={{margin: 0, width: '100%'}} xs={12} justify="center" alignItems="center">
        <Grid item style={{maxWidth: '12rem'}}>
          <img src={visionairebutton} style={{maxWidth: '100%'}} className={classes.imgWrapper} />
        </Grid>
        <Grid item>
        <Typography className={classes.class3}>© VFH Sanctuaries 2023. The information, designs and concepts contained herein is the 
copyright and intellectual property of Vision for Humanity Charitable Trust.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Section1);
