import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import visionairebutton from '~/assets/images/layout/visionaire-btn.png';
import OuterContainer from '~/components/ui/OuterContainer';
import {TextField, Button} from '@material-ui/core';
import {getPath} from '~/static/routes';
import {useHistory} from 'react-router-dom';

const styles = (theme) => ({
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(847)]: {
      flexDirection: 'column',
    },
  },
  class2: {
    flex: 0.5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&>img': {
      width: '18em',
    },
    '&>p': {
      color: '#798004',
      fontSize: '1rem',
      fontWeight: 300,
    },
    cursor: 'pointer',
  },
  class3: {
    flex: 1,
    flexDirection: 'column',
    color: '#8C8C8C',
    [theme.breakpoints.down(847)]: {
      textAlign: 'center',
      alignItems: 'center',
    },
    '&>h2': {
      fontWeight: 500,
      margin: 0,
      fontSize: '1.5rem',
    },
    '&>p': {
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '1rem',
    },
  },
  backgroundColor: {
    backgroundColor: 'rgb(250,250,242)',
    padding: '24px 16px',
  },
  class4: {
    justifyContent: 'space-between',
    maxWidth: 666,
    [theme.breakpoints.down(847)]: {
      width: 666,
    },
    [theme.breakpoints.down(720)]: {
      flexDirection: 'column',
      maxWidth: 400,
      width: '100%',
    },
  },
  class5: {
    minWidth: '40%',
    [theme.breakpoints.down(720)]: {
      marginTop: 16,
    },
  },
});

const OutlinedButton = withStyles((theme) => ({
  root: {
    marginTop: 2,
    borderColor: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    color: '#9aa200',
    borderWidth: 2,
    '&:hover': {
      borderColor: '#9aa200',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: '#9aa200',
    },
    '&:focus': {
      boxShadow: '#9aa200',
    },
  },
}))(Button);

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#9aa200',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9aa200',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#9aa200',
      },
      '&:hover fieldset': {
        borderColor: '#9aa200',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9aa200',
      },
    },
  },
})(TextField);

const Section1 = (props) => {
  const {classes} = props;
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const onClickSignup = () => {
    history.push(`${getPath('Get Started')}?firstName=${firstName}&email=${email}`);
  };
  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1)}>
        <div className={clsx(classes.class0, classes.class2)} onClick={onClickSignup}>
          <img src={visionairebutton} />
          <p>Create your account today!</p>
        </div>
        <div className={clsx(classes.class0, classes.class3)}>
          <h2>Sign up to receive health span tips from the Sanctuary Stores Six Health Guides, create and save meal plans, and later access and integrate data with our health Apps</h2>
          <p>
          Join us now and get a weekly well-being strategy to incorporate these essential health keys into your life for greater vitality and longevity.
          </p>
          <form className={clsx(classes.class0, classes.class4)} autoComplete="0ff">
            <CssTextField
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              className={classes.class5}
              label="First name"
              id="firstName"
              variant="outlined"
              size="small"
            />
            <CssTextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={classes.class5}
              label="Email"
              id="email"
              variant="outlined"
              size="small"
            />
            <OutlinedButton
              onClick={onClickSignup}
              className={classes.class5}
              style={{minWidth: 64}}
              variant="outlined"
            >
              SignUp
            </OutlinedButton>
          </form>
        </div>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(Section1);
