import {withStyles} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import {setBackground} from '~/store/actions';
import HealthProtocol from './HealthProtocol';

const styles = (theme) => ({});

function ProtocolsDetailPage(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.root}>
      <HealthProtocol pageData={pageData} />
    </div>
  );
}

export default connect(null, {setBackground})(withStyles(styles)(ProtocolsDetailPage));
