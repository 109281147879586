import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import bannerCardBg from '~/assets/images/page/3dscanning/background.png';

const styles = (theme) => ({
  bannerCardWrapper: {
    background: `url(${bannerCardBg}) no-repeat fixed`,
    backgroundSize: 'cover',
    zIndex: '2',
    position: 'relative',
    minHeight: '400px',
    marginTop: '-200px',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 10,
    },
  },
  videoImage: {
    width: '300px',
  },
  title: {
    color: '#ffffff',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '75px 0px 15px 20px',
  },
  subTitle: {
    fontSize: '11px',
    marginLeft: '20px',
  },
  description: {
    fontSize: '20px',
    fontFamily: 'AmazonEmberDisplay',
  },
  list: {
    marginTop: '30px',
    color: 'rgba(240, 240, 238, 0.4)',
    fontFamily: 'AmazonEmberDisplay',
  },
  listItem: {
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
});

function BannercardSection(props) {
  const {classes} = props;
  return (
    <div className={classes.bannerCardWrapper}>
      <Container>
        <div className={classes.root}>
          <Grid container className={classes.root}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h4" className={classes.title}>
                Lorem Title
              </Typography>
              <Typography variant="body1" className={classes.subTitle}>
                Giant stars have a much lower surface gravity
              </Typography>
              <div className={classes.list}>
                {[
                  '. Body fat, lean mass, & posture reports.',
                  '. Simple reports online of via our app.',
                  '. Full body circumference measurements.',
                  '. Stop the hassle of paper printouts & empty ink cartridges.',
                ].map((item) => (
                  <Typography key={item} className={classes.listItem}>
                    {item}
                  </Typography>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(BannercardSection);
