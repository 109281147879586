import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 18.27 27.47"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 2.32 19.78)"
      >
        <tspan x={0} y={0}>
          {"100%"}
        </tspan>
        <tspan x={-2.26} y={6.2}>
          {"Natu"}
        </tspan>
        <tspan
          x={9.48}
          y={6.2}
          style={{
            letterSpacing: "-.02em",
          }}
        >
          {"r"}
        </tspan>
        <tspan x={11.23} y={6.2}>
          {"al"}
        </tspan>
      </text>
      <path
        d="M9.15 14.61c-4.13 0-7.49-3.28-7.49-7.3S5.02 0 9.15 0s7.49 3.28 7.49 7.31-3.36 7.3-7.49 7.3Zm0-14.27c-3.93 0-7.14 3.13-7.14 6.97s3.2 6.96 7.14 6.96 7.14-3.12 7.14-6.96S13.09.34 9.15.34Z"
        className="cls-1"
      />
      <g id="sun">
        <g id="Group_1020" data-name="Group 1020">
          <g id="Group_1019" data-name="Group 1019">
            <path
              id="Path_1422"
              d="M13.41 9.64H5c-.18 0-.33.14-.33.32s.15.32.33.32h8.41c.18 0 .33-.14.33-.32s-.15-.32-.33-.32Z"
              className="cls-1"
              data-name="Path 1422"
            />
          </g>
        </g>
        <g id="Group_1022" data-name="Group 1022">
          <g id="Group_1021" data-name="Group 1021">
            <path
              id="Path_1423"
              d="M10.8 10.91H7.61c-.18 0-.33.14-.33.32s.15.32.33.32h3.19c.18 0 .33-.14.33-.32s-.15-.32-.33-.32Z"
              className="cls-1"
              data-name="Path 1423"
            />
          </g>
        </g>
        <g id="Group_1024" data-name="Group 1024">
          <g id="Group_1023" data-name="Group 1023">
            <path
              id="Path_1424"
              d="M9.2 5.76c-1.4 0-2.54 1.11-2.54 2.47 0 .78.38 1.52 1.02 1.99.15.11.35.08.46-.06s.08-.34-.06-.45a1.81 1.81 0 0 1-.39-2.57c.62-.81 1.8-.98 2.63-.38a1.81 1.81 0 0 1 0 2.95c-.15.11-.18.31-.07.45s.31.17.46.07c1.13-.81 1.36-2.37.52-3.46-.48-.63-1.24-1-2.04-1Z"
              className="cls-1"
              data-name="Path 1424"
            />
          </g>
        </g>
        <g id="Group_1026" data-name="Group 1026">
          <g id="Group_1025" data-name="Group 1025">
            <path
              id="Path_1425"
              d="M9.2 3.11c-.18 0-.33.14-.33.32v1.71c0 .18.15.32.33.32s.33-.14.33-.32V3.43c0-.18-.15-.32-.33-.32Z"
              className="cls-1"
              data-name="Path 1425"
            />
          </g>
        </g>
        <g id="Group_1028" data-name="Group 1028">
          <g id="Group_1027" data-name="Group 1027">
            <path
              id="Path_1426"
              d="M14.04 7.83h-1.76c-.18 0-.33.14-.33.32s.15.32.33.32h1.76c.18 0 .33-.14.33-.32s-.15-.32-.33-.32Z"
              className="cls-1"
              data-name="Path 1426"
            />
          </g>
        </g>
        <g id="Group_1030" data-name="Group 1030">
          <g id="Group_1029" data-name="Group 1029">
            <path
              id="Path_1427"
              d="M6.13 7.83H4.37c-.18 0-.33.14-.33.32s.15.32.33.32h1.76c.18 0 .33-.14.33-.32s-.15-.32-.33-.32Z"
              className="cls-1"
              data-name="Path 1427"
            />
          </g>
        </g>
        <g id="Group_1032" data-name="Group 1032">
          <g id="Group_1031" data-name="Group 1031">
            <path
              id="Path_1428"
              d="M12.86 4.58a.339.339 0 0 0-.46 0l-1.24 1.21c-.12.13-.1.34.03.45.12.1.31.1.43 0l1.24-1.21c.13-.13.13-.33 0-.45Z"
              className="cls-1"
              data-name="Path 1428"
            />
          </g>
        </g>
        <g id="Group_1034" data-name="Group 1034">
          <g id="Group_1033" data-name="Group 1033">
            <path
              id="Path_1429"
              d="M7.26 5.79 6.02 4.58c-.12-.13-.33-.15-.46-.03a.31.31 0 0 0-.03.45l.03.03L6.8 6.24c.14.12.35.1.46-.03.11-.12.11-.3 0-.42Z"
              className="cls-1"
              data-name="Path 1429"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as NaturalIcon }
