import {GETPRODUCTS} from '../action-types';

const INITIAL_STATE = {
  productsArr: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETPRODUCTS:
      return {...state, productsArr: action.payload};
    default:
      return state;
  }
};
