import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GlobalImpact from '../../NutritionalDetail/Sections/GlobalImpact';
import Nutritional from './Nutritional';
import {products} from '../../lifeforcefood/productMockData';
import foodSciIcon from '~/assets/images/page/lifeforcefood/detail/foodSci.png';
import {Button, Grid} from '@material-ui/core';
import { getPath } from '~/static/routes';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { GreenButton } from '~/components/ui/FormikInputFields';
import useWindowSize from '~/hooks/useWindowSize';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: 24,
  },
  indicator: {
    backgroundColor: '#9aa200', // Update the indicator color here
  },
  scititle: {
    color: '#9aa300',
    fontSize: '16px',
    fontWeight: '700',
    cursor: 'pointer',
  },
  icon: {
    width: '32px',
    marginRight: '16px',
  },
}));

export function FoodTabs({product}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory()
  const { url } = useRouteMatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
const windowSize = useWindowSize()
  return (
    <div className={classes.root}>
      <Tabs
        variant={windowSize.width < 500 ?"scrollable":"fullWidth"}
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        classes={{indicator: classes.indicator}}
      >
        <Tab label="Ingredients" value={0} />
        {product?.preparation?.value && <Tab label="Preparation" value={1} />}
        <Tab label="Nutritional data" value={2} />
        <Tab label="Global Impact" value={3} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div dangerouslySetInnerHTML={{__html: product?.ingredients?.value ?? ''}} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div dangerouslySetInnerHTML={{__html: product?.preparation?.value ?? ''}} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={2}>
          {product?.food_science?.value && (
            <Grid item sm={6}>
              <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                <img src={foodSciIcon} alt="food science icon" className={classes.icon} />
                <Typography className={classes.scititle}>FOOD SCIENCE</Typography>
                <p>{product?.food_science?.value}</p>
              </Box>
            </Grid>
          )}
          <Grid container item sm={6} justify="center">
            <img style={{width: '100%', maxWidth: 300}} src={product?.nutrition_information?.reference?.image?.url} />
          </Grid>
          <Grid container justify='center' item xs={12}>
            <GreenButton
              onClick={() => {
                history.push(`${url}/nutritional-details`);
              }}
            >
              Full Analysis
            </GreenButton>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GlobalImpact />
      </TabPanel>
    </div>
  );
}
