import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import SectionTitle from '~/components/ui/SectionTitle';

import ImageSrc from '~/assets/images/page/weight-shifter/calorie_count.png';
const list = [
  {
    title: 'Nutrition & exact calorie count ',
    description:
      'Weight shifter integrates Health Creator, its direct partner App, to ensure the best nutrition and exact calorie count for losing fat and/or gaining muscle.',
  },
  {
    title: 'Physique management and maintenance',
    description:
      'Once you have achieved your ideal physique, adjust your program to maintain your ultimate weight and form.',
  },
];

const styles = (theme) => ({
  description: {
    color: '#707070',
    padding: '1.2vmax',
    fontSize: '1rem',
    backgroundColor: 'rgba(242,242,242,0.85)',
    textAlign: 'center',
    margin: '1.2vmax 2.4vmax',
  },
  content: {
    padding: '35px 0 0 35px',
    [theme.breakpoints.down('sm')]: {
      padding: 25,
    },
  },
});

function NutritionExactCalorieCount(props) {
  const {classes} = props;

  return (
    <CommonComponent reverse={false} imageWidthInPercentage={55} imageSrc={ImageSrc}>
      <div className={classes.content}>
        <SectionTitle text={list[0].title} />
        <Typography variant="body2" className={classes.description}>
          {list[0].description}
        </Typography>
        <SectionTitle text={list[1].title} />
        <Typography variant="body2" className={classes.description}>
          {list[1].description}
        </Typography>
      </div>
    </CommonComponent>
  );
}

export default withStyles(styles)(NutritionExactCalorieCount);
