import React from 'react';
import {makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import IconVision from '~/assets/images/page/register/icon_vision.png';
import {useDispatch} from 'react-redux';
import Image1 from '~/assets/images/page/healthProtocols/calloutimage.png';
import Image2 from '~/assets/images/page/register/image2.png';
import Image3 from '~/assets/images/page/register/image3.png';
import Image4 from '~/assets/images/page/register/image4.png';
import Image5 from '~/assets/images/page/register/image5.png';
import Image6 from '~/assets/images/page/register/image6.png';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import OuterContainer from '../../components/ui/OuterContainer';
import {signup} from '~/store/actions';
import FormBuilder from 'shared/formBuilder';
import {register} from 'shared/formStructures/register';
import {useLocation, useHistory} from 'react-router';
import {useCreateCustomer} from 'shared/react-query/hooks';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 440,
      md: 768,
      lg: 950,
    },
  },
});
const ImageSeries = [
  {
    image: Image1,
    title: 'Health Psychology',
    desc: 'Principle Health Guide and Book available now',
    path: 'https://vfhurbansanctuary.com/guide-landing-page',
  },
  {
    image: Image2,
    title: 'Life Force Food',
    desc: 'Advance Order',
    path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=lifeforcefood',
  },
  {
    image: Image3,
    title: 'Longevity Activities',
    desc: 'Advance Order',
    path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=longevity-activities',
  },
  {
    image: Image4,
    title: 'Physiological Efficiencies',
    desc: 'Advance Order',
    path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=physiological-efficiencies',
  },
  {
    image: Image5,
    title: 'Natural Remedies',
    desc: 'Advance Order',
    path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=natural-remedies',
  },
  {
    image: Image6,
    title: 'Purity',
    desc: 'Advance Order',
    path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=purity',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  mainContainer: {
    background: 'white',
    color: '#595959',
  },
  leftSection: {
    padding: '30px 0 30px 10%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  divider1: {
    position: 'absolute',
    top: '10%',
    bottom: '10%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  divider2: {
    position: 'absolute',
    right: 0,
    top: '10%',
    bottom: '10%',
    height: '80%',
  },
  registerTitle: {
    fontWeight: '100',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  iconVision: {
    maxWidth: '100%',
  },
  inputListBox: {
    width: '80%',
    [theme.breakpoints.down(600)]: {
      width: '95%',
    },
  },
  inputItem: {
    marginTop: '15px',
    position: 'relative',
  },
  inputLabel: {
    fontSize: '13px',
    color: 'rgba(82,82,82,0.98)',
  },
  errorLabel: {
    fontSize: '13px',
    color: 'red',
  },
  input: {
    height: '45px',
    width: '100%',
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #9AA200',
    borderRadius: '5px',
    padding: '0 5px',
    '&:focus': {
      outline: '#9AA200',
    },
  },
  iconVisibility: {
    height: '15px',
    position: 'absolute',
    right: '15px',
    bottom: '15px',
  },
  checkboxContainer: {
    marginTop: '15px',
  },

  btnSignUp: {
    width: '15ch',
    marginTop: '10px',
    height: '45px',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '45px',
    background: '#9AA200',
    borderRadius: '45px',
    color: 'white',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightSection: {
    padding: '30px 10% 30px 5%',
    color: '#8C8C8C',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },

  rightDesc: {
    fontStyle: 'italic',
    fontFamily: 'Georgia',
  },
  imageGrid: {
    marginTop: '40px',
  },
  imageGridItem: {
    width: 'calc(33.3% - 1px)',
    marginTop: '1.5px',
    [theme.breakpoints.only('xs')]: {
      width: 'calc(50% - 0.5px)',
      marginTop: '1px',
    },
  },
  squareGridItemRoot: {
    position: 'relative',
    paddingTop: '100%',
  },
  squareGridItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  imageItem: {
    width: '100%',
    height: '100%',
  },
  imageCover: {
    background: 'rgba(30, 13, 13, 0.3)',
  },
  imageDesc: {
    color: 'white',
    lineHeight: '1.2',
    wordBreak: 'no-break',
    textAlign: 'center',
    [theme.breakpoints.down(864)]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down(864)]: {
      fontSize: '0.7rem',
    },
  },
  bottomSection: {
    color: 'white',
    background: '#9AA200',
    textAlign: 'center',
    padding: '30px 20%',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },
  blueprint: {
    margin: 0,
    width: '100%',
  },
  cardDesc: {
    fontSize: 15,
    textDecoration: 'underline',
  },
  class1: {
    borderLeft: '1px rgba(0, 0, 0, 0.12) solid',
    borderRight: '1px rgba(0, 0, 0, 0.12) solid',
  },
}));

function Register(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const firstName = Object.fromEntries(new URLSearchParams(location.search)).firstName ?? '';
  const email = Object.fromEntries(new URLSearchParams(location.search)).email ?? '';
  const modifiedRegister = register(firstName, email);

  const customerCreate = useCreateCustomer();
  return (
    <OuterContainer className={classes.mainContainer}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} className={classes.blueprint}>
          <Grid container justify="center" item md={6} spacing={2} className={clsx(classes.blueprint)}>
            <Grid item xs={12} className={classes.blueprint}>
              <Typography variant="h4" className={classes.registerTitle}>
                BECOME A VISIONAIRE
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              justify="center"
              item
              xs={12}
              className={clsx(classes.blueprint, classes.class1)}
            >
              <Grid item xs={12} md={9} className={classes.blueprint} style={{textAlign: 'center'}}>
                <img src={IconVision} className={classes.iconVision} alt="Icon Vision" />
              </Grid>
              <Grid container item xs={12} className={classes.blueprint}>
                <FormBuilder
                  xs={12}
                  spacing={2}
                  justify="center"
                  structure={modifiedRegister}
                  onSubmit={(values) => {
                    return customerCreate
                      .mutateAsync({
                        input: {
                          email: values.email,
                          password: values.password,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          acceptsMarketing: values.tacCheckbox,
                        },
                      })
                      .then((res) => {
                        return new Promise((resolve, reject) => {
                          setTimeout(() => {
                            if(res.customerCreate.userErrors.length){
                              resolve({error: res.customerCreate.userErrors.reduce((acc,cv)=>{
                                return `${cv.message}` + ` ${acc}`
                              },'')});  
                            }else{
                              resolve({info: 'Signup successful. You can sign in now.'});
                            }
                          }, 300);
                        });
                      });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            spacing={2}
            alignItems="flex-start"
            style={{height: '100%', color: '#8C8C8C'}}
            className={classes.blueprint}
          >
            <Grid item xs={12} className={classes.blueprint}>
              <Typography variant="h6">
                Sign up to receive health span tips from the Sanctuary Stores Six Health Guides, create and save meal
                plans, and later access and integrate data with our health Apps once complete.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.blueprint}>
              <Typography variant="body1" className={classes.rightDesc}>
                Join us now and get a weekly well-being strategy to incorporate these essential health keys into your
                life for greater vitality and longevity.
              </Typography>
            </Grid>
            <Grid container spacing={1} item xs={12} className={classes.blueprint}>
              {ImageSeries.map((item, index) => (
                <Grid item key={index} xs={4} className={classes.blueprint}>
                  <AnimationWrapper index={index}>
                    <div style={{position: 'relative', aspectRatio: '1', width: '100%'}}>
                      <img
                        style={{width: '100%', height: '100%', objectFit: 'cover', verticalAlign: 'baseline'}}
                        src={item.image}
                        alt={item.title}
                      ></img>
                      <div
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: 'rgba(30, 13, 13, 0.3)',
                          height: '100%',
                          width: '100%',
                          top: 0,
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          alignItems="center"
                          onClick={() => {
                            if (item.path.startsWith('https://') || item.path.startsWith('http://')) {
                              window.location.href = item.path;
                              return;
                            }
                            history.push(item.path);
                          }}
                          style={{background: 'rgba(255, 255, 255,0.25)', cursor: 'pointer'}}
                        >
                          <Grid item>
                            <Typography variant="body1" align="center" style={{fontSize: 16, color: 'white'}}>
                              {item.title}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{color: 'white'}}
                              variant="subtitle1"
                              align="center"
                              className={classes.cardDesc}
                            >
                              {item.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </AnimationWrapper>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}} className={classes.blueprint}>
              <Typography variant="body1">
                <span style={{color: '#9AA200'}}>Vitality and Longevity Series -</span> Living in Vibrant Health to 120
                Years of Age (a series of six journals)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </OuterContainer>
  );
}

export default Register;
