import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import {flattenedRoutes} from '~/static/routes';

const styles = (theme) => ({
  root: {
    background: 'white',
    overflow: 'hidden',
  },
  imageItem: {
    position: 'relative',
  },
  imageWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
  },
  imageCover: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    color: 'white',
  },

  link: {
    textDecoration: 'none',
  },
  imageDesc: {
    textAlign: 'center',
    marginTop: '10px',
    color: '#9AA300',
    fontWeight: 'bold',
  },
  column5: {
    flexGrow: 0,
    maxWidth: '20%',
    flexBasis: '20%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
});

function ImageRow(props) {
  const {
    classes,
    imageRatio = '100%',
    imageGap = 1,
    imageRowNum = [3, 2],
    imageCover = () => {},
    imageList = [],
  } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={imageGap}>
        {imageList.map((imageItem, index) => (
          <AnimationWrapper key={index} index={index}>
            <Grid
              item
              {...(imageRowNum[0] === 5 ? {md: 12 / 4} : {md: 12 / imageRowNum[0]})}
              // sm={12 / imageRowNum[1]}
              // xs={12 / (imageRowNum[2] || imageRowNum[1])}
              sm={12 / imageRowNum[0]}
              xs={12 / imageRowNum[0]}
              className={clsx(classes.item, {
                // Special case
                [classes.column5]: imageRowNum[0] === 5,
              })}
            >
              {imageItem.path && imageItem.path.includes('https://' || 'http://') ? (
                <a href={imageItem.path} className={classes.link}>
                  <div className={classes.imageItem} style={{paddingTop: imageRatio}}>
                    <div className={classes.imageWrapper} style={{backgroundImage: `url(${imageItem.image})`}}>
                      <div className={classes.imageCover}>{imageCover(imageItem)}</div>
                    </div>
                  </div>
                  {imageItem.desc && (
                    <Typography className={classes.imageDesc} variant="subtitle1">
                      {imageItem.desc}
                    </Typography>
                  )}
                </a>
              ) : (
                <Link to={imageItem.path} className={classes.link}>
                  <div className={classes.imageItem} style={{paddingTop: imageRatio}}>
                    <div className={classes.imageWrapper} style={{backgroundImage: `url(${imageItem.image})`}}>
                      <div className={classes.imageCover}>{imageCover(imageItem)}</div>
                    </div>
                  </div>
                  {imageItem.desc && (
                    <Typography className={classes.imageDesc} variant="subtitle1">
                      {imageItem.desc}
                    </Typography>
                  )}
                </Link>
              )}
            </Grid>
          </AnimationWrapper>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ImageRow);
