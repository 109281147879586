import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import ReadMoreComponent from '~/components/ui/ReadMoreComponent';
import {Grid, Typography, Box, Container} from '@material-ui/core';
import Image1 from '~/assets/images/page/lifeIngredients/show4.png';
import Image2 from '~/assets/images/page/lifeIngredients/show3.png';
import Image3 from '~/assets/images/page/lifeIngredients/show2.png';
import Image4 from '~/assets/images/page/lifeIngredients/show1.png';
import Image5 from '~/assets/images/page/lifeIngredients/show5.png';
import Image6 from '~/assets/images/page/lifeIngredients/show6.png';
import {flattenedRoutes} from '~/static/routes';
import RelevantProducts from '../Sections/RelevantProducts';
import ReleventIngredients from './ReleventIngredients';
import Grow from '@material-ui/core/Grow';
import {SpaceBarTwoTone} from '@material-ui/icons';

const styles = (theme) => ({
  itemWrapper: {
    height: '100%',
    padding: '40px 0px',
  },
  title: {
    color: '#595959',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  desc: {
    color: '#434343',
    width: '84%',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

function HealthInterest(props) {
  const {classes} = props;
  const [productType, setProductType] = useState(null);

  const getShopbyHealthInterestData = (flattenedRoutes) => {
    const img = [Image1, Image2, Image3, Image4, Image6, Image5];
    const objArr = [];
    flattenedRoutes.map((ele) => {
      if (ele.path.includes('health-protocols') && ele.path.split('/').length - 1 > 2) {
        const obj = {
          name: ele.name,
          imageSrc: '',
          path: ele.path,
        };
        objArr.push(obj);
      }
    });

    img.map((item, index) => {
      objArr[index].imageSrc = item;
    });
    ['Health Protocol 1','Health Protocol 2','Health Protocol 3','Health Protocol 4','Health Protocol 5','Health Protocol 6'].map((item, index) => {
      objArr[index].subText = item;
    });
    return objArr;
  };

  const handleClickProtocols = (text) => {
    // console.log('clicked working', text);
    setProductType(text);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        {/* <Grid item md={6} xs={12}> */}
        <Grid item sm={6} xs={12}>
          <Grid container className={classes.itemWrapper} justify="center" alignItems="center">
            <Typography variant="h5" align="center" className={classes.title}>
              HEALTH INTEREST
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item md={6} xs={12}> */}
        <Grid item sm={6} xs={12}>
          <Grid container className={classes.itemWrapper} alignItems="center">
            <span align="justify" className={classes.desc}>
              <Container maxWidth="lg">
                Shop with a focus on optimising or improving a certain aspect of your health through our specialised
                Foods, Protocols and Guides.
              </Container>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container style={{width: '100%', margin: 0}} spacing={1}>
          {getShopbyHealthInterestData(flattenedRoutes).map((item, index) => {
            if (['Immune System', 'Brain Health', 'Heart Health'].indexOf(item.name) > -1) {
              return (
                <Grid item sm={4} xs={12} key={index}>
                  <ReadMoreComponent
                    text={item.name}
                    subText={item.subText}
                    imageSrc={item.imageSrc}
                    height={200}
                    textStyle={{textAlign: 'center', paddingTop: '15%'}}
                    path={item.path}
                    handleClickProtocols={handleClickProtocols}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
        <Grid container style={{width: '100%', margin: 0}} spacing={1}>
          {/* <Grow in={true}> */}
          {/* <RelevantProducts productType={productType} /> */}
          {/* </Grow> */}
        </Grid>
        {/* <ReleventIngredients /> */}
        <Grid container style={{width: '100%', margin: 0}} spacing={1}>
          {getShopbyHealthInterestData(flattenedRoutes).map((item, index) => {
            if (['Cellular Health', 'Bone and Cartilage Health', 'Increased Health Span'].indexOf(item.name) > -1) {
              return (
                <Grid item sm={4} xs={12} key={index}>
                  <ReadMoreComponent
                    text={item.name}
                    subText={item.subText}
                    imageSrc={item.imageSrc}
                    height={200}
                    textStyle={{textAlign: 'center', paddingTop: '15%'}}
                    path={item.path}
                    handleClickProtocols={handleClickProtocols}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </div>
  );
}

export default withStyles(styles)(HealthInterest);
