import React from 'react';
import {withStyles, Grid} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    minHeight: 32,
    position: 'relative',
  },
  line: {
    width: 48,
    height: 2,
    borderRadius: 2,
    marginBottom: 8,
    position: 'relative',
    background: '#9aa200',
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 48,
      marginBottom: 6,
    },
  },
});

function MenuIcon(props) {
  const {classes} = props;
  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.root}>
      <Grid item className={classes.line}></Grid>
      <Grid item className={classes.line}></Grid>
    </Grid>
  );
}

export default withStyles(styles)(MenuIcon);
