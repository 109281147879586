import React, {useState} from 'react';
import {withStyles, Box, TextField, Select, MenuItem} from '@material-ui/core';

import IcSearch from '~/assets/images/page/findastore/ic-search.png';

const styles = (theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
  selectRoot: {
    width: '100%',
    borderRadius: '5px',
    background: '#9AA200',
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&.Mui-focused:after': {
      borderBottom: 'none',
    },
  },
  listBox: {
    border: '1px solid #8C8C8C',
    borderRadius: '5px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
    padding: '10px 20px',
  },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#9AA200',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#9AA200',
      },
    },
  },
})(TextField);

const CssSelect = withStyles({
  root: {
    minHeight: '35px',
    lineHeight: '35px',
    textAlign: 'center',
    fontSize: '1.1em',
    color: 'white',
    borderBottom: 'none',
  },
  icon: {
    color: 'white',
    marginRight: '10px',
  },
})(Select);

function BaseSearch(props) {
  const {classes, inputHint = 'Search Location', doSearch, dataList = [], itemRenderFun} = props;
  const [searchText, setSearchText] = React.useState('');
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };
  const [selectDataIndex, setSelectDataIndex] = useState(0);
  const handleChangeDataIndex = (event) => {
    setSelectDataIndex(event.target.value);
  };
  const [searchResultList, setSearchResultList] = useState([]);

  const showList =
    searchResultList.length > 0 ? searchResultList : dataList.length > 0 ? dataList[selectDataIndex].items : [];

  return (
    <Box className={classes.root} display="flex" height={1} flexDirection="column">
      <Box position="relative">
        <CssTextField
          id="outlined-basic"
          size="small"
          label={inputHint}
          variant="outlined"
          value={searchText}
          style={{width: '100%'}}
          onChange={handleChange}
        />
        <Box
          position="absolute"
          right="0"
          top="0"
          bottom="0"
          onClick={() => setSearchResultList(doSearch(searchText, selectDataIndex))}
        >
          <img src={IcSearch} style={{height: '100%', cursor: 'pointer'}} alt="search" />
        </Box>
      </Box>
      <Box mt="20px">
        <CssSelect
          id="select-menu"
          value={selectDataIndex}
          className={classes.selectRoot}
          onChange={handleChangeDataIndex}
        >
          {dataList.map((item, index) => {
            return (
              <MenuItem value={index} key={item.title}>
                {item.title}
              </MenuItem>
            );
          })}
        </CssSelect>
      </Box>
      <Box className={classes.listBox} flex="1">
        {showList.map((item) => itemRenderFun(item))}
      </Box>
    </Box>
  );
}

export default withStyles(styles)(BaseSearch);
