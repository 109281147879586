import React from 'react';

import {withStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Container} from '@material-ui/core';
import OuterContainer from '../../../components/ui/OuterContainer';
import clsx from 'clsx';
import {getPath} from '~/static/routes';
import {useHistory} from 'react-router';

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    padding: '5px 30px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const styles = (theme) => ({
  root: {
    margin: 'auto',
    textAlign: 'left',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 21,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    color: '#9aa200',
    marginBottom: 12,
    height: 35,
  },
  info: {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.72px',
    textAlign: 'justify',
    color: '#434343',
    padding: '10px',
  },
  highlight: {
    color: '#9AA300',
    cursor: 'pointer',
  },
  padding: {
    padding: '60px 16px',
    backgroundColor: '#f2f2f2',
  },
  class1: {
    textAlign: 'right',
    [theme.breakpoints.down(768)]: {
      textAlign: 'center',
    },
  },
});
const theme = createMuiTheme({
  breakpoints: {
    values: {sm: 768},
  },
});

function PlatoSection(props) {
  const {classes} = props;
  const history = useHistory();

  const handleClickFindOutMore = () => {
    history.push(getPath('prosper'));
  };

  return (
    <ThemeProvider theme={theme}>
      <OuterContainer className={classes.padding}>
        <Grid container className={classes.root}>
          <Grid className={classes.box} item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.title}>THE PLATO FRANCHISE SYSTEM</div>
            <Typography variant="body2" className={classes.info}>
              Participate in our Plato Franchise opportunity and prosper while being instrumental in helping create “A
              Successful Planet”. <br />  <br /> Plato, born 427 BC, a philosopher in Classical Greece, became the founder of
              the Academy in Athens, the first institution of higher learning in the Western world. Plato has become
              widely considered as one of the most respected thinkers of all time. One of Plato’s philosophies was that
              a person’s wage should not be more than 5 times that of another's. Plato was thinking of the power
              structures of the era, however to help establish a sense of equality in the world, we believe this
              thinking is now more important than ever.
              {/* <a className={classes.highlight} href="."> */}
              <br />  <br />
              <span className={classes.highlight} onClick={() => handleClickFindOutMore()}>
                Find out more here.
              </span>
              {/* </a> */}
            </Typography>
          </Grid>

          <Grid className={classes.box} item xs={12} sm={6}>
            <div className={clsx(classes.title, classes.class1)}>
              <ColorButton onClick={handleClickFindOutMore}>ENQUIRE ABOUT THIS</ColorButton>
            </div>

            <Typography variant="body2" className={classes.info}>
              Share your skills and passions with others, form your syndicate, build your teams ability, establish each
              person's role on our operational template, then have your chosen team leader submit your syndicates
              application to us. If you and your team are approved we will help you open your very own Sanctuary Store
              or Urban Sanctuary.
              <br />  <br />
              We believe the Sanctuary and the Plato Franchise System opportunity is the way of the future; creating
              ecologically and financially sustainable pathways forward while collaborating people. Find out more here.
            </Typography>
          </Grid>
        </Grid>
      </OuterContainer>
    </ThemeProvider>
  );
}

export default withStyles(styles)(PlatoSection);
