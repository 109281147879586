import React from 'react';
import * as d3 from 'd3';

const Arc = ({data, index, createArc, colors}) => (
  <g key={index} className="arc">
    <path className="arc" d={createArc(data)} fill={colors[index]} />
  </g>
);

const Pie = (props) => {
  const createPie = d3
    .pie()
    .padAngle(0.05)
    .value((d) => d.value)
    .sort(null);

  const createArc = d3.arc().innerRadius(props.innerRadius).outerRadius(props.outerRadius);

  const colors = props.data.map((item) => item.color);

  const data = createPie(props.data);

  return (
    <svg width={props.width} height={props.height}>
      <g transform={`translate(${props.width / 2} ${props.height / 2})`}>
        {data.map((d, i) => (
          <Arc key={i} index={i} data={d} createArc={createArc} colors={colors} />
        ))}
      </g>
    </svg>
  );
};

function PieChart(props) {
  const {i, width, height, innerRadius, outerRadius, value} = props;

  const pieType = [
    [
      {value: 1, color: '#ea9591'},
      {value: 1, color: '#ecc59c'},
      {value: 1, color: '#d7da9a'},
      {value: 3, color: '#dedede'},
    ],
    [
      {value: 1, color: '#9dc05e'},
      {value: 1, color: '#cadb80'},
      {value: 1, color: '#ea9591'},
      {value: 3, color: '#dedede'},
    ],
    [
      {value: 1.3, color: '#eeaaa7'},
      {value: 0.4, color: '#77c6ae'},
      {value: 1.3, color: '#9390b8'},
      {value: 3, color: '#dedede'},
    ],
    [
      {value: 2.5, color: '#9bbad3'},
      {value: 1.5, color: '#c2dc85'},
      {value: 1.1, color: '#ecc59c'},
      {value: 0.9, color: '#77c6ae'},
    ],
    [
      {value: 55, color: '#7e489c'},
      {value: 22, color: '#9dc05e'},
      {value: 15, color: '#ec5569'},
      {value: 8, color: '#659bbf'},
    ],
  ];

  return (
    <>
      <div className="piechart" style={{position: 'relative'}}>
        <Pie data={pieType[i]} width={width} height={height} innerRadius={innerRadius} outerRadius={outerRadius} />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center',
            color: '#434343',
            fontSize: '12px',
            lineHeight: '1',
          }}
        >
          <p>{value}</p>
        </div>
      </div>
    </>
  );
}

export default PieChart;
