import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  class1: {
    margin: 0,
    textAlign: 'center',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
}));

const MainMenu = (props) => {
  const {routes, setsubmenulvl1Obj, setsubmenulvl2Obj, historyPush, setShowFade1, setShowFade2} = props;
  const classes = useStyles();

  const clickLogic = (element) => {
    setsubmenulvl2Obj(null);
    if(element.url){
      window.open(element.url);
      return
    }
    if (!element.noPath) {
      historyPush(element.path);
    } else {
      setsubmenulvl1Obj(element);
      setShowFade1(true);
    }
  };

  const mouseEnterLogic = (element) => {
    setsubmenulvl2Obj(null);
    if (element.hasOwnProperty('submenu')) {
      setsubmenulvl1Obj(element);
      setShowFade1(true);
    } else {
      setShowFade1(false);
      setShowFade2(false);
    }
  };

  return routes.map((element, index, arr) => {
    return (
      <Grid item key={element.name} xs={Math.floor(12 / arr.length)} className={classes.class1}>
        <Button
          className={classes.text}
          onClick={() => clickLogic(element)}
          onMouseEnter={() => mouseEnterLogic(element)}
        >
          {element.name}
        </Button>
      </Grid>
    );
  });
};

export default MainMenu;
