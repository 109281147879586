import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  factorsWrapper: {
    zIndex: '2',
    position: 'relative',
    backgroundColor: 'white',
  },
  root: {
    zIndex: '2',
    padding: '50px 0px',
    fontFamily: 'Lato',
    [theme.breakpoints.down('sm')]: {
      padding: 'auto 10px',
    },
  },

  title: {
    opacity: '0.8',
    fontSize: '1.5rem',
    fontWeight: '500',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#595959',
    paddingBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  subTitle: {
    fontSize: '1.2rem',
    color: '#454545',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '1.2',
      padding: '10px 20px',
    },
  },
  leftContent: {
    padding: '0 1%',
  },
  leftText: {
    fontSize: '16px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#646464',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '6px 20px',
    },
  },
  imageWrapper: {
    textAlign: 'center',
    width: '95%',
  },
  immuneResponseImage: {
    backgroundColor: '#ffffff',
    margin: '0px auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

const ProtocolElementSection = (props) => {
  const {classes, pageData} = props;

  return (
    <div className={classes.factorsWrapper}>
      <Container>
        <div className={classes.root}>
          <Typography variant="h5" className={classes.title}>
            {pageData.protocolElementSection.title}
          </Typography>
          <Typography className={classes.subTitle}>{pageData.protocolElementSection.subTitle}</Typography>
          <Grid container>
            <Grid item md={6} className={classes.imageWrapper}>
              <img
                src={pageData.protocolElementSection.imgSrc}
                className={classes.immuneResponseImage}
                alt="Our Scanner"
              />
            </Grid>
            <Grid item md={6}>
              <div className={classes.leftContent}>
                {pageData.protocolElementSection.content.map((content, index) => (
                  <Typography variant="body1" className={classes.leftText} key={index}>
                    {content}
                  </Typography>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(ProtocolElementSection);
