import React from 'react';
import {withStyles, Box} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  card: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  media: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.3)'
  },
});

function Card(props) {
  const {classes, ratio, imageSrc, children} = props;
  return (
    <Box
      className={classes.root}
      style={{
        paddingTop: ratio ? ratio : '100%',
      }}
    >
      <div className={classes.card}>
        <div className={classes.media}>
          <img src={imageSrc} className={classes.image} alt="Media" />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </Box>
  );
}

export default withStyles(styles)(Card);
