import coffeeImg from '~/assets/images/page/prosper/barista.jpg';
import lifeforceImg from '~/assets/images/page/prosper/platting-4282016_1280.jpg';
import beautyTechImg from '~/assets/images/page/prosper/Gemini_Generated_Image (1).jpg';
import facilitatorImg from '~/assets/images/page/prosper/Gemini_Generated_Image.png';
import healthProfImg from '~/assets/images/page/prosper/front of house.jpg';

export default [
  {
    title: 'An experienced barista/ bar person for our Natural Highs Bar',
    text:
      `<strong>Craft Euphoric Brews, And Get Your Customers Naturally High!</strong>
      
Brewing happiness! Are you a coffee virtuoso and mocktail connoisseur yearning to craft magic? Join our Natural Highs Bar! Blend barista brilliance with mixology mastery, serving up healthy elixirs, creative brews & delicious drinks. Passionate, positive & a team player? Sanctuary Store has hundreds of delicious health concoctions but needs a person who understands the drink science to make the magic! Attach your resume & prepare to elevate every guest's experience!`,
    imageSrc: coffeeImg,
  },
  {
    title: 'An experienced health oriented chef for our Life Force Food',
    text:
      `<strong>Fuel Life Force! Be Our Health-Focused Chef</strong>
      
Passionate about vibrant, delicious food that nourishes body & soul? At the heart of a Sanctuary Store is a foodie who understands the Life Force that comes from Food! Be this team's experienced, health-oriented chef! Craft innovative, seasonal dishes packed with flavour & functionality. Deep dive into dietary needs, allergies, & preferences, creating personalized culinary experiences. Sanctuary Store has hundreds of delicious health recipes but needs a person who understands food science to correctly engineer the benefits. Join our mission to empower well-being through food – apply now & bring your culinary magic!`,
    imageSrc: lifeforceImg,
  },
  {
    title: 'A beauty technician for all personal care products and services',
    text:
    `<strong>Unleash your creativity! Join our team as a versatile Beauty Technician!</strong>

Do you have a passion for helping people look and feel their best? We're seeking a talented and enthusiastic beauty technician to join our vibrant team. You're savvy knowing a wide range of personalised beauty services, from luxurious facials and soothing massages to ancient anti-aging tips. However, at the heart of your passion is the detail of the natural formulations of the Vison Products Biospherically Correct skin and hair care line. As a true beauty magician, not only do you know how to make skin and hair come alive from these divine products, but how purifying and rejuvenating foods, and lifestyle factors contribute to create long-term natural beauty from the inside out!`,
    imageSrc: beautyTechImg,
  },
  {
    title: 'A health facilitator for our wellness Apps, health testing and literature',
    text:
      `<strong>Be a Wellness Champion! Join us as a Health Facilitator!</strong>

Passionate about empowering others to prioritise their well-being? We seek a driven and knowledgeable Health Facilitator to guide users through our innovative wellness apps, health testing, and educational resources. You'll provide personalised support, help recommend the best food, drink, exercise, and lifestyle choices, answer questions, and spark motivation to help enable healthier customers. Tech-savvy, empathetic, and eager to make a difference? Apply now and help us build a thriving wellness community!`,
    imageSrc: facilitatorImg,
  },
  {
    title: 'A charismatic front of house person for all customer service and waitering',
    text:
      `<strong>Charm & Serve: Become our Front of House Rockstar!</strong>
      
Love creating memorable moments? Crave a fast-paced, dynamic environment? Join our team as a charismatic Front of House star! You'll captivate guests with warm welcomes, expert menu knowledge, and seamless service. From taking orders to beautifying and keeping the store in order, you'll do it all with a smile and infectious energy. Your customer service and all-around skills make you the lynchpin of the team. If you bring passion, personality, and a genuine love for exceeding expectations, apply now!`,
    imageSrc: healthProfImg,
  },
];
