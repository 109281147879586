import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    padding: '20px 0',
  },
  grow: {
    flexGrow: 1,
  },
  itemNormal: {
    color: '#8C8C8C',
    margin: '0 20px',
    borderBottom: '1px solid transparent',
    cursor: 'pointer',
  },
  itemActive: {
    color: '#9AA200',
    borderBottom: '1px solid #9AA200',
  },
});

function TabSwitch(props) {
  const {classes, items, selectedIndex, onSelectIndex} = props;

  return (
    <Grid container alignItems="center" justify="center" className={classes.root}>
      {items.map((item, index) => (
        <Grid
          item
          key={item.text}
          onClick={() => {
            onSelectIndex(index);
          }}
          className={clsx(classes.itemNormal, {
            [classes.itemActive]: index === selectedIndex,
          })}
        >
          <Typography variant="subtitle1">{item.text}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(TabSwitch);
