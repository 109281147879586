import React from 'react';
import {withStyles, Typography, Container, Grid, Card, CardMedia, CardContent, CardActionArea} from '@material-ui/core';
import VisionPack from '~/assets/images/page/giftpacks/vision.jpg';

const styles = (theme) => ({
  root: {
    background: 'white',
    color: 'black',
    padding: '2%',
  },
  quote: {
    width: '300px',
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    margin: 'auto',
  },
  quoteSentence: {
    background: '#9aa200',
    color: 'white',

    padding: '10px',
  },

  quoteDesc: {
    fontSize: '14px',
    padding: '10px',
  },
  visionCard: {
    marginTop: '-200px',
    maxWidth: '520px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      maxWidth: '360px',
    },
  },
  cardContent: {},
  media: {
    height: '480px',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
    },
  },
  visionDesc: {
    fontSize: '14px',
    fontWeight: '300',
    marginTop: '20px',
  },
});

function Quote(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justify="center" spacing={4}>
          <Grid item md={4} xs={12} sm={6}>
            {' '}
            <div className={classes.quote}>
              <div className={classes.quoteSentence}>
                <div>
                  <div>
                    {' '}
                    <Typography variant="h5">{'“'}</Typography>
                  </div>
                  <Typography variant="body1">
                    {' '}
                    Nunc hendrerit a diam at ultricies. Nam ex eros, dapibus egestas metus ultrices, ullamcorper.
                  </Typography>

                  <div style={{textAlign: 'right'}}>
                    <Typography variant="h5">{'”'}</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.quoteDesc}>
                <Typography variant="body2">
                  Nunc hendrerit a diam at ultricies. Nam ex eros, dapibus egestas metus ultrices, ullamcorper euismod
                  libero. Duis ac mi tempus, fringilla eros et, pulvinar lorem. Ullamcorper porttitor non pharetra
                  cursus nisl mollis pellentesque primis penatibus platea, dictum himenaeos eget mi bibendum ad molestie
                  aliquet curae quis quisque, nunc duis ac at elementum dui integer viverra tempus.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item md={8} xs={12} sm={6} className={classes.visionCard}>
            <Card className={classes.cardContent}>
              <CardActionArea>
                <CardMedia className={classes.media} image={VisionPack} title="Contemplative Reptile" />
                <CardContent style={{textAlign: 'right', padding: '2% 10%'}}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Vision Giftpacks
                  </Typography>

                  <Typography variant="body2" color="textSecondary" component="p">
                    See all
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Typography variant="body2" className={classes.visionDesc}>
              Take back the power to achieve your greatest health span, experience the best natural products to enrich
              your personal beauty, vitality and longevity, while nurturing our planet.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(Quote);
