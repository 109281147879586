// import React from 'react';
// import {withStyles} from '@material-ui/core/styles';
// import Image1 from '~/assets/images/page/inbodyscanning/head2.png';
// import Image2 from '~/assets/images/page/inbodyscanning/head4.png';
// import Image3 from '~/assets/images/page/inbodyscanning/newmain901Global@3x.png';

// import clsx from 'clsx';

// const styles = (theme) => ({
//   class1: {
//     display: 'flex',
//     backgroundColor: '#f6f6f6',
//     fontFamily: 'Helvetica',
//     '& h2': {
//       color: '#8c8c8c',
//       fontWeight: 'bold',
//       fontSize: '2.4rem',
//     },
//   },
//   class2: {
//     maxWidth: 1600,
//     width: '100%',
//     margin: '0 auto',
//     padding: '4% 2%',
//   },
//   class3: {
//     display: 'flex',
//     boxSizing: 'border-box',
//     justifyContent: 'space-between',
//     [theme.breakpoints.down(1024)]: {
//       flexDirection: 'column',
//     },
//     '& > div': {
//       flexBasis: '45%',
//     },
//   },
//   class4: {
//     display: 'flex',
//     boxSizing: 'border-box',
//     flexDirection: 'column',
//     color: '#202124',
//     '& > div': {
//       display: 'flex',
//       boxSizing: 'border-box',
//       alignItems: 'center',
//     },
//     '& > div >h3': {
//       fontSize: '2rem',
//       fontWeight: 'bold',
//       marginLeft: '0.5em',
//     },
//     '& > div >img': {
//       width: 60,
//       height: 60,
//       borderRadius: 40,
//     },
//     '& > p': {
//       fontSize: '1.2rem',
//       marginTop: 0,
//     },
//   },
//   class5: {
//     display: 'flex',
//     alignItems: 'center',
//     '& > img': {
//       maxWidth: '100%',
//       maxHeight: '100%',
//     },
//   },
// });

// const CustomerReview = withStyles(styles)((props) => {
//   const {classes} = props;
//   return (
//     <div className={classes.class4}>
//       <div>
//         <img src={props.image} />
//         <h3>{props.name}</h3>
//       </div>
//       <p>{props.comment}</p>
//     </div>
//   );
// });
// const Section4 = (props) => {
//   const {classes} = props;
//   return (
//     <div className={classes.class1}>
//       <div className={clsx(classes.class3, classes.class2)}>
//         <div>
//           <h2>Our Customers</h2>
//           <CustomerReview
//             image={Image1}
//             name="Jonathan Walker"
//             comment="In hac habitasse platea dictumst. Sed nec venenatis odio. Nulla faucibus ipsum sed faucibus accumsan. Donec rhoncus luctus massa vitae lobortis. Duis consequat, nunc a pretium imperdiet, neque est rhoncus massa, tristique rutrum nisl risus at libero."
//           />
//           <CustomerReview
//             image={Image2}
//             name="Uncle Joe"
//             comment="In hac habitasse platea dictumst. Sed nec venenatis odio. Nulla faucibus ipsum sed faucibus accumsan. Donec rhoncus luctus massa vitae lobortis. Duis consequat, nunc a pretium imperdiet, neque est rhoncus massa, tristique rutrum nisl risus at libero."
//           />
//         </div>
//         <div className={classes.class5}>
//           <img src={Image3} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withStyles(styles)(Section4);

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import Image3 from '~/assets/images/page/inbodyscanning/newmain901Global@3x.png';
import OurCustomer from '~/components/ui/OurCustomer';

const styles = (theme) => ({
  ourCustomerWrapper: {
    backgroundColor: '#f6f6f6',
    zIndex: '2',
    position: 'relative',
    padding: '60px 0px',
  },
  title: {
    opacity: '0.8',
    fontFamily: 'Arial',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#8c8c8c',
    marginBottom: '40px',
  },
  videoLink: {
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

function Section4(props) {
  const {classes} = props;
  return (
    <div className={classes.ourCustomerWrapper}>
      <Container>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.title}>Our Customers</Typography>
            <OurCustomer
              initial="J"
              customerName="Jonathan Walker"
              customerComments="In hac habitasse platea dictumst. Sed nec venenatis odio. Nulla faucibus ipsum sed faucibus accumsan. Donec rhoncus luctus massa vitae lobortis. Duis consequat, nunc a pretium imperdiet, neque est rhoncus massa, tristique rutrum nisl risus at libero."
            />
            <OurCustomer
              initial="B"
              customerName="Brenda Mercer"
              customerComments="Lorem ipsum dolor sit amet consectetur adipiscing elit sodales primis, mollis viverra conubia ligula inceptos laoreet libero tortor, nascetur non habitasse iaculis tempor nec egestas fames augue, platea porta integer nostra curae sed arcu. Nec ut diam vulputate ante scelerisque ridiculus lobortis orci mi curae."
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <img src={Image3} className={classes.videoLink} alt="Our Scanner" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(Section4);
