import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import GiftpacksBg from '~/assets/images/page/giftpacks/gfbg.jpg';

const styles = (theme) => ({
  bgWrapper: {
    background: `url(${GiftpacksBg}) no-repeat fixed`,
    backgroundSize: 'cover',
    position: 'relative',
    marginTop: '50px',
    height: '360px',
  },
  root: {
    background: 'rgba(27,27,27,0.4)',
    height: '100%',
  },

  title: {
    color: '#ffffff',
    fontFamily: 'Georia',
    fontSize: '28px',
    fontWeight: 'normal',
    margin: '5% auto 2%',
  },

  description: {
    border: 'dashed 1px rgba(240, 240, 238, 0.8)',
    width: '400px',
    borderRadius: '8px',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
  text: {
    color: 'rgba(240, 240, 238, 0.8)',
    fontFamily: 'Lato',
  },
});

function GiftDescription(props) {
  const {classes} = props;
  return (
    <div className={classes.bgWrapper}>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h4" className={classes.title}>
                Lorem ipsum dolor sit amet
              </Typography>

              <div className={classes.description}>
                <Typography className={classes.text}>
                  Nunc hendrerit a diam at ultricies. Nam ex eros, dapibus egestas metus ultrices, ullamcorper euismod
                  libero. Duis ac mi tempus, fringilla eros et, pulvinar lorem. Dapibus egestas metus ultrices,
                  ullamcorper euismod libero. Duis ac mi tempus, fringilla eros et, pulvinar lorem.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(GiftDescription);
