import React, {useEffect, useRef} from 'react';
import {withStyles, Box} from '@material-ui/core';
import {Container} from '@material-ui/core';
import Coverflow from 'react-coverflow';

import useWindowWidth from '~/hooks/useWindowWidth';
import Image0 from '~/assets/images/page/register/image0.png';
import Image1 from '~/assets/images/page/healthProtocols/calloutimage.png';
import Image2 from '~/assets/images/page/register/image2.png';
import Image3 from '~/assets/images/page/register/image3.png';
import Image4 from '~/assets/images/page/register/image4.png';
import Image5 from '~/assets/images/page/register/image5.png';
import Image6 from '~/assets/images/page/register/image6.png';

import AllHealthGuides from './Sections/AllHealthGuides';
import HealthProtocols from '~/components/ui/HealthProtocols';
import Disclaimer from './Sections/Disclaimer';
import GuideIntro from './Sections/GuideIntro';
import {useHistory} from 'react-router';
import {getPath} from '~/static/routes';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const styles = (theme) => ({
  coverFlow: {
    // backgroundColor: 'rgba(0, 0, 0, 0.20)',
  },
  mainContent: {
    background: 'white',
    marginTop: '20px',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0) translateX(-50%)',
    },
    '40%': {
      transform: 'translateY(-30px) translateX(-50%)',
    },
    '60%': {
      transform: 'translateY(-15px) translateX(-50%)',
    },
  },

  arrow: {
    animation: '$bounce 2s',
    color: '#999797',
    transform: 'translateX(-50%)',
  },
});

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);

function HealthGuides(props) {
  const {classes} = props;
  const width = useWindowWidth();
  const numbers = 4 > Math.ceil(width / 375) ? Math.ceil(width / 375) : 4;
  const history = useHistory();
  const handleMouseEnter = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleMouseLeave = () => {
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    return () => {
      handleMouseLeave();
    };
  }, []);

  const handleCoverFlow = (e) => {
    const path = getPath(e.currentTarget.alt);
    path && history.push(path);
  };
  const myRef = useRef(null);

  return (
    <div className={classes.root}>
      <div className={classes.coverFlow} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Coverflow
          width={width}
          infiniteScroll={true}
          enableScroll={true}
          displayQuantityOfSide={numbers}
          navigation={false}
          enableHeading={true}
          currentFigureScale={2}
          otherFigureScale={1}
          media={{
            '@media (max-width: 900px)': {
              width: '100%',
              height: '82vh',
            },
            '@media (min-width: 900px)': {
              width: '100%',
              height: '82vh',
            },
          }}
        >
          <img style={{aspectRatio:'1/1.3', objectFit:'cover'}} src={Image1} alt="Health Psychology" onClick={(e) => handleCoverFlow(e)} />
          <img src={Image2} style={{aspectRatio:'1/1.3', objectFit:'cover'}} alt="Life Force Food" onClick={(e) => handleCoverFlow(e)} />
          <img src={Image3} style={{aspectRatio:'1/1.3', objectFit:'cover'}} alt="Longevity Activities" onClick={(e) => handleCoverFlow(e)} />
          <img src={Image4} style={{aspectRatio:'1/1.3', objectFit:'cover'}} alt="Physiological Efficiencies" onClick={(e) => handleCoverFlow(e)} />
          <img src={Image5} style={{aspectRatio:'1/1.3', objectFit:'cover'}} alt="Natural Remedies" onClick={(e) => handleCoverFlow(e)} />
          <img src={Image6} style={{aspectRatio:'1/1.3', objectFit:'cover'}} alt="Purity" onClick={(e) => handleCoverFlow(e)} />
        </Coverflow>

        <ArrowDownwardIcon
          className={classes.arrow}
          onClick={() => {
            scrollToRef(myRef);
            handleMouseLeave();
          }}
          style={{position: 'sticky', bottom: '10px', left: '50%', cursor: 'pointer'}}
        />
      </div>

      <Box onMouseEnter={handleMouseLeave} className={classes.mainContent} ref={myRef}>
        <GuideIntro />
        <AllHealthGuides />
        <HealthProtocols />
        <Disclaimer />
      </Box>
    </div>
  );
}

export default withStyles(styles)(HealthGuides);
