import {MEALPLANSARR, SELECTEDMEALPLAN, EMPTYMEALPLANNER, SELECTEDITEMCAT} from '../action-types';

const INITIAL_STATE = {
  mealPlansArr: [],
  selectedMealPlan: null,
  selectedItemCat: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEALPLANSARR:
      return {...state, mealPlansArr: action.payload};
    case SELECTEDMEALPLAN:
      return {...state, selectedMealPlan: action.payload};
    case SELECTEDITEMCAT:
      return {...state, selectedItemCat: action.payload};
    case EMPTYMEALPLANNER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
