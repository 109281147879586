import React from 'react';

import FindStoreCard from '~/components/ui/FindStoreCard';

export default function FindStoreSection(props) {
  return (
    <FindStoreCard
      text="Create a life of limitless potential with an Optimize epigenetic report and free your genes today?"
      buttonText=""
    />
  );
}
