import React, {useRef, useEffect, useState, useCallback} from 'react';

import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';

export default function AnimationWraper(props) {
  const {index = 1, type = 'Grow', children, timeOut = 200, ...rest} = props;

  const [show, setShow] = useState(false);
  const oneRowRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (oneRowRef.current) {
      const itemHeight = oneRowRef.current.scrollHeight,
        itemOffset = oneRowRef.current.offsetTop,
        windowHeight = window.innerHeight,
        windowScrollY = window.scrollY;
      if (windowScrollY + windowHeight >= itemOffset && itemOffset + itemHeight >= windowScrollY) {
        setShow(true);
        document.removeEventListener('scroll', handleScroll);
      }
    }
  }, []);
  useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return type === 'Grow' ? (
    <Grow ref={oneRowRef} in={show} timeout={timeOut * index} {...rest}>
      {children}
    </Grow>
  ) : (
    <Fade ref={oneRowRef} in={show} timeout={timeOut * index} {...rest}>
      {children}
    </Fade>
  );
}
