import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import SectionTitle from '~/components/ui/SectionTitle';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import {Container} from '@material-ui/core';
import OuterContainer from '../../../components/ui/OuterContainer';

const styles = (theme) => ({
  root: {
    padding: '5% 10px',
    display: 'flex',
    flexDirection: (props) => (props.reverse ? 'row-reverse' : 'row'),
    color: '#000',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: () => 'column',
    },
  },
  title: {
    marginLeft: '20px',
    marginTop: '15px',
    marginBottom: '18px',
  },
  desc: {
    background: 'rgba(242,242,242,0.85)',
    color: '#707070',
    margin: (props) => (props.reverse ? `0 ${props.descMargin} 0 0` : `0 0 0 ${props.descMargin}`),
    [theme.breakpoints.down('sm')]: {
      margin: () => 0,
    },
  },
});
const useStyles = makeStyles(styles);

export default function CommonComponent(props) {
  const {reverse, imageWidth, imageRatio, imageSrc, descMargin, desc, title} = props;
  const classes = useStyles({reverse, descMargin});
  return (
    <OuterContainer>
      <AnimationWrapper>
        <div className={classes.root}>
          <div
            style={{
              flex: imageWidth,
            }}
          >
            <div
              style={{
                paddingTop: imageRatio,
                background: `url(${imageSrc})`,
                backgroundSize: 'cover',
              }}
            />
          </div>
          <div style={{flex: 100 - imageWidth}}>
            <div className={classes.title}>
              <SectionTitle text={title} />
            </div>
            <div className={classes.desc}>{desc && desc()}</div>
          </div>
        </div>
      </AnimationWrapper>
    </OuterContainer>
  );
}
