import React from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

const styles = (theme) => ({
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    width: '100%',
    flexDirection: 'column',
    marginBottom: '5rem',
    [theme.breakpoints.down('sm')]: {
      margin: '2rem 0',
    },
    zIndex: 2,
  },
  class2: {},
  class3: {
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
    },
  },
  class4: {
    marginRight: '-13em',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 10,
    minWidth: '30em',
    position: 'relative',
    [theme.breakpoints.down(1024)]: {
      marginRight: '0em',
      justifyContent: 'center',
    },
    '& > img': {
      maxWidth: '90%',
      maxHeight: '90%',
    },
  },
  class4reverse: {
    order: 1,
    marginRight: '0em',
    marginLeft: '-13em',
    justifyContent: 'flex-start',
  },
  class5: {
    backgroundColor: '#f6f6f6',
    flexDirection: 'column',
    fontFamily: 'montserrat',
    paddingLeft: '13em',
    [theme.breakpoints.down(1024)]: {
      padding: '1em',
    },
    '& > h2': {
      //fontWeight: 'bold',
      fontWeight: 500,
      marginTop: '-0.8em',
      textAlign: 'center',
      // fontSize: '3.8rem',
      fontSize: '2rem',
      color: '#8c8c8c',
      padding: 0,
      margin: 0,
      [theme.breakpoints.down(598)]: {
        fontSize: '1.5rem',
        marginTop: '-1em',
      },
    },
    '& > p': {
      fontSize: '1rem',
      padding: '1em 3rem',
      color: '#434343',
      [theme.breakpoints.down(1024)]: {
        padding: '1em',
      },
    },
  },
  class5reverse: {
    paddingLeft: '0em',
    paddingRight: '15em',
    [theme.breakpoints.down(1024)]: {
      padding: '1em',
    },
  },
  class6: {
    backgroundColor: 'white',
  },
  class7: {
    opacity: 5,
    position: 'absolute',
    zIndex: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: -8,
    right: 0,
    textAlign: 'center',
    width: '50%',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    color: 'white',
  },
});

function CommonSection(props) {
  const {title, classes, info, imageSrc, reverse = true, imageText = false} = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1024));
  return (
    <div className={clsx(classes.class0, classes.class1)}>
      <div className={clsx(classes.class0, classes.class3)}>
        {matches
          ? null
          : !!imageSrc && (
              <div
                className={clsx(classes.class0, classes.class4, reverse && classes.class4reverse)}
                style={!!imageText ? {top: '12em'} : {}}
              >
                <div className={classes.class7}>{imageText}</div>
                <img src={imageSrc} />
              </div>
            )}
        <div
          className={clsx(classes.class0, classes.class5, reverse && classes.class5reverse)}
          style={!!imageSrc ? {} : {padding: 0}}
        >
          <h2>{title}</h2>
          {matches
            ? !!imageSrc && (
                <div className={clsx(classes.class0, classes.class4)}>
                  <div className={classes.class7}>{imageText}</div>
                  <img src={imageSrc} />
                </div>
              )
            : null}
          <p>{info}</p>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CommonSection);
