import React, {useRef} from 'react';
import styles from './Scroller.module.css';
import {Box, Typography} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {getPath} from '~/static/routes';
import {useHistory} from 'react-router-dom';

function Scroller({items}) {
  const scrollerRef = useRef(null);
  const history = useHistory();
  const scroll = (direction) => {
    if (direction === 'left') {
      scrollerRef.current.scrollBy({left: -220, behavior: 'smooth'});
    } else {
      scrollerRef.current.scrollBy({left: 220, behavior: 'smooth'});
    }
  };
  return (
    <div className={styles.scroller}>
      <ArrowBackIosIcon className={styles.arrow} onClick={() => scroll('left')} />
      <div className={styles['scroller-container']} ref={scrollerRef}>
        {items.map((item) => (
          <div
            key={item.id}
            className={styles['scroller-item']}
            onClick={() => {
              history.push(`/shop-by-product-and-services/${item.collections.edges[0].node.handle}/${item.handle}`);
            }}
          >
            <Box width="100%">
              <Box position="relative" width="100%" display="flex" justifyContent="center">
                <img
                  src={item?.variants?.nodes?.[0]?.image?.src}
                  alt="products img"
                  style={{width: '100px', height: '150px', objectFit: 'contain'}}
                />
              </Box>

              <Box color="#434343" mt={1} mb={3}>
                <Typography variant="body1" style={{textAlign: 'center'}}>
                  {item.title}
                </Typography>
                {item?.text_above_product_title?.value && (
                  <Typography style={{color: '#9aa200', fontSize: 13, fontFamily: 'Lato', textAlign: 'center'}}>
                    {item.text_above_product_title?.value}
                  </Typography>
                )}
                {item?.text_below_product_title?.value && (
                  <Typography style={{color: '#9aa200', fontSize: 13, fontFamily: 'Lato', textAlign: 'center'}}>
                    {item.text_below_product_title?.value}
                  </Typography>
                )}
              </Box>
            </Box>
          </div>
        ))}
      </div>
      <ArrowForwardIosIcon className={styles.arrow} onClick={() => scroll('right')} />
    </div>
  );
}

export default Scroller;
