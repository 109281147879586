import React, {useState} from 'react';
import {withStyles, useTheme} from '@material-ui/core/styles';
import Image3 from '~/assets/images/page/techtesting/acroAcroPoseAcroYoga1139491@3x.png';
import Image4 from '~/assets/images/page/techtesting/timMossholderSxPiSzSxJrQUnsplash@3x.png';
import Image5 from '~/assets/images/page/techtesting/wx202004031433072X@3x.png';
import Image6 from '~/assets/images/page/techtesting/wx202010071644522X@3x.png';
import {Link} from 'react-router-dom';

const routes = [
  {title: 'Products and Services', to: '/productandservice'},
  {title: 'Tech Testing', to: '/productandservice/techtesting'},
];

const styles = (theme) => ({
  class1: {
    backgroundImage: `url(${Image3})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  class3: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '1rem',
  },
  class2: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: '3rem',
    marginTop: '1rem',
    fontWeight: 500,
    letterSpacing: '0.1rem',
  },
  class4: {
    padding: '2.5% 12% 0% 12%',
  },
  class5: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    [theme.breakpoints.down(630)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

const HeaderPhotos = (props) => {
  return (
    <Link
      to={props.to}
      style={{
        display: 'flex',
        boxSizing: 'border-box',
        flex: 1,
        justifyContent: props.justifyContent,
        textDecoration: 'none',
      }}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 260}}>
        <img style={{maxWidth: '100%', maxHeight: '100%'}} src={props.image} />
        <div style={{color: 'white', fontFamily: 'georgia', fontStyle: 'italic', fontSize: '1.5rem', padding: '5%'}}>
          {props.text}
        </div>
      </div>
    </Link>
  );
};

const Section1 = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class5}>
      <div className={classes.class3}>
        <div style={{borderBottom: '5px white solid', width: '5rem'}}></div>
        <div className={classes.class2}>TECH TESTING</div>
      </div>
      <div
        className={classes.class3}
        style={{
          maxWidth: 400,
        }}
      >
        <HeaderPhotos
          to={'/productandservice/techtesting/hairtesting'}
          text="Hair Testing"
          image={Image4}
          justifyContent="flex-start"
        />
        <HeaderPhotos
          to={'/productandservice/techtesting/3dscanning'}
          text="3D Scanning"
          image={Image5}
          justifyContent="flex-end"
        />
        <HeaderPhotos
          to={'/productandservice/techtesting/inbodyscanning'}
          text="Inbody Scanning"
          image={Image6}
          justifyContent="flex-start"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(Section1);
