import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Container} from '@material-ui/core';
import Card from '~/components/ui/Card';

import Image1 from '~/assets/images/page/healthGuides/protocal_image1.png';
import Image2 from '~/assets/images/page/healthGuides/protocal_image2.png';

const ImageData = [
  {
    image: Image1,
    title:
      'Book a consultation to consider one of our <a href="/shop-by-health-interest/health-protocols">6 Health Protocol programs</a>',
  },
  {
    image: Image2,
    title: 'Integrate our Health Creater App with your chosen Health Protocol',
  },
];
const styles = () => ({
  root: {
    color: '#595959',
  },
  header: {
    padding: '50px 0',
    background: 'white',
  },
  cardTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
    '& a, a:visited, a:hover, a:active': {
      color: 'inherit',
    },
    whiteSpace: 'break-spaces',
    transform: 'translate(-50%, -50%)',
  },
  cardButton: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },

  headerTitle: {
    color: '#595959',
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

function HealthProtocols(props) {
  const {classes} = props;

  return (
    <>
      <Grid container className={classes.header} alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <Typography variant="h5" align="center" className={classes.headerTitle}>
            HEALTH PROTOCOLS
          </Typography>
        </Grid>
        <Grid item md={5} sm={5} xs={11}>
          <Container maxWidth="lg" className={classes.root}>
            <Typography variant="body1" align="justify" className={classes.headerDesc}>
              Book a consultation to establish a health protocol to best suit your health needs, work with a set format
              every day to advance your well-being or to use as a preventative health measure. Get access to all of the
              fundamental yet vital healing and regenerative methods, to use in an easy to follow format, as a platform
              to greater health.
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {ImageData.map((item) => (
          <Grid item key={item.title} md={6} sm={6} xs={12}>
            <Card ratio="41.7%" imageSrc={item.image}>
              <Typography variant="h6" className={classes.cardTitle} align="center">
                <div dangerouslySetInnerHTML={{__html: item.title}} />
              </Typography>
              <ColorButton
                onClick={() => {
                  window.open('https://vfhurbansanctuary.com/health-facilitators', '_blank');
                }}
                className={classes.cardButton}
              >
                READ MORE
              </ColorButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default withStyles(styles)(HealthProtocols);
