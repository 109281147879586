import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import backgroundImage from '~/assets/images/page/weight-shifter/longevity_img.png';
import Typography from '@material-ui/core/Typography';
import SectionTitle from '~/components/ui/SectionTitle';

const styles = (theme) => ({
  root: {
    background: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    height: '500px',
    color: '#000',
    padding: '0px 6%',
    margin: '40px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0px',
    },
  },
  wrapper: {
    background: 'rgba(255,255,255, 0.7)',
    height: '100%',
    width: '42%',
    padding: '50px 3%',
    [theme.breakpoints.down('sm')]: {
      background: 'rgba(255,255,255, 0.88)',
      width: '97%',
      padding: '20px 1%%',
    },
  },
  subHeading: {
    padding: '25px 0 8px 27px',
    fontSize: '1rem',
    color: '#707070',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0 4px 14px',
      fontSize: '.9rem',
    },
  },
  dot: {
    width: 10,
    height: 10,
    display: 'inline-block',
    borderRadius: '5px',
    background: '#8DBF4C',
    margin: '5px 8px 0 27px',
    [theme.breakpoints.down('sm')]: {
      width: 9,
      height: 9,
    },
  },
  bulletItem: {
    display: 'inline',
    fontSize: '1rem',
    lineHeight: 1.75,
    color: '#707070',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem',
    },
  },
});

const Data = {
  bulletPoints: ['Fusion Exercise', 'Yoga', 'Modern and classic Dance', 'Martial Arts'],
  benefits: [
    'Weekly timetable of all longevity classes',
    'Exact calorie burn per class to add to your Weight Shifter App calculations',
  ],
};

function LongevityExercisePrograms(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <SectionTitle text="Longevity exercise programs" />
        <Typography variant="body2" className={classes.subHeading}>
          Select from our range of longevity exercise programs to achieve precise exercise data:
        </Typography>

        {Data.bulletPoints.map((item) => (
          <div key={item}>
            <span className={classes.dot} />
            <Typography variant="body2" className={classes.bulletItem}>
              {item}
            </Typography>
          </div>
        ))}

        <Typography variant="body2" className={classes.subHeading}>
          Weight Shifter taps into our Sanctuary Exercise Calculator and Calendar to create your:
        </Typography>

        {Data.benefits.map((item) => (
          <div key={item}>
            <span className={classes.dot} />
            <Typography variant="body2" className={classes.bulletItem}>
              {item}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(LongevityExercisePrograms);
