import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import HealthProtocols from '~/components/ui/HealthProtocols';
import {LifeForceSectionDataList} from '~/data';
import HealthInterest from './Sections/HealthInterest';
import RelevantProducts from './Sections/RelevantProducts';
import Guides from './Sections/Guides';
import CardGrid from '~/pages/lifeIngredients/Sections/CardGrid';
import {Box, Grid} from '@material-ui/core';
import {useFetchLifeIngredients} from 'shared/react-query/hooks';
import {useState} from 'react';
import {useEffect} from 'react';
import CardItem from '~/pages/lifeIngredients/Sections/CardItem';
import { sortBy } from 'lodash';

const styles = (theme) => ({
  root: {},
  sectionContainer: {
    backgroundColor: 'white',
    // padding: '2%',
  },
  itemWrapper: {
    height: '100%',
  },
  title: {
    color: '#595959',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  desc: {
    color: '#434343',
    width: '84%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sectionHealthProtocol: {
    paddingBottom: '2%',
  },
  sectionHealthGuides: {
    marginTop: '-30px',
  },
});

function ShopByHealth(props) {
  const {classes} = props;

  const lifeIngredients = useFetchLifeIngredients();
  const [ingredientsToShow, setIngredientsToShow] = useState([]);

  useEffect(() => {
    const arr = sortBy(lifeIngredients?.data?.metaobjects?.nodes,'name.value');
    setIngredientsToShow(arr);
  }, [lifeIngredients.data]);

  return (
    <div className={classes.root}>
      <div className={classes.sectionContainer}>
        {/* <Container maxWidth="lg"> */}
        <HealthInterest />
        {/* <AllHealthGuides /> */}
        <RelevantProducts />
        <Box mb="50px">
          <h1 style={{textAlign:'center',marginBottom:50,marginTop:50,color:"rgb(154, 162, 0)"}}>RELEVANT INGREDIENTS</h1>
          {ingredientsToShow && (
            <Grid container spacing={4} wrap="nowrap" style={{width: '100%', overflowX: 'scroll'}}>
              {ingredientsToShow?.map((item, index) => (
                <Grid item style={{maxWidth:'366px'}}>
                  <CardItem cardData={item} clamp={true} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <Guides section={LifeForceSectionDataList[2]} lastOne={false} />
        {/* <div className={classes.sectionHealthGuides}>
            <AllHealthGuides />
          </div> */}
        {/* {LifeForceSectionDataList[2].bottomDec && LifeForceSectionDataList[2].bottomDec(classes.desc)} */}
        {/* </Container> */}
        <div className={classes.sectionHealthProtocol}>
          <HealthProtocols />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(ShopByHealth);
