import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import AllHealthGuides from '~/pages/healthGuides/Sections/AllHealthGuides';
import SectionHeader from '~/components/ui/LifeForceComponent/SectionHeader';

const styles = (theme) => ({
  root: {},
  section: {
    marginBottom: '20px',
  },
  sectionLast: {
    marginBottom: '100px',
  },
});

function Guides(props) {
  const {classes, section, lastOne = true} = props;

  return (
    <div
      className={clsx(classes.section, {
        [classes.sectionLast]: lastOne,
      })}
    >
      {section.title && (
        <SectionHeader title={section.title} reverse={section.reverse} desc={() => section.desc(classes)} />
      )}
      <AllHealthGuides />
    </div>
  );
}

export default withStyles(styles)(Guides);
