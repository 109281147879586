import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    margin: 0,
    width: '100%',
  },
  bannerCard: {
    position: 'relative',
    textAlign: 'center',
  },
  bannerImg: {
    WebkitBackdropFilter: 'blur(3.8px)',
    backdropFilter: 'blur(3.8px)',
    backgroundColor: '#fffafa',
    width: '100%',
    height: '210px',
    objectFit: 'cover',
  },

  title: {
    [theme.breakpoints.down('631')]: {
      fontSize: '1.2rem',
    },
    fontSize: '2rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '2rem',
    transform: 'translate(-50% , -50%)',
    color: 'white',
    fontWeight: '700',
    cursor: 'pointer',
  },
});

function Banner(props) {
  const {classes, filters, setFilters, bannerFilterData} = props;
  const handleChange = (tag, value) => {
    setFilters({
      ...filters,
      tag2: null,
      [tag]: value,
    });
  };
  return (
    <Grid container className={classes.root} spacing={1}>
      {bannerFilterData.map((item, index) => (
        <Grid
          onClick={() => {
            handleChange('tag1', item.key);
          }}
          item
          container
          md={3}
          sm={3}
          xs={6}
          key={index}
          className={classes.bannerCard}
        >
          <img alt="banner" src={item.image} className={classes.bannerImg} />
          <span
            className={classes.title}
            style={
              filters.tag1 == item.key
                ? {border: '1px solid white', borderRadius: 5, padding: '0px 4px', background: '#ffffff36'}
                : {}
            }
          >
            {item.name}
          </span>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(Banner);
