import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section4-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    padding: '5%',
  },
});

function NutritionCalculatorSection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Nutrition Calculator"
      reverse
      imageWidth={60}
      imageRatio="65%"
      imageSrc={ImageBg}
      descMargin="-25%"
      desc={() => (
        <div className={classes.desc}>
          <Typography variant="body1">
            Calculate per meal, per day, per week nutritional support derived from our foods and drinks. Receive a
            comprehensive break down consisting of:
          </Typography>
          <br />
          <br />
          <Typography variant="body1" style={{color: '#5499C7'}}>
            - Plant versus animal protein ratios
            <br />
            - Micronutrients: vitamins / macro minerals / micro minerals / amino acids
            <br />
            - Calorie composition: slow release carbohydrates / proteins / fats / natural sugars
            <br />
            - Ph levels, glycaemic Index, arginine / lysine ratios, delivery of specialised ingredients / fermented
            foods
            <br />
            - Detoxification and intermittent fasting time windows
            <br />
            - A complete Nutritional Attributes Summary
            <br />
            - The Food Consciousness and Global impact rating - +
            <br />
          </Typography>
        </div>
      )}
    />
  );
}

export default withStyles(styles)(NutritionCalculatorSection);
