import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

const styles = (theme) => ({
  root: {
    background: 'transparent',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '9aa200',
    backdropFilter: '"blur(30px)',
    color: '#9aa200',
    padding: '10px 40px',
    cursor: 'pointer',
    userSelect: 'none',
    width: 'fit-content',
    fontSize: '22px',
    fontFamily: 'Arial',
    margin: '20px auto',
  },
});

function Button(props) {
  const {classes, text, hollow, icon, style} = props;

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={clsx(classes.root, {
        hollow: hollow,
        icon: !!icon,
      })}
      style={style}
    >
      <Link to="/findastore" style={{textDecoration: 'none', color: '#9aa200'}}>
        <span>
          {' '}
          {icon && <img src={icon} className={classes.icon} alt="Icon" />}
          {text}
        </span>
      </Link>
    </Grid>
  );
}

export default withStyles(styles)(Button);
