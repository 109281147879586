import React from 'react';
import {withStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Grid, Card, Button, Typography} from '@material-ui/core';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import {withRouter} from 'react-router-dom';

const theme = createMuiTheme({
  breakpoints: {
    values: {md: 984},
  },
});

const styles = (theme) => ({
  paperContainer: {
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '24px',
    },
  },
  imageContainer: {
    display: 'block',
    width: '100%',
  },
  leftContainer: {
    position: 'relative',
  },
  timebtn: {
    backgroundColor: '#9aa200',
    color: 'white',
    fontWeight: '300',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  tag: {
    color: 'white',
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '14px',
  },

  contentContainer: {
    padding: '2%',
    position: 'relative',
  },
  subtitle: {
    color: '#dfdfdf',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  title: {
    color: '#9aa200',
    fontWeight: 'bold',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '6px',
      fontSize: '16px',
    },
  },
  text: {
    marginBottom: '10px',
    lineHeight: '1.4',
    color: '#595959',
    fontWeight: '300',
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
  },
  textTitle: {
    fontWeight: '900',
  },
  textLink: {
    color: '#9aa200',
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      bottom: '-10px',
      fontSize: '14px',
    },
  },
});

function ParticipateCard(props) {
  const {classes, data, history} = props;

  return (
      <div className={classes.root}>
        {data &&
          data.map((item, index) => (
            <AnimationWrapper key={index} index={index}>
              <Card elevation={3} className={classes.paperContainer}>
                <Grid container>
                  <Grid item md={4} className={classes.leftContainer}>
                    <img src={item.image} className={classes.imageContainer} alt="participate" />
                    <Button size="small" className={classes.timebtn}>
                      {item.day + ' ' + item.time}
                    </Button>
                    <Typography className={classes.tag}>{item.tag.join(' | ')}</Typography>
                  </Grid>
                  <Grid item md={8} className={classes.contentContainer}>
                    <Typography variant="h6" className={classes.subtitle}>
                      {item.category}
                    </Typography>
                    <Typography variant="h5" className={classes.title}>
                      {item.name}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <Typography className={classes.text}>
                          <span className={classes.textTitle}>Description:</span>
                          {'    ' + item.description.substring(0, 400) + '...'}
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className={classes.text}>
                          <span className={classes.textTitle}>Date:</span>
                          {'    ' + item.date}
                        </Typography>
                        <Typography className={classes.text}>
                          <span className={classes.textTitle}>Time:</span>
                          {'    ' + item.day + ' ' + item.time}
                        </Typography>
                        <Typography className={classes.text}>
                          <span className={classes.textTitle}>Location:</span>
                          {'    ' + item.location}
                        </Typography>
                        <Typography className={classes.text}>
                          <span className={classes.textTitle}>Maximum Participant:</span>
                          {'    ' + item.participate}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      className={classes.textLink}
                      onClick={() => {
                        history.push(`/participate/${item.id}`);
                      }}
                    >
                      {' '}
                      {'>> READ MORE'}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </AnimationWrapper>
          ))}
      </div>
  );
}

export default withRouter(withStyles(styles)(ParticipateCard));
