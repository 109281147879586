import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import ourScannerImage from '~/assets/images/page/3dscanning/scannercompare.png';

const styles = (theme) => ({
  ourScannerWrapper: {
    position: 'relative',
    backgroundColor: 'rgba(154, 162, 0, 0.1)',
  },
  root: {
    padding: '50px 0px',
  },

  title: {
    opacity: '0.8',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: '500',
    fontstretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#8c8c8c',
  },
  hr: {
    margin: '20px 0px',
    opacity: '0.2',
    backgroundColor: 'rgba(154, 162, 0, 0.1)',
  },
  leftText: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.63',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    padding: '10px 0px',
  },
  imageWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  scanningImage: {
    backgroundColor: '#ffffff',
    margin: '0px auto',
    width: '300px',
  },
});

function OurScannerSection(props) {
  const {classes} = props;

  return (
    <div className={classes.ourScannerWrapper}>
      <Container>
        <div className={classes.root}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="h5" className={classes.title}>
                Our Scanner
              </Typography>
              <hr className={classes.hr} />
              <Typography variant="body1" className={classes.leftText}>
                BIA tests are not “true body scans” – they measure hydration. The methodology of BIA has a huge amount
                of inconsistency due to one’s hydration which can change from visit to visit. You simply can’t control
                when your members drink water or eat on a regular basis.
              </Typography>
              <Typography variant="body1" className={classes.leftText}>
                Additionally, most brands choose 3d body scans over BIA due to the imaging. Showing both data & body
                imaging is a powerful client experience.
              </Typography>
              <Typography variant="body1" className={classes.leftText}>
                In the fitness space, there are a few other scanners in the market. Brand’s like Crunch & F45 choose us
                for a few reasons. Accuracy being the main one. The handles on our machine help with accurate capture,
                and our official partnership with Dexa continually updates our algorithms for the most accurate body fat
                predictions.
              </Typography>
              <Typography variant="body1" className={classes.leftText}>
                The second big reason is that we have better software. We have a web portal that allows for access &
                control of your data. This allows owners & coaches to better work with their clients for a better
                overall member experience.
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.imageWrapper}>
              <img src={ourScannerImage} className={classes.scanningImage} alt="Our Scanner" />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(OurScannerSection);
