import React from 'react';

import {IconButton, withStyles} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import PublicIcon from '@material-ui/icons/Public';
const styles = (theme) => ({
  socialIconsWrapper: {
    color: '#000000',
    position: 'relative',
    height: '100%',
  },
  socialIcons: {
    width: '16px',
    height: '16px',
    color: '#595959',
    marginLeft: '15px',
    float: 'right',
  },
});

function SocialIcons(props) {
  const {classes, className} = props;
  return (
    <div className={classes.socialIconsWrapper}>
      {/* <FacebookIcon className={className ? className : classes.socialIcons} />
      <TwitterIcon className={className ? className : classes.socialIcons} /> */}
      <IconButton onClick={()=>{
                window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
              }}>
      <InstagramIcon className={className ? className : classes.socialIcons} />
      </IconButton>
      <PublicIcon className={className ? className : classes.socialIcons} />
    </div>
  );
}

export default withStyles(styles)(SocialIcons);
