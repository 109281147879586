import React from 'react';
import {withRouter} from 'react-router';
//import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
// Styled Component
import {Typography, Grid} from '@material-ui/core';
import BreadcrumbsComponent from '~/components/ui/Breadcrumbs';

const styles = (theme) => ({
  content: {
    paddingTop: '8%',
  },
  img: {
    width: '200px',
    height: '200px',
  },
  headingPart: {
    order: 1,
    [theme.breakpoints.only('xs')]: {
      order: 2,
    },
    [theme.breakpoints.only('sm')]: {
      order: 2,
    },
  },
  breadCrumb: {
    opacity: 0.49,
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    color: '#ffffff',
  },
  mainHeading: {
    fontSize: 'calc(1vw + 1.2em)',
    fontWeight: 'bold',
    letterSpacing: '5px',
    color: 'white',
    marginTop: '2vh',
    textTransform: 'uppercase',
    lineHeight: '1.2',
    marginBottom: '2vh',
  },
  subHead: {
    borderRadius: '30px',
    backgroundColor: '#9aa200',
    color: 'white',
    display: 'inline-block',
    alignItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    fontSize: '1.5em',
    fontWeight: 'bold',
    lineHeight: '2.4',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.2em',
      lineHeight: '2.5',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      fontSize: '1em',
      lineHeight: '1.8',
    },
  },
  contentDescription: {
    width: '100%',
    fontSize: '1.1em',
    color: 'white',
    margin: '20px auto 6%',
    lineHeight: '1.6',
    letterSpacing: '2px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.8em',
      lineHeight: '1.5',
      letterSpacing: '2px',
    },
  },
});

const useStyles = makeStyles(styles);
const routes = [{title: 'Products and Services'}, {title: 'Participate', to: 'Participate'}];

function SectionHeading({title, description, subHeading, history}) {
  const classes = useStyles();
  // const pathname = history.location.pathname;

  return (
    <Grid container className={classes.content}>
      <Grid item className={classes.headingPart}>
        <BreadcrumbsComponent text="" routes={routes} />
        <Typography className={classes.mainHeading}>{title}</Typography>
        <div>
          <span className={classes.subHead}>{subHeading.join(' | ')}</span>
        </div>
        <Grid container>
          <Grid item md={8} sm={8} xs={12}>
            <Typography className={classes.contentDescription}>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(SectionHeading);
