import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 28.1 27.59"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 .95 19.9)"
      >
        <tspan
          x={0}
          y={0}
          style={{
            letterSpacing: "-.01em",
          }}
        >
          {"F"}
        </tspan>
        <tspan x={2.98} y={0}>
          {"unctional"}
        </tspan>
        <tspan x={-0.93} y={6.2}>
          {"Ingredients"}
        </tspan>
      </text>
      <path
        id="Ellipse_23"
        d="M14.07 14.47c-3.99 0-7.23-3.25-7.23-7.23S10.08 0 14.07 0s7.23 3.25 7.23 7.23-3.25 7.23-7.23 7.23Zm0-14.17c-3.82 0-6.93 3.11-6.93 6.93s3.11 6.93 6.93 6.93S21 11.05 21 7.23 17.89.3 14.07.3Z"
        className="cls-1"
        data-name="Ellipse 23"
      />
      <g id="radiant-sun">
        <g id="Group_1018" data-name="Group 1018">
          <path
            id="Path_1413"
            d="M14.07 4.51c-1.51 0-2.73 1.22-2.73 2.73s1.22 2.73 2.73 2.73 2.73-1.22 2.73-2.73-1.22-2.73-2.73-2.73Zm0 4.51c-.99 0-1.79-.8-1.79-1.79s.8-1.79 1.79-1.79 1.79.8 1.79 1.79-.8 1.79-1.79 1.79Z"
            className="cls-1"
            data-name="Path 1413"
          />
          <path
            id="Path_1414"
            d="M11.22 4.53c.12.12.31.13.44.01.33-.29.71-.52 1.12-.68.16-.06.24-.24.19-.4l-.1-.31a.323.323 0 0 0-.4-.21h-.02c-.53.2-1.02.5-1.44.88-.13.12-.14.32-.03.45l.01.01.23.23Z"
            className="cls-1"
            data-name="Path 1414"
          />
          <path
            id="Path_1415"
            d="M10.14 7.34c.17 0 .31-.13.32-.3.02-.44.13-.87.31-1.27.07-.16 0-.34-.15-.42l-.29-.15a.317.317 0 0 0-.43.14v.02a4.48 4.48 0 0 0-.39 1.65c-.01.18.13.33.3.34H10.16Z"
            className="cls-1"
            data-name="Path 1415"
          />
          <path
            id="Path_1416"
            d="M17.44 5.96c.06.16.24.24.4.19l.31-.1c.17-.05.26-.23.21-.4v-.02a4.7 4.7 0 0 0-.88-1.45.318.318 0 0 0-.45-.03l-.01.01-.23.23c-.12.12-.13.31-.02.44.29.33.52.71.68 1.12Z"
            className="cls-1"
            data-name="Path 1416"
          />
          <path
            id="Path_1417"
            d="M14.27 3.62c.44.03.87.13 1.27.31.16.07.34 0 .42-.15l.15-.29c.08-.16.02-.35-.14-.43h-.02a4.7 4.7 0 0 0-1.64-.4.317.317 0 0 0-.34.3V3.31c0 .17.13.31.3.32Z"
            className="cls-1"
            data-name="Path 1417"
          />
          <path
            id="Path_1418"
            d="M10.7 8.53a.325.325 0 0 0-.4-.19l-.31.1c-.17.05-.26.24-.21.4v.02c.2.53.5 1.02.88 1.44.06.07.14.1.23.11.08 0 .17-.03.23-.09l.23-.23c.12-.12.13-.32.01-.44-.29-.33-.53-.71-.69-1.12Z"
            className="cls-1"
            data-name="Path 1418"
          />
          <path
            id="Path_1419"
            d="M18.56 7.2a.326.326 0 0 0-.23-.1H18a.32.32 0 0 0-.32.31c-.02.44-.12.87-.3 1.27-.07.16 0 .34.15.42l.29.15c.16.08.35.02.43-.14v-.02c.23-.52.36-1.08.39-1.65 0-.09-.03-.17-.09-.24Z"
            className="cls-1"
            data-name="Path 1419"
          />
          <path
            id="Path_1420"
            d="M16.93 9.92c-.12-.12-.32-.13-.44-.01-.33.29-.71.53-1.11.69-.16.06-.24.24-.19.4l.1.31c.06.17.24.26.4.2h.02c.53-.21 1.02-.51 1.44-.89.13-.12.14-.32.02-.45l-.01-.01-.23-.23Z"
            className="cls-1"
            data-name="Path 1420"
          />
          <path
            id="Path_1421"
            d="M13.88 10.85c-.44-.02-.87-.13-1.27-.3-.16-.07-.34 0-.42.15l-.15.29c-.08.16-.02.35.14.43h.02c.52.23 1.08.36 1.65.39h.02c.18 0 .32-.14.32-.32v-.33c0-.17-.13-.31-.3-.32Z"
            className="cls-1"
            data-name="Path 1421"
          />
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as FunctionalIngredientsIcon }
