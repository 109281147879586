import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '../../../components/ui/OuterContainer';
import clsx from 'clsx';
import {Button} from '@material-ui/core';
import experiencedData from './experiencedData';
import {withRouter} from 'react-router-dom';
import {getPath} from '~/static/routes';

const styles = (theme) => ({
  class0: {
    fontFamily: 'Montserrat',
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    width: '100%',
    backgroundColor: '#f7f7f7',
    zIndex: 1,
    marginTop: '-15em',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em',
    '&>h3': {
      fontSize: '2rem',
      color: '#9aa200',
      textAlign: 'center',
      fontWeight: 500,
    },
  },
  class6: {
    fontSize: '1rem',
  },
  class7: {
    textDecoration: 'underline',
    color: '#9aa200',
    fontSize: '0.8rem',
  },
  class8: {
    color: '#9aa200',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  class9: {
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.down('929')]: {
      width: '95%',
    },
  },
  class2: {
    marginBottom: '7em',
    backgroundColor: 'white',
  },
  class3: {
    width: '80%',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    margin: '1em 0',
    [theme.breakpoints.down('929')]: {
      width: '95%',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1em',
    },
  },
  class4: {
    '&>img': {
      display: 'block',
      maxHeight: '100%',
      minWidth: 200,
      [theme.breakpoints.down('929')]: {
        maxWidth: '100%',
      },
    },
  },
  class5: {
    padding: '1em 4em',
    color: '#595959',
    [theme.breakpoints.down('929')]: {
      padding: '0em',
    },
    '& >p:first-child': {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    '& >p:last-child': {
      fontSize: '1rem',
    },
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    margin: '1em',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const TeamCard = withStyles(styles)((props) => {
  const {classes} = props;
  return (
    <div className={clsx(classes.class0, classes.class3)}>
      <div className={classes.class4}>
        <img style={{width:300,height:"100%", objectFit:"cover"}} src={props.imageSrc} />
      </div>
      <div className={classes.class5}>
        <p>{props.title}</p>
        <p dangerouslySetInnerHTML={{__html:props.text}} style={{whiteSpace:"break-spaces"}}></p>
      </div>
    </div>
  );
});

const TeamsSection = (props) => {
  const {classes} = props;
  return (
    <OuterContainer className={classes.class2}>
      <div className={clsx(classes.class0, classes.class1)}>
        <h3>Requirements for you and your Team</h3>
        <p className={clsx(classes.class6, classes.class9)}>
          Each member of your team must be intimate with their individual roles as well as all aspects of the stores
          concepts. Each person needs to be versed in all of the guides, protocols, food science, products and testing
          facilities to be able to genuinely demonstrate that they live the philosophy of Sanctuary, in order to help
          others.
        </p>
        <p className={clsx(classes.class7, classes.class9)}>View existing teams and vacancies</p>
        <p className={clsx(classes.class9, classes.class7)}>
          View currents teams in progress of formation and needing more members to compete their team of 5
        </p>
        <ColorButton
          onClick={() => {
            props.history.push(getPath('Volunteer'));
          }}
        >
          Create your team
        </ColorButton>
        <p className={clsx(classes.class8, classes.class9)}>Each Store requires 5 skilled people to operate it.</p>
        {experiencedData.map((element, index) => {
          return <TeamCard {...element} />;
        })}
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(withRouter(TeamsSection));
