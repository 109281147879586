import {combineReducers} from 'redux';
// import AuthReducer from './AuthReducer';
import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import SetBackgroundReducer from './SetBackgroundReducer';
import {firestoreReducer} from 'redux-firestore';
import {firebaseReducer} from 'react-redux-firebase';
import ProductsReducer from './ProductsReducer';
import CartReducer from './CartReducer';
import MealPlannerReducer from './MealPlannerReducer';
import ProfileReducer from './ProfileReducer';
const history = createBrowserHistory();

export default combineReducers({
  // auth: AuthReducer,
  app: SetBackgroundReducer,
  router: connectRouter(history),
  products: ProductsReducer,
  cart: CartReducer,
  mealPlanner: MealPlannerReducer,
  profile: ProfileReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// import {combineReducers} from 'redux';
// import {connectRouter} from 'connected-react-router';
// import {ACTION_SET_BACKGROUND} from '../action-types';

// const reducer = (history) =>
//   combineReducers({
//     router: connectRouter(history),
//     app: (
//       state = {
//         background: null,
//       },
//       action
//     ) => {
//       switch (action.type) {
//         case ACTION_SET_BACKGROUND: {
//           return {
//             ...state,
//             background: action.payload,
//           };
//         }
//         default: {
//           return state;
//         }
//       }
//     },
//   });

// export default reducer;
