import React, { useEffect } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';

const styles = (theme) => ({
  class1: {
    marginTop: 56,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    flex: 1,
  },
});

const InBodyScanning = (props) => {
  const {classes} = props;

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  return (
    <div className={classes.class1}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
};
export default withStyles(styles)(InBodyScanning);
