import React from 'react';
import {Typography, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = (bgImage) =>
  makeStyles({
    root: {
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      overflow: 'hidden',
      height: '100%',
      boxSizing: 'border-box',
    },
    content: {
      background: 'rgba(27,27,27,0.3)',
      height: '100%',
      textAlign: 'center',
      padding: '5%',
    },
    title: {
      fontSize: '20px',
      fontWeight: '500',
    },
    description: {
      fontFamily: 'Lato',
      fontSize: '16px',
      opacity: '0.9',
      margin: '20px',
    },
    atcBtn: {
      background: '#9aa200',
      color: 'white',
    },
  });

function PackCard(props) {
  const classes = useStyles(props.cardBgImage)();
  const {title, description} = props;
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        <Button className={classes.atcBtn}>ADD TO CART</Button>
      </div>
    </div>
  );
}

export default PackCard;
