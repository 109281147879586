import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {useFetchRelatedProducts} from 'shared/react-query/hooks';
import Scroller from '~/components/ui/Scroller/Scroller';

const styles = (theme) => ({
  title: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },

  foodimages: {
    height: '160px',
    width: '240px',
    position: 'relative',
  },

  imageBox: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

  icon: {
    color: '#9aa200',
    cursor: 'pointer',
  },

  cardRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: '1',
    margin: '0 30px',
    position: 'relative',
  },
});

function OtherProducts(props) {
  const {classes, id} = props;

  const {data: products, isLoading} = useFetchRelatedProducts({
    id,
  });

  if (!products?.productRecommendations?.length) {
    return null;
  }
  return (
    <>
      <Box mb={4}>
        <Typography align="center" className={classes.title}>
          RELATED PRODUCTS
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" pb={2}>
        <div className={classes.cardRow}>
          <Scroller items={products?.productRecommendations ?? []} />
        </div>
      </Box>
    </>
  );
}

export default withStyles(styles)(OtherProducts);
