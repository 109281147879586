import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section3-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    textAlign: 'center',
    padding: '5%',
  },
});

function PreciseNutritionalSection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Precise nutritional analysis"
      imageWidth={60}
      imageRatio="56%"
      imageSrc={ImageBg}
      descMargin="-20%"
      desc={() => (
        <Typography variant="body1" className={classes.desc}>
          Foremost Health Creator is a complimentary Sanctuary service that allows you to evaluate every Life Force Food
          & Natural Highs Bar; meal, dressing and drink. Have the confidence of knowing exactly what you are consuming
          when buying any product from the Sanctuary or any of our Stores
          <br />
          <br />
          Health Creator can analyse all Sanctuary food in a matter of seconds, in order for you to evaluate and ensure
          maximum nutrition every time you visit. Enquire about our complimentary chilled day food packs, to keep a
          whole day worth of food and drink fresh.
        </Typography>
      )}
    />
  );
}

export default withStyles(styles)(PreciseNutritionalSection);
