import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Typography} from '@material-ui/core';
import bannerCardBg from '~/assets/images/page/hairTesting/bannerCardBg.png';

const styles = (theme) => ({
  bannerCardWrapper: {
    background: `url(${bannerCardBg}) no-repeat fixed`,
    backgroundSize: 'cover',
    position: 'relative',
    marginTop: '-270px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
  },
  overlayBlur: {
    minHeight: '515px',
    height: '30vw',
    marginTop: '-270px',
    paddingBottom: '20px',
    background: 'rgba(0,0,0, 0.64)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
      minHeight: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  root: {
    paddingTop: '200px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
    },
  },
  title: {
    fontFamily: 'Arial',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    color: '#ffffff',
  },
  subTitle: {
    fontFamily: 'AmazonEmberDisplay, Roboto, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.4',
    letterSpacing: 'normal',
    color: '#fafafa',
  },
  description: {
    maxWidth: '950px',
    fontFamily: 'AmazonEmberDisplay, Roboto, Helvetica, Arial, sans-serif',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.28',
    letterSpacing: 'normal',
    color: 'rgba(240, 240, 238, 0.4)',
  },
});

function BannercardSection(props) {
  const {classes} = props;
  return (
    <div className={classes.bannerCardWrapper}>
      <div className={classes.overlayBlur}>
        <Container className={classes.root}>
          <Typography variant="h4" className={classes.title}>
            Lorem Title
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            Giant stars have a much lower surface gravity
          </Typography>
          <Typography variant="h6" className={classes.description}>
            Diverted and concentrated by drains that create constant flows over the beach above the sea or river level,
            the beach will be eroded
          </Typography>
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(BannercardSection);
