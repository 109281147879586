import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme) => ({
    root: {
        padding: '0px 30px 5px 0px',
    },  
   
    
    card: {
        background: 'transparent',
        boxShadow: 'none',
    },
    avatar: {
        backgroundColor: '#454545',
        height: '40px',
        width: '40px',
    },
    cardHeader: {
        padding: '0px',
        fontFamily: 'Helvetica',
        'title': {
            fontSize: '40px',
        }
    },
    content: {
        fontSize: '40px',
    },
    cardContent: {
        padding: '10px 0px',
    },
    customerComments: {
        fontSize: '12px',
    }

});


function OurCustomer(props) {
    const {classes, initial, customerName, customerComments} = props;
    //const [expanded, setExpanded] = React.useState(false);

//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                            avatar={
                            <Avatar aria-label='recipe' className={classes.avatar}>
                                {initial}
                            </Avatar>
                            }
                            title={
                                <Typography variant='h6' >
                                    {customerName}
                                </Typography>
                            }
                        />
                        <CardContent className={classes.cardContent}>
                            <Typography variant='body2' color='textSecondary' component='p' className={classes.customerComments}>
                                {customerComments}
                            </Typography>
                        </CardContent>
                
                    </Card>
                </Grid>
            </Grid>
        </div>    
    );
}

export default withStyles(styles)(OurCustomer);