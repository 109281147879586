import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const styles = (theme) => ({
    table: {
        minWidth: 700,
        minHeight: 300,
      },
      container: {
        maxHeight: 240,
      },
});

function ConsciousnessData(props) {
  const {classes} = props;
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


  function createData(id, name, calories, fat, carbs, protein) {
    return { id, name, calories, fat, carbs, protein };
  }
 
  const rows = [
    createData(1, <span>Plant based whole foods grown via permaculture or Biodynamic methods, high brix levels max human longevity & vitality + max eco system health: soil/plants/animals the maximum produce capacity  for sustainably feeding  healthy food to the most amount of people extremely conscious food <span style={{color: '#7fb305'}} >health (Increase)</span></span>),
    createData(2, 'Plant based whole foods from organic, or spray free crops, or home grown General human longevity & vitality + eco system health highly conscious food'),
    createData(3, 'Permaculture, Biodynamic or organic and free range eggs at 1 egg per day-less demand on production, less cholesterol in the human body + compassionate- non harm food'),
    createData(4, 'Permaculture meat at 100 grams per serve once per fortnight, maintenance of the permaculture model and healthy human food consumption ratios, degree of animal respect'),
    createData(5, 'Wild, line caught mature fish at 100 grams per serve once per week, maintenance of the seas vast eco-systems and food producing capacity, and healthy human food consumption ratio’s (managing bioaccumulation), degree of respect for species without the by catch'),
    createData(6, 'Wild caught duck/ farmed organic and free range chicken at 100 grams per serve once per week, maintenance of eco-systems and the body’s ability to process effects of meats, a degree of respect for animals wellbeing'),
    createData(7, <span>Plant based whole foods from commercial growing standards (fertilisers/pesticides- usually low Brix levels)        human health and eco-systems begin to become compromised,  however less bioaccumulation that meat <span style={{color: '#ea7902'}} >concern</span></span>),
    createData(8, 'Farmed grass feed organic beef and lamb more than 100 grams per day stresses eco-systems and the body’s ability to process the effects of meat'),
    createData(9, 'Commercially caught fish more than 100 grams per day, stresses seas eco-systems, wasteful by catch, species extinction risk, and the body’s ability to deal with bioaccumulation, no respect for animals '),
    createData(10, 'Caged / factory farmed animals feed substituted feed stock regular consumption means growth hormones and antibiotics bioaccumulates and contribute to ill health of animal/planet/person, low efficiency as plant based feed stock could be grown for direct human consumption, at a cost of 1/10 the energy and water usage. Extreme animal cruelty. Low consciousness food'),
    createData(11, 'Engineered foods / commercially processed imitation foods          purposeless food void of nutrients yet containing chemical laden toxins and excess sugars which  contributes to obesity, type 2 Diabetes and cancer loading our medical industry and contributing to a misuse of resources: unnecessary packaging and waste. Low consciousness food'),
    createData(12, <span>Processed food from commercially farmed animals added intoxification from processing, greater health destruction to people, largest eco footprint, most effective ‘modern disease’ creator, extreme cruelty to animals, high energy use, packaging waste. Very low consciousness food <span style={{color: '#ec5569'}} >Disease (Decrease)</span></span>),
    
  ];

  return (
    <>
        This chart provides a snap shot analysis of how the food we choose to eat effects us and our planet.
                It focuses on the bio- interdependence which exists between the health of the planet and in turn our
                own health. It is fascinating that when we observe the people who live the longest eat the food
                which is the most gentle on the earth and which can be produced most abundantly. Human health
                Eco- prosperity go hand in hand. While human disease is generated by food production methods and
                processes that also contaminate our planet. Make the right choice.
                <br />
                <br />
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                          <StyledTableCell align="left">Type of food effect on you and planet quick check.</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>          
                  <br /><br />
                
                  Meat is not meant for regular diet, as hunter gathers we mostly had plant based foods which the women and elderly gather. The men occasionally where successful in bringing home meat. 
    </>
  );
}

export default withStyles(styles)(ConsciousnessData);
