import {ACTION_SET_BACKGROUND} from '../action-types';

const INITIAL_STATE = {
  background: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_BACKGROUND: {
      return {
        ...state,
        background: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
