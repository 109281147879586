import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 20.62 27.47"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 4.85 19.78)"
      >
        <tspan x={0} y={0}>
          {"Give"}
        </tspan>
        <tspan
          x={-4.73}
          y={6.2}
          style={{
            letterSpacing: "-.01em",
          }}
        >
          {"F"}
        </tspan>
        <tspan
          x={-1.74}
          y={6.2}
          style={{
            letterSpacing: 0,
          }}
        >
          {"o"}
        </tspan>
        <tspan
          x={1.39}
          y={6.2}
          style={{
            letterSpacing: 0,
          }}
        >
          {"r"}
        </tspan>
        <tspan x={3.3} y={6.2}>
          {"ward"}
        </tspan>
      </text>
      <path
        d="M10.34 14.61c-4.13 0-7.49-3.28-7.49-7.3S6.21 0 10.34 0s7.49 3.28 7.49 7.31-3.36 7.3-7.49 7.3Zm0-14.27C6.4.34 3.2 3.47 3.2 7.31s3.2 6.96 7.14 6.96 7.14-3.12 7.14-6.96S14.28.34 10.34.34Z"
        className="cls-2"
      />
      <path
        id="Path_590"
        d="M15.37 4.28a.196.196 0 0 0-.21-.16c-.46.04-.8.43-.78.88l.03 1.38c-.38.04-.71.26-.87.6l-.52 1.06c-.08.16-.2.31-.34.42l-.11.08c-.55.42-.85 1.08-.82 1.76l-.21-.1c-.1-.04-.21 0-.26.09l-.57 1.16c-.05.09 0 .2.09.25l2.61 1.21c.1.04.21 0 .26-.09l.57-1.16c.05-.09 0-.2-.09-.25l-.07-.03-.07-.39c0-.14.04-.28.13-.38l1.35-1.59c.28-.39.42-.84.42-1.31v-.03l-.55-3.39Zm-1.95 8.21-2.26-1.05.4-.82 2.26 1.05-.4.82Zm1.78-3.71-1.35 1.59c-.16.18-.23.42-.21.66v.02l.03.14-1.5-.7c-.11-.63.14-1.26.65-1.65l.11-.08c.19-.15.34-.34.45-.55l.52-1.06c.13-.28.44-.43.75-.38l-.75 1.54c-.05.09 0 .2.09.25.1.04.21 0 .26-.09l.85-1.74c.05-.09 0-.2-.09-.25-.06-.03-.12-.05-.19-.07l-.04-1.42c-.01-.19.09-.36.26-.45l.52 3.17c0 .38-.12.75-.34 1.06Z"
        className="cls-2"
        data-name="Path 590"
      />
      <path
        id="Path_591"
        d="M9.37 10.29a.199.199 0 0 0-.25-.09l-.21.1c.03-.68-.27-1.33-.82-1.76l-.11-.08a1.15 1.15 0 0 1-.34-.42l-.52-1.06c-.16-.33-.49-.56-.87-.6L6.28 5a.849.849 0 0 0-.78-.88c-.1 0-.19.06-.21.16l-.55 3.39v.03c0 .47.14.92.42 1.3s1.35 1.6 1.35 1.6c.09.11.14.24.13.38l-.07.39-.07.03c-.1.04-.14.16-.09.25l.57 1.16c.05.09.16.13.26.09l2.61-1.21c.1-.04.14-.16.09-.25l-.57-1.16Zm-2.55.08L5.47 8.78a1.7 1.7 0 0 1-.34-1.06l.52-3.17c.17.08.27.26.26.44s-.04 1.42-.04 1.42c-.07.02-.13.04-.19.07-.1.04-.14.16-.09.25l.85 1.74c.05.09.16.13.26.09.1-.04.14-.16.09-.25l-.75-1.54c.31-.05.61.1.75.38l.52 1.06c.11.21.26.4.45.55l.11.08c.51.4.76 1.03.65 1.65l-1.5.7.03-.14v-.02a.936.936 0 0 0-.21-.66Zm.43 2.12-.4-.82 2.26-1.05.4.82-2.26 1.05Z"
        className="cls-2"
        data-name="Path 591"
      />
      <path
        id="Path_592"
        d="M10.23 5.24c.07.02.14.02.21 0h.01c1.53-.61 2.04-1.72 1.88-2.56-.08-.56-.57-.98-1.15-.99-.3 0-.59.1-.84.27-.25-.17-.55-.27-.85-.28-.59 0-1.07.44-1.14 1-.17.84.35 1.96 1.88 2.55h.01ZM8.72 2.75c.04-.39.37-.69.77-.7.27.02.53.11.74.28.07.05.16.05.23 0 .21-.16.47-.26.73-.27.4 0 .73.3.77.69.19.94-.67 1.74-1.62 2.12-1.31-.51-1.75-1.43-1.62-2.12Z"
        className="cls-2"
        data-name="Path 592"
      />
      <path
        id="Path_593"
        d="M12.4 7.3h.01c.06.02.12.02.18 0h.01c.42-.15.78-.42 1.03-.78.19-.27.27-.61.21-.94a.816.816 0 0 0-.8-.69c-.19 0-.38.06-.54.16-.16-.1-.35-.16-.55-.17a.8.8 0 0 0-.8.7c-.11.57.23 1.32 1.24 1.71Zm-.87-1.64c.02-.22.2-.39.43-.39.16 0 .31.07.43.17.07.05.16.05.23 0 .12-.1.27-.15.43-.16.22 0 .41.17.43.39.08.41-.19.95-.97 1.28-.78-.32-1.05-.87-.97-1.28Z"
        className="cls-2"
        data-name="Path 593"
      />
      <path
        id="Path_594"
        d="M8.71 4.89c-.19 0-.38.06-.54.16-.16-.1-.35-.16-.55-.17a.8.8 0 0 0-.8.7c-.11.57.23 1.32 1.25 1.71h.01c.06.02.12.02.18 0h.01c.42-.15.78-.42 1.03-.78.19-.27.27-.61.21-.94-.05-.4-.4-.69-.81-.69Zm-.54 2.04c-.78-.32-1.05-.87-.97-1.28.02-.22.2-.39.43-.39.16 0 .31.07.43.17.07.05.16.05.23 0 .12-.1.27-.15.43-.16.22 0 .41.17.43.39.08.41-.19.95-.97 1.28Z"
        className="cls-2"
        data-name="Path 594"
      />
      <path
        id="Path_595"
        d="M11.35 7.21a.636.636 0 0 0-.63-.53.73.73 0 0 0-.38.11.765.765 0 0 0-.39-.11c-.32 0-.58.23-.62.54-.06.59.33 1.13.92 1.28h.01c.05.02.11.02.16 0h.01c.58-.16.97-.69.92-1.28Zm-1.01.91c-.51-.21-.69-.57-.63-.84 0-.13.12-.23.25-.23.1 0 .2.04.27.11.07.05.16.05.23 0 .08-.06.17-.1.27-.11.13 0 .24.11.25.23.05.26-.13.62-.64.84Z"
        className="cls-2"
        data-name="Path 595"
      />
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as GiveForwardIcon }
