/**
 * I18N data constants.
 */

export const LABEL = 'label.';
export const APP_TITLE = 'app_title';
export const SECOND_GENOME = 'second_genome';

export const SANCTUARY_STORE = 'sanctuary_store';
export const URBAN_SANCTUARY = 'urban_sanctuary';
export const MASTER_SANCTUARY = 'master_sanctuary';

export const DISCOVER_YOUR = 'discover_your';
export const WELL_BEING = 'well_being';
export const LIFE_FORCE = 'life_force';
export const LIFE_FORCE2 = 'life_force2';
export const LEARN_ABOUT_US = 'learn_about_us';

export const HUMAN_WELL_BEING = 'human_well_being';
export const SENCTUARY_WELL_BEING = 'sanctuary_well_being';
export const MASTER_RETREAT = 'master_retreat';
export const SHOP_ONLINE = 'shop_online';
export const READ_MORE = 'read_more';
export const BANNER_TEXT = 'banner_text';

//Menu

export const MENU_ABOUT_US = 'menu_about_us';
export const MENU_DISCOVER = 'menu_discover';
export const MENU_PROSPER = 'menu_prosper';
export const MENU_PARTICIPATE = 'menu_participate';
export const MENU_GETSTARTED = 'get_started';

//Signup
export const SIGNUP_TITLE = 'signup_title';
export const SIGNUP_TEXT = 'signup_text';
export const CREATE_ACC_TEXT = 'create_acc_text';

//Store Links
export const HOLISTIC_ADVANCEMENT = 'holistic_advancement';
export const RECONNECT_WITH = 'reconnect_with';
export const INCREASE_HEALTH = 'increase_health';
export const WELL_BEING_SERVICES = 'well_being_services';

//footer
export const URBAN_SANCTUARIES_CONSCIOUS = 'urban_sanctuaries_conscious';
export const DISCOVER_YOUR_MAGNIFICENCE = 'discover_your_magnificence';
export const URBAN_SANCTUARIES_VFH = 'urban_sanctuaries_vfh';
export const CLICK_HERE_TO_INT = 'click_here_to_int';
export const PHONE_NO = 'phone_no';
export const STREET = 'street';
export const SUBURB = 'suburb';
export const NZ = 'nz';
export const COPYRIGHT = 'copyright';

//Organize UI component
export const ORGANIZE_QUESTIONBOX_QA = 'organize_questionbox_qa';
export const ORGANIZE_QUESTIONBOX_VIEWMORE_BUTTON = 'organize_questionbox_viewmore_button';

export const ORGANIZE_ARTICLEBOX_TITLE = 'organize_articlebox_title';

export const ORGANIZE_GUIDEBOX_TITLE = 'organize_guidebox_title';
export const ORGANIZE_GUIDEBOX_TAKEME_BUTTON = 'organize_questionbox_viewmore_button';

export const ORGANIZE_INITIATIVESBOX_TITLE = 'organize_initiativesbox_title';
export const ORGANIZE_INITIATIVESBOX_VISION = 'organize_initiativesbox_vision';
export const ORGANIZE_INITIATIVESBOX_MISSION = 'organize_initiativesbox_mission';
export const ORGANIZE_INITIATIVESBOX_CHARACTER = 'organize_initiativesbox_character';
export const ORGANIZE_INITIATIVESBOX_CREATION = 'organize_initiativesbox_creation';
export const ORGANIZE_INITIATIVESBOX_METAWHEEL = 'organize_initiativesbox_metawheel';
export const ORGANIZE_INITIATIVESBOX_INITIATIVES = 'organize_initiativesbox_initiatives';

//Organize Section content
export const ORGANIZE_SECTIONCONTENT_SHARE = 'organize_sectioncontent_share';
export const ORGANIZE_SECTIONCONTENT_VISIT = 'organize_sectioncontent_visit';
export const ORGANIZE_SECTIONCONTENT_GO_BUTTON = 'organize_sectioncontent_go_button';
