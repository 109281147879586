import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import videoImg from '~/assets/images/page/3dscanning/video.png';
import OurCustomer from '~/components/ui/OurCustomer';

const styles = (theme) => ({
  ourCustomerWrapper: {
    backgroundColor: '#f6f6f6',
    zIndex: '2',
    position: 'relative',
    padding: '60px 0px',
  },
  title: {
    opacity: '0.8',
    fontFamily: 'Arial',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#8c8c8c',
    marginBottom: '40px',
  },
  videoLink: {
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

function OurCustomerSection(props) {
  const {classes} = props;
  return (
    <div className={classes.ourCustomerWrapper}>
      <Container>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.title}>Our Customers</Typography>
            <OurCustomer
              initial="J"
              customerName="Jonathan Walker"
              customerComments="In hac habitasse platea dictumst. Sed nec venenatis odio. Nulla faucibus ipsum sed faucibus accumsan. Donec rhoncus luctus massa vitae lobortis. Duis consequat, nunc a pretium imperdiet, neque est rhoncus massa, tristique rutrum nisl risus at libero."
            />
            <OurCustomer
              initial="B"
              customerName="Brenda Mercer"
              customerComments="Lorem ipsum dolor sit amet consectetur adipiscing elit sodales primis, mollis viverra conubia ligula inceptos laoreet libero tortor, nascetur non habitasse iaculis tempor nec egestas fames augue, platea porta integer nostra curae sed arcu. Nec ut diam vulputate ante scelerisque ridiculus lobortis orci mi curae."
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <img src={videoImg} className={classes.videoLink} alt="Our Scanner" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(OurCustomerSection);
