import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
});

function GetStarted(props) {
  const {classes} = props;

  return <div className={classes.root}>Get Started</div>;
}

export default withStyles(styles)(GetStarted);
