import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import cellWellBeingImage from '~/assets/images/page/hairTesting/cellWellBeing.png';

const styles = (theme) => ({
  ourScannerWrapper: {
    zIndex: '2',
    position: 'relative',
    backgroundColor: 'rgba(154, 162, 0, 0.1)',
  },
  root: {
    zIndex: '2',
    paddingTop: '50px',
    paddingBottom: '50px',
  },

  leftText: {
    fontFamily: 'Helvetica',
    fontSize: '0.8rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.63',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    padding: '10px 0px',
  },
  imageWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  scanningImage: {
    margin: '0px auto',
    width: '300px',
  },
});

function CellWellBeingSection(props) {
  const {classes} = props;

  return (
    <div className={classes.ourScannerWrapper}>
      <Container className={classes.root}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="body1" className={classes.leftText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
              </Typography>
              <Typography variant="body1" className={classes.leftText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.imageWrapper}>
              <img src={cellWellBeingImage} className={classes.scanningImage} alt="Our Scanner" />
            </Grid>
          </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(CellWellBeingSection);
