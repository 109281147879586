import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 15.34 27.29"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "4.35px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 .63 16.91)"
      >
        <tspan x={0} y={0}>
          {"Reuse/"}
        </tspan>
        <tspan x={-0.62} y={4.6}>
          {"Recycle"}
        </tspan>
        <tspan x={-0.35} y={9.2}>
          {"System"}
        </tspan>
      </text>
      <path
        d="M7.69 11.33c-3.2 0-5.81-2.54-5.81-5.66S4.49 0 7.69 0s5.81 2.54 5.81 5.66-2.61 5.66-5.81 5.66Zm0-11.07c-3.05 0-5.53 2.43-5.53 5.4s2.48 5.4 5.54 5.4 5.54-2.42 5.54-5.4S10.75.26 7.69.26Z"
        className="cls-1"
      />
      <path
        id="Path_586"
        d="M11.99 4.38a4.087 4.087 0 0 0-2.03-2.47.549.549 0 0 0-.71.18.464.464 0 0 0 .19.66c.75.4 1.31 1.08 1.55 1.88.02.07.04.15.05.22l.03.16c.02.09.11.16.2.14.1-.01.16-.1.15-.19 0-.06-.02-.12-.03-.18l-.06-.24c-.26-.89-.88-1.64-1.72-2.09-.07-.04-.1-.13-.06-.2.05-.07.14-.1.22-.06.91.48 1.58 1.3 1.86 2.27.28.96.14 1.99-.39 2.84a3.82 3.82 0 0 1-1.45 1.37c-.06.03-.09.1-.09.16 0 .07.06.12.12.14l.43.13c.08.02.13.11.1.19a.17.17 0 0 1-.2.1l-1.26-.63-.02.24.03-.04c-.06-.03-.06-.05 0-.06-.05-.01-1-.02-1-.02L9.28 7.7c.01-.04.04-.07.08-.09.04-.02.08-.02.12 0 .04.01.07.04.09.08.02.04.02.08 0 .11l-.18.49c-.02.07 0 .14.05.18.06.05.14.05.2.02.56-.3 1.03-.74 1.36-1.28.16-.26.28-.54.37-.83.02-.09-.03-.18-.12-.2a.19.19 0 0 0-.22.12 3.116 3.116 0 0 1-1.12 1.62c.05-.13.04-.26-.02-.38a.498.498 0 0 0-.3-.25.58.58 0 0 0-.39.02c-.12.05-.22.15-.26.28L8.9 8.77s-.4.02-.4.03v.04c0 .03 0 .06-.01.09 0 .03-.58.08-.58.08s.59.03.59.04v.03c0 .01.4.02.4.02l-.38.02-.62.02.63.02h.37v.02l-.35.02h.35l-.33.03.02.03.02.03.02.03.02.02h.01l.02.02v-.52h.01l.02.54.02.46.02-.45h.02l.02-.55 1.26.95c.05.02.11.02.16.02.22 0 .42-.13.49-.34a.487.487 0 0 0-.32-.61h-.01l-.07-.02c.51-.34.94-.78 1.25-1.3.58-.93.73-2.05.43-3.09Z"
        className="cls-1"
        data-name="Path 586"
      />
      <path
        id="Path_587"
        d="M6.05 8.49c-.75-.4-1.31-1.08-1.55-1.88-.23-.8-.12-1.65.32-2.36.21-.34.47-.63.79-.87-.02.06-.03.11-.03.17 0 .21.14.39.35.46.27.09.56-.04.66-.3l.27-1.17c.16-.02.17-.03.17-.05v-.06c0-.01 0-.02.82-.02v-.07c-.82 0-.82-.02-.82-.04v-.08s0-.03-.01-.04-.01-.03-.02-.04-.02-.03-.02-.04-.01-.03-.02-.03h-.01l-.02-.03h-.01s-.02-.02-.02-.03h-.01l-.03-.03h-.01l-.04-.03h-.02l-.03-.02v-.14h-.02l-1.26-.25c-.27-.08-.56.05-.65.31a.476.476 0 0 0 .33.61l.07.02c-.51.34-.94.78-1.25 1.3a3.84 3.84 0 0 0-.42 3.1 4.087 4.087 0 0 0 2.03 2.47c.08.04.17.06.26.06.05 0 .09 0 .14-.02a.48.48 0 0 0 .31-.23c.14-.23.06-.52-.17-.65h-.02Zm-.12.5s-.06.06-.1.07c-.04.01-.08 0-.12-.01-.91-.48-1.58-1.3-1.86-2.27-.28-.96-.14-1.99.39-2.84.35-.57.85-1.05 1.45-1.37.06-.03.09-.1.09-.16 0-.07-.06-.12-.12-.14l-.43-.13s-.07-.04-.09-.07c-.02-.03-.03-.08-.01-.11.02-.06.08-.1.15-.1h.05l1.26-.07v1h.01v-.54h.01v.02H5.8l1 .02v.08c-.18.02-.18.02 0 .03s-.61 1.18-.61 1.18c-.03.08-.12.12-.2.09-.06-.02-.11-.08-.11-.14v-.05l.18-.49c.02-.07 0-.14-.05-.18a.184.184 0 0 0-.2-.02c-.56.3-1.03.74-1.36 1.28-.48.79-.61 1.73-.36 2.62.26.89.88 1.64 1.72 2.09.07.04.1.13.06.2Z"
        className="cls-1"
        data-name="Path 587"
      />
      <path
        id="Path_588"
        d="M7.8 5.78c.52.43 1.04.26 1.44-.08.43-.41.69-.96.73-1.54 0-.1-.07-.18-.17-.18h-.03c-1.25.14-1.82.68-2.08 1.11-.14.23-.22.48-.25.74-.41-.45-.99-.73-1.61-.75-.1-.01-.19.06-.2.15v.03c.04.49.25.95.61 1.29.33.28.75.42.63.41v.95c.56.1.64.17.74.17.1 0 .18-.08.26-.17V5.78h-.06Zm.2-.57c.36-.52.94-.85 1.58-.91-.07.41-.27.79-.58 1.08-.33.28-.76.42-1.2.4.03-.2.1-.4.2-.57ZM6.48 6.3c-.24-.22-.4-.52-.46-.84 1.12.2 1.35.85 1.4 1.16-.34 0-.68-.11-.94-.32Z"
        className="cls-1"
        data-name="Path 588"
      />
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as ReuseRecycleSystemIcon }
