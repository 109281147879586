import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = () =>
  makeStyles({
    shareText: {
      marginTop: '1em',
      textAlign: 'center',
      fontSize: '1.8em',
      fontWeight: 'bold',
      color: 'rgba(255,255,255,0.67)',
      lineHeight: '1.6',
      letterSpacing: '5px',
      '&:hover': {
        color: 'rgba(255,255,255,1)',
      },
    },
  });

function ShareVideoBox(props) {
  const classes = useStyles()();
  return (
    <div>
      <img src={props.shareBtn} className={classes.shareImg} alt="butterfly" />
      <Typography className={classes.shareText}>{intl.get(messageKeys.ORGANIZE_SECTIONCONTENT_SHARE)}</Typography>
    </div>
  );
}

export default ShareVideoBox;
