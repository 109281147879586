import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Image8 from '~/assets/images/page/techtesting/rectangle139092@3x.png';
import Image9 from '~/assets/images/page/techtesting/prod3@3x.png';
import Image10 from '~/assets/images/page/techtesting/reportsOct2019WebB@3x.png';

const styles = (theme) => ({
  class1: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: 40,
    flex: 1,
    backgroundImage: `url(${Image8})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      padding: 10,
    },
    '& > div': {
      [theme.breakpoints.down(1024)]: {
        padding: '1em',
      },
    },
  },
  class2: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Lato',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2rem',
    flex: 1.5,
    zIndex: 1,
    '& > div': {
      textAlign: 'right',
      [theme.breakpoints.down(1024)]: {
        textAlign: 'center',
      },
    },
  },
});

const Section4 = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class1}>
      <div
        style={{
          backgroundColor: 'rgba(41, 41, 41,30%)',
          zIndex: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(2.3px)',
          padding: 0,
        }}
      ></div>
      <div style={{flex: 1, zIndex: 1, textAlign: 'center'}}>
        <img style={{width: '80%', maxHeight: '100%'}} src={Image9} />
      </div>
      <div style={{flex: 1, zIndex: 1, textAlign: 'center'}}>
        <img style={{width: '80%', maxHeight: '100%'}} src={Image10} />
      </div>
      <div className={classes.class2}>
        <div>Optimize Reports</div>
        <div style={{fontWeight: 'normal', fontSize: '1rem', marginTop: '1rem'}}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Section4);
