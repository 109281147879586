import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Container} from '@material-ui/core';
import SelectionRow from './Sections/SelectionRow';
import CardGrid from './Sections/CardGrid';
import ImageGallary from './Sections/ImageGallary';
import {useLocation} from 'react-router';
import {useFetchLifeIngredients} from 'shared/react-query/hooks';

const styles = (theme) => ({
  shrinkBox: {
    padding: '0 70px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
});

function LifeIngredients(props) {
  const {classes} = props;
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState('a');
  const lifeIngredients = useFetchLifeIngredients();
  const [ingredientsToShow, setIngredientsToShow] = useState([]);

  useEffect(() => {
    setSelectedItem(!!location?.hash ? location?.hash?.charAt(1) : 'a');
  }, [location]);

  useEffect(() => {
    const arr = lifeIngredients?.data?.metaobjects?.nodes.filter((item) => {
      return item?.name?.value?.charAt(0)?.toLowerCase() === selectedItem.toLowerCase();
    });
    setIngredientsToShow(arr);
  }, [selectedItem, lifeIngredients.data]);

  if (lifeIngredients.status === 'loading') {
    return <></>;
  }
  return (
    <Box>
      <div ref={props.ingredientsTopRef}></div>
      <Box bgcolor="white" pt="30px">
        <Container maxWidth="lg">
          <Box className={classes.shrinkBox}>
            <Box textAlign="left" fontWeight="fontWeightBold" fontSize="h6.fontSize">
              Food Science
            </Box>
            <Box mt="40px" color="#434343" fontSize="body1.fontSize" lineHeight="1.6">
              The world has never offered such variety and nutrition from food as it does today. The Sanctuary Store
              offers recipes comprised of the most nutrient dense, mostly organic, ingredients sourced from all over our
              planet. Enjoy these exceptionally flavoursome dishes which deliver supreme bio-available nutrition to aid:
              preventative health, muscle and bone density, cellular purity, brain health, immunity, weight management,
              vitality and generally - a greater health span. Empower yourself with the food science behind these for
              each specifically identified ingredient to understand how equipped nature actually is at providing for
              your health and well-being.
              <br />
              <br />
              “Let food be thy medicine and medicine be thy food.” Hippocrates, approx. 400 BC.
            </Box>
          </Box>
        </Container>
        <Box mt="30px">
          <ImageGallary />
        </Box>
        <Container maxWidth="lg">
          <Box className={classes.shrinkBox}>
            <Box my="60px" mx="-10px" pb="15px" borderBottom="1px solid rgb(207,207,207)">
              <SelectionRow selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            </Box>
          </Box>
          <Box mb="50px">{ingredientsToShow && <CardGrid data={ingredientsToShow} />} </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(LifeIngredients);
