import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Container} from '@material-ui/core';
import Button from './Button';

const styles = (theme) => ({
  root: {
    margin: '20px 0px',
    textAlign: 'center',
  },
  text: {
    fontFamily: 'HelveticaNeue',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#9aa200',
  },
  buttonWrapper: {
    textAlign: 'center',
  },
});

function FindStoreCard(props) {
  const {classes, text} = props;
  return (
    <div>
      <Container>
        <div className={classes.root}>
          <Grid container alignItems="center" className={classes.root}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body1" className={classes.text}>
                {text}
              </Typography>
            </Grid>
            <Grid className={classes.buttonWrapper} item md={12} sm={12} xs={12}>
              <Button text="Find a Store" />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(FindStoreCard);
