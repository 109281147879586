import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '../../../components/ui/OuterContainer';
import clsx from 'clsx';
import building from '~/assets/images/layout/building.png';
import cartIcon from '~/assets/images/layout/cart_01.png';
import park from '~/assets/images/layout/park.png';
import {Box, Button, Card, CardActionArea, CardContent, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 252,
    padding: theme.spacing(2),
  },
  darkCard: {
    backgroundColor: '#585858',
    color: '#fff',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    fontSize: '16px',
  },
  leftBtn: {
    borderColor: '#fff',
    color: '#fff',
    textTransform: 'uppercase',
  },
}));

const CardComponent = ({dark, title, text, description, link, buttonLabel}) => {
  const classes = useStyles();
  return (
    <Card className={dark ? `${classes.card} ${classes.darkCard}` : classes.card}>
      <CardActionArea href={link}>
        <CardContent>
          <Typography variant="h6" component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {text}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
          {buttonLabel && (
            <Box mt={2}>
              <Button variant="outlined" className={classes.leftBtn}>
                {buttonLabel}
              </Button>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const styles = (theme) => ({
  backgroundColor: {
    backgroundColor: 'white',
    padding: '100px 0',
    [theme.breakpoints.down(1024)]: {
      padding: '50px 0',
    },
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    maxWidth: '1420px',
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '&>a': {
      flex: 1,
      flexDirection: 'column',
      border: 'solid 1px #95989a',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  class2: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  class3: {
    fontSize: '22px',
    color: '#9aa300',
    fontWeight: 700,
    margin: 0,
  },
  class4: {
    fontSize: '22px',
    color: '#8c8c8c',
    marginTop: 0,
    marginBottom: '.5em',
    textAlign: 'center',
  },
  class5: {
    fontSize: '1rem',
    color: '#8c8c8c',
    marginBottom: '.5em',
    fontStyle: 'italic',
    marginTop: 0,
    padding: '0 10px',
    textAlign: 'center',
  },
  class6: {
    color: 'white',
  },
  class7: {
    backgroundColor: '#9aa300',
    padding: '24px 24px',
    [theme.breakpoints.down(1024)]: {
      width: 310,
      padding: '3rem 0',
    },
  },
  class8: {
    padding: '16px 24px',
    [theme.breakpoints.down(1024)]: {
      maxWidth: 290,
      padding: '3rem 0',
    },
  },
});

const StoreLinksSection = (props) => {
  const {classes} = props;
  return (
    <OuterContainer className={classes.backgroundColor}>
      <div className={clsx(classes.class0, classes.class1)}>
        <a
          className={clsx(classes.class0, classes.class8)}
          href="https://vfhurbansanctuary.com/"
          rel="noopener noreferrer"
          style={{textDecoration: 'none', color: 'inherit', minHeight:252}}
        >
          <img src={building} class={classes.class2} />
          <p class={classes.class3}>URBAN SANCTUARY</p>
          <p class={classes.class4}>Holistic Advancement Architecture</p>
          <p class={classes.class5}>Human Well-being & Advancement Services</p>
        </a>
        <a className={clsx(classes.class0, classes.class7)} style={{ minHeight:271 }}>
          <img src={cartIcon} class={classes.class2} />
          <p class={clsx(classes.class3, classes.class6)}>SANCTUARY STORE</p>
          <p class={clsx(classes.class4, classes.class6)}>Increasing Health Span </p>
          <p class={clsx(classes.class5, classes.class6)}>Well-being Services and Supplies</p>
        </a>
        <a
          className={clsx(classes.class0, classes.class8)}
          href="https://vfhurbansanctuary.com/master-sanctuary"
          style={{textDecoration: 'none', color: 'inherit', minHeight:252}}
        >
          <img src={park} class={classes.class2} />
          <p class={classes.class3}>MASTER SANCTUARY</p>
          <p class={classes.class4}>Reconnect with Land and Sea</p>
          <p class={classes.class5}>Master Retreat & Conscious Lifestyle Experience</p>
        </a>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(StoreLinksSection);
