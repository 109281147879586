import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 24.8 26.98"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#7b9d41}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <text
        style={{
          fontFamily: "Roboto-Regular,Roboto",
          fontSize: "5.5px",
          fill: "#7b9d41",
        }}
        transform="matrix(1.03 0 0 1 7.74 19.29)"
      >
        <tspan
          x={0}
          y={0}
          style={{
            letterSpacing: 0,
          }}
        >
          {"E"}
        </tspan>
        <tspan
          x={3.07}
          y={0}
          style={{
            letterSpacing: 0,
          }}
        >
          {"co"}
        </tspan>
        <tspan
          x={-7.55}
          y={6.2}
          style={{
            letterSpacing: 0,
          }}
        >
          {"P"}
        </tspan>
        <tspan x={-4.11} y={6.2}>
          {"ackaged"}
        </tspan>
      </text>
      <path
        id="Ellipse_23"
        d="M12.43 14.47c-3.99 0-7.23-3.25-7.23-7.23S8.44 0 12.43 0s7.23 3.25 7.23 7.23-3.25 7.23-7.23 7.23Zm0-14.17C8.61.3 5.5 3.41 5.5 7.23s3.11 6.93 6.93 6.93 6.93-3.11 6.93-6.93S16.25.3 12.43.3Z"
        className="cls-2"
        data-name="Ellipse 23"
      />
      <path
        id="Path_586"
        d="M17.77 5.64a5.292 5.292 0 0 0-2.56-3.19.672.672 0 0 0-.89.23c-.17.29-.07.67.22.84 0 0 .02 0 .02.01.95.51 1.65 1.39 1.95 2.43.03.09.05.19.07.28.01.07.03.14.04.21.02.12.13.2.25.18.12-.01.21-.12.19-.24-.01-.08-.03-.16-.04-.23-.02-.1-.05-.21-.08-.31a4.464 4.464 0 0 0-2.16-2.69.194.194 0 0 1-.08-.26c.06-.09.18-.12.27-.07 1.14.62 1.99 1.67 2.35 2.92.35 1.24.18 2.56-.49 3.67-.44.74-1.07 1.35-1.82 1.77-.08.04-.12.12-.11.21.01.09.07.16.15.18l.54.17c.1.03.16.14.13.24-.04.1-.15.16-.25.12l-1.58-.5h-.03v-.04l-.02-.06v-.1l.54-1.52c.02-.05.05-.09.1-.11.05-.02.1-.03.15 0 .05.02.09.05.11.1.02.05.03.1 0 .15l-.23.63c-.03.09 0 .18.07.24s.17.07.26.02a4.517 4.517 0 0 0 2.17-2.72.212.212 0 0 0-.15-.26.223.223 0 0 0-.27.15c-.09.34-.23.66-.41.97-.26.43-.6.82-.99 1.13.06-.17.05-.34-.03-.49a.647.647 0 0 0-.37-.32.72.72 0 0 0-.5.03c-.15.07-.27.2-.33.36l-.55 1.52v.09c0 .04-.01.08-.01.12v.28l.02.02.01.02v.02h.01l.02.02h.01l.02.04h.01l.02.04h.01l.03.03h.01l.03.03h.01l.03.03h.04l.02.02h.03l.02.02h.02l1.59.5c.07.02.13.03.2.03.28 0 .53-.17.62-.43a.624.624 0 0 0-.4-.79h-.02l-.09-.03c.64-.44 1.18-1.01 1.58-1.68.73-1.2.92-2.64.55-3.99Z"
        className="cls-2"
        data-name="Path 586"
      />
      <path
        id="Path_587"
        d="M10.3 10.94a4.016 4.016 0 0 1-1.95-2.43c-.29-1.03-.15-2.13.4-3.04.26-.43.6-.82 1-1.13-.02.08-.04.15-.04.22 0 .27.18.51.44.59.33.11.7-.06.83-.39l.54-1.51s.01-.04.01-.06v-.32s0-.02-.01-.03v-.02s-.02-.02-.02-.03-.01-.04-.02-.05-.02-.04-.03-.05h-.01s-.01-.03-.02-.04l-.02-.02-.02-.02h-.02s-.02-.03-.03-.04h-.02l-.04-.04h-.02l-.04-.04h-.02l-.04-.02h-.02l-1.59-.5c-.34-.11-.7.07-.82.4a.618.618 0 0 0 .42.79l.09.03c-.64.44-1.18 1.01-1.58 1.68-.72 1.2-.91 2.65-.53 4a5.292 5.292 0 0 0 2.56 3.19c.1.05.21.08.32.08.06 0 .12 0 .17-.02a.64.64 0 0 0 .39-.29c.17-.29.07-.67-.22-.84 0 0-.01 0-.02-.01Zm-.15.64s-.07.08-.12.09c-.05.01-.11 0-.15-.02a4.859 4.859 0 0 1-2.35-2.92c-.35-1.24-.18-2.56.49-3.67.44-.74 1.07-1.35 1.82-1.77.08-.04.12-.12.11-.21a.204.204 0 0 0-.15-.18l-.54-.17s-.09-.05-.11-.1c-.03-.04-.03-.1-.02-.15.03-.08.1-.13.19-.13h.06l1.58.5H11V3.08l-.54 1.52c-.04.1-.15.15-.25.12a.218.218 0 0 1-.14-.18c0-.02 0-.04.01-.06l.23-.63a.22.22 0 0 0-.07-.24.224.224 0 0 0-.26-.02c-.71.39-1.3.96-1.71 1.65a4.285 4.285 0 0 0-.45 3.37 4.464 4.464 0 0 0 2.16 2.69c.09.05.13.16.08.26Z"
        className="cls-2"
        data-name="Path 587"
      />
      <path
        id="Path_588"
        d="M12.49 7.97c.66.03 1.31-.2 1.81-.63.54-.53.86-1.23.92-1.98 0-.12-.09-.23-.21-.23h-.04c-1.57.18-2.29.88-2.61 1.43-.17.29-.28.61-.32.95-.52-.59-1.25-.94-2.03-.97-.12-.01-.24.07-.25.2v.04c.05.63.32 1.22.77 1.66.42.36.95.55 1.5.53v1.23c0 .12.1.23.22.23s.23-.1.23-.22V7.99h.02Zm.25-1.19a2.67 2.67 0 0 1 1.99-1.17A2.48 2.48 0 0 1 14 7c-.42.36-.96.54-1.51.52.03-.26.12-.51.25-.74ZM10.83 8.1c-.3-.29-.51-.67-.58-1.08 1.4.26 1.7 1.1 1.76 1.49-.43.01-.85-.14-1.18-.42Z"
        className="cls-2"
        data-name="Path 588"
      />
    </g>
  </svg>
)
const Memo = memo(SvgComponent)
export { Memo as EcoPackagedIcon }
