import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography, Button, Container} from '@material-ui/core';
import teamEffort from '~/assets/images/page/prosper/team.png';
import franchiseBg from '~/assets/images/page/prosper/franBg.jpg';
import CommonSection from './CommonSection';
import ExperiencedComponent from './ExperiencedComponent';
import experiencedData from './experiencedData';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import OuterContainer from '../../../components/ui/OuterContainer';

const WhiteButton = withStyles((thme) => ({
  root: {
    color: '#9aa200',
    backgroundColor: '#ffffff',
    fontSize: '16px',
    padding: '10px 50px',
    border: '1px solid #9aa200',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#9AA200',
      color: '#ffffff',
    },
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    padding: '5 30px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const styles = (theme) => ({
  bottomContentWrapper: {
    margin: 'auto',
    backgroundColor: 'white',
    // marginBottom: '8em',
  },
  buttonWrapper: {
    margin: '5px auto',
    textAlign: 'center',
  },
  franchiseSystemsWrapper: {
    position: 'relative',
    zIndex: '-1',
    top: '-250px',
  },
  franchiseSystem: {
    position: 'relative',
  },
  franchiseSystemBg: {
    width: '100%',
    zIndex: '-1',
    background: `url(${franchiseBg}) no-repeat fixed`,
    position: 'relative',
    minHeight: '500px',
    backgroundSize: 'cover',
  },
  franchiseTitle: {
    fontSize: 'calc(2vw + 1.5rem)',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.46',
    letterSpacing: '2.88px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.65)',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '32%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
  },
  requirementsForTeamOuterWrapper: {
    position: 'relative',
    zIndex: '1',
    marginTop: '-450px',
  },
  requirementsTeamInnerContent: {
    backgroundColor: '#f6f6f6',
    margin: 'auto',
    textAlign: 'center',
    padding: '5%',
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      marginBottom: '20px',
    },
  },
  requirementsTitle: {
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#9aa200',
    marginBottom: '40px',
  },
  reqTeamInfo: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.72px',
    textAlign: 'left',
    color: '#434343',

    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  reqCardInfo: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.72px',
    textAlign: 'left',
    color: '#434343',

    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  requirementsSubTitle: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#9aa200',
    marginBottom: '40px',
  },
  requirementsCardTitle: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    marginBottom: '20px',

    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.5',
    },
  },
  requirementsCardContentWrapper: {
    marginBottom: '20px',
    backgroundColor: '#ffffff',
  },
  highlight: {
    color: '#9AA300',
    fontSize: '16px',
  },
  class1: {
    boxSizing: 'border-box',
    border: '1px red solid',
    width: '100%',
  },
  class2: {
    display: 'flex',
    boxSizing: 'border-box',
    border: '2px blue solid',
    padding: 20,
    flex: 1,
    width: '75%',
    minWidth: 1024,
    margin: '0 auto',
    flexDirection: 'column',
  },
});

function MiddleSection(props) {
  const {classes} = props;

  return (
    <OuterContainer className={classes.bottomContentWrapper}>
      <CommonSection
        imageWidth={0}
        title="APPRENTICESHIP PROGRAM"
        info={
          <center>
            <p>
              We will consider training people that are certain about the Plato Franchise Opportunity but need help in
              lifting skill levels. The apprenticeship teaches you how to be efficient and effective in every manner
              associated with your desired Sanctuary Store role, skills you will have for life, and equips you to
              successfully join any syndicate wishing to participate in running a VFH Sanctuary Store.
            </p>
            <p>
              Apprenticeships range from 6-12 months depending on the role. At the end of a successful apprenticeship
              you may also apply for our micro loan funding scheme to financially help you into a franchise operation.
            </p>
            <div className={classes.buttonWrapper}>
              <WhiteButton variant="contained" color="primary">
                Learn More
              </WhiteButton>
            </div>
          </center>
        }
      ></CommonSection>
      {/*   <Grid container className={classes.franchiseSystemsWrapper}>
        <Grid item md={12} sm={12} xs={12} className={classes.franchiseSystem}>
          <div className={classes.franchiseSystemBg}>
            <Typography variant="h4" className={classes.franchiseTitle}>
              The Sanctuary Store Plato Franchise System
            </Typography>
          </div>
        </Grid>
      </Grid>
      <div className={clsx(classes.requirementsForTeamOuterWrapper, classes.class1)}>
        <Grid container className={classes.class2}>
          <Grid item md={12} sm={12} xs={12} className={classes.requirementsTeamInnerContent}>
            <Typography variant="h4" className={classes.requirementsTitle}>
              Requirements for you and your Team
            </Typography>
            <Typography variant="body1" className={classes.reqTeamInfo}>
              Each member of your team must be intimate with their individual roles as well as all aspects of the stores
              concepts. Each person needs to be versed in all of the guides, protocols, food science, products and
              testing facilities to be able to genuinely demonstrate that they live the philosophy of Sanctuary, in
              order to help others.
            </Typography>
            <Box m={2}>
              <a className={classes.highlight} href=".">
                View existing teams and vacancies
              </a>
            </Box>
            <Box m={2.5}>
              <a className={classes.highlight} href=".">
                View currents teams in progress of formation and needing more members to compete their team of 5
              </a>
            </Box>
            <ColorButton
              onClick={() => {
                props.history.push('/prosper/team');
              }}
            >
              Create your team
            </ColorButton>
            <Box m={2.5}>
              <Typography variant="h4" className={classes.requirementsSubTitle}>
                Each Store requires 5 skilled people to operate it.
              </Typography>
              {experiencedData.map((data, index) => (
                <ExperiencedComponent key={index} title={data.title} text={data.text} imageSrc={data.imageSrc} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </div> */}
    </OuterContainer>
  );
}

export default withStyles(styles)(withRouter(MiddleSection));
