import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

import ImageSrc from '~/assets/images/page/weight-shifter/viewdemo.png';

import Image1 from '~/assets/images/page/weight-shifter/Logo_MetaWheel.png';
import Image2 from '~/assets/images/page/weight-shifter/Logo_healthcreator.png';
import Image3 from '~/assets/images/page/weight-shifter/Logo_TreasureBox.png';

const styles = (theme) => ({
  root: {
    padding: '40px 6%',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 3%',
    },
  },
  leftImage: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
      width: '100%',
    },
  },
  appsTitle: {
    color: '#707070',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: '65px 0 30px 60px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 30px 30px',
    },
  },
  appItemImage: {
    width: '60px',
    height: '80px',
    objectFit: 'contain',
  },
  appItemName: {
    color: '#8DBF4C',
    textDecoration: 'underline',
    marginTop: '10px',
  },
  partContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
  },
  liveAppDemoText: {
    flex: 50,
    fontSize: '1.25rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.21',
    letterSpacing: 'normal',
    color: '#5499c7',
    textAlign: 'center',
  },
  appWrap: {minWidth: 320, width: '70%', marginLeft: '5%'},
});
const data = [
  {
    image: Image1,
    name: 'Meta Wheel',
  },
  {
    image: Image2,
    name: 'Health Creator',
  },
  {
    image: Image3,
    name: 'Treasure Box',
  },
];

function PartnerApps(props) {
  const {classes} = props;
  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Grid item md={6} xs={12} className={classes.partContainer}>
          <Typography className={classes.appsTitle}>Partner Apps</Typography>
          <Grid container className={classes.appWrap} justify="space-around">
            {data.map((item) => (
              <Grid item key={item.name}>
                <Grid container direction="column" alignItems="center">
                  <img src={item.image} alt={item.name} className={classes.appItemImage} />
                  <Typography className={classes.appItemName}>{item.name}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <img src={ImageSrc} alt={'img'} className={classes.leftImage} />
          <div className={classes.liveAppDemoText}>View Live App Demo</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(PartnerApps);
