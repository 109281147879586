import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MapCard from '~/components/ui/MapCard';
import ProvideInfoSection from './Sections/ProvideInfoSection';
import OurMissionSection from './Sections/OurMissionSection';
import TopSection from './Sections/TopSection';
import MiddleSection from './Sections/MiddleSection';
import PageHeader from '~/components/ui/PageHeader';
import BackgroundSection from './Sections/BackgroundSection';
import TeamsSection from './Sections/TeamsSection';


const styles = () => ({
  root: {
    color: '#000000',
  },
});

function Prosper(props) {
  const {classes} = props;

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  return (
    <div className={classes.root}>
      <TopSection />
      <MiddleSection />
      <BackgroundSection />
      <TeamsSection />
      <MapCard />
      <OurMissionSection />
      <ProvideInfoSection />
    </div>
  );
}

export default withStyles(styles)(Prosper);
