import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import aboutbg from '~/assets/images/page/hairTesting/aboutBg.png';
import optimizebg from '~/assets/images/page/hairTesting/optimizeBg.png';
import providerbg from '~/assets/images/page/hairTesting/providerBg.png';
import ReadMoreComponent from '~/components/ui/ReadMoreComponent';

const styles = (theme) => ({
  thumbnailWrapper: {
    margin: '60px 0',
  },
});

function ThumbNailCardSection(props) {
  const {classes} = props;
  const textStyle = {
    textAlign: 'left',
    position: 'absolute',
    top: '17%',
    right: '10%',
    width: 'auto',
    height: 'auto',
    padding: '0px 8px 0px 0px',
  };

  return (
    <div className={classes.thumbnailWrapper}>
      <Grid container style={{width: '100%', margin: 0}} spacing={1}>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={aboutbg} text="About Epigenetics" textStyle={textStyle} />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={optimizebg} text="Optimize Reports" textStyle={textStyle} />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={providerbg} text="Providers" textStyle={textStyle} />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ThumbNailCardSection);
