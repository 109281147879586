import React, {Component} from 'react';
import * as d3 from 'd3';

class ProgressChart extends Component {
  render() {
    const {color, percentage, radius} = this.props;
    const gaugeProps = {
      tau: 2 * Math.PI,
      radius: radius,
      percentage: percentage,
      color: color,
    };

    gaugeProps.boxSize = gaugeProps.radius * 2;
    gaugeProps.ratio = gaugeProps.percentage;

    return (
      <section>
        <GaugeGraph {...gaugeProps} />
      </section>
    );
  }
}
export default ProgressChart;

class GaugeGraph extends Component {
  componentDidMount() {
    const {tau, radius, boxSize, ratio, color} = this.props;

    this.renderSVG(radius, boxSize, tau, ratio, color);
  }

  renderSVG(radius, boxSize, tau, ratio, color) {
    // Transition function
    const arcTween = function (newAngle) {
      return function (d) {
        const interpolate = d3.interpolate(d.endAngle, newAngle);

        return function (t) {
          d.endAngle = interpolate(t);

          return arc(d);
        };
      };
    };

    // Arc function
    const arc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius - 10)
      .startAngle(0);

    // Initial SVG render
    this.svg = d3
      .select(this.refs.container)
      .attr('width', boxSize)
      .attr('height', boxSize)
      .append('g')
      .attr('transform', `translate(${boxSize / 2}, ${boxSize / 2})`);

    // Background Arc
    this.svg.append('path').datum({endAngle: tau}).style('fill', '#dfe3e6').attr('d', arc);

    // Foreground Arc
    this.svg
      .append('path')
      .datum({endAngle: 0})
      .style('fill', color)
      .transition()
      .duration(1000)
      .delay(1000)
      .attrTween('d', arcTween(ratio * tau));
  }

  render() {
    const {boxSize, percentage} = this.props;
    return (
      <div style={{position: 'relative', width: boxSize}}>
        <svg ref="container"></svg>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            textAlign: 'center',
            color: '#434343',
            lineHeight: '1',
          }}
        >
          <p className="percentage">{`${percentage * 100}%`}</p>
        </div>
      </div>
    );
  }
}
