import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Button';
import ImageBg from '~/assets/images/page/home/earth.png';
import Life from '~/assets/images/page/home/lifeforcefood.png';
import ThreeD from '~/assets/images/page/home/3dscanning.png';
import Inbody from '~/assets/images/page/home/inbody.png';
import Vision from '~/assets/images/page/home/visionproducts.png';
import Personal from '~/assets/images/page/home/healthprotocol.png';
import Hair from '~/assets/images/page/home/hairtesting.png';
import ReadMoreComponent from '~/components/ui/ReadMoreComponent';
import {Link} from 'react-router-dom';
import {Container} from '@material-ui/core';
import OuterContainer from '../../../components/ui/OuterContainer';
import clsx from 'clsx';
import {getPath} from '~/static/routes';

const styles = (theme) => ({
  visionaireWrapper: {
    padding: '40px 10%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  boiText: {
    padding: '10px 10px',
    color: '#8c8c8c',
    fontFamily: 'Georgia',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'italic',
    marginLeft: 10,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  leftTitle: {
    fontFamily: 'Lato',
    fontSize: '1.6rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.21',
    letterSpacing: 'normal',
    paddingLeft: '0px',
    color: '#8c8c8c',
    textAlign: 'center',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  storeContent: {
    margin: 'auto',
    textAlign: 'center',
    padding: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  storeTitle: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: '700',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '0.98',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#9aa200',
    marginBottom: '20px',
  },
  storeInfo: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.72px',
    textAlign: 'left',
    color: '#434343',
    paddingBottom: 30,
    // [theme.breakpoints.down('sm')]: {
    //   padding: '20px',
    // },
  },
  highlight: {
    textDecoration: 'none',
    color: '#9AA300',
    fontWeight: '500',
  },
  readMore: {
    height: 207,
  },

  bio: {display: 'flex', justifyContent: 'space-around'},
  backgroundColor: {
    backgroundColor: '#f2f2f2',
    padding: '0 16px',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    fontFamily: 'Montserrat',
    color: '#8c8c8c',
    '&>div': {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down(847)]: {
      flexDirection: 'column',
    },
  },
  class2: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  class3: {
    flex: 0.5,
    '&>a>img': {
      height: '4rem',
      weight: '4rem',
      padding: 16,
    },
    '&>p': {
      fontSize: '1rem',
      fontStyle: 'italic',
    },
  },
});

function DiscoverSection(props) {
  const {classes} = props;
  return (
    <div style={{background: '#f2f2f2'}}>
      <OuterContainer className={classes.backgroundColor}>
        <div className={clsx(classes.class0, classes.class1)}>
          <div className={classes.class0} style={{flex: 1}}>
            <p className={classes.class2}>
              Urban Sanctuaries for Conscious Creatures
            </p>
          </div>
          <div className={clsx(classes.class0, classes.class3)} style={{flex: 0.5}}>
            <a href="http://www.biosphericallycorrect.org/"><img src={ImageBg} /> </a>
            <p>The Sanctuary Project is being designed via Biospherically Correct principles.</p>
          </div>
        </div>
      </OuterContainer>

      {/* <Grid container className={classes.visionaireWrapper}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <div className={classes.leftTitle}>Discover your Magnificence</div>
          <div className={classes.leftTitle}>Urban Sanctuaries for Conscious Creatures</div>
        </Grid>
        <Grid className={classes.bio} item xs={12} sm={12} md={4} lg={4}>
          <div>
            <img src={ImageBg} style={{width: 50, height: 50, paddingTop: 15}} alt="earth" />
          </div>
          <div className={classes.boiText}>
            Biospherically Correct shopping experience, elevating global consciousness and helping create “A Successful
            Planet.”
          </div>
        </Grid>
      </Grid> */}

      <Grid container style={{width: '100%', margin: 0}} spacing={1}>
        <Grid className={classes.readMore} alignItems="flex-start" item xs={12} sm={4} md={4}>
          <ReadMoreComponent
            text="Life Force Food, Drinks & Dressings"
            imageSrc={Life}
            height={200}
            path={getPath('Life Force Food')}
            additionText="Raw Organic Ingredients"
            additionTextPath={getPath('Raw Organic Ingredients')}
          />
        </Grid>
        <Grid className={classes.readMore} item xs={12} sm={4} md={4}>
          <ReadMoreComponent
            text="Natural Biospherically Correct Products "
            imageSrc={Vision}
            height={200}
            path="https://visionproductsdirect.com/"
          />
        </Grid>
        <Grid className={classes.readMore} item xs={12} sm={4} md={4}>
          <ReadMoreComponent
            text="Personal Health Guides and Protocols"
            imageSrc={Personal}
            height={200}
            path={getPath('Shop by Health Interest')}
          />
        </Grid>
      </Grid>
      <OuterContainer>
        <Grid container className={classes.storeContent}>
          <Typography variant="h5" className={classes.storeTitle}>
            A store with integrated products and services for your greatest health span{' '}
            <span style={{color: '#8c8c8c'}}>- Create your account today.</span>
          </Typography>
          <Typography variant="body2" className={classes.storeInfo}>
            <span style={{fontWeight: '700'}}>Our Sanctuary Stores </span>stock the entire Vision, Biospherically
            Correct, product range of Superfood Skincare, Eco Hair and Body care, Super soft eco apparel, Natures
            Greeting Cards and Coconut Oil, along with our extensive range of Life Force Foods, drinks and dressings,
            which you can nutritionally analyse using our Health Creator App for optimum everyday well-being. Sign up to
            access the food science, gain greater health, help achieve your ideal weight, and quick purchase products
            with your Urban Sanctuary account.
          </Typography>
          <Grid container justify={'space-around'}>
            <Box>
              <Link className={classes.highlight} to={getPath('Shop by Product & Services')}>
                {'>>'} Explore Products and Services
              </Link>
            </Box>
            <Box>
              <Link className={classes.highlight} to={getPath('Shop by Health Interest')}>
                {'>>'} Explore by Health Interest
              </Link>
            </Box>
          </Grid>
        </Grid>
      </OuterContainer>
      <Grid container style={{width: '100%', margin: 0}} alignItems="flex-start" spacing={1}>
        <Grid className={classes.readMore} item xs={12} sm={6}>
          <ReadMoreComponent text="Hair Testing" imageSrc={Hair} height={200} path={"https://vfhurbansanctuary.com/tech-hair-testing"} />
        </Grid>
        <Grid className={classes.readMore} item xs={12} sm={6}>
          <ReadMoreComponent text="Inbody Scanning" imageSrc={Inbody} height={200} path={"https://vfhurbansanctuary.com/store-service-inbody-scanning"} />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(DiscoverSection);
