import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import playerIcon from '../../assets/images/page/account/pro-icon.png';
import bannerImg from '../../assets/images/page/account/banner.png';
import profileImg from '../../assets/images/page/account/profile.png';
import home from '../../assets/images/page/account/home.png';
import person from '../../assets/images/page/account/person.png';
import giftcard from '../../assets/images/page/account/giftcard.png';
import share from '../../assets/images/page/account/share.png';
import lockOpen from '../../assets/images/page/account/lock_open.png';
import satisfied from '../../assets/images/page/account/very_satisfied.png';
import list from '../../assets/images/page/account/list.png';
import deliveryBox from '../../assets/images/page/account/delivery-box.png';
import network from '../../assets/images/page/account/network.png';
import addCircle from '../../assets/images/page/account/add_circle.png';
import star from '../../assets/images/page/account/star.png';
import guid1 from '../../assets/images/page/account/ord1.png';
import guid2 from '../../assets/images/page/account/ord2.png';
import { Edit } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { useAuth } from 'shared/auth';
import { useQuery } from 'react-query';
import { fetchVisionaireProfile } from 'shared/react-query/strapiAPI';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetchCustomer, useFetchOrderHistory } from 'shared/react-query/hooks';
import CustomerOrdersTable from './CustomerOrdersTable';
import { isNil } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StrapiVideo } from "shared/ui/StrapiVideo"
import {getStrapiAssetURL} from "shared/utils/strapiUtils";
import { ArrowBack, PlayCircleOutline } from '@material-ui/icons';
import { Button, Card, CardContent, Typography } from '@material-ui/core';

const Account = () => {
  const {id: slug} = useParams();
  const [playVideo, setPlayVideo] = useState(false);
  const {auth, profileData} = useAuth();
  const { data, error, isLoading } = useQuery(['visionaireProfile', slug, auth.token], () => fetchVisionaireProfile(slug ?? profileData?.slug, auth.token), { enabled: !isNil(slug ?? profileData?.slug)});
  const isLoggedinUserProfile = !isNil(data?.data?.attributes?.email)
  console.log(isLoggedinUserProfile,data?.data?.attributes?.email,'data?.email')

  const {data:orderHistoryData} = useFetchOrderHistory({token:auth.token})
  console.log(orderHistoryData,'orderHistoryData')
  if(!data){
    return <></>
    }
  const images = data?.data.attributes?.displayPhotosAndVideos?.data?.map((e, i) => {
    if (e?.attributes?.mime == "video/mp4") {
      return <div style={{ position: "relative", width: "100%", height: "100%" }} onClick={() => { setPlayVideo(e?.attributes?.url) }}> <StrapiVideo segment={e?.attributes?.url} controls={false} />
        <PlayCircleOutline style={{ position: "absolute", bottom: "35%", left: "50%", transform: "translateX(-50%)", height: "60px", width: "60px" }} />
      </div>
    }
    return (
      <img key={i} src={getStrapiAssetURL(e?.attributes?.url)} className="dynamicimg" />
    )
  })
  return (
    
    <div className="account-detail">
      <div className='profile-wrap'>
        <Grid container spacing={2}>
          <Grid item md={6} style={{width:"100%"}}>
            <div className="profile-sec">
              <div className='pro'>
              <Carousel showArrows={true} showThumbs={false}>
                {images}
              </Carousel>
              <div className='btn-pro'>
                  <button>UPLOAD Image</button>
                  <button>SET DEFAULT</button>
                </div>
              </div>
              <div className='pro2'>
                <Typography className="userName">{data.data.attributes.firstName} {data.data.attributes?.lastName ?? ""}</Typography>
                <p> <img alt="not found" src={home} />{data.data.attributes.location}</p>
                <p> <img alt="not found" src={star} />{data.data.attributes.title}</p>
                {/* <button>MY SCREEN SAVER</button> */}
              </div>
              
            </div>
          </Grid>
          <Grid item md={2} style={{width:"100%"}}>
            <div className="profile-sec2">
              <p>METAWHEEL</p>
              <img alt="not found" src={playerIcon} />
            </div>
          </Grid>
          <Grid item md={4} style={{width:"100%"}}>
            <div className="profile-sec3">
              <button>Progress account profile</button>
              <button>set public profile</button>
            </div>
          </Grid>
        </Grid>
        <div className='tab-wraper'>
          <Grid container>
            <Grid item md={3} className="no-pad">
              <div className="profile-tab">
                <ul>
                  <Link to='/account'><li><img src={person} /><span>Account Detail</span></li></Link>
                  <li><img alt="not found" src={giftcard} /><span>Visionaire Rewards</span></li>
                  <li><img alt="not found" src={share} /><span>Visionaire Network</span></li>
                  <li><img alt="not found" src={lockOpen} /><span>Change Password</span></li>
                  <li><Edit/><span>Create Detail</span></li>
                  <li><img alt="not found" src={satisfied} /><span>About Me</span></li>
                  <Link to='/account3'><li><img alt="not found" src={list} /><span>My Order</span></li></Link>
                  <Link to='/account1'><li className='tab-active'><img alt="not found" src={deliveryBox} /><span>Advance Order</span></li></Link>
                  <li><img alt="not found" src={network} /><span>Participate</span></li>
                  <li><img alt="not found" src={addCircle} /><span>Create Profile</span></li>
                </ul>
              </div>
            </Grid>
            <Grid item md={9} className="no-pad">
              <div className="profile-content">
                <h2 style={{marginTop:0}}>ADVANCE ORDER</h2>
                <Grid container>
                  <CustomerOrdersTable data={orderHistoryData ?? []}/>
                  {/* <Grid item md={9}>
                    <div className='order-wrappers'>
                      <div className='ord-detail'>
                        <p>ORDER NUMBER : <span>ON392609722123</span></p>
                        <p>REFERENCE: <span>URBAN SANCTUARY</span></p>
                        <p>DATE: : <span>28/05/2020</span></p>
                      </div>
                      <div className='table-responsive'>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Item</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Regular Price</th>
                              <th scope="col">Advance Purchase <br />Price</th>
                              <th scope="col">Saving</th>
                              <th scope="col">Estimated Delivery</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div>
                                  <img alt="not found" src={guid2} />
                                  <p>Health Guide 1</p>
                                  <p>Life Force Food </p>
                                </div>
                              </td>
                              <td>1</td>
                              <td>$39.99</td>
                              <td>$19.99</td>
                              <td>$20.00</td>
                              <td>28/12/22</td>
                            </tr>
                            <tr>
                              <td>
                                <div>
                                  <img alt="not found" src={guid1} />
                                  <p>Health Guide 1</p>
                                  <p>Longevity Activities</p>
                                </div>
                              </td>
                              <td>1</td>
                              <td>$39.99</td>
                              <td>$19.99</td>
                              <td>$20.00</td>
                              <td>28/12/22</td>
                            </tr>
                            <tr>
                              <td colSpan={6}>
                                <div className='tot-sec'>
                                  <h4>total advance purchases : 2h</h4>
                                  <h4>total savings : $40</h4>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <div className='ord-right'>
                      <div className='p-title'>
                        <h3>Sanctuary Project</h3>
                        <p>Status</p>
                      </div>
                      <div className='p-detail'>
                        <p>$1999.00</p>
                        <h4>Funds Required</h4>
                      </div>
                      <div className='p-detail'>
                        <p>$19.99</p>
                        <h4>funds raised <br />towards this item</h4>
                      </div>
                      <div className='progres'>
                        <span><i className="fa fa-circle" aria-hidden="true"></i> In progess</span>
                      </div>
                      <div className='p-detail'>
                        <p>$1999.00</p>
                        <h4>Funds Required</h4>
                      </div>
                      <div className='p-detail'>
                        <p>$1999.00</p>
                        <h4>funds raised <br />towards this item</h4>
                      </div>
                      <div className='reached'>
                        <span><i className="fa fa-circle" aria-hidden="true"></i> Fund level reached</span>
                      </div>
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}


export default Account