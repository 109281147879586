import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography, Container, Divider} from '@material-ui/core/';
import PublicIcon from '@material-ui/icons/Public';
import clsx from 'clsx';


const styles = (theme) => ({
  root: {
    color: '#343434',
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mainTitle: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  subtitle: {
    color: '#8c8c8c',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    cursor: 'pointer',
    margin: '5px',
  },
  greenBox: {
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    height: '100%',
  },

  itemName: {
    color: '#595959',
    fontWeight: '500',
  },
  itemData: {
    color: '#595959',
    fontWeight: '300',
  },
  desc: {
    color: '#8c8c8c',
    fontWeight: '300',
  },
  globeIcon: {
    width: '40px',
    height: '40px',
    [theme.breakpoints.down('xs')]: {
      width: '20px',
      height: '20px',
    }
  },
  globeIcon1: {
    fill: '#0ff517'
  },
  globeIcon2: {
    fill: '#27f62d'
  },
  globeIcon3: {
    fill: '#37f63d'
  },
  globeIcon4: {
    fill: '#46f74c'
  },
  globeIcon5: {
    fill: '#56f85b'
  },
  globeIcon6: {
    fill: '#66f86a'
  },
  globeIcon7: {
    fill: '#75f979'
  },
  globeIcon8: {
    fill: '#85fa89'
  },
  globeIcon9: {
    fill: '#95fa98'
  },
  globeIcon10: {
    fill: '#a4fba7'
  },
});

function GlobalImpact(props) {
  const {classes} = props;
  return (
    <>
      <Container maxWidth="lg" className={classes.root}>
        <Box mb="40px">
          <Typography align="center" className={classes.mainTitle}>
            {' '}
            Global impact
          </Typography>
        </Box>
        <Grid container spacing={1} display="flex" alignItems="stretch">
          <Grid item md={4} sm={6} xs={12}>
            <Box className={classes.greenBox}>
              <Box textAlign="center">
                <Typography className={classes.subtitle}>oVERVIEW</Typography>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="column" p={2} justifyContent="space-between">
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Conscious Food Chart Rating:</Typography>
                  <Typography className={classes.itemData}>9+</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Approximate Carbon Foot Print:</Typography>
                  <Typography className={classes.itemData}>99</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.itemName}>Carry Capacity GPM:</Typography>
                  <Typography className={classes.itemData}>1 planet</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <Box className={classes.greenBox}>
              <Box textAlign="center">
                <Typography className={classes.subtitle}>If the whole world ate like me</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Typography className={classes.desc}>
                  Feed the world within the carry capacity of the planet, dramatically reduce; animal cruelty and global
                  green house gas emissions, ensure more biodiversity and reduce bioaccumulation, reduce medical
                  industry costs by billions of dollars.
                </Typography>
                <Box display="flex" mt="10px" justifyContent="space-between">
                  {/* {[...Array(10).keys()].map((item, index) => (
                    <Box className={classes.iconWrapper} width="30px" height="30px" key={item}>
                      <img alt="global" src={IconEarth} style={{width: '100%'}} /> 
                    </Box>
                  ))}  */}
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon1)}  />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon2 )}  />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon3)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon4)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon5)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon6)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon7)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon8)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon9)} />
                    </Box>
                    <Box>
                      <PublicIcon className={clsx(classes.globeIcon, classes.globeIcon10)} />
                    </Box>

        
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withStyles(styles)(GlobalImpact);
