import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, IconButton} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import {getPath} from '~/static/routes';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import OuterContainer from '../OuterContainer';
const useStyles = makeStyles((theme) => ({
  class0: {
    padding: '40px 0',
    [theme.breakpoints.down(450)]: {
      padding: '2px 0',
    },
  },
  class1: {
    width: '100%',
    margin: 0,
  },
  class2: {
    color: 'white',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down(450)]: {
      fontSize: '1.2rem',
    },
  },
  class3: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Roboto',
  },
  class4: {
    color: 'white',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: 1.2,
    fontStretch: 'normal',
    letterSpacing: 1,
    [theme.breakpoints.down(450)]: {
      fontSize: '1.1rem',
    },
  },
  socialIcons: {
    width: '20px',
    height: '20px',
    color: '#9aa200',
    [theme.breakpoints.down(450)]: {
      width: '16px',
      height: '16px',
    },
  },
  class5: {
    maxWidth: 150,
    [theme.breakpoints.down(450)]: {
      maxWidth: 136,
    },
  },
  class6: {
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.20)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

const dehyphenateStr = (str) => {
  let string = str;
  const newStr = string.replace(/and/g, '&').replace(/-+/g, ' ');
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

export const Breadcrumbs = (props) => {
  const {Extension = () => <></>, url, location} = props;
  const classes = useStyles();

  const pathnames = location.pathname.split('/').filter((x) => x);


  if (pathnames.length == 1) {
    return (
      <OuterContainer
        style={{
          backgroundImage: `url(${url})`,
        }}
        className={classes.class6}
      >
        <Grid container className={clsx(classes.class0, classes.class1)} justify="center" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.class2}>{dehyphenateStr(pathnames[0]).toUpperCase()}</Typography>
          </Grid>
          <Grid container className={classes.class1} item xs={12}>
            <Extension />
          </Grid>
        </Grid>
      </OuterContainer>
    );
  } else {
    return (
      <OuterContainer
        style={{
          backgroundImage: `url(${url})`,
        }}
        className={classes.class6}
      >
        <Grid container spacing={2} className={clsx(classes.class1, classes.class0)}>
          <Grid item container xs spacing={2}>
            {pathnames.map((element, index) => {
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              const label = dehyphenateStr(element)
              if (pathnames.length - 1 === index) {
                return (
                  <Grid key={element + index} item xs={12}>
                    <Typography className={clsx(classes.class3, classes.class4)}>
                      {label.toUpperCase()}
                    </Typography>
                  </Grid>
                );
              } else {
                return (
                  <Grid key={element + index} item>
                    <Link className={classes.class3} to={to}>
                      {label} &gt;
                    </Link>
                  </Grid>
                );
              }
            })}
          </Grid>
          <Grid container item xs alignItems="center" className={classes.class5}>
            {/* <Grid item xs style={{textAlign: 'right'}}>
              <IconButton >
                <FacebookIcon className={classes.socialIcons} />
              </IconButton>
            </Grid>
            <Grid item xs style={{textAlign: 'right'}}>
              <IconButton>
                <TwitterIcon className={classes.socialIcons} />
              </IconButton>
            </Grid> */}
            <Grid item xs style={{textAlign: 'right'}}>
              <IconButton onClick={()=>{
                window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
              }}>
                <InstagramIcon className={classes.socialIcons} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.class1} item xs={12}>
            <Extension />
          </Grid>
        </Grid>
      </OuterContainer>
    );
  }
};
