import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';

const styles = (theme) => ({
  class0: {
    margin: 0,
    width: '100%',
    height: '100%',
  },
  footerLink: {
    textDecoration: 'none',
    color: 'black',
    fontWeight: 500,
    fontSize: '1rem',
    textAlign:'center',
  },
});

const Section2 = (props) => {
  const {classes, routes} = props;
  return (
    <Grid container item alignItems="flex-start" spacing={2} className={classes.class0} xs={12} sm={3} md={2} style={{maxWidth:'250px', paddingLeft: 27}}>
      {routes.map((route, index) => (
        <Grid item xs={6} key={index}>
          {route.path ? <Link to={route.path } variant="body2" className={classes.footerLink}>
            {route.name}
          </Link> :
          <a className={classes.footerLink} href={route.url}>
{route.name}
          </a>
           }
          
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(Section2);
