import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography, Button} from '@material-ui/core';
import reportingImage from '~/assets/images/page/hairTesting/reporting.png';
import laptopImage from '~/assets/images/page/hairTesting/laptop.png';

const styles = (theme) => ({
  root: {
    zIndex: '2',
    paddingTop: '50px',
    paddingBottom: '50px',
    '& div': {},
  },
  title: {
    width: '430px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      padding: '0px 5px 0px 10px',
    },
    height: '50px',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#8c8c8c',
    margin: '0px auto',
  },
  rightContent: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px',
    },
  },
  leftContent: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px',
    },
  },
  imageWrapper: {
    textAlign: 'center',
  },
  laptopImage: {
    maxWidth: '445px',
    textAlign: 'center',
    position: 'relative',
    zIndex: '3',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  reportingImage: {
    position: 'relative',
    zIndex: '3',
    maxWidth: '545px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  button: {
    width: '200px',
    height: '50px',
    borderRadius: '5px',
    boxShadow: '5px 5px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#8c8c8c',
    '&:hover': {
      backgroundColor: '#8c8c8c',
    },
    fontFamily: 'Arial',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2',
    letterSpacing: '0.8px',
    color: '#ffffff',
  },
});

function ReportingSection(props) {
  const {classes} = props;

  return (
    <div className={classes.exerciseWrapper}>
      <Container className={classes.root}>
        <Grid container>
          <Grid item md={6} className={classes.rightContent}>
            <img src={reportingImage} className={classes.reportingImage} alt="Reporting Dashboard" />
          </Grid>
          <Grid item md={6} className={classes.leftContent}>
            <img src={laptopImage} className={classes.laptopImage} alt="Reporting Dashboard" />
            <Typography variant="h6" className={classes.title}>
              Diverted and concentrated by drains that create constant flows over the beach above the sea or river
              level, the beach will be eroded.
            </Typography>
            <Button className={classes.button}>Discover More</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(ReportingSection);
