import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Container, Box} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
  },
  enjoyInfo: {
    color: 'rgba(38,38,38,0.8)',
  },
  boxWrapper: {
    border: 'solid 0.5px #9AA200',
    borderRadius: '8px',
    padding: '2%',
  },
  expressColor: {
    color: 'red',
  },
  viewText: {
    color: '#9AA200',
    fontSize: '16px',
  },
  storeName: {
    fontWeight: '500',
    color: '#8C8C8C',
  },
});

function StoreTypeBox(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.boxWrapper}>
          <Typography variant="subtitle1" className={classes.storeName}>
            <span style={{color: '#9AA200'}}>Sanctuary Store </span> Express
          </Typography>
          <Typography variant="body2" className={classes.enjoyInfo}>
            Small Convenience Stores to access our unique health span products. Shop with our Heath Creator app.{' '}
          </Typography>
          <Typography variant="subtitle1" className={classes.storeName}>
            <span style={{color: '#9AA200'}}> Sanctuary Store </span> Express
            <Typography component="span" variant="body2" className={classes.expressColor}>
              {' '}
              + Services
            </Typography>
          </Typography>
          <Typography variant="body2" className={classes.enjoyInfo}>
            Small Convenience Stores to access our unique health span products, equipped with our streamlined health
            testing services.
          </Typography>
          <Typography variant="subtitle1" className={classes.storeName}>
            <span style={{color: '#9AA200'}}>Sanctuary Store</span>
          </Typography>
          <Typography variant="body2" className={classes.enjoyInfo}>
            Full services healthy life style Stores to access our entire range of unique health span products, services
            and testing.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default withStyles(styles)(StoreTypeBox);
