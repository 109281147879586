import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Container, Typography, Avatar, TextField, Button, Grid} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import avatarIcon from '~/assets/images/page/lifeforcefood/detail/avatar.png';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    textAlign: 'center',
  },

  icon: {
    color: '#9aa300',
    marginRight: '10px',
    fontSize: '14px',
  },

  largeAvatar: {
    width: '56px',
    height: '56px',
  },

  reviewText: {
    fontSize: '14px',
    color: '#404040',
  },

  reviewBox: {
    width: '100%',
    background: 'white',
  },
  avatarBox: {
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  reviewBtn: {
    width: '200px',
    border: 'solid 1px #9aa300',
    color: '#9aa300',
    background: 'white',
  },
  blueprint: {
    // border: '1px red solid',
    margin: 0,
    width: '100%',
  },
}));

function Reviews(props) {
  const classes = useStyles();
  const review = (key) => (
    <>
      <Grid item xs={12} sm={2} md={1} key={key} className={classes.blueprint}>
        <Box display="flex" className={classes.avatarBox} pt={0.5}>
          <Avatar alt="Cindy Baker" src={avatarIcon} className={classes.largeAvatar} />
        </Box>
      </Grid>
      <Grid container item xs={12} sm={10} md={11} spacing={1} key={key + 100} className={classes.blueprint}>
        <Grid item xs={12} className={classes.blueprint}>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              <strong>Jane Swift</strong>
            </Typography>
            <Typography>Yesterday, 23:22</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Typography className={classes.reviewText}>
            Lorem ipsum dolor sit amet, solum dictas vim cu, ne his hendrerit deterruisset, id sed doctus fuisset
            intellegam. Per case melius assentior ea. Et scaevola insolens eum. Ad vix verear eruditi ancillae, fabulas
            assentior his at, eum no dico euripidis reprehendunt. Cibo delectus eu ius, usu veri putent at. Lorem ipsum
            dolor sit amet, solum dictas vim cu, ne his hendrerit deterruisset, id sed doctus fuisset intellegam. Per
            case melius assentior ea. Et scaevola insolens eum.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Grid container spacing={2} className={classes.blueprint}>
        <Grid item xs={12} key={'productReview'} className={classes.blueprint}>
          <Typography className={classes.title}>PRODUCT REVIEWS</Typography>
        </Grid>
        {[1, 2, 3, 4, 5].map((ele) => review(ele))}
        <Grid item xs={12} key={'writeReview'} className={classes.blueprint}>
          <Box display="flex" justifyContent="center">
            <TextField className={classes.reviewBox} multiline rows={6} variant="outlined" />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.blueprint}>
          <Box display="flex" justifyContent="center">
            <Button className={classes.reviewBtn} size="large">
              WRITE REVIEWS
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box width="100%" mt={3}>
        {' '}
      </Box>
      <Box m={2}></Box>
    </>
  );
}

export default Reviews;
