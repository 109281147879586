import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Container} from '@material-ui/core';

import Card from './Card';

import callout from '~/assets/images/page/healthProtocols/calloutimage.png';
import healthPsyBg from '~/assets/images/page/healthProtocols/healthPsy.png';

const styles = (theme) => ({
  root: {
    color: 'white',
    padding: '2% 0',
    font: 'Lato',
    [theme.breakpoints.down('sm')]: {
      background: 'rgba(27, 27, 27, 0.64) !important',
      padding: '0',
    },
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  headerTitle: {
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'white',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'white',
    paddingBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  cardTitle: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    color: 'white',
    whiteSpace: 'break-spaces',
    transform: 'translate(-50%, 0)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      top: '20%',
    },
  },
  leftText: {
    paddingRight: '20px',
    fontWeight: '300',
    margin: '4px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '1.2',
      padding: '0px',
    },
  },
  wrapper: {
    padding: '5%',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      marginTop: '10px',
    },
  },
  cardButton: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'rgba(255,255,255,0.2)',
    border: 'solid 1px white',
    fontSize: '13px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const HealthPsychology = (props) => {
  const {classes, pageData} = props;

  return (
    <div
      className={classes.root}
      style={{
        background: `url(${healthPsyBg}) no-repeat`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
      }}
    >
      <Container>
        <Grid container className={classes.header} alignItems="center">
          <Grid item sm={6} xs={12}>
            <Typography variant="h5" className={classes.headerTitle}>
              Health Psychology
            </Typography>
            <Typography variant="h5" className={classes.subTitle}>
              A Paradigm Shift to Greater Health Span
            </Typography>
            <div className={classes.leftText}>
              <Typography>
                <strong>Author:</strong>
                <i>Kim Larking</i>
              </Typography>
              <br />
              <Typography className={classes.leftText}>
                <strong>Description:</strong> Awaken Mind X Body by understanding and successfully governing your Self
                Protecting and Healing Mind-Body System - shifting the health paradigm from institutional reliance to
                self-care, through simple ‘Life Logical Health Care Principles & Systems’, which every person can
                comfortably take charge of.
                <br />
                <br />
                Learn the processors of the nervous, endocrine and immune systems and discover how your psychology
                determines your physiology. Gain the Tools and Techniques to create a mind that serves you, champion
                mental health issues, evolve emotional vibrancy and joy, and unlock faster Natural Remedies and trauma
                processing through amplifying the power of the body through the advanced application of the mind.
                <br />
                <br />
                Use this Principle Guide as a solid base to start proactively generating holistic health, and to lead
                into and interlock the following five Guides in the Vitality and Longevity Series.
              </Typography>
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            <div className={classes.wrapper}>
              <Card ratio="50%" imageSrc={callout}>
                <Typography variant="h6" className={classes.cardTitle} align="center">
                  UNDERSTAND THE WORKINGS OF THE MIND
                </Typography>
                <ColorButton className={classes.cardButton}>VIEW MORE</ColorButton>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(HealthPsychology);
