import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Typography} from '@material-ui/core';

import {Link} from 'react-router-dom';

import Image1_1 from '~/assets/images/page/services/shopbyproduct/1-1.png';
import Image1_2 from '~/assets/images/page/services/shopbyproduct/1-2.png';
import Image1_3 from '~/assets/images/page/services/shopbyproduct/1-3.png';
import Image1_4 from '~/assets/images/page/services/shopbyproduct/1-4.png';

import Image2_1 from '~/assets/images/page/services/shopbyproduct/2-1.png';
import Image2_2 from '~/assets/images/page/services/shopbyproduct/2-2.png';
import Image2_3 from '~/assets/images/page/services/shopbyproduct/2-3.png';
import Image2_4 from '~/assets/images/page/services/shopbyproduct/2-4.png';
import Image2_5 from '~/assets/images/page/services/shopbyproduct/2-5.png';

import Image3_1 from  '~/assets/images/page/healthProtocols/calloutimage.png';
import Image3_2 from '~/assets/images/page/services/shopbyproduct/3-2.png';
import Image3_3 from '~/assets/images/page/services/shopbyproduct/3-3.png';
import Image3_4 from '~/assets/images/page/services/shopbyproduct/3-4.png';
import Image3_5 from '~/assets/images/page/services/shopbyproduct/3-5.png';
import Image3_6 from '~/assets/images/page/services/shopbyproduct/3-6.png';

import Image4_1 from '~/assets/images/page/services/shopbyproduct/4-1.png';
import Image4_2 from '~/assets/images/page/services/shopbyproduct/4-2.png';
import Image4_3 from '~/assets/images/page/home/inbody.png';

import Image5_1 from '~/assets/images/page/services/shopbyproduct/5-1.png';
import Image5_2 from '~/assets/images/page/services/shopbyproduct/5-2.png';
import Image5_3 from '~/assets/images/page/services/shopbyproduct/5-3.png';

import Image6_1 from '~/assets/images/page/services/shopbyproduct/6-1.png';
import Image6_2 from '~/assets/images/page/services/shopbyproduct/6-2.png';
import {getPath} from '~/static/routes';


const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9AA200',
    fontSize: '13px',
    padding: '5 30px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const sectionStyles = {
  sectionContainer: {
    backgroundColor: 'white',
  },

  row3ItemCover: {
    width: '100%',
    height: '100%',
    background: 'rgba(30,13,13,0.3)',
    textAlign: 'center',
    position: 'relative',
  },
  row3ItemCoverDesc: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '5%',
    right: '5%',
    fontWeight: 'bold',
    lineHeight: '1.3',
  },
  row3ItemCoverTitle: {
    position: 'absolute',
    bottom: '2%',
    left: '5%',
    right: '5%',
  },
  row3BottomDesc: {
    color: '#8C8C8C',
    marginTop: '10px',
    textAlign: 'center',
    padding: '0 20px',
  },
  highlight: {
    color: '#9AA300',
  },
  row4ItemCover: {
    width: '100%',
    height: '100%',
    background: 'rgba(30,13,13,0.13)',
  },
  row6ItemCover: {
    width: '100%',
    height: '100%',
    background: 'rgba(30,13,13,0.3)',
    textAlign: 'center',
    position: 'relative',
  },
  row6ItemCoverDesc: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '20%',
    right: '20%',
    lineHeight: '1.3',
  },
  row6ItemCoverBtn: {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

const SectionDataList = [
  {
    id: 1,
    title: 'LIFE FORCE FOOD',
    desc: () => {
      return (
        <span>
          The Sanctuary Store offers exceptionally flavoursome dishes comprised of the most nutrient dense, mostly
          organic, ingredients sourced from all over our planet.{' '}
          <Link style={sectionStyles.highlight} to={getPath('Life Ingredients')}>
            See our ingredients.
          </Link>
        </span>
      );
    },
    imageRatio: '100%',
    imageGap: 1,
    imageRowNum: [4, 2],
    imageCover: null,
    imageList: [
      {
        desc: 'Food',
        image: Image1_1,
        path: '/shop-by-product-and-services/life-force-food?tag1=food',
      },
      {
        desc: 'Drinks',
        image: Image1_2,
        path: '/shop-by-product-and-services/life-force-food?tag1=drinks',
      },
      {
        desc: 'Condiments',
        image: Image1_3,
        path: '/shop-by-product-and-services/life-force-food?tag1=condiments',
      },
      {
        desc: 'Desserts',
        image: Image1_4,
        path: '/shop-by-product-and-services/life-force-food?tag1=desserts',
      },
    ],
    bottomDec: () => {},
  },
  {
    id: 2,
    desc: () =>
      'Take back the power to achieve your greatest health span, experience the best natural products to enrich your personal beauty, vitality and longevity, while nurturing our planet.',
    title: 'VISION PRODUCTS',
    reverse: true,
    imageRatio: '100%',
    imageGap: 1,
    imageRowNum: [5, 2],
    imageCover: null,
    imageList: [
      {
        desc: 'Superfood Skin Care',
        image: Image2_1,
        path: 'https://visionproductsdirect.com/collections/natural-skin-care',
      },
      {
        desc: 'Eco Hair and Body Care',
        image: Image2_2,
        path: 'https://visionproductsdirect.com/collections/hair-care',
      },
      {
        desc: 'Coconut Oil',
        image: Image2_3,
        path: 'https://visionproductsdirect.com/collections/nutrition',
      },
      {
        desc: 'Super-soft Eco Apparel',
        image: Image2_4,
        path: 'https://visionproductsdirect.com/collections/apparel',
      },
      {
        desc: 'Natures Greeting Cards',
        image: Image2_5,
        path: 'https://visionproductsdirect.com/collections/greeting-cards',
      },
    ],
    bottomDec: () => {},
  },
  {
    id: 3,
    desc: () =>
      'Take control of your well-being by investing in these concise health guides, developed by the Sanctuary, to help provide you vital context to our health systems and protocols. Compliment your well-being knowledge through the unique perspectives contained in this Vitality and Longevity series. Vital Guides to greater Health Span.',
    title: 'VITALITY AND LONGEVITY HEALTH GUIDES',
    imageRatio: '141%',
    imageGap: 1,
    imageRowNum: [6, 3, 2],
    imageCover: (imageItem) => {
      return (
        <div style={sectionStyles.row3ItemCover}>
          <Typography variant="subtitle1" style={sectionStyles.row3ItemCoverDesc}>
            {imageItem.coverDesc}
          </Typography>
          <Typography variant="body1" style={sectionStyles.row3ItemCoverTitle}>
            {imageItem.coverTitle}
          </Typography>
        </div>
      );
    },
    imageList: [
      {
        image: Image3_1,
        coverTitle: 'Health Psychology',
        coverDesc: 'Principle Health Guide and Book available now',
        path: 'https://vfhurbansanctuary.com/guide-landing-page',
      },
      {
        image: Image3_2,
        coverTitle: 'Life Force Food',
        coverDesc: 'Advance Order',
        path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=lifeforcefood',
      },
      {
        image: Image3_3,
        coverTitle: 'Longevity Activities',
        coverDesc: 'Advance Order',
        path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=longevity-activities',
      },
      {
        image: Image3_4,
        coverTitle: 'Physiological Efficiencies',
        coverDesc: 'Advance Order',
        path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=physiological-efficiencies',
      },
      {
        image: Image3_5,
        coverTitle: 'Natural Remedies',
        coverDesc: 'Advance Order',
        path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=natural-remedies',
      },
      {
        image: Image3_6,
        coverTitle: 'Purity',
        coverDesc: 'Advance Order',
        path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=purity',
      },
    ],
    bottomDec: () => {
      return (
        <div style={sectionStyles.row3BottomDesc}>
          <Typography variant="body1">
            <span style={sectionStyles.highlight}>Vitality and Longevity Series</span> - Living in Vibrant Health to 120
            Years of Age (a series of six journals)
          </Typography>
        </div>
      );
    },
  },
  {
    desc: () =>
      'Use our non-invasive tech to evaluate your current health state, create a robust health regime and then to monitor and prevent long term health issues. Achieve greater vitality and gain important insights to enjoying a longer, healthier life.',
    title: 'TECH TESTING',
    reverse: true,
    imageRatio: '63%',
    imageGap: 1,
    imageRowNum: [2, 1],
    imageCover: () => {
      return <div style={sectionStyles.row4ItemCover}></div>;
    },
    imageList: [
      {
        image: Image4_1,
        desc: 'Hair Testing',
        path: 'https://vfhurbansanctuary.com/tech-hair-testing',
      },
      {
        image: Image4_3,
        desc: 'Inbody Scanning',
        path: 'https://vfhurbansanctuary.com/store-service-inbody-scanning',
      },
    ],
  },
  {
    desc: () =>
      'Give gifts that count, health and well-being supplies, to help your friends and family achieve a greater more vibrant life.',
    title: 'GIFT PACKS',
    imageRatio: '63%',
    imageGap: 1,
    imageRowNum: [3, 2, 1],
    imageList: [
      {
        image: Image5_1,
        desc: 'Vision Products personal care gift packs',
        path: 'https://visionproductsdirect.com/collections/gift-packs',
      },
      {
        image: Image5_2,
        desc: 'Sanctuary Store Specialty food gift packs',
        path: '/shop-by-product-and-services/gift-packs',
      },
      {
        image: Image5_3,
        desc: 'Gift Vouchers',
        path: '/shop-by-product-and-services/gift-packs',
      },
    ],
  },
  {
    imageRatio: '33%',
    imageGap: 1,
    imageRowNum: [2, 1, 1],
    imageList: [
      {
        image: Image6_1,
        path: 'https://vfhurbansanctuary.com/discover#healthcreator',
        btnText: 'HEALTH CREATOR APP',
        coverDesc:
          'Bring together everything the Sanctuary has to offer; food science, tech testing, pure products and health strategies into a customisable App to excel your health',
      },
      {
        image: Image6_2,
        path: '/getStarted',
        btnText: 'CREATE AN ACCOUNT',
        coverDesc: 'Create your account to stream line purchases and integrate our network at will.',
      },
    ],
    imageCover: (imageItem) => {
      return (
        <div style={sectionStyles.row6ItemCover}>
          <Typography variant="subtitle1" style={sectionStyles.row6ItemCoverDesc}>
            {imageItem.coverDesc}
          </Typography>
          <ColorButton style={sectionStyles.row6ItemCoverBtn}>{imageItem.btnText}</ColorButton>
        </div>
      );
    },
  },
];

export default SectionDataList;
