import React from 'react';
import {withStyles, Grid, Container, Typography, Box} from '@material-ui/core';
import PackCard from './PackCard';
import LifeForceFoodBg from '~/assets/images/page/giftpacks/lffpkg.png';
import HealthBg from '~/assets/images/page/giftpacks/healthpkg.png';
import AllDayLifeForceFoodBg from '~/assets/images/page/giftpacks/alldaypkg.png';
import AppsBg from '~/assets/images/page/giftpacks/appspkg.png';
import GuidesBg from '~/assets/images/page/giftpacks/guidesPkg.png';
import InspectBg from '~/assets/images/page/giftpacks/inspectPkg.png';

const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '3% 12%',
    background: 'rgba(27,27,27,0.64)',

    [theme.breakpoints.down('sm')]: {
      padding: '2% 4%',
    },
  },
  prdCard: {
    width: '400px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    margin: '4% 2%',
  },

  desc: {
    fontFamily: 'Lato',
    color: 'rgba(255,255,255,0.8)',
    margin: '4% 2%',
  },

  heading: {
    margin: '20px',
    textAlign: 'center',
    fontWeight: '700',
  },
});

const packageDataList = [
  {
    id: 1,
    title: 'Life Force Food Packages',
    description: 'Food + Drinks + Dessert',
    backgroundImg: LifeForceFoodBg,
    gridMdSmXs: [4, 4, 12],
  },
  {
    id: 2,
    title: 'Health Products Packages',
    description: 'Immune + Brain + Bone and Cartilage + Heart + Cellular Health ',
    backgroundImg: HealthBg,
    gridMdSmXs: [8, 8, 12],
  },
  {
    id: 3,
    title: 'All Day Life Force Food ',
    description: 'Breakfast + Lunch + Dinner',
    backgroundImg: AllDayLifeForceFoodBg,
    gridMdSmXs: [6, 6, 12],
  },
  {
    id: 4,
    title: 'All App premium packages',
    description: 'Health Creator + Weight Shifter + Treasure box + Communication Coach + Prosperity Pal',
    backgroundImg: AppsBg,
    gridMdSmXs: [6, 6, 12],
  },
  {
    id: 5,
    title: 'Six Personal Health Guides Packages',
    description:
      'Health Psychology + Life Force Food + Longevity Activities + Physiological Efficiencies + Natural Remedies + Purity ',
    backgroundImg: GuidesBg,
    gridMdSmXs: [8, 8, 12],
  },
  {
    id: 6,
    title: 'Health Inspect Packages',
    description: '3D scanning + Hair testing',
    backgroundImg: InspectBg,
    gridMdSmXs: [4, 4, 12],
  },
];

function OurGiftPacks(props) {
  const {classes} = props;
  return (
    <>
      <Container maxWidth="lg">
        <Box className={classes.heading}>
          <Typography variant="h4" color="textSecondary">
            Our Gift Packs
          </Typography>
        </Box>

        <Grid container spacing={1}>
          {packageDataList &&
            packageDataList.map((item, index) => (
              <Grid item md={item.gridMdSmXs[0]} sm={item.gridMdSmXs[1]} xs={item.gridMdSmXs[2]} key={item.id}>
                {' '}
                <PackCard cardBgImage={item.backgroundImg} title={item.title} description={item.description} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}

export default withStyles(styles)(OurGiftPacks);
