import React, {useRef, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageHeader from '~/components/ui/PageHeader';
import SelectYourPlan from '~/components/ui/SelectYourPlan';
import GlobalMetawheel from '~/components/ui/GlobalMetawheel';
import HealthyLongTerm from './Sections/HealthyLongTerm';
import BannerSection from './Sections/BannerSection';
import MicroApps from './Sections/MicroApps';
import NutritionExactCalorieCount from './Sections/NutritionExactCalorieCount';
import PartnerApps from './Sections/PartnerApps';
import HowToShiftWeight from './Sections/HowToShiftWeight';
import LongevityExercisePrograms from './Sections/LongevityExercisePrograms';
import Carousel from '~/components/ui/Carousel';
import headerBackground from '~/assets/images/page/weight-shifter/weightshifter_banner.png';
import SetBackgroundComponent from '~/components/ui/SetBackgroundComponent';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
});

function WeightShifter(props) {
  const {classes, location, history} = props;
  const parentRef = useRef(null);
  const scrollToRef = (y) => window.scroll({top: y, left: 0, behavior: 'smooth'});
  const handleClick = (index) => {
    if (!isNaN(index)) {
      scrollToRef(parentRef.current.children[index].offsetTop);
    }
  };

  useEffect(() => {
    if (location.state && location.state.index) {
      scrollToRef(parentRef.current.children[location.state.index].offsetTop);
    } else {
      scrollToRef(0);
    }
  }, [location.state, history]);

  return (
    <div className={classes.root}>
      {/* <SetBackgroundComponent headerBackground={headerBackground} /> */}
      <PageHeader text="Weight Shifter" subtitle={'Healthy physique Management'} />
      <BannerSection handleClick={handleClick} />
      <MicroApps />
      <div ref={parentRef}>
        <HealthyLongTerm />
        <NutritionExactCalorieCount />
        <PartnerApps />
        <div>
          <LongevityExercisePrograms />
          <Carousel />
          <SelectYourPlan />
        </div>
        <HowToShiftWeight />
      </div>
      <GlobalMetawheel />
    </div>
  );
}

export default withStyles(styles)(WeightShifter);
