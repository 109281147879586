import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
    },
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: '500',
    margin: '20% 0px 15px',
    letterSpacing: 'normal',
    color: 'rgba(240, 240, 238, 0.8)',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '10px auto',
      margin: '0px auto 5px',
      fontSize: '1.5rem',
      lineHeight: '1.2',
    },
  },
  subTitle: {
    fontFamily: 'Lato',
    fontSize: '1.5rem',
    color: 'rgba(240, 240, 238, 0.8)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  bottomText: {
    fontSize: '16px',
    color: 'rgba(240, 240, 238, 0.6)',
    padding: '10px 0 5px',
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
      fontSize: '14px',
    },
    margin: '15px 0px 10%',
    whiteSpace: 'pre-wrap',
  },
});

function HeaderRight(props) {
  const {classes, pageData} = props;

  return (
    <Grid className={classes.root} container direction="column" justify="space-between">
      <Grid item>
        <div>
          <Grid item md={12}>
            <Typography variant="h5" className={classes.title}>
              {pageData.mainTitle}
            </Typography>
            <Typography variant="h3" className={classes.subTitle}>
              {pageData.subTitle}
            </Typography>
            <Typography variant="body1" className={classes.bottomText}>
              {pageData.contents}
            </Typography>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(HeaderRight);
