import Participate1 from '~/assets/images/page/participate/participate_1.jpg';
import Participate2 from '~/assets/images/page/participate/participate_2.jpg';
import Participate3 from '~/assets/images/page/participate/participate_3.jpg';

export default [
  {
    id: 1,
    category: 'Regional Initiatives at the Sanctuaries ',
    image: Participate1,
    tag: ['EDUCATION'],
    name: 'Scholarship and apprenticeship Program',
    description:
      'Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean luctus, lorem in hendrerit interdum, leo orci egestas diam, ac euismod massa est et turpis. Etiam auctor lectus vel neque convallis pharetra. Ut turpis eros, aliquet non ante id, interdum placerat erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras sagittis sagittis sagittis. In hac habitasse platea dictumst. Phasellus diam erat, porttitor sed ligula at, ultricies auctor tellus.',
    date: '2020-09-06',
    day: 'Thursday',
    time: '4pm',
    location: '322 Dominion Rd, Mt roskill 1010,  AUCKLAND',
    participate: '16',
  },
  {
    id: 2,
    category: 'Regional Initiatives at the Sanctuaries ',
    image: Participate2,
    tag: ['HEALTH', 'ECONOMY', 'F&C'],
    name: 'Organic Sustainable Food System',
    description:
      'Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean luctus, lorem in hendrerit interdum, leo orci egestas diam, ac euismod massa est et turpis. Etiam auctor lectus vel neque convallis pharetra. Ut turpis eros, aliquet non ante id, interdum placerat erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras sagittis sagittis sagittis. In hac habitasse platea dictumst. Phasellus diam erat, porttitor sed ligula at, ultricies auctor tellus. ',
    date: '2020-09-06',
    day: 'Saturday',
    time: '4pm',
    location: '322 Dominion Rd, Mt roskill 1010,  AUCKLAND',
    participate: '16',
  },
  {
    id: 3,
    category: 'Regional Initiatives at the Sanctuaries ',
    image: Participate3,
    tag: ['ECONOMY'],
    name: 'Scholarship and apprenticeship Program',
    description:
      'Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit amet arcu semper, vel eleifend tortor egestas. Aenean luctus, lorem in hendrerit interdum, leo orci egestas diam, ac euismod massa est et turpis. Etiam auctor lectus vel neque convallis pharetra. Ut turpis eros, aliquet non ante id, interdum placerat erat. Curabitur sit amet eros vel orci venenatis hendrerit. Cras sagittis sagittis sagittis. In hac habitasse platea dictumst. Phasellus diam erat, porttitor sed ligula at, ultricies auctor tellus. ',
    date: '2020-09-06',
    day: 'Monday',
    time: '4pm',
    location: '322 Dominion Rd, Mt roskill 1010,  AUCKLAND',
    participate: '16',
  },
];
