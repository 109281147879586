import React from 'react';
import ourmissionImg from '~/assets/images/page/prosper/ourmission.png';
import {withStyles} from '@material-ui/core/styles';
import CommonSection from './CommonSection';
import OuterContainer from '../../../components/ui/OuterContainer';
const styles = (theme) => ({});

function OurMission(props) {
  return (
    <OuterContainer>
      <CommonSection
        imageWidth={40}
        title="OUR MISSION"
        imageSrc={ourmissionImg}
        info={
          <>
            <p>
              Sanctuary Store is a Vision for Humanity initiative, our mission is to aid the charity in helping Elevate
              Global Conscious. In context to the Sanctuary Stores this means helping improve both human and planetary
              health.
            </p>
            <p>
              We wish to help the millions of people globally who suffer unnecessarily from chronic disease, much of
              which could be avoided by simply Enriching, Empowering and Inspiring with quality products and services -
              that are delivered via Biospherically Correct systems to lift the strain we place on the carry capacity of
              our planet.
            </p>
            <p>
              Help us achieve our mission by embarking upon a worthy career as a Sanctuary Store franchise operator.
              Achieve a sense of purpose and worthwhile work, while achieving financial prosperity in a rapid growth
              industry - which Sanctuary Store is at the cutting edge."
            </p>
          </>
        }
        imageText="TO BE A CATALYST
        TO ELEVATE GLOBAL CONSCIOUSNESS."
      />
    </OuterContainer>
  );
}

export default withStyles(styles)(OurMission);
