import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import SocialIcons from '~/components/ui/SocialIcons';
import Person from '~/assets/images/page/home/businessman.png';
import {Container, Grid, IconButton, Typography} from '@material-ui/core';
import OuterContainer from '~/components/ui/OuterContainer';
import clsx from 'clsx';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = (theme) => ({
  padding: {
    padding: '64px 0',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    color: '#434343',
    fontFamily: 'montserrat',
    justifyContent: 'space-between',
    padding: '0 16px',
    [theme.breakpoints.down('898')]: {
      flexDirection: 'column',
    },
  },
  class2: {
    textAlign: 'center',
    '&>img': {
      maxWidth: '85%',
      maxHeight: '100%',
    },
  },
  class3: {
    flex: 0.9,
    flexDirection: 'column',
    '&>h2': {
      color: '#9aa200',
      fontSize: '1.2rem',
    },
    '&>h3': {
      color: '#9aa200',
      fontSize: '1rem',
    },
  },
  class4: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  class5: {
    fontSize: '1rem',
    textAlign: 'justify',
  },

  class6: {
    fontWeight: 600,
    fontStyle: 'italic',
    fontSize: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  class7: {},
  socialIcons: {
    width: '1rem',
    height: '1rem',
    color: '#9aa200',
    marginLeft: '1rem',
    float: 'right',
  },
});

function PersonSection(props) {
  const {classes} = props;
  return (
    null
    // <OuterContainer className={classes.padding}>
    //   <div className={clsx(classes.class0, classes.class1)}>
    //     <div className={classes.class2}>
    //       <img src={Person} />
    //     </div>
    //     <div className={clsx(classes.class0, classes.class3)}>
    //       <h2>KIM LARKING</h2>
    //       <h3>Founder of Vision for Humanity and the Sanctuary Project</h3>
    //       <p className={classes.class4}>Greater people for better planet</p>
    //       <p className={classes.class5}>
    //         The VFH Sanctuaries work together as a Biospherically Correct eco system, providing you a stream of organic
    //         foods, ethical products and personal development services, all based around bringing out the magnificence in
    //         you. From our Charter we promote that ‘we are all creators, with a life purpose to be our fullest capacity
    //         and most magnificent expression’. The Sanctuaries also interlock your advancement into planetary wellbeing.
    //         Through their greater purpose and ethos, the Sanctuaries show the path of greater people for better planet.
    //         See our Global Metawheel.
    //         <br />
    //         Consciously shop online or at our Sanctuary Stores, personally advance and grow through our Urban Sanctuary
    //         services, enjoy the bliss and serenity of our Master Sanctuary, engage with other conscious creatures on our
    //         Higher Consciousness Network and ultimately prosper through ‘right livihood’ with our Plato Franchise System
    //         opportunity.
    //       </p>
    //       <div className={clsx(classes.class0, classes.class6)}>
    //         <p>Discover your Magnificence at the Sanctuary</p>
    //         <div className={clsx(classes.class0, classes.class7)}>
    //           {/* <FacebookIcon className={classes.socialIcons} />
    //           <TwitterIcon className={classes.socialIcons} /> */}
    //           <IconButton onClick={()=>{
    //             window.open('https://www.instagram.com/vfhsanctuaries/','_blank')
    //           }}>
    //           <InstagramIcon className={classes.socialIcons} />
    //           </IconButton>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </OuterContainer>
    // <Container maxWidth="lg">
    //   <Grid container spacing={2}>
    //     <Grid item xs={6}></Grid>
    //     <Grid item xs={6}>
    //       <Typography variant={'h2'} style={{fontSize:35,color:"#585858",marginBottom:12}}>Our Story</Typography>
    //       <Typography style={{fontSize:20,color:"#8c8c8c",marginBottom:12,fontWeight:'bold'}}>Vision for Humanity and the Sanctuary Project</Typography>
    //       <Typography>Greater people for better planet</Typography>
    //       <Typography>
    //         The VFH Sanctuaries work together as a Biospherically Correct eco system, providing you a stream of organic
    //         foods, ethical products and personal development services, all based around bringing out the magnificence in
    //         you. From our Charter we promote that ‘we are all creators, with a life purpose to be our fullest capacity
    //         and most magnificent expression’. The Sanctuaries also interlock your advancement into planetary wellbeing.
    //         Through their greater purpose and ethos, the Sanctuaries show the path of greater people for better planet.
    //         See our Global Metawheel.
    //         <br />
    //         <br />
    //         Consciously shop online or at our Sanctuary Stores, personally advance and grow through our Urban Sanctuary
    //         services, enjoy the bliss and serenity of our Master Sanctuary, engage with other conscious creatures on our
    //         Higher Consciousness Network and ultimately prosper through ‘right livihood’ with our Plato Franchise System
    //         opportunity.
    //       </Typography>
    //     </Grid>
    //   </Grid>
    //   <Grid container className={classes.root}>
    //     <Grid item className={classes.imageContainer} xs={12} sm={12} md={6} lg={6}>
    //       <img className={classes.image} src={Person} alt={'kim'} />
    //     </Grid>
    //     <Grid className={classes.column} item xs={12} sm={12} md={6} lg={6}>
    //       <Typography variant="h4" className={classes.title}>
    //         KIM LARKING
    //       </Typography>
    //       <Typography variant="h4" className={classes.title + ' ' + classes.title2}>
    //         Founder of Vision for Humanity and the Sanctuary Project
    //       </Typography>
    //       <Typography variant="h4" className={classes.title + ' ' + classes.title2} style={{color: '#434343'}}>
    //         Greater people for better planet
    //       </Typography>
    //       <Typography variant="body2" className={classes.info}>
    //         The VFH Sanctuaries work together as a Biospherically Correct eco system, providing you a stream of organic
    //         foods, ethical products and personal development services, all based around bringing out the magnificence in
    //         you. From our Charter we promote that ‘we are all creators, with a life purpose to be our fullest capacity
    //         and most magnificent expression’. The Sanctuaries also interlock your advancement into planetary wellbeing.
    //         Through their greater purpose and ethos, the Sanctuaries show the path of greater people for better planet.
    //         See our Global Metawheel.
    //       </Typography>
    //       <Typography variant="body2" className={classes.info}>
    //         Consciously shop online or at our Sanctuary Stores, personally advance and grow through our Urban Sanctuary
    //         services, enjoy the bliss and serenity of our Master Sanctuary, engage with other conscious creatures on our
    //         Higher Consciousness Network and ultimately prosper through ‘right livihood’ with our Plato Franchise System
    //         opportunity.
    //       </Typography>
    //       <Grid container className={classes.discoverContainer}>
    //         <Typography
    //           component={'div'}
    //           className={classes.title + ' ' + classes.title2}
    //           style={{color: '#595959', maxWidth: '70%'}}
    //         >
    //           Discover your Magnificence at the Sanctuary
    //         </Typography>
    //         <SocialIcons />
    //       </Grid>
    //     </Grid>
    //   </Grid> 
    // </Container>
  );
}

export default withStyles(styles)(PersonSection);
