import mainImg from '~/assets/images/page/lifeforcefood/detail/blackricesushi.png';
import jarImg from '~/assets/images/page/lifeforcefood/detail/jar.png';
import labelImg from '~/assets/images/page/lifeforcefood/detail/label.png';
import lidImg from '~/assets/images/page/lifeforcefood/detail/lid.png';
import foodVideo from '~/assets/video/foodvideo.mp4';

import curryImg from '~/assets/images/page/lifeforcefood/detail/curry.png';
import noodlesImg from '~/assets/images/page/lifeforcefood/detail/noodles.png';
import brunchImg from '~/assets/images/page/lifeforcefood/detail/brunch.png';
import casseroleImg from '~/assets/images/page/lifeforcefood/detail/casserole.png';
import saladImg from '~/assets/images/page/lifeforcefood/detail/salad.png';
import riceImg from '~/assets/images/page/lifeforcefood/detail/rice.png';

import sushiImg1 from '~/assets/images/page/lifeforcefood/detail/sushi1.png';
import sushiImg2 from '~/assets/images/page/lifeforcefood/detail/sushi2.png';
import sushiImg3 from '~/assets/images/page/lifeforcefood/detail/sushi3.png';
import sushiImg4 from '~/assets/images/page/lifeforcefood/detail/sushi4.png';
import sushiImg5 from '~/assets/images/page/lifeforcefood/detail/sushi5.png';
import sushiImg6 from '~/assets/images/page/lifeforcefood/detail/sushi6.png';
import sushiImg7 from '~/assets/images/page/lifeforcefood/detail/sushi7.png';

export const products = [
  {
    id: 1,
    name: 'Black Rice Sushi',
    description:
      'A naturally chewy texture with beautiful favours of Soy sauce, Carrots and Cabbage, very filling and delicious. ',
    starReview:
      'I love this black rice sushi, you can tell it’s healthy but its tastes amazing!- love the dressing they use to make it extra delicious ',
    price: 13.99,
    video: foodVideo,
    videoDescription:
      'Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. ',
    image: [
      {id: 1, name: 'food', price: 8.99, imgsrc: mainImg},
      {id: 2, name: 'lid', price: 1.5, imgsrc: lidImg},
      {id: 3, name: 'jar', price: 2, imgsrc: jarImg},
      {id: 4, name: 'label', price: 0.5, imgsrc: labelImg},
    ],
    ingredients: [
      'Organic Tempeh',
      'Carrot',
      'Purple Cabbage',
      'Toasted nori',
      'Black rice',
      'Avacado',
      'English Cucumber',
      'Cashews',
      'Water',
    ],
    nurtritionalSum: {
      servePerPkg: '1.00',
      servingSize: '120.00g',
      nutrientDensity: 'Excellent',
      lifeForce: '3 days',
      h320: '84% 25gm/mls',
      raw: '60%',
    },
    nurtritionalAnalysis: {
      micronutrients: [
        {id: 1, name: 'vitamins', data: 0.15},
        {id: 2, name: 'marcominerals', data: 0.65},
        {id: 3, name: 'mircominerals', data: 0.12},
        {id: 4, name: 'amino acids', data: 0.23},
      ],
      microDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, ',
      caloriecomposition: [
        {id: 1, name: 'carbohydrates', data: 0.15},
        {id: 2, name: 'proteins', data: 0.55},
        {id: 3, name: 'fats', data: 0.08},
        {id: 4, name: 'sugar', data: 0.22},
      ],
      calDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis.',
      specialisedFunc: [
        {id: 1, name: 'Glycaemic Index (GI)', data: 48},
        {id: 2, name: 'Arginine', data: 0.62},
        {id: 3, name: 'Lysine', data: 0.31},
        {id: 4, name: 'HDL', data: 72},
        {id: 5, name: 'PH', data: 6.8},
      ],
      deliverySpecial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna.',
    },
  },
  {
    id: 2,
    name: 'Black Rice Sushi',
    description:
      'A naturally chewy texture with beautiful favours of Soy sauce, Carrots and Cabbage, very filling and delicious. ',
    starReview:
      'I love this black rice sushi, you can tell it’s healthy but its tastes amazing!- love the dressing they use to make it extra delicious ',
    price: 13.99,
    video: foodVideo,
    videoDescription:
      'Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. ',
    image: [
      {id: 1, name: 'food', price: 8.99, imgsrc: mainImg},
      {id: 2, name: 'lid', price: 1.5, imgsrc: lidImg},
      {id: 3, name: 'jar', price: 2, imgsrc: jarImg},
      {id: 4, name: 'label', price: 0.5, imgsrc: labelImg},
    ],
    ingredients: [
      'Organic Tempeh',
      'Carrot',
      'Purple Cabbage',
      'Toasted nori',
      'Black rice',
      'Avacado',
      'English Cucumber',
      'Cashews',
      'Water',
    ],
    nurtritionalSum: {
      servePerPkg: '1.00',
      servingSize: '120.00g',
      nutrientDensity: 'Excellent',
      lifeForce: '3 days',
      h320: '84% 25gm/mls',
      raw: '60%',
    },
    nurtritionalAnalysis: {
      micronutrients: [
        {id: 1, name: 'vitamins', data: 0.15},
        {id: 2, name: 'marcominerals', data: 0.65},
        {id: 3, name: 'mircominerals', data: 0.12},
        {id: 4, name: 'amino acids', data: 0.23},
      ],
      microDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, ',
      caloriecomposition: [
        {id: 1, name: 'carbohydrates', data: 0.15},
        {id: 2, name: 'proteins', data: 0.55},
        {id: 3, name: 'fats', data: 0.08},
        {id: 4, name: 'sugar', data: 0.22},
      ],
      calDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis.',
      specialisedFunc: [
        {id: 1, name: 'Glycaemic Index (GI)', data: 48},
        {id: 2, name: 'Arginine', data: 0.62},
        {id: 3, name: 'Lysine', data: 0.31},
        {id: 4, name: 'HDL', data: 72},
        {id: 5, name: 'PH', data: 6.8},
      ],
      deliverySpecial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna.',
    },
  },
  {
    id: 3,
    name: 'Black Rice Sushi',
    description:
      'A naturally chewy texture with beautiful favours of Soy sauce, Carrots and Cabbage, very filling and delicious. ',
    starReview:
      'I love this black rice sushi, you can tell it’s healthy but its tastes amazing!- love the dressing they use to make it extra delicious ',
    price: 13.99,
    video: foodVideo,
    videoDescription:
      'Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. ',
    image: [
      {id: 1, name: 'food', price: 8.99, imgsrc: mainImg},
      {id: 2, name: 'lid', price: 1.5, imgsrc: lidImg},
      {id: 3, name: 'jar', price: 2, imgsrc: jarImg},
      {id: 4, name: 'label', price: 0.5, imgsrc: labelImg},
    ],
    ingredients: [
      'Organic Tempeh',
      'Carrot',
      'Purple Cabbage',
      'Toasted nori',
      'Black rice',
      'Avacado',
      'English Cucumber',
      'Cashews',
      'Water',
    ],
    nurtritionalSum: {
      servePerPkg: '1.00',
      servingSize: '120.00g',
      nutrientDensity: 'Excellent',
      lifeForce: '3 days',
      h320: '84% 25gm/mls',
      raw: '60%',
    },
    nurtritionalAnalysis: {
      micronutrients: [
        {id: 1, name: 'vitamins', data: 0.15},
        {id: 2, name: 'marcominerals', data: 0.65},
        {id: 3, name: 'mircominerals', data: 0.12},
        {id: 4, name: 'amino acids', data: 0.23},
      ],
      microDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, ',
      caloriecomposition: [
        {id: 1, name: 'carbohydrates', data: 0.15},
        {id: 2, name: 'proteins', data: 0.55},
        {id: 3, name: 'fats', data: 0.08},
        {id: 4, name: 'sugar', data: 0.22},
      ],
      calDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis.',
      specialisedFunc: [
        {id: 1, name: 'Glycaemic Index (GI)', data: 48},
        {id: 2, name: 'Arginine', data: 0.62},
        {id: 3, name: 'Lysine', data: 0.31},
        {id: 4, name: 'HDL', data: 72},
        {id: 5, name: 'PH', data: 6.8},
      ],
      deliverySpecial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna.',
    },
  },
  {
    id: 4,
    name: 'Black Rice Sushi',
    description:
      'A naturally chewy texture with beautiful favours of Soy sauce, Carrots and Cabbage, very filling and delicious. ',
    starReview:
      'I love this black rice sushi, you can tell it’s healthy but its tastes amazing!- love the dressing they use to make it extra delicious ',
    price: 13.99,
    video: foodVideo,
    videoDescription:
      'Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. ',
    image: [
      {id: 1, name: 'food', price: 8.99, imgsrc: mainImg},
      {id: 2, name: 'lid', price: 1.5, imgsrc: lidImg},
      {id: 3, name: 'jar', price: 2, imgsrc: jarImg},
      {id: 4, name: 'label', price: 0.5, imgsrc: labelImg},
    ],
    ingredients: [
      'Organic Tempeh',
      'Carrot',
      'Purple Cabbage',
      'Toasted nori',
      'Black rice',
      'Avacado',
      'English Cucumber',
      'Cashews',
      'Water',
    ],
    nurtritionalSum: {
      servePerPkg: '1.00',
      servingSize: '120.00g',
      nutrientDensity: 'Excellent',
      lifeForce: '3 days',
      h320: '84% 25gm/mls',
      raw: '60%',
    },
    nurtritionalAnalysis: {
      micronutrients: [
        {id: 1, name: 'vitamins', data: 0.15},
        {id: 2, name: 'marcominerals', data: 0.65},
        {id: 3, name: 'mircominerals', data: 0.12},
        {id: 4, name: 'amino acids', data: 0.23},
      ],
      microDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, ',
      caloriecomposition: [
        {id: 1, name: 'carbohydrates', data: 0.15},
        {id: 2, name: 'proteins', data: 0.55},
        {id: 3, name: 'fats', data: 0.08},
        {id: 4, name: 'sugar', data: 0.22},
      ],
      calDes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcu, mattis quis.',
      specialisedFunc: [
        {id: 1, name: 'Glycaemic Index (GI)', data: 48},
        {id: 2, name: 'Arginine', data: 0.62},
        {id: 3, name: 'Lysine', data: 0.31},
        {id: 4, name: 'HDL', data: 72},
        {id: 5, name: 'PH', data: 6.8},
      ],
      deliverySpecial:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna, ullamcorper at mauris et, vehicula cursus quam. Donec ligula arcus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at turpis nec erat interdum pulvinar. Nunc efficitur neque arcu. Morbi arcu magna.',
    },
  },
];

export const myMeals = [
  {
    id: 1,
    name: 'Casserole',
    tag: 'Breakfast',
    imgSrc: casseroleImg,
  },
  {
    id: 2,
    name: 'Salad',
    tag: 'Breakfast',
    imgSrc: saladImg,
  },
  {
    id: 3,
    name: 'Rice',
    tag: 'Lunch',
    imgSrc: riceImg,
  },
  {
    id: 4,
    name: 'Curry',
    tag: 'Lunch',
    imgSrc: curryImg,
  },
  {
    id: 5,
    name: 'Casserole',
    tag: 'Breakfast',
    imgSrc: casseroleImg,
  },
  {
    id: 6,
    name: 'Noodles',
    tag: 'Dinner',
    imgSrc: noodlesImg,
  },
  {
    id: 7,
    name: 'Brunch',
    tag: 'Breakfast',
    imgSrc: brunchImg,
  },
  {
    id: 8,
    name: 'Casserole',
    tag: 'Breakfast',
    imgSrc: casseroleImg,
  },
  {
    id: 9,
    name: 'Noodles',
    tag: 'Dinner',
    imgSrc: noodlesImg,
  },
  {
    id: 10,
    name: 'Curry',
    tag: 'Lunch',
    imgSrc: curryImg,
  },
];

export const otherSushis = [
  {
    id: 1,
    name: 'Sushi',
    imgSrc: sushiImg1,
  },
  {
    id: 2,
    name: 'Sushi Roll',
    imgSrc: sushiImg2,
  },
  {
    id: 3,
    name: 'Tempeh',
    imgSrc: sushiImg3,
  },
  {
    id: 4,
    name: 'Rice Sushi',
    imgSrc: sushiImg4,
  },
  {
    id: 5,
    name: 'Salmon',
    imgSrc: sushiImg5,
  },
  {
    id: 6,
    name: 'Chiken',
    imgSrc: sushiImg6,
  },
  {
    id: 7,
    name: 'Sushi Roll',
    imgSrc: sushiImg7,
  },
];
