import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Container} from '@material-ui/core';

import Card from './Card';

import Image1 from '~/assets/images/page/healthProtocols/bookImage.png';

const styles = (theme) => ({
  root: {
    color: '#595959',
    padding: '4% 0',
    font: 'Lato',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  header: {
    background: 'white',
  },
  headerTitle: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      padding: '10px 0',
    },
  },
  cardTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
    whiteSpace: 'break-spaces',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  leftText: {
    paddingRight: '20px',
    fontWeight: '300',
    margin: '4px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '1.2',
    },
  },
  cardButton: {
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  cardWrapper: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9aa200',
    fontSize: '13px',
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '5px 0px',
      padding: '4px 20px',
    },
  },
}))(Button);

function BookConsultantCard(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.root}>
      <Container>
        <Grid container className={classes.header} alignItems="center">
          {/* <Grid item md={5} sm={12} xs={12}> */}
          <Grid item sm={6} xs={12}>
            <Typography variant="h5" className={classes.headerTitle}>
              Book a consultant
            </Typography>
            {pageData.bookConsultant.map((text, index) => (
              <Typography variant="body1" className={classes.leftText} key={index}>
                {text}
              </Typography>
            ))}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid item md={12} sm={12} xs={12} className={classes.cardWrapper}>
              <Card ratio="50%" imageSrc={Image1}>
                <Typography variant="h6" className={classes.cardTitle} align="center">
                  Book a consultation to consider one of our 6 Health Protocol programs
                </Typography>
                <ColorButton onClick={()=>{
                  window.open("https://vfhurbansanctuary.com/health-facilitators", "_blank")
                }} className={classes.cardButton}>READ MORE</ColorButton>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(BookConsultantCard);
