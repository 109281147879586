import React, { useState } from 'react';
import { Avatar, makeStyles, Popover, Typography } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  biosphericallyRatingIcon: (props) => ({
    position: 'relative',
    '& > img': {
      left: props.left || '60px',
      position: 'absolute',
      overflow: 'visible',
    },
  }),
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '400px',
  },
}));

const BiosphericallyRatingIcon = ({ src, rating }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  let left;
  let popoverText;

  switch(rating) {
    case 1:
      left = 60;
      popoverText = "The First Generation marker means that you can trust we’ve endeavoured to consider all social and ecological elements intrinsic to this product. Your purchase is an important part in helping us bring 2nd and them 3rd generation Biospherically Correct products to the world.";
      break;
    case 2:
      left = 20;
      popoverText = "A second generation Biospherically Correct service (2) represents a service that has scored extremely highly in all areas of the Biospherically Correct criteria. It is appraised as being ecologically and socially enhancing.  This service would have no nett negative health effect on humanity (psychologically/ physically) or the environment (eco systems/ habitat, species, ascetics of) and therefore would be considered ‘correct’.";
      break;
    case 3:
      left = -20;
      popoverText = "A 3rd generation Biospherically Correct service (3) represents a service which has a positive effect upon the entire biosphere (humanity, environment, species and ascetics of). This service would produce well-being and potentially provide a restorative effect for the individual, society our environment (the biosphere). This level of service would represent an exemplary bio-mimic standard (imitating nature’s creative process), providing increase – life creating /life enhancing.";
      break;
    case 4:
      left = -60;
      popoverText = ""; // Here you can add the text for rating 4.
      break;
    default:
      left = 60;
      popoverText = "";
      break;
  }

  const classes = useStyles({ left });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Avatar 
        className={classes.biosphericallyRatingIcon} 
        src={src} 
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
           <strong style={{color:'#4472c4'}}>Biospherically Correct Rating {rating}</strong> <br/>
            {popoverText}</Typography>
      </Popover>
    </>
  );
};

export default BiosphericallyRatingIcon;
