import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section10-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    padding: '5%',
  },
  descTitle: {
    fontWeight: 'bold',
    marginTop: '10px',
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  descInfo: {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
});

function CreateHealthSection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Create Health"
      reverse
      imageWidth={56}
      imageRatio="82%"
      imageSrc={ImageBg}
      descMargin="-20%"
      desc={() => (
        <div className={classes.desc}>
          <Typography variant="body2" className={classes.descTitle}>
            Preventative health
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Use Health Creator as the tool to fortify the body and mind with optimum levels of nutrition for
            preventative health. Nutrition and cellular purity plays a significant role in enabling: cell regeneration,
            neuro-genesis, muscle retention, organ protection, cardio vascular efficiency and general life force or
            vibrancy.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Longevity and Anti-aging
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Use our Health Creator App to reduce your biological age and extend life expectancy through accountable
            nutrition intake, pure Biospherically Correct products and healthy lifestyle choices.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Assist healing
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Use our App to improve recovery and healing time after a sickness or surgery, and/or naturally improve a
            health condition.
          </Typography>
          <Typography variant="body2" className={classes.descTitle}>
            Integrate a Sanctuary Health Protocol
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Use our Precision testing methods to determine the best Sanctuary Health Protocol for you to catalysis
            dynamic health improvements.
          </Typography>
        </div>
      )}
    />
  );
}

export default withStyles(styles)(CreateHealthSection);
