import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section7-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    padding: '5%',
  },
  descTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  descInfo: {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
});

function PuritySection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Purity "
      reverse
      imageWidth={66}
      imageRatio="55%"
      imageSrc={ImageBg}
      descMargin="-25%"
      desc={() => (
        <div className={classes.desc}>
          <Typography variant="body2" className={classes.descTitle}>
            Cellular Purity
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Calculate the degree of toxins you are being exposed to, and chart your detoxification process to perfect
            health and well-being.
          </Typography>

          <Typography variant="body2" className={classes.descTitle}>
            Detoxification and weight loss
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Use our Health Creator App as a guide to ‘drainage’ and detoxifying the body for greater health, healing and
            potential weight loss through toxin release.
          </Typography>

          <Typography variant="body2" className={classes.descTitle}>
            Biospherically Correct Products
          </Typography>
          <Typography variant="body2" className={classes.descInfo}>
            Incorporate pure BC Vision Products to ensure toxin free personal regimes for long term health and vibrant
            living.
          </Typography>
        </div>
      )}
    />
  );
}

export default withStyles(styles)(PuritySection);
