import {SET_USERNAMEANDPHONENUMBER} from '../action-types';

const INITIAL_STATE = {
  nameAndPhoneNumber: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERNAMEANDPHONENUMBER:
      return {...state, nameAndPhoneNumber: action.payload};
    default:
      return state;
  }
};
