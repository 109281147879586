import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
const styles = (theme) => ({
  list: {
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
  listItem: {
    marginTop: 15,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
    },
  },
  iconArrow: {
    height: 16,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      height: 14,
      marginRight: 7,
    },
    [theme.breakpoints.down('sm')]: {
      height: 13,
      marginRight: 5,
    },
  },
  itemDesc: {
    color: '#9AA200',
    fontWeight: 'normal',
    fontSize: '1.1rem',
    [theme.breakpoints.down('md')]: {
      color: '#fff',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      fontSize: '0.9rem',
    },
  },
});

function NavigationList(props) {
  const {classes, handleClick, titleList = [], color, icon} = props;

  return (
    <div className={classes.list}>
      {titleList.map((item, index) => (
        <Grid
          container
          key={index}
          alignItems="center"
          className={classes.listItem}
          onClick={() => {
            handleClick(index);
          }}
        >
          <img src={icon} className={classes.iconArrow} alt="Icon arrow" />
          <Typography variant="h6" className={classes.itemDesc} style={{color}}>
            {item}
          </Typography>
        </Grid>
      ))}
    </div>
  );
}
export default withStyles(styles)(NavigationList);
