import React from 'react';
import {Grid, Button, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import NavigationList from '~/components/ui/NavigationList';
import IconPlayVideo from '~/assets/images/page/discover/section1-video-play.png';
import mobileImage from '~/assets/images/page/weight-shifter/app_on_phone.png';
import Iconfinder from '~/assets/images/page/weight-shifter/iconfinder_next.png';

const styles = (theme) => ({
  sectionWrapper: {
    padding: '0px 6% 0',
    textAlign: 'right',
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  sideImage: {
    width: 180,
    height: 360,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 200,
    },
  },
  listItem: {
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  listItemImage: {
    height: '15px',
    margin: '2px 10px 0 0',
  },
  leftSectionWrapper: {
    position: 'relative',
  },
  leftText: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    position: 'absolute',
    bottom: '0px',
    margin: 'auto 10%',
    [theme.breakpoints.down('sm')]: {},
  },

  videoIcon: {
    height: '15px',
    margin: '2px 10px 0 0',
  },
  getNow: {
    borderRadius: '27px',
    backgroundColor: '#ffffff',
    height: 48,
    width: 200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      height: 36,
      width: 150,
      marginTop: 10,
    },
  },
  demoGet: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
});

const titleList = [
  'Healthy, long term, weight shifting',
  'Nutrition & exact calorie count ',
  'Partner Apps',
  'Longevity exercise programs ',
  'How to shift weight',
];

function Banner(props) {
  const {classes, handleClick} = props;

  return (
    <div className={classes.sectionWrapper}>
      <Grid container className={classes.root}>
        <Grid item className={classes.leftSectionWrapper} md={6} xs={12}>
          <Typography className={classes.leftText}>
            Sanctuary Software to help create your optimum weight and feel alive! It's as simple as loading in the
            results you want and then plotting the course of action required to fulfil your goals. Make a plan now!
          </Typography>
        </Grid>
        <Grid container item md={6} xs={12}>
          <Grid container item lg={7} md={8} sm={8} xs={9}>
            <NavigationList titleList={titleList} handleClick={handleClick} color={'#ffffff'} icon={Iconfinder} />
            <Grid container className={classes.demoGet}>
              <div className={classes.listItem}>
                <img src={IconPlayVideo} className={classes.videoIcon} alt="Video finder" />
                <span>View Live App Demo</span>
              </div>
              <Button variant="contained" className={classes.getNow}>
                GET NOW
              </Button>
            </Grid>
          </Grid>
          <Grid container item lg={5} md={4} sm={4} xs={3}>
            <img src={mobileImage} alt={mobileImage} className={classes.sideImage} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Banner);
