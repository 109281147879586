import React from 'react';
import {withStyles} from '@material-ui/core';
import hairTestingBg from '~/assets/images/page/hairTesting/hairTestingBg.png';
import HeroSectionData from './HeroSectionData';
import SetBackgroundComponent from '~/components/ui/SetBackgroundComponent';
const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '5% 12%',
    background: 'rgba(27,27,27,0.64)',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: '2% 4%',
    },
  },
});

const routes = [
  {title: 'Products and Services', to: '/productandservice'},
  {title: 'Tech Testing', to: '/productandservice/techtesting'},
  {title: 'Hair Testing', to: '/productandservice/techtesting/hairtesting'},
];
function HeroSection(props) {
  const {classes} = props;

  return (
    <div className={classes.content}>
      {/* <SetBackgroundComponent headerBackground={hairTestingBg} bgHeight="800px" overlayDisplay="block" /> */}
      <HeroSectionData />
    </div>
  );
}

export default withStyles(styles)(HeroSection);
