import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core/';

const style = (theme) => ({});

function Lengends(props) {
  const {dataList} = props;
  return (
    <Box>
      {dataList.map((item, index) => (
        <Box key={index} mt="8px" display="flex" alignItems="center">
          <Box width="10px" height="10px" bgcolor={item.color} mr="5px" />
          <Box color="#434343" flex="1" style={{fontSize: '13px'}}>
            {item.text}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default withStyles(style)(Lengends);
