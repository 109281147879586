import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import ThumbNailCard from './ThumbNailCard';

import biosphere from '../../../assets/images/page/healthProtocols/cardImages/biosphere.png';
import biosphereIcon from '../../../assets/images/page/healthProtocols/cardImages/biosphereIcon.png';
import body from '../../../assets/images/page/healthProtocols/cardImages/body.png';
import bodyIcon from '../../../assets/images/page/healthProtocols/cardImages/bodyIcon.png';
import mind from '../../../assets/images/page/healthProtocols/cardImages/mind.png';
import mindIcon from '../../../assets/images/page/healthProtocols/cardImages/mindIcon.png';

const cardData = [
  {
    icon: mindIcon,
    title: 'Mind',
    bgImage: mind,
    content:
      'Engage proactive mind activities to strengthen belief, attitude and emotional vibrancy to effectively process and navigate a constructive way forward concerning prevention, maintenance and regeneration of all biological conditions.',
    bgImage: mind,
  },
  {
    icon: bodyIcon,
    title: 'Body',
    bgImage: body,
    content:
      'Discover the inter-relationship of Mind x Body; the full physiological effects the mind has on the body and the full physiological effects the body has on the mind. The role of Life Force Food, Longevity Exercise, quality sleep, and supplements to further improve and protect holistic health.',
  },
  {
    icon: biosphereIcon,
    title: 'Biosphere',
    bgImage: biosphere,
    content:
      'Eliminate environmental stressors and toxins, and engage the full force and nurture of our earth, sun, soils and sea to embrace Mother Nature’s intrinsic processors and remedies for continued healthy living.',
  },
];

const styles = (theme) => ({
  thumbnailWrapper: {
    margin: '0 0 2%',
  },
  thumbnailMidCardWrapper: {
    margin: '0px 10px',
  },
});

function ThumbNailCardSection(props) {
  const {classes} = props;
  return (
    <div className={classes.thumbnailWrapper}>
      <Grid container>
        {cardData.map((card, index) => (
          <Grid item sm={4} xs={12} key={index}>
            <div className={classes.thumbnailMidCardWrapper}>
              <ThumbNailCard card={card} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ThumbNailCardSection);
