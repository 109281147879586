import React, {useState, useEffect} from 'react';
import GuideDetailPage from './Sections/GuideDetailPage';
import healthPsychology from '~/assets/images/page/healthProtocols/calloutimage.png';
import lifeForceFood from '~/assets/images/page/register/image2.png';
import longevityActivities from '~/assets/images/page/register/image3.png';
import physiologicalEfficiencies from '~/assets/images/page/register/image4.png';
import naturalRemedies from '~/assets/images/page/register/image5.png';
import pureProducts from '~/assets/images/page/register/image6.png';

const PageData = [
  {
    id: 1,
    title: 'Health Psychology',
    subTitle: 'Principle Health Guide and Book available now',
    cardBg: healthPsychology,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Health Psychology Personal Guide',
    price: '19.91',
    priceJournal: '29.91',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'psychology',
  },
  {
    id: 2,
    title: 'Life Force Food',
    subTitle: 'Advance Order',
    cardBg: lifeForceFood,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Life Force Food Personal Guide',
    price: '12.92',
    priceJournal: '22.92',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'life',
  },
  {
    id: 3,
    title: 'Longevity Activities',
    subTitle: 'Advance Order',
    cardBg: longevityActivities,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Longevity Activities Personal Guide',
    price: '19.93',
    priceJournal: '29.93',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'longevity',
  },
  {
    id: 4,
    title: 'Physiological Efficiencies',
    subTitle: 'Advance Order',
    cardBg: physiologicalEfficiencies,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Physiological Efficiencies Personal Guide',
    price: '29.94',
    priceJournal: '39.94',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'physiological',
  },
  {
    id: 5,
    title: 'Natural Remedies',
    subTitle: 'Advance Order',
    cardBg: naturalRemedies,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Natural Remedies',
    price: '15.95',
    priceJournal: '25.95',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'natural',
  },
  {
    id: 6,
    title: 'Purity',
    subTitle: 'Advance Order',
    cardBg: pureProducts,
    cardDesc:
      'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
    guideName: 'Purity Personal Guide',
    price: '18.96',
    priceJournal: '28.96',
    length: 12,
    language: 'English',
    Ebook: 'pdf',
    word: '2305',
    path: 'purity',
  },
];

function Psychology(props) {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    PageData.forEach((onePage) => {
      if (props.location && props.location.pathname.includes(onePage.path)) {
        setPageData(onePage);
      }
    });
  }, [props.location]);
  return (
    <div>
      {pageData && <GuideDetailPage pageData={pageData} />}
    </div>
  )}

export default Psychology;
