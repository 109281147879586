import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {Breadcrumbs} from '@material-ui/core';
import {withRouter, Link} from 'react-router-dom';
import SocialIcons from '~/components/ui/SocialIcons';
import headerBackground from '~/assets/images/layout/SanctuaryStore_home.png';

const styles = (theme) => ({
  root: {
    padding: '100px 10px 10px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '65px 5px 5px 5px',
    },
  },
  path: {
    opacity: '0.8',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.47',
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: '0 0 2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  title: {
    fontSize: '32px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: '1px',
    color: '#ffffff',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  socialIconsWrapper: {
    textAlign: 'right',
  },
  socialIcons: {
    padding: '1% 2%',
    color: '#c5d003',
    [theme.breakpoints.down('sm')]: {
      width: 18,
      height: 18,
    },
  },
});

function BreadcrumbsWithHeader(props) {
  const {classes, routes, text, history, background = headerBackground} = props;
  const handleClick = (to) => {
    history.push(to);
  };
  return (
    <Grid container className={classes.root} style={{background: `url(${background})`}}>
      <Grid item md={8} sm={8} xs={7}>
        <Breadcrumbs separator=">" aria-label="breadcrumb" className={classes.path}>
          {routes.map((route, index) => {
            // console.log('link', `/${route.to}`);
            return (
              <Link
                key={index + route.title}
                color="inherit"
                to={`${route.to}`}
                // onClick={() => {
                //   handleClick(route.to);
                // }}
                style={{cursor: route.to ? 'pointer' : 'default', color: 'white', textDecoration: 'none'}}
              >
                {route.title}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Grid>
      <Grid item md={4} sm={4} xs={5} className={classes.socialIconsWrapper}>
        <SocialIcons className={classes.socialIcons} />
      </Grid>
      <div className={classes.title}>{text}</div>
    </Grid>
  );
}

export default withStyles(styles)(withRouter(BreadcrumbsWithHeader));
