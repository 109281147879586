import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '90px',
    height: '90px',
    border: '2px solid #fff',
  },
  tooltip: {
    backgroundColor: '#9aa200',
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}));

const TopIngredients = ({ ingredients }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', gap:'20px', justifyContent:'flex-start', marginBottom:20, padding:'0 24px', marginTop:30, overflow:'auto' }}>
      {ingredients.map((ingredient, index) => (
        <Tooltip 
          title={ingredient.name} 
          classes={{ tooltip: classes.tooltip }} 
          key={index} 
        >
          <Link to={ingredient.to}>
          <Avatar alt={ingredient.name} src={ingredient.img} className={classes.avatar} />
          </Link>
        </Tooltip>
      ))}
    </div>
  );
};

export default TopIngredients;
