import React, {useState} from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

const $defaultHeight = 80;
const $defaultWidth = 35;

const $level2HeightVal = $defaultHeight - 8;
const $level2WidthVal = $defaultWidth - 8;
const $level1HeightVal = $defaultHeight - 2;
const $level1WidthVal = $defaultWidth - 2;
const $level0HeightVal = $defaultHeight + 5;
const $level0WidthVal = $defaultWidth + 5;

const $level2Height = $level2HeightVal + '%';
const $level2Width = $level2WidthVal + 'vmax';
const $level1Height = $level1HeightVal + '%';
const $level1Width = $level1WidthVal + 'vmax';
const $level0Height = $level0HeightVal + '%';
const $level0Width = $level0WidthVal + 'vmax';

const $level_2Left = '25%';
const $level_1Left = '35%';
const $level0Left = '50%';
const $level1Left = '65%';
const $level2Left = '75%';

const $level0Bs = '0 0 1.2em #999';
const $level_2Bs = $level0Bs;
const $level_1Bs = $level0Bs;
const $level2Bs = $level0Bs;
const $level1Bs = $level0Bs;

const styles = (theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  scrollBox: {
    width: '100%',
    height: 'calc(28.13vmax)',
    position: 'relative',
  },
  item: {
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition:
      'box-shadow 1s, height 1s, width 1s, left 1s, margin-top 1s, lineHeight 1s, background-color 1s, z-index 0.05s',
  },

  level_2: {
    boxShadow: $level_2Bs,
    height: $level2Height,
    width: $level2Width,
    lineHeight: $level2Height,
    left: $level_2Left,
    zIndex: 1,
  },

  level_1: {
    boxShadow: $level_1Bs,
    height: $level1Height,
    width: $level1Width,
    lineHeight: $level1Height,
    left: $level_1Left,
    zIndex: 2,
  },

  level0: {
    boxShadow: $level0Bs,
    height: $level0Height,
    width: $level0Width,
    lineHeight: $level0Height,
    left: $level0Left,
    zIndex: 3,
  },

  level1: {
    boxShadow: $level1Bs,
    height: $level1Height,
    width: $level1Width,
    lineHeight: $level1Height,
    left: $level1Left,
    zIndex: 2,
  },

  level2: {
    boxShadow: $level2Bs,
    height: $level2Height,
    width: $level2Width,
    lineHeight: $level2Height,
    left: $level2Left,
    zIndex: 1,
  },
});
const calculateActive = (a, i, m) => {
  let result = i - a;
  if (result < -2) {
    result = result + 5;
  }
  if (result > 2) {
    result = result - 5;
  }
  return result - m;
};
function AppCarousel2(props) {
  const {classes, items} = props;
  const [active, setActive] = useState(0);
  const [moveDirection, setMoveDirection] = useState(0);

  const clickScrollBox = (e) => {
    if (e.nativeEvent.clientX < window.innerWidth / 2) {
      moveRight();
    } else {
      moveLeft();
    }
  };

  const moveLeft = () => {
    setMoveDirection(1);
    setActive((active) => (active + 1) % items.length);
    setTimeout(() => {
      setMoveDirection(0);
    }, 0);
  };

  const moveRight = () => {
    setMoveDirection(-1);
    setActive((active) => (active === 0 ? items.length - 1 : active - 1));
    setTimeout(() => {
      setMoveDirection(0);
    }, 0);
  };

  return (
    <div className={classes.root}>
      <div className={classes.scrollBox} onClick={clickScrollBox}>
        <div>
          {items.map((item, i) => {
            let level = calculateActive(active, i, moveDirection);
            return <Item key={item.id} item={item} level={level} classes={classes} />;
          })}
        </div>
      </div>
    </div>
  );
}

// class AppCarousel extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       items: props.items,
//       active: 0,
//       levelOffset: 0,
//       moveDirection: 0,
//     };
//     this.rightClick = this.moveRight.bind(this);
//     this.leftClick = this.moveLeft.bind(this);

//     this.clickScrollBox = (e) => {
//       if (e.nativeEvent.clientX < window.innerWidth / 2) {
//         this.rightClick();
//       } else {
//         this.leftClick();
//       }
//     };
//   }

//   generateItems(classes) {
//     let items = [];
//     let offsetLevel = -this.state.levelOffset;

//     console.log('====');
//     for (let i = this.state.active - 2; i < this.state.active + 3; i++) {
//       let index = i;
//       if (i < 0) {
//         index = this.state.items.length + i;
//       } else if (i >= this.state.items.length) {
//         index = i % this.state.items.length;
//       }
//       let level = this.state.active - i + offsetLevel;
//       if (level > 2) {
//         level = -2;
//       } else if (level < -2) {
//         level = 2;
//       }

//       const item = this.state.items[index];

//       let disableAnim = false;
//       if (
//         (this.state.moveDirection === 1 && i === this.state.active + 2) ||
//         (this.state.moveDirection === -1 && i === this.state.active - 2)
//       ) {
//         disableAnim = true;
//       }

//       console.log(
//         `index = ${index} id = ${item.id} level = ${level} offsetLevel = ${offsetLevel} disableAnim = ${disableAnim}`
//       );

//       items.push(
//         <Item
//           key={item.id}
//           id={item.id}
//           item={item}
//           level={level}
//           style={
//             disableAnim && {
//               transition: 'none',
//             }
//           }
//           classes={classes}
//         />
//       );
//     }
//     return items;
//   }

//   moveLeft() {
//     let newActive = this.state.active;
//     this.setState({
//       active: newActive - 1 < 0 ? this.state.items.length - 1 : newActive - 1,
//       moveDirection: -1,
//       levelOffset: -1,
//     });
//     setTimeout(() => {
//       if (this.state.levelOffset !== 0) {
//         this.setState({
//           levelOffset: 0,
//         });
//       }
//     }, 0);
//   }

//   moveRight() {
//     var newActive = this.state.active;
//     this.setState({
//       active: (newActive + 1) % this.state.items.length,
//       moveDirection: 1,
//       levelOffset: 1,
//     });
//     setTimeout(() => {
//       if (this.state.levelOffset !== 0) {
//         this.setState({
//           levelOffset: 0,
//         });
//       }
//     }, 0);
//   }

//   render() {
//     const {classes} = this.props;
//     return (
//       <div className={classes.root}>
//         <div className={classes.scrollBox} onClick={this.clickScrollBox}>
//           <div>{this.generateItems(classes)}</div>
//         </div>
//       </div>
//     );
//   }
// }

function Item({level, classes, item, style}) {
  const className = clsx(classes.item, classes[`level${level < 0 ? '_' : ''}${Math.abs(level)}`]);
  return (
    <div
      className={className}
      style={{
        ...style,
        backgroundImage: `url(${item.image}`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    ></div>
  );
}

export default withStyles(styles)(AppCarousel2);
