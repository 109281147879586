import {Container, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {Link} from 'react-router-dom';
import boneCard from '../../../assets/images/page/healthProtocols/card/boneCard.png';
import brainCard from '../../../assets/images/page/healthProtocols/card/brainCard.png';
import cellularCard from '../../../assets/images/page/healthProtocols/card/cellularCard.png';
import heartCard from '../../../assets/images/page/healthProtocols/card/heartCard.png';
import immuneCard from '../../../assets/images/page/healthProtocols/card/immuneCard.png';
import increasedCard from '../../../assets/images/page/healthProtocols/card/increasedCard.png';

const cardData = [
  {
    title: 'Immune System',
    bgImage: immuneCard,
    path: '/shop-by-health-interest/health-protocols/immune-system',
  },
  {
    title: 'Brain Health',
    bgImage: brainCard,
    path: '/shop-by-health-interest/health-protocols/brain-health',
  },
  {
    title: 'Heart Health',
    bgImage: heartCard,
    path: '/shop-by-health-interest/health-protocols/heart-health',
  },
  {
    title: 'Cellular Health',
    bgImage: cellularCard,
    path: '/shop-by-health-interest/health-protocols/cellular-health',
  },
  {
    title: 'Bone and Cartilage Health',
    bgImage: boneCard,
    path: '/shop-by-health-interest/health-protocols/bone-and-cartilage-health',
  },

  {
    title: 'Increased Health Span',
    bgImage: increasedCard,
    path: '/shop-by-health-interest/health-protocols/increased-health-span',
  },
];

const styles = (theme) => ({
  thumbnailWrapper: {
    margin: '2% 0',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '2rem',
    color: '#595959',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      margin: '20px 10px 10px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  thumbnailMidCardWrapper: {
    margin: '0px 6px',
    height: '240px',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '160px',
      marginTop: '10px',
    },
  },
  cardWrapper: {
    height: '64px',
    width: '100%',
    color: 'white',
    fontSize: '12px',
    backgroundColor: 'rgba(180,180,180,0.6)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function ProtocolsCard(props) {
  const {classes} = props;
  return (
    <div className={classes.thumbnailWrapper}>
      <Container>
        <Typography className={classes.title}>Health Protocols</Typography>
        <Grid container>
          {cardData.map((card, index) => (
            <Grid item sm={2} xs={4} key={index}>
              <Link to={card.path} className={classes.link}>
                <div
                  className={classes.thumbnailMidCardWrapper}
                  style={{background: `url(${card.bgImage}) no-repeat`, backgroundSize: 'cover'}}
                >
                  <div className={classes.cardWrapper}>
                    <div>{card.title}</div>
                  </div>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(ProtocolsCard);
