import React from 'react';
import {Grid} from '@material-ui/core';
import OuterContainer from '../../ui/OuterContainer';
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import {withStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import logo from '../../../assets/images/logo/footerLogo.png';
import {SanctuaryStoreLogoWithText} from '../SanctuaryStoreLogoWithText';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 700,
    },
  },
});

const styles = (theme) => ({
  class0: {
    width: '100%',
    margin: 0,
  },
  class1: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '1em',
  },
  class2: {
    fontSize: '2rem',
    fontFamily: 'montserrat',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    padding: '0.5em',
  },
  greenBg: {
    backgroundColor: '#9aa200',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  calloutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
  },
  imgContainer: {
    width: '60px',
    [theme.breakpoints.down(768)]: {
      width: '36px',
    },
  },
  logo: {
    width: '100%',
  },
  calloutTitle: {
    fontSize: '32px',
    fontWeight: '700',
    padding: '5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  vitalityContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    padding: '5px 0',
  },
  vitality: {
    border: 'solid 1px white',
    borderRadius: '4px',
    padding: '2px 4px',
    fontSize: '18px',
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  healthCreator: {
    border: 'solid 1px #8DD54E',
    borderRadius: '4px',
    padding: '1px 2px',
    color: '#8DD54E',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  calloutSubtitle: {
    fontFamily: 'montserrat',
    fontSize: '18px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: '10px 20px',
    },
  },

  class3: {
    fontFamily: 'montserrat',
    textAlign: 'right',
    color: 'black',
    fontSize: '0.75rem',
  },
  class4: {
    width: '33.3%',
    [theme.breakpoints.down(768)]: {
      width: '100%',
    },
  },
  class5: {
    width: '33.3%',
    [theme.breakpoints.down(768)]: {
      marginTop: '1em',
      width: '50%',
    },
    [theme.breakpoints.down(530)]: {
      width: '100%',
      marginTop: '1em',
    },
  },
  class6: {
    width: '33.3%',
    [theme.breakpoints.down(768)]: {
      marginTop: '1em',
      width: '50%',
    },
    [theme.breakpoints.down(530)]: {
      width: '100%',
      marginTop: '1em',
    },
  },
  backgroundColor2: {
    background: 'white',
  },
});

function Footer(props) {
  const {classes, routes} = props;
  return (
    <ThemeProvider theme={theme}>
      <Grid container className={classes.class0}>
        <Grid item xs={12}>
          <OuterContainer className={classes.greenBg}>
            <div className={classes.calloutContainer}>
              <div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'flex-end',justifyContent:'center', padding: 10, gap:10}}>
            <div className={classes.healthCreator}>Health Creator</div>
              <SanctuaryStoreLogoWithText style={{maxWidth: 350, width:'100%'}} />
              <div className={classes.healthCreator}>Weight Shifter</div>
              </div>
              {/* <div className={classes.imgContainer}>
                <img src={logo} className={classes.logo} />
              </div>
              <div className={classes.calloutTitle}>Sanctuary Store</div>
              <div className={classes.vitalityContainer}>
                <div className={classes.healthCreator}>Health Creator</div>
                <div className={classes.vitality}>Health ~ Vitality ~ Longevity</div>
           
              </div> */}
              <div className={classes.calloutSubtitle}>
              Sanctuaries for human wellbeing and advancement, Elevating Global Consciousness, and helping create “A Successful Planet.”
              </div>
            </div>
          </OuterContainer>
        </Grid>
        <OuterContainer className={classes.backgroundColor2}>
          <Grid container item xs={12} spacing={2} className={classes.class0} style={{justifyContent:'space-between', alignItems:'flex-start'}}>
            <Section1 />
            <Section2 routes={routes} />
            <Section3 />
          </Grid>
        </OuterContainer>
      </Grid>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Footer);
