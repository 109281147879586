import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Image1 from '~/assets/images/page/techtesting/image@3x.png';
import Image7 from '~/assets/images/page/techtesting/sDriveLarge470X390@3x.png';
import Image11 from '~/assets/images/page/techtesting/image@3x(2).png';
import Image12 from '~/assets/images/page/techtesting/hybrid2700T2@3x.png';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

const styles = (theme) => ({
  class1: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    marginTop: 25,
    padding: '0 5vw',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      padding: '0 3vw',
    },
  },
  class2: {
    display: 'flex',
    boxSizing: 'border-box',
    flexBasis: '20%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& a': {
      textDecoration: 'none',
      color: '#8c8c8c',
    },
    '& > div:nth-of-type(1)': {
      borderLeft: '0.6vw solid #8c8c8c',
      color: '#8c8c8c',
      fontFamily: 'Roboto',
      fontSize: '1.5vw',
      padding: '1vw 1vw',
      fontWeight: 500,
      alignSelf: 'flex-start',
      [theme.breakpoints.down(1024)]: {
        borderLeft: '0.3em solid #8c8c8c',
        fontSize: '1.4rem',
        padding: '.5em',
        marginLeft: '0.5em',
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      boxSizing: 'border-box',
      color: 'black',
      padding: '5% 13%',
      color: '#8c8c8c',
      fontSize: '1vw',
      fontFamily: 'Roboto',
      [theme.breakpoints.down(1024)]: {
        padding: '1em',
        fontSize: '1rem',
        textAlign: 'center',
      },
    },
  },
  class3: {
    [theme.breakpoints.down(1024)]: {
      '& > :nth-child(1)': {order: 2},
    },
  },
  class4: {
    width: '60%',
    maxHeight: '100%',
  },
  class5: {
    [theme.breakpoints.down(1024)]: {
      width: '50%',
      minWidth: 300,
    },
  },
  class6: {
    display: 'flex',
    boxSizing: 'border-box',
    flexBasis: '79%',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
    },
  },
  class7: {
    maxWidth: '72%',
    maxHeight: '66%',
    marginRight: '-29%',
    top: '10%',
    position: 'relative',
    [theme.breakpoints.down(1024)]: {
      marginRight: 0,
      top: 0,
      maxWidth: '100%',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(41, 41, 41,30%)',
      zIndex: 10,
      position: 'absolute',
      width: '81%',
      height: '100%',
      backdropFilter: 'blur(2.3px)',
      color: 'white',
      alignItems: 'center',
      fontFamily: 'Lato',
      justifyContent: 'center',
      [theme.breakpoints.down(1024)]: {
        width: '100%',
      },
    },
  },
  class8: {
    borderBottom: '0.1vw solid white',
    width: '7%',
    margin: '1vw',
    [theme.breakpoints.down(480)]: {
      borderBottom: '0.1rem solid white',
      width: '7%',
      margin: '0.5em',
    },
  },
  class9: {
    fontSize: '1rem',
    width: '50%',
    textAlign: 'center',
    [theme.breakpoints.down(480)]: {
      fontSize: '13px',
    },
  },
  class10: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    [theme.breakpoints.down(480)]: {
      fontSize: '19px',
    },
  },
  class11: {
    padding: '1rem',
    fontSize: '4rem',
    [theme.breakpoints.down(480)]: {
      padding: '0.2rem',
      fontSize: '2.5rem',
    },
  },
  class12: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    color: 'black',
    backgroundColor: '#f2f2f2',
    padding: '3vw 1vw 1vw 9vw',
    color: '#8c8c8c',
    fontFamily: 'Roboto',
    fontSize: '1vw',
    [theme.breakpoints.down(1024)]: {
      padding: '0.5em',
      fontSize: '1rem',
    },
  },
});

const SubSection2 = withStyles(styles)((props) => {
  const {classes} = props;
  return (
    <div className={classes.class6}>
      <div className={classes.class7}>
        <div>
          <div className={classes.class10}>{props.videoTitle}</div>
          <div className={classes.class8}></div>
          <div className={classes.class9}>{props.videoSubtitle}</div>
          <PlayCircleOutlineIcon className={classes.class11} />
        </div>
        <img style={{maxWidth: '100%', maxHeight: '100%'}} src={props.videoImage} />
      </div>
      <div className={classes.class12}>{props.videoDescription}</div>
    </div>
  );
});

const SubSection1 = withStyles(styles)((props) => {
  const {classes} = props;
  return (
    <div className={classes.class2}>
      <div>
        <Link to={props.to}>{props.heading}</Link>
      </div>
      <img className={clsx(classes.class4, classes.class5)} src={props.image} />
      <div>{props.subtitle}</div>
    </div>
  );
});

const Section2 = (props) => {
  const {classes} = props;
  return (
    <>
      <div className={classes.class1}>
        <SubSection1
          to={'/productandservice/techtesting/hairtesting'}
          heading="HAIR TESTING"
          image={Image7}
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
      purus."
        />
        <SubSection2
          videoTitle="Tiny thing makes a big difference"
          videoSubtitle="Lorem ipsum dolor sit amet, in eam odio amet, vix id nullam detracto vidit."
          videoImage={Image1}
          videoDescription={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
        purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
        varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.
        ${'\n'}
        ${'\n'}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
        commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a
        dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.`}
        />
      </div>

      <div className={clsx(classes.class1, classes.class3)}>
        <SubSection2
          videoTitle="Tiny thing makes a big difference"
          videoSubtitle="Lorem ipsum dolor sit amet, in eam odio amet, vix id nullam detracto vidit."
          videoImage={Image11}
          videoDescription={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
        purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
        varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.
        ${'\n'}
        ${'\n'}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
        commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a
        dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.`}
        />
        <SubSection1
          to={'/productandservice/techtesting/3dscanning'}
          heading="3D SCANNING"
          image={Image12}
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
      purus."
        />
      </div>
    </>
  );
};

export default withStyles(styles)(Section2);
