import config from 'shared/config/config';

export const shopifyPOST = (query) => {
  const shopifyAccessToken = config.shopifyConfig.storefrontToken;
  const shopifyShopName = config.shopifyConfig.shopname;

  const shopifyGraphQLURL = 'https://' + shopifyShopName + '.myshopify.com/api/2024-01/graphql.json';
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
    },
    body: JSON.stringify({
      query,
    }),
  };

  return fetch(shopifyGraphQLURL, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      if (json.hasOwnProperty('errors')) {
        throw json.errors;
      } else {
        return json;
      }
    });
};

export const statusObj = (status, message = '') => {
  return {
    status,
    message,
  };
};

export const productObj = (obj, shopifyNode) => {
  const newObj = {
    ...obj,
    price: shopifyNode.priceRange.minVariantPrice.amount,
    image: shopifyNode.images.edges[0].node.originalSrc,
    variants: shopifyNode.variants.edges.map((ele) => ele.node),
  };
  newObj.variants = newObj.variants.map((ele) => ({...ele, image: ele.image.originalSrc}));
  return newObj;
};

export const openLoginModal = (history, location) => {
  history.push(location.pathname + location.search + '?login');
};

export const closeLoginModal = (history, location) => {
  history.push(location.pathname + location.search.replace('?login', ''));
};

export const loggedInCheck = (firebase) => {
  if (firebase.auth().currentUser === null) {
    return new Promise((resolve, reject) => {
      reject('notLoggedIn');
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(firebase.auth().currentUser.uid);
    });
  }
};

export const setSSCheckoutID = (firestore, firebase, ID) => {
  return loggedInCheck(firebase).then((uid) => {
    return firestore.collection('users').doc(uid).update({
      SSCheckoutID: ID,
    });
  });
};

export const getSSCheckoutID = (firestore, firebase) => {
  return loggedInCheck(firebase).then((uid) => {
    return firestore
      .collection('users')
      .doc(uid)
      .get()
      .then((doc) => doc.data());
  });
};

// export const addItemToCart = (recipeID, shopifyID, quantity, itemCat, firestore, docLocation, mealPlanner = false) => {
//   return firestore
//     .doc(docLocation)
//     .get()
//     .then((doc) => {
//       const cart = doc.data().cart;
//       const obj2 = {recipeID, quantity, shopifyID, itemCat, itemID: Date.now()};
//       if (
//         cart.find((ele) => {
//           return ele.shopifyID == obj2.shopifyID;
//         }) &&
//         !mealPlanner
//       ) {
//         console.log('nope');
//         return null;
//       } else {
//         if (mealPlanner == true && itemCat == null) {
//           return null;
//         } else {
//           cart.push(obj2);
//           return firestore
//             .doc(docLocation)
//             .update({cart})
//             .then(() => {
//               return 'done';
//             });
//         }
//       }
//     });
// };

export const addItemToCart = (recipeID, shopifyID, quantity, itemCat, firestore, docLocation, mealPlanner = false) => {
  return firestore
    .doc(docLocation)
    .get()
    .then((doc) => {
      const cart = doc.data().cart;
      const obj2 = {recipeID, quantity, shopifyID, itemCat, itemID: Date.now()};
      
      if (cart.length > 0 && cart.find(ele => ele.recipeID === recipeID)) {
        cart.find(ele => ele.recipeID === recipeID).quantity = quantity;
        firestore
            .doc(docLocation)
            .update({cart})
            .then(() => {
              console.log('updated only quantity');
            });
      }
      
      if (
        cart.find((ele) => {
          return ele.shopifyID == obj2.shopifyID;
        }) &&
        !mealPlanner
      ) {
        console.log('nope');
        return null;
      } else {
        if (mealPlanner == true && itemCat == null) {
          return null;
        } else {
          cart.push(obj2);
          return firestore
            .doc(docLocation)
            .update({cart})
            .then(() => {
              return 'done';
            });
        }
      }
    });
};


export const removeItemFromCart = (itemID, firestore, docLocation) => {
  return firestore
    .doc(docLocation)
    .get()
    .then((doc) => {
      const cart = doc.data().cart.filter((ele) => {
        return ele.itemID !== itemID;
      });
      return firestore
        .doc(docLocation)
        .update({cart})
        .then(() => {
          return 'done';
        });
    });
};

export const shopifyIDsArrToStr = (IDsArr) => {
  return IDsArr.reduce((acc, cv) => {
    return acc.concat('"' + cv.shopifyID + '",');
  }, '');
};

export const getMealPlansShopifyDetails = (data) => {
  const mealPlansArr = data.docs.map((e) => {
    return {...e.data(), mealPlanId: e.id};
  });
  const promises = mealPlansArr.map((element) => cartItemShopify(element.cart, shopifyPOST));
  return Promise.all(promises).then((res) => {
    return mealPlansArr.map((element, index) => {
      return {...element, cart: res[index]};
    });
  });
};

export const getMealPlans = (firestore, uid) => {
  return firestore
    .collection(`users/${uid}/mealPlans`)
    .get()
    .then((data) => {
      return getMealPlansShopifyDetails(data);
    });
};

export const cartItemShopify = (cart, shopifyPOST) => {
  const query = (ids) => `{
    nodes(ids: [${ids}]) {
      ... on ProductVariant {
        id
        image {
          transformedSrc(maxWidth: 100)
        }
        available
        title
        product {
          id
          title
        }
        price
      }
    }
  }
  `;
  // console.log(cart, 'asdas');
  return shopifyPOST(query(shopifyIDsArrToStr(cart))).then((res) => {
    const cart2 = cart.map((ele) => {
      return {...ele, ...res.data.nodes.find((element) => element.id === ele.shopifyID)};
    });
    return new Promise((res, rej) => res(cart2));
  });
};

export const cartItemFirestore = (documentLoc, firestore) => {
  return firestore
    .doc(documentLoc)
    .get()
    .then((doc) => doc.data());
};
