// import React from 'react';
// import {withStyles, useTheme} from '@material-ui/core/styles';
// import Image2 from '~/assets/images/page/inbodyscanning/1602630867544@3x (1).png';
// import Image1 from '~/assets/images/page/inbodyscanning/acroAcroPoseAcroYoga1139491@3x.png';
// import Image3 from '~/assets/images/page/inbodyscanning/newmain901Global@3x.png';
// import {useMediaQuery} from '@material-ui/core';

// const styles = (theme) => ({
//   class1: {
//     display: 'flex',
//     boxSizing: 'border-box',
//     flex: 1,
//     flexDirection: 'column',
//     marginTop: 50,
//     [theme.breakpoints.down(1024)]: {
//       marginTop: '22vw',
//     },
//   },
//   class4: {
//     flex: 1,
//     // backgroundImage: `url(${Image1})`,
//     background: `url(${Image1}) no-repeat fixed`,
//     backgroundSize: 'cover',
//     // backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//   },
//   class2: {
//     display: 'flex',
//     boxSizing: 'border-box',
//     flex: 1,
//     backgroundColor: 'rgba(41, 41, 41,30%)',
//     backdropFilter: 'blur(2.3px)',
//     color: '#8c8c8c',
//     fontFamily: 'Roboto',
//     flexDirection: 'column',
//     [theme.breakpoints.down(1024)]: {
//       flexDirection: 'column',
//       alignItems: 'center',
//     },
//     '& >img': {
//       maxWidth: '50%',
//       maxHeight: '80%',
//       marginTop: '-35%',
//       position: 'relative',
//       [theme.breakpoints.down(1024)]: {
//         marginTop: '-20%',
//         maxWidth: '100%',
//       },
//     },
//     '& >div': {
//       padding: ' 0 4vw 3vw 4vw',
//       fontSize: '1vw',
//       [theme.breakpoints.down(1024)]: {
//         fontSize: '1rem',
//       },
//     },
//     '& h2': {
//       fontWeight: 'bold',
//       color: 'white',
//       fontSize: '3vw',
//       [theme.breakpoints.down(1024)]: {
//         fontSize: '2rem',
//       },
//     },
//   },
//   class3: {
//     display: 'flex',
//     boxSizing: 'border-box',
//     flex: 1,
//     height: '33vw',
//     padding: '0 2%',
//     justifyContent: 'flex-end',
//     [theme.breakpoints.down(1024)]: {
//       marginTop: 50,
//       height: 'auto',
//       justifyContent: 'flex-start',
//     },
//     '& > div': {
//       display: 'flex',
//       boxSizing: 'border-box',
//       flexBasis: '50%',
//       alignItems: 'center',
//       [theme.breakpoints.down(1024)]: {
//         justifyContent: 'flex-start',
//         flexBasis: '100%',
//         alignItems: 'flex-start',
//         padding: '0 3vw',
//       },
//     },
//     '& > div > div': {
//       display: 'flex',
//       boxSizing: 'border-box',
//       flexDirection: 'column',
//       color: '#8c8c8c',
//       fontFamily: 'Roboto',
//       fontSize: '1.2vw',
//       fontWeight: 500,
//       textAlign: 'center',
//       [theme.breakpoints.down(1024)]: {
//         fontSize: '1.2rem',
//       },
//     },
//   },
//   class5: {
//     '& > img': {
//       maxWidth: '81%',
//       maxHeight: '100%',
//       [theme.breakpoints.down(1024)]: {
//         maxWidth: '100%',
//       },
//     },
//   },
//   class6: {
//     color: 'white',
//     fontSize: '1.5vw',
//     [theme.breakpoints.down(1024)]: {
//       fontSize: '1.5rem',
//     },
//   },
//   class7: {
//     fontSize: '3vw',
//     color: 'rgba(240, 240, 238, 0.4)',
//     [theme.breakpoints.down(1024)]: {
//       fontSize: '2rem',
//     },
//   },
// });

// const Section3 = (props) => {
//   const {classes} = props;
//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down(1024));
//   return (
//     <>
//       {matches ? (
//         <div className={classes.class3}>
//           <div>
//             <div>
//               <div className={classes.class5}>
//                 <img src={Image3} />
//               </div>
//               <p>
//                 As a global enterprise, InBody’s buyers are located in over 60 countries around the world. Over 50% of
//                 the total revenue is generated from exports. In addition to InBody Co., Ltd, which serves as the head
//                 office of operations, subsidiaries are present in USA, China, and Japan. It takes priority in keeping a
//                 close and healthy relationship with its agents worldwide.
//               </p>
//             </div>
//           </div>
//         </div>
//       ) : null}
//       <div className={classes.class1}>
//         {matches ? null : (
//           <div className={classes.class3}>
//             <div>
//               <div>
//                 <div className={classes.class5}>
//                   <img src={Image3} />
//                 </div>
//                 <p>
//                   As a global enterprise, InBody’s buyers are located in over 60 countries around the world. Over 50% of
//                   the total revenue is generated from exports. In addition to InBody Co., Ltd, which serves as the head
//                   office of operations, subsidiaries are present in USA, China, and Japan. It takes priority in keeping
//                   a close and healthy relationship with its agents worldwide.
//                 </p>
//               </div>
//             </div>
//           </div>
//         )}
//         <div className={classes.class4}>
//           <div className={classes.class2}>
//             <img src={Image2} />
//             <div>
//               <h2>INBODY SCANNING</h2>
//               <p className={classes.class6}>Giant stars have a much lower surface gravity</p>
//               <p className={classes.class7}>
//                 Diverted and concentrated by drains that create constant flows over the beach above the sea or river
//                 level, the beach will be eroded
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default withStyles(styles)(Section3);

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';
import Image2 from '~/assets/images/page/inbodyscanning/1602630867544@3x (1).png';
import Image1 from '~/assets/images/page/inbodyscanning/acroAcroPoseAcroYoga1139491@3x.png';
import Image3 from '~/assets/images/page/inbodyscanning/newmain901Global@3x.png';
const styles2 = (theme) => ({
  root: {
    zIndex: '2',
    padding: '50px 0px',
  },
  imageCaption: {
    opacity: '0.8',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontstretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#8c8c8c',
  },
  leftContent: {
    textAlign: 'center',
    paddingTop: '20px',
  },
  imageWrapper: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  reportingImage: {
    maxWidth: '500px',
    textAlign: 'center',
    margin: '0px auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  exerciseImage: {
    margin: '0px auto',
    maxWidth: 600,
    zIndex: '3',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

const ExerciseSection = withStyles(styles2)((props) => {
  const {classes} = props;
  return (
    <div className={classes.exerciseWrapper}>
      <Container>
        <div className={classes.root}>
          <Grid container>
            <Grid item md={6} className={classes.imageWrapper}>
              <img src={Image2} className={classes.exerciseImage} alt="Our Scanner" />
            </Grid>
            <Grid item md={6} className={classes.leftContent}>
              <img src={Image3} className={classes.reportingImage} alt="Our Scanner" />
              <Typography variant="h5" className={classes.imageCaption}>
                As a global enterprise, InBody’s buyers are located in over 60 countries around the world. Over 50% of
                the total revenue is generated from exports. In addition to InBody Co., Ltd, which serves as the head
                office of operations, subsidiaries are present in USA, China, and Japan. It takes priority in keeping a
                close and healthy relationship with its agents worldwide.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
});

const styles = (theme) => ({
  bannerCardWrapper: {
    background: `url(${Image1}) no-repeat fixed`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: '2',
    position: 'relative',
    minHeight: '400px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-200px',
  },
  backgroundOverlay: {
    backgroundColor: 'rgba(41, 41, 41,30%)',
    backdropFilter: 'blur(2.3px)',
    flex: 1,
  },
  root: {
    paddingTop: 30,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  videoImage: {
    width: '300px',
  },
  title: {
    color: '#ffffff',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '75px 0px 15px 20px',
  },
  subTitle: {
    fontSize: '11px',
    marginLeft: '20px',
  },
  description: {
    fontSize: '20px',
    fontFamily: 'AmazonEmberDisplay',
  },
  list: {
    marginTop: '30px',
    color: 'rgba(240, 240, 238, 0.4)',
    fontFamily: 'AmazonEmberDisplay',
  },
  listItem: {
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
});

function Section3(props) {
  const {classes} = props;
  return (
    <>
      <ExerciseSection />
      <div className={classes.bannerCardWrapper}>
        <div className={classes.backgroundOverlay}>
          <Container>
            <div className={classes.root}>
              <Grid container className={classes.root}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    Lorem Title
                  </Typography>
                  <Typography variant="body1" className={classes.subTitle}>
                    Giant stars have a much lower surface gravity
                  </Typography>
                  <div className={classes.list}>
                    <Typography className={classes.listItem}>
                      Diverted and concentrated by drains that create constant flows over the beach above the sea or
                      river level, the beach will be eroded{' '}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(Section3);
