import {axiosGet, axiosPost} from '~/utils/axiosMethods';
import {profileValidation, changePasswordValidation} from '~/utils/inputValidation';
import {SET_USERNAMEANDPHONENUMBER} from '../action-types';
import config from 'shared/config/config';
const userEndPoint = `${config.apiURL}/user`;

export const updateProfile = (obj) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log(firebase);
    return profileValidation(obj)
      .then(() => {
        return firestore
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .update({
            nickname: obj.nickname,
            name: obj.name,
            mobileNumber: obj.mobileNumber,
          })
          .then(() => {
            console.log('DONE');
          });
      })
      .catch((error) => {
        // if (error.code && error.code.includes('email-already-in-use')) {
        //   throw {...error, message: 'Account with this email already exists.'};
        // }
        throw error;
      });
  };
};

export const changePassword = (obj) => {
  return (dispatch, getState, {getFirebase, getFireStore}) => {
    const firebase = getFirebase();
    var emailAddress = firebase.auth().currentUser.email;
    return changePasswordValidation(obj)
      .then(() => {
        return firebase
          .auth()
          .signInWithEmailAndPassword(emailAddress, obj.currentPassword)
          .then((res) => {
            console.log(res);
            return firebase.auth().currentUser.updatePassword(obj.newPassword);
          });
      })
      .catch(function (error) {
        throw error;
      });
  };
};

export const getUserNameAndPhoneNumber = () => {
  return (dispatch, getState, {getFirebase, getFireStore}) => {
    const firebase = getFirebase();
    return firebase
      .auth()
      .currentUser.getIdToken(false)
      .then((token) => {
        return axiosGet(`${userEndPoint}/userNameAndPhoneNumber`, token)
          .then(function (response) {
            dispatch({type: SET_USERNAMEANDPHONENUMBER, payload: response.data});
          })
          .catch(function (error) {
            throw JSON.stringify(error.message);
          });
      });
  };
};

export const updateUserNameAndPhoneNumber = (obj) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return firebase
      .auth()
      .currentUser.getIdToken(false)
      .then((token) => {
        return firestore
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then((doc) => {
            return axiosPost(
              `${userEndPoint}/updateUserNameAndPhoneNumber`,
              {
                first_name: obj.firstName,
                last_name: obj.lastName,
                phone: obj.phone,
                shopifyToken: doc.data().customerAccessTokenShopify,
              },
              token
            );
          })
          .then((response) => {
            return 'updated!';
          })
          .catch(function (error) {
            throw JSON.stringify(error.message);
          });
      });
  };
};
