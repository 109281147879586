import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '~/components/ui/OuterContainer';
import clsx from 'clsx';
import HealthCreatorIcon from '~/assets/images/page/lifeforcefood/healthCreatorIcon.svg';
import HealthIcon from '~/assets/images/page/lifeforcefood/healthIcon.svg';
import {Button} from '@material-ui/core';
const styles = (theme) => ({
  OuterContainer: {
    background: '#EEF8E5',
    color: '#8C8C8C',
  },
  class0: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  class1: {
    background: '#DFF2CE',
    alignSelf: 'center',
    textAlign: 'center',
    margin: 8,
  },
  class2: {
    padding: '0 2px',
    '& > span > p': {
      margin: 0,
    },
    '& > span > img': {
      paddingRight: 8,
    },
    color: 'inherit',
    fontStyle: 'inherit',
    fontSize: 'inherit',
  },
  class3: {
    justifyContent: 'space-around',
    borderRadius: '48px 4px 4px 4px',
    background: '#DFF2CE',
    alignSelf: 'center',
    margin: '4px 0',
    paddingLeft: 16,
    '& > p': {
      padding: '0 8px',
    },
  },
});

const FoodQuestions = (props) => {
  const {classes} = props;
  return (
    <OuterContainer className={classes.OuterContainer}>
      <div className={clsx(classes.class0, classes.class1)}>
        <Button onClick={() => {}} className={classes.class2}>
          <img src={HealthCreatorIcon} style={{width: '1rem'}} />
          <p>
            Create your Health
            <br />
            Take our free assessment
          </p>
        </Button>
      </div>
    </OuterContainer>
  );
};

export default withStyles(styles)(FoodQuestions);
