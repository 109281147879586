import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@material-ui/core';

const CustomerOrdersTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="order history table">
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell align="right">Total Price</TableCell>
            <TableCell align="right">Total Tax</TableCell>
            <TableCell align="right">Shipping Price</TableCell>
            <TableCell align="right">Fulfillment Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.customer?.orders?.nodes?.map((order, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {order.name}
              </TableCell>
              <TableCell align="right">${order.totalPrice.amount}</TableCell>
              <TableCell align="right">${order.totalTaxV2.amount}</TableCell>
              <TableCell align="right">${order.totalShippingPriceV2.amount}</TableCell>
              <TableCell align="right">{order.fulfillmentStatus}</TableCell>
              <TableCell align="right">
                <Link href={order.statusUrl} target="_blank">Status</Link> | 
                <Link href={order.customerUrl} target="_blank">Details</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerOrdersTable;
