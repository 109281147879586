import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import OuterContainer from '../../components/ui/OuterContainer';
import PageHeader from '~/components/ui/PageHeader';
import {connect} from 'react-redux';

const styles = (theme) => ({
  background: {
    background: 'white',
  },
  class1: {
    textAlign: 'center',
    fontFamily: 'roboto',
    padding: '4rem 0',
  },
  class2: {
    fontSize: '3rem',
    color: '#9aa200',
    marginTop: 0,
  },
  class3: {
    fontSize: '1.5rem',
    maxWidth: 700,
    margin: '0 auto',
    color: 'black',
  },
});

const EmailVerification = (props) => {
  const {classes} = props;
  const auth = props.firebase.auth.emailVerified;
  return (
    <>
      <PageHeader text="Email Verification" />
      <OuterContainer className={classes.background}>
        {!!auth ? (
          <div className={classes.class1}>
            <h1 className={classes.class2}>Your Account is verified</h1>
            <p className={classes.class3}>Thanks for verifying your account.</p>
          </div>
        ) : (
          <div className={classes.class1}>
            <h1 className={classes.class2}>Verification Email Sent</h1>
            <p className={classes.class3}>
              We are happy you signed up for Visionaire network. To start exploring our Sanctuary store and other
              services. Please verify your email by opening the link the email we just sent. <br />
              Thanks
            </p>
          </div>
        )}
      </OuterContainer>
    </>
  );
};

const mapStateToProps = ({firebase}) => {
  return {firebase};
};
export default withStyles(styles)(connect(mapStateToProps, {})(EmailVerification));
