import React from 'react';
// Styled Component
import {withStyles, Grid} from '@material-ui/core';

import ArticleBox from '../Components/ArticleBox/ArticleBox';
import GuideBox from '../Components/GuideBox/GuideBox';
import SeeWorkShopBox from '../Components/SeeWorkShopBox/SeeWorkShopBox';
import ShareVideoBox from '../Components/ShareVideoBox/ShareVideoBox';
import VideoBox from '../Components/VideoBox/VideoBox';
import QuestionBox from '../Components/QuestionBox/QuestionBox';
import SuspendisBox from '../Components/SuspendisBox/SuspendisBox';
import InitiativesBox from '../Components/InitiativesBox/InitiativesBox';
// Resources
import sampleAvatar from '~/assets/images/page/participate/details/sample_avatar.png';
import shareBtn from '~/assets/images/page/participate/details/share_btn.png';

const styles = (theme) => ({
  suspendisBox: {
    alignSelf: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '1em auto',
    },
  },
  contentQA: {
    background: 'rgba(255,255,255,0.15)',
    border: 'solid 1px rgb(255,255,255)',
    color: 'white',
  },
  contentGuide: {
    background: 'rgba(255,255,255,0.15)',
    border: 'solid 1px rgb(255,255,255)',
    color: 'white',
  },
  qaHeading: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    letterSpacing: '4px',
  },
  shareVideo: {
    alignSelf: 'center',
    padding: '2%',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '2em auto',
    },
  },
});

function SectionContent(props) {
  const {
    classes,
    suspendBtnText,
    questions,
    articleContent,
    textColor,
    themeColor,
    articleBg,
    videoBg,
    btnColor,
    videoDescription,
    videoName,
    guideContent,
    initiativesBg,
    inititativesDes,
  } = props;
  return (
    <div>
      <Grid container spacing={0} alignItems="stretch">
        <Grid item md={3} xs={12} className={classes.suspendisBox}>
          <SuspendisBox btnText={suspendBtnText} />
        </Grid>
        <Grid item md={3} xs={12} className={classes.contentQA}>
          <QuestionBox imagesrc={sampleAvatar} questions={questions} textColor={textColor} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ArticleBox
            articlebBgImage={articleBg}
            articleContent={articleContent}
            articleTitle="LOREM IPSUM"
            isContentTop={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems="stretch">
        <Grid item md={3} xs={12}>
          <VideoBox
            videoBgImage={videoBg}
            btnColor={btnColor}
            videoDescription={videoDescription}
            videoName={videoName}
          />
        </Grid>
        <Grid item md={3} xs={12} className={classes.shareVideo}>
          <ShareVideoBox shareBtn={shareBtn} />
        </Grid>
        <Grid item md={6} xs={12} className={classes.contentGuide}>
          <GuideBox guideContent={guideContent} title="GUIDE" btnText="TAKE ME THERE" />
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems="stretch">
        <Grid item md={9} xs={12}>
          <InitiativesBox initiativesBg={initiativesBg} isTitleLeft={true} description={inititativesDes} />
        </Grid>
        <Grid item md={3} xs={12} className={classes.shareVideo}>
          <SeeWorkShopBox themeColor={themeColor} />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SectionContent);
