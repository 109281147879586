import React, {useEffect} from 'react';

import {connect} from 'react-redux';
import {setBackground} from '~/store/actions';
import homeHeaderBackground from '~/assets/images/layout/SanctuaryStore_home.png';


// Currently not working. Hence disabled from everywhere. Robin also told to avoid using this
function SetBackgroundComponent(props) {
  const {
    setBackground,
    headerBackground = `url(${homeHeaderBackground})`,
    bgHeight = '80px',
    overlayDisplay = 'none',
    overlayBg = 'none',
  } = props;
  useEffect(() => {
    setBackground({
      bg: `url(${headerBackground})`,
      height: bgHeight,
      overlayDisplay: overlayDisplay,
      overlayBg: overlayBg,
    });
    return () => {
      setBackground({
        bg: `url(${homeHeaderBackground})`,
        bgHeight: '80px',
        overlayDisplay: 'none',
        overlayBg: 'none',
      });
    };
  }, [setBackground, headerBackground, bgHeight, overlayBg, overlayDisplay]);

  return <></>;
}

export default connect(null, {setBackground})(SetBackgroundComponent);
