import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Grid,
  Select,
  FormControl,
  Button,
  Typography,
  Avatar,
  TextField,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import PageHeader from '~/components/ui/PageHeader';
import {useTheme} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Image1 from '~/assets/images/page/team/Image1.png';
import Image2 from '~/assets/images/page/prosper/barista.jpg';
import Image3 from '~/assets/images/page/prosper/platting-4282016_1280.jpg';
import Image4 from '~/assets/images/page/prosper/Gemini_Generated_Image (1).jpg';
import Image5 from '~/assets/images/page/prosper/Gemini_Generated_Image.png';
import Image6 from '~/assets/images/page/prosper/front of house.jpg';
import OuterContainer from '../../components/ui/OuterContainer';
const cardData = [
  {
    avatarURL: Image2,
    avatarTitle: 'Barista',
    description: 'An experienced barista/ bar person for our Natural Highs Bar',
  },
  {
    avatarURL: Image3,
    avatarTitle: 'Health chef',
    description: 'An experienced health oriented chef for our Life Force Food',
  },
  {
    avatarURL: Image4,
    avatarTitle: 'Beauty Technician',
    description: 'A beauty technician for all personal care products and services',
  },
  {
    avatarURL: Image5,
    avatarTitle: 'Health facilitator',
    description: 'A health facilitator for our wellness Apps, health testing and literature',
  },
  {
    avatarURL: Image6,
    avatarTitle: 'Front of house',
    description: 'A charismatic front of house person for all customer service and waitering',
  },
];

const styles = (theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
  container: {
    backgroundColor: 'white',
  },
  innerContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  cardContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    maxWidth: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  formContainer: {
    display: 'flex',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  h1: {
    color: '#9aa200',
    fontFamily: 'Montserrat',
    fontWeight: 'Bold',
    fontSize: '3rem',
    textAlign: 'center',
  },
  h2: {
    color: '#9aa200',
    fontFamily: 'Montserrat',
    fontWeight: 'Bold',
    fontSize: '2rem',
  },
  avatar: {
    width: '4em',
    height: '4em',
  },
  formItem: {
    fontFamily: 'Lato',
    maxWidth: '400',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  select: {
    minWidth: '30ch',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },

  ageFormItem: {
    minWidth: '15ch',
  },
  subtitle: {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    color: '#434343',
    fontSize: '1.2rem',
  },
  avatarTitle: {
    fontFamily: 'Lato',
    color: '#434343',
  },
  avatarContainer: {
    border: '2px red solid',
  },
  helperText: {
    fontFamily: 'Lato',
    color: '#434343',
    fontSize: '0.8rem',
  },
  backgroundColor: {
    backgroundColor: 'white',
  },
});
const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9AA200',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#686D03',
    },
  },
}))(Button);

const OutlinedButton = withStyles((theme) => ({
  root: {
    borderColor: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    color: '#9aa200',
    borderWidth: 2,
    '&:hover': {
      borderColor: '#9aa200',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: '#9aa200',
    },
    '&:focus': {
      boxShadow: '#9aa200',
    },
  },
}))(Button);

const Card = (props) => {
  const {classes} = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid item className={classes.cardContainer} container justify={'center'}>
      {props.index === 0 ? (
        <>
          <Grid item xs={false} sm={2} />
          <Grid item container sm={10} alignItems={matches ? 'flex-start' : 'center'} direction="column">
            <Typography className={classes.h2}>Member Information</Typography>
          </Grid>
        </>
      ) : null}
      <Grid item sm={2} className={classes.innerContainer}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Avatar src={props.avatarURL} className={classes.avatar} />
          <Typography className={classes.avatarTitle}>{props.avatarTitle}</Typography>
        </div>
      </Grid>
      <Grid container item sm={10} className={classes.formContainer} direction={'column'}>
        <form noValidate autoComplete="off">
          <Grid
            container
            className={classes.innerContainer}
            item
            spacing={2}
            justify={matches ? 'flex-start' : 'center'}
          >
            <Grid
              item
              className={classes.innerContainer}
              container
              alignItems={matches ? 'flex-start' : 'center'}
              direction="column"
            >
              <Typography className={classes.subtitle}>{props.description}</Typography>
            </Grid>
            <Grid item className={classes.formItem}>
              <TextField id="outlined-basic" fullWidth className={classes.formItem} label="Name" variant="outlined" />
            </Grid>
            <Grid item className={classes.formItem}>
              <FormControl variant="outlined" className={`${classes.ageFormItem} ${classes.select}`}>
                <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Age">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.formItem}>
              <TextField id="outlined-basic" fullWidth className={classes.formItem} label="Email" variant="outlined" />
            </Grid>
            <Grid item className={classes.formItem}>
              <TextField
                id="outlined-basic"
                className={classes.formItem}
                fullWidth
                label="Contact"
                variant="outlined"
              />
            </Grid>
            <Grid container className={classes.innerContainer} item>
              <TextField
                id="outlined-multiline-static"
                label="Experience"
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                helperText="0/200 (Maximum 200 words)"
              />
            </Grid>
            <Grid container item justify={'space-between'}>
              <Grid item>
                <ColorButton
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload CV
                </ColorButton>
              </Grid>
              <Grid item>
                <Typography className={classes.helperText} style={{textAlign: 'left'}}>
                  *Max file size: 3M
                </Typography>
                <Typography className={classes.helperText} style={{textAlign: 'left'}}>
                  *File format supported: MS word doc, PDF
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
function Team(props) {
  const {classes} = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const CardWithStyles = withStyles(styles)(Card);
  return (
    <div>
      <OuterContainer className={classes.backgroundColor}>
        <Grid container spacing={0} className={classes.container} alignItems={'center'} direction="column">
          <Grid item container sm={12} justify={'center'}>
            <Typography className={classes.h1}>Team Submission</Typography>
          </Grid>
          <Grid item className={classes.cardContainer} container justify={'center'}>
            <Grid item xs={false} sm={2} />
            <Grid item container sm={10} alignItems={matches ? 'flex-start' : 'center'} direction="column">
              <Typography className={classes.h2}>Team Information</Typography>
            </Grid>
            <Grid item sm={2} className={classes.innerContainer}>
              <Avatar src={Image1} className={classes.avatar} />
            </Grid>
            <Grid container item sm={10} className={classes.formContainer} direction={'column'}>
              <form noValidate autoComplete="off">
                <Grid
                  container
                  className={classes.innerContainer}
                  item
                  spacing={2}
                  justify={matches ? 'flex-start' : 'center'}
                  alignItems="center"
                >
                  <Grid item className={classes.formItem}>
                    <FormControl variant="outlined" className={classes.select}>
                      <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        // value={age}
                        // onChange={handleChange}
                        label="Location"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Auckland, New Zealand</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.formItem}>
                    <FormControl variant="outlined" className={classes.select}>
                      <InputLabel id="demo-simple-select-outlined-label">Team Leader</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        // value={age}
                        // onChange={handleChange}
                        label="Team Leader"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Separate team leader</MenuItem>
                        <MenuItem value={20}>Appointed from one of the accompanying roles</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container className={classes.innerContainer} item>
                    <TextField
                      id="outlined-multiline-static"
                      label="General Information"
                      multiline
                      fullWidth
                      rows={4}
                      variant="outlined"
                      helperText="0/200 (Maximum 200 words)"
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          {cardData.map((element, index) => {
            return (
              <CardWithStyles
                avatarURL={element.avatarURL}
                avatarTitle={element.avatarTitle}
                description={element.description}
                index={index}
              />
            );
          })}
          <Grid item xs={12} container justify={'center'}>
            {/* <Grid item className={classes.innerContainer}>
              <OutlinedButton variant="outlined">ADVERTISE POSITION</OutlinedButton>
            </Grid> */}
            <Grid item className={classes.innerContainer}>
              <OutlinedButton variant="outlined">SUBMIT</OutlinedButton>
            </Grid>
          </Grid>
        </Grid>
      </OuterContainer>
    </div>
  );
}

export default withStyles(styles)(Team);
