import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import * as serviceWorker from './serviceWorker';
import {createFirestoreInstance, reduxFirestore, getFirestore} from 'redux-firestore';
import {ReactReduxFirebaseProvider, getFirebase} from 'react-redux-firebase';
import 'firebase/firestore';
import reducers from './store/reducers';
import thunk from 'redux-thunk';
import firebase from 'shared/config/firebaseConfig';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter as Router} from 'react-router-dom';

const store = createStore(
  reducers,
  compose(applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})), reduxFirestore(firebase))
);
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const rffProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
const queryClient = new QueryClient();

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactReduxFirebaseProvider {...rffProps}>
          <App />
        </ReactReduxFirebaseProvider>
      </QueryClientProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
