import React from 'react';
import {withStyles} from '@material-ui/core';
import Banner from './Sections/Banner';
import Quote from './Sections/Quote';
import OurGiftPacks from './Sections/OurGiftPacks';
import GiftDescription from './Sections/GiftDescription';
import { useEffect } from 'react';
const styles = (theme) => ({});

function Giftpacks(props) {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div>
      <Banner />
      <Quote />
      <OurGiftPacks />
      <GiftDescription />
    </div>
  );
}

export default withStyles(styles)(Giftpacks);
