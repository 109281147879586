import React, {useState, useEffect, useRef, useContext} from 'react';
import {makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {Grid, Typography, Paper, Button, TextField, MenuItem, Box, Avatar} from '@material-ui/core/';
import FavoriteIcon from '@material-ui/icons/Favorite';
import deliveryImg from '~/assets/images/page/lifeforcefood/detail/delivery.png';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import {useCheckoutId} from '~/hooks/uselocalForage';
import {useAddProductToCart} from 'shared/react-query/hooks';
import {CartOpenContext} from '~/App';
import {EcoPackagedIcon} from '../svg-icons/EcoPackagedIcon';
import {MadeInNZIcon} from '../svg-icons/MadeInNZIcon';
import {CertifiedOrganicIcon} from '../svg-icons/CertifiedOrganicIcon';
import {FunctionalIngredientsIcon} from '../svg-icons/FunctionalIngredientsIcon';
import {GiveForwardIcon} from '../svg-icons/GiveForwardIcon';
import {NaturalIcon} from '../svg-icons/NaturalIcon';
import {NutrientDenseIcon} from '../svg-icons/NutrientDenseIcon';
import {PalmOilFreeIcon} from '../svg-icons/PalmOilFreeIcon';
import {ProteinSourceIcon} from '../svg-icons/ProteinSourceIcon';
import TopIngredients from './TopIngredients';
import BiosphericallyRating from '~/assets/images/biosphericallyRating.png';
import BiosphericallyRatingIcon from './BiosphericallyRatingIcon';
import {PackedInNZIcon} from '../svg-icons/PackedInNZIcon copy';
import {ReuseRecycleSystemIcon} from '../svg-icons/ReuseRecycleSystemIcon';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 440,
      md: 768,
      lg: 950,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  class2: {
    alignItems: 'center',
  },
  productDes: {
    textAlign: 'center',
    margin: '10px 0',
    fontFamily: 'Lato',
    fontSize: '20px',
    color: '#434343',
    fontWeight: '300',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  class0: {display: 'flex', boxSizing: 'border-box'},
  review: {
    padding: '2px 8px',
    margin: '10px 0',
    borderRadius: '4px',
    alignItems: 'center',
    [theme.breakpoints.down('700')]: {
      flexDirection: 'column',
    },
  },
  favIcon: {
    color: '#ec5569',
    cursor: 'pointer',
  },
  reviewDetail: {
    fontFamily: 'Lato',
    margin: '0px 10px',
    fontSize: '16px',
    color: '#434343',
    fontWeight: '500',
  },
  productImg: {
    justifyContent: 'space-between',
  },
  mainImg: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    '& > p': {
      // backgroundColor: 'white',
    },
    '& > img': {
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  subImg: {
    width: '136px',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
  },

  productsBuyInfo: {
    backgroundColor: 'white',
    margin: '20px auto',
    padding: '2%',
    textAlign: 'center',
  },
  prdName: {
    textAlign: 'center',
    fontSize: '42px',
    color: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: '300',
    [theme.breakpoints.down('440')]: {
      fontSize: '32px',
    },
  },
  prdAction: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  smallDevice: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },
  },
  atcBtn: {
    maxWidth: '160px',
    width: '100%',
    color: 'white',
    backgroundColor: '#9aa200',
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    '&:hover': {
      color: '#9aa200',
    },
  },
  viewBtn: {
    maxWidth: '160px',
    width: '100%',
    color: '#9aa200',
    backgroundColor: 'white',
    border: 'solid 1px #9aa200',
    borderRadius: '4px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#9aa200',
    },
  },
  prdPrice: {
    textAlign: 'center',
    fontSize: '24px',
    color: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: '400',
  },

  videoWrapper: {
    width: '80%',
  },
  videoImg: {
    width: '100%',
  },
  blueprint: {
    // border: '1px blue solid',
    margin: 0,
    // padding:10
  },
  class4: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>img': {
      width: '95%',
      objectFit: 'cover',
      height: '95%',
    },
    '&>p': {
      background: 'white',
    },
  },
  class1: {
    display: 'flex',
    gap: '25px',
    overflow: 'auto',
    width: '100%',
    marginTop: 20,
    padding: '0px 24px',
    '& > svg': {
      maxHeight: 80,
      height: 80,
      minWidth: '80px', // minimum width
      minHeight: '80px',
    },
  },
}));
const truncate = (input) => (input.length > 85 ? `${input.substring(0, 85)}...` : input);
function removeDuplicateImages(data) {
  const imageIdSet = new Set(); // Store unique image ids
  const filteredData = []; // Store filtered data

  for (let item of data) {
    let imageId;

    // Determine the correct location of the image id based on the item structure
    if (item.node.image && item.node.image.id) {
      imageId = item.node.image.id;
    } else if (item.node.image && item.node.image.node && item.node.image.node.id) {
      imageId = item.node.image.node.id;
    } else {
      continue; // If no image id is found, skip this item
    }

    // If image id is not in the set, add the item to filtered data and the id to the set
    if (!imageIdSet.has(imageId)) {
      filteredData.push(item);
      imageIdSet.add(imageId);
    }
  }

  return filteredData;
}
export default (props) => {
  const {product, productVariants = []} = props;
  const classes = useStyles();
  const addItemToCartMutation = useAddProductToCart();
  const {checkoutId} = useCheckoutId();
  const {setCartOpen} = useContext(CartOpenContext);
  if (!product && !productVariants.length) {
    return null;
  }
  const productImages = removeDuplicateImages([
    ...productVariants,
    ...(product?.images?.edges?.map((el, index) => ({node: {id: `nonvariant-${index}`, image: el.node}})) ?? []),
  ]);
  const [currentProductVariant, setCurrentProductVariant] = useState(productImages?.[0]?.node?.id);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.productDes} dangerouslySetInnerHTML={{__html: product?.descriptionHtml ?? ''}}></div>
      <div className={clsx(classes.class0, classes.review)}>
        <div className={clsx(classes.class0, classes.class2)}>
          <div className={classes.favIcon} style={{padding: '0 10px'}}>
            <FavoriteIcon />
          </div>
          <span style={{fontSize: '1rem', whiteSpace: 'nowrap'}}>HAPPY CUSTOMER REVIEW</span>
        </div>
        <div className={classes.reviewDetail}>
          {/* truncate({data && data.starReview})  */}
          Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
          <span onClick={props.scrollDown} className={classes.favIcon}>
            {' >> '} See all reviews
          </span>
        </div>
      </div>
      <Box className={classes.class1}>
        {product?.ecopackaged?.value === 'true' && <EcoPackagedIcon />}
        {product?.made_in_nz?.value === 'true' && <MadeInNZIcon />}
        {product?.packed_in_nz?.value === 'true' && <PackedInNZIcon />}
        {product?.reuse_recycle_system?.value === 'true' && <ReuseRecycleSystemIcon />}
        {product?.certified_organic?.value === 'true' && <CertifiedOrganicIcon />}
        {product?.functional_ingredients?.value === 'true' && <FunctionalIngredientsIcon />}
        {product?.give_forward?.value === 'true' && <GiveForwardIcon />}
        {product?.natural?.value === 'true' && <NaturalIcon />}
        {product?.nutrient_dense?.value === 'true' && <NutrientDenseIcon />}
        {product?.palm_oil_free?.value === 'true' && <PalmOilFreeIcon />}
        {product?.protein_source?.value === 'true' && <ProteinSourceIcon />}
      </Box>
      <Grid container xs={12} spacing={2} alignItems="stretch" className={classes.blueprint}>
        <Grid container item xs={12} md={6} spacing={4} style={{aspectRatio: '0.614'}} className={classes.blueprint}>
          <Grid item xs={12} className={clsx(classes.class4, classes.blueprint)} style={{height: '80%'}}>
            {/* <Paper className={clsx(classes.class4, classes.mainImg)} elevation={0}> */}
            <img
              src={productImages?.find((el) => el.node.id === currentProductVariant)?.node?.image?.originalSrc}
              alt="products img"
              style={{width: '100%', maxHeight: '100%', height: '100%'}}
            />
            {/* <Typography variant="body2">{productVariant.title}</Typography> */}
            {/* </Paper> */}
          </Grid>
          {productImages.map(({node}, index) => {
            return (
              <Grid item xs={4} key={node.id} className={clsx(classes.blueprint)} style={{height: '20%'}}>
                <Paper
                  onClick={() => {
                    setCurrentProductVariant(node.id);
                  }}
                  className={classes.class4}
                  style={
                    currentProductVariant == node.id
                      ? {
                          background: 'white',
                        }
                      : {background: 'none'}
                  }
                  elevation={0}
                >
                  <img src={node?.image?.originalSrc} alt={node?.title} key={node?.id} />
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        <Grid container item xs={12} md={6} spacing={4} className={classes.blueprint}>
          <Grid item xs={12} className={clsx(classes.blueprint)}>
            <Paper elevation={0} style={{height: '100%'}}>
              <Grid container xs={12} spacing={4} alignItems="flex-start" className={clsx(classes.blueprint)}>
                <Grid item xs={12} className={clsx(classes.blueprint)}>
                  <Typography style={{color: '#9aa200', fontFamily: 'Lato', textAlign: 'center'}}>
                    {product?.text_above_product_title?.value}
                  </Typography>
                  <Typography className={classes.prdName}>{product.title}</Typography>
                  <Typography style={{color: '#9aa200', fontFamily: 'Lato', textAlign: 'center'}}>
                    {product?.text_below_product_title?.value}
                  </Typography>
                </Grid>
                <Grid container spacing={5} item xs={12} sm={6} md={6} className={classes.blueprint}>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    style={{padding: '10px 0'}}
                    className={classes.blueprint}
                  >
                    <Button
                      size="large"
                      className={classes.atcBtn}
                      onClick={() => {
                        addItemToCartMutation.mutate({
                          cartId: checkoutId,
                          lineItems: {merchandiseId: currentProductVariant, quantity: 1},
                        });
                      }}
                    >
                      ADD TO CART
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    className={classes.blueprint}
                    style={{padding: '10px 0'}}
                  >
                    <Button
                      size="large"
                      className={classes.viewBtn}
                      onClick={() => {
                        setCartOpen(true);
                      }}
                    >
                      VIEW CART
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  spacing={3}
                  className={classes.blueprint}
                  style={{justifyContent: 'space-between'}}
                >
                  <Grid container item xs={12} justify="center" className={classes.blueprint}>
                    <TextField
                      select
                      label="Options"
                      value={
                        currentProductVariant?.split('-')?.at(0) !== 'nonvariant'
                          ? currentProductVariant
                          : productImages?.[0]?.node?.id
                      }
                      onChange={(e) => {
                        setCurrentProductVariant(e.target.value);
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      {productVariants.map(({node}) => (
                        <MenuItem key={node.id} value={node.id}>
                          {node.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} className={classes.blueprint}>
                    <Typography className={classes.prdPrice}>
                      $
                      {
                        productImages?.find(
                          (el) =>
                            el.node.id ===
                            (currentProductVariant?.split('-')?.at(0) !== 'nonvariant'
                              ? currentProductVariant
                              : productImages?.[0]?.node?.id)
                        )?.node.priceV2.amount
                      }
                    </Typography>
                  </Grid>
                  {/* <Grid item className={classes.blueprint} style={{textAlign: 'left'}}>
                    <Typography variant="body2">Returns: </Typography>
                    <Typography variant="body2">Jar. $2.00 worth credits</Typography>
                    <Typography variant="body2">Lid. $1.50 worth credits</Typography>
                    <Typography variant="body2">Label. $1.50 worth credits</Typography>
                  </Grid> */}
                  <Grid item className={classes.blueprint} style={{textAlign: 'right'}}>
                    <BiosphericallyRatingIcon src={BiosphericallyRating} rating={1} />
                  </Grid>

                  {/* <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.blueprint}
                  >
                    <img src={deliveryImg} alt="delivery icon" />
                    <Typography variant="body2">
                      DELIVERY
                      <br /> AVAILABLE
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid container item xs={12} spacing={1} alignItems="stretch" className={classes.blueprint}>
                  {product?.video?.reference?.sources?.[0]?.url && (
                    <Grid item xs={12} style={{textAlign: 'center', justifyContent: 'center'}}>
                      <ReactPlayer
                        style={{maxWidth: '100%', margin: '0 auto'}}
                        url={product?.video?.reference?.sources?.[0]?.url}
                        controls={true}
                      />
                      <p>{product?.video_caption?.value ?? ''}</p>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {product?.top_ingredients?.references?.nodes && (
        <TopIngredients
          ingredients={product?.top_ingredients?.references?.nodes?.map((ingredient) => ({
            name: ingredient?.name?.value,
            img: ingredient.image?.reference?.image?.url,
            to: `/life-force-food-ingredients#${ingredient?.name?.value?.charAt(0)}`,
          }))}
        />
      )}
    </ThemeProvider>
  );
};
