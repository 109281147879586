import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Divider} from '@material-ui/core';
import SubmenuAnimationWrapper from './SubmenuAnimationWrapper';
import OuterContainer from '~/components/ui/OuterContainer';
// import submenuButton from "./helper";

const useStyles = makeStyles((theme) => ({
  class0: {
    width: '100%',
    margin: 0,
    fontFamily: 'Roboto',
  },
  class1: {
    // border: '1px red solid',
  },
  class5: {padding: '10px 0'},
  class2: {
    color: '#8C8C8C',
    fontSize: '24px',
  },
  class3: {
    color: '#595959',
    fontSize: '16px',
    cursor: 'pointer',
  },
  class4: {
    color: '#9AA200',
    fontSize: '13px',
    cursor: 'pointer',
  },
}));

const Submenu1 = (props) => {
  const {submenulvl1Obj, submenulvl2Obj, setsubmenulvl2Obj, historyPush, showFade, setShowFade2} = props;

  const classes = useStyles();

  const mouseEnterLogic = (element) => {
    if (element.hasOwnProperty('submenu')) {
      setsubmenulvl2Obj(element);
      setShowFade2(true);
    }
    if (element.hasOwnProperty('url')) {
      window.location.assign(element.url);
    }
  };

  const renderMenu = (submenu) => {
    return (
      <>
        <Grid container item xs={12} spacing={3} className={classes.class0}>
          {submenu.map((element, index) => (
            <React.Fragment key={element.name + index}>
              <Grid key={element.name + index} item xs className={classes.class1}>
                <Typography className={classes.class2}>
                  {element.name}{' '}
                  <span
                    onClick={() => {
                      historyPush(element.path);
                    }}
                    className={classes.class4}
                  >
                    &gt;&gt; Explore
                  </span>
                </Typography>
              </Grid>
              {index !== submenu.length - 1 && <Grid key={index} item xs={1} className={classes.class1}></Grid>}
            </React.Fragment>
          ))}
        </Grid>
        <Grid container item xs={12} spacing={3} className={classes.class0}>
          {submenu.map((element, index) => {
            return (
              <React.Fragment key={element.name}>
                <Grid container item xs className={classes.class0} key={element.name}>
                  {element.hasOwnProperty('submenu') && (
                    <>
                      <Grid container spacing={1} item className={classes.class0} xs={6}>
                        { 
                          element.head == true
                          ? element.submenu.map((ele) => {
                              if (ele.head == true) {
                                return (
                                  <Grid item xs={12} key={ele.name}>
                                    <Typography
                                      onClick={
                                        ele.noPath
                                          ? () => {
                                              mouseEnterLogic(ele);
                                            }
                                          : () => {
                                            if(ele.url){
                                              window.open(ele.url, '_blank');
                                            }else{
                                              historyPush(ele.path);
                                            }
                                            }
                                      }
                                      className={classes.class3}
                                      style={
                                        submenulvl2Obj !== null && submenulvl2Obj.name == ele.name
                                          ? {color: '#9AA200', textDecoration: 'underline'}
                                          : {}
                                      }
                                      // onMouseEnter={() => mouseEnterLogic(ele)}
                                    >
                                      {ele.name}
                                    </Typography>
                                  </Grid>
                                );
                              }
                            })
                          : null
                        }
                      </Grid>
                      <Grid item xs={6} container justify="center" alignItems="center" className={classes.class0}>
                        <img
                          style={{maxHeight: '121px', objectFit: 'contain'}}
                          src={`${
                            submenulvl2Obj !== null
                              ? element.submenu.find((ele3) => ele3.name == submenulvl2Obj.name)
                                ? element.submenu.find((ele3) => ele3.name == submenulvl2Obj.name).menuThumbnail
                                : element.menuThumbnail
                              : element.menuThumbnail
                          }`}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                {index !== submenu.length - 1 && (
                  <Grid item container xs={1} justify="center">
                    <Divider orientation="vertical" />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </>
    );
  };
  return (
    <SubmenuAnimationWrapper showFade={showFade}>
      {/* {submenulvl1Obj !== null && ( */}
      <OuterContainer className={classes.class5}>
        <Grid container className={classes.class0}>
          {submenulvl1Obj !== null && renderMenu(submenulvl1Obj.submenu)}
        </Grid>
      </OuterContainer>
      {/* )} */}
    </SubmenuAnimationWrapper>
  );
};

export default Submenu1;
