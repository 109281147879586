import React, {useRef, useEffect} from 'react';
import BannerCardSection from './Sections/BannerCardSection';
import SignUpSection from './Sections/SignUpSection';
import StoreLinksSection from './Sections/StoreLinksSection';
import BannerSection from '../discover/Sections/BannerSection';
import DiscoverSection from './Sections/DiscoverSection';
import MapCard from '~/components/ui/MapCard';
import PersonSection from './Sections/PersonSection';
import PlatoSection from './Sections/PlatoSection';
import PeopleData from './Sections/peopleData';
import PeopleSection from 'shared/sections/peopleSection';
import {useSelector} from 'react-redux';
import Disclaimer from '../healthGuides/Sections/Disclaimer';
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);

export default function Home(props) {
  const myRef = useRef(null);
  const {history} = props;
  const handleClick = (index) => {
    window.open('https://vfhurbansanctuary.com/discover#healthcreator', '_blank')
  };
  const auth = useSelector(({firebase}) => firebase.auth);

  return (
    <>
      <BannerCardSection
        scrollDown={() => {
          scrollToRef(myRef);
        }}
      />
      <div ref={myRef}></div>
      {auth.isEmpty && <SignUpSection />}
      <StoreLinksSection />
      <DiscoverSection />
      <BannerSection handleClick={handleClick} />
      <PersonSection />
      <MapCard />
      <PlatoSection />
      <PeopleSection peopleData={PeopleData} />
      <Disclaimer />
    </>
  );
}
