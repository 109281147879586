import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import AnimationWrapper from '~/components/ui/AnimationWrapper';
import CardItem from './CardItem';

const styles = (theme) => ({
  root: {},
});

function CardGrid({data}) {
const [activeIndex,setActiveIndex] = useState(null)
  return (
    <Grid container spacing={4}>
      {data?.map((item, index) => (
        <AnimationWrapper key={item.name.value} index={index} timeOut={400}>
          <Grid item md={4} sm={6} xs={12}>
            <CardItem
              cardData={item}
              isExpand={activeIndex === item.name.value}
              onClickExpand={() => {
                if (activeIndex === item.name.value) {
                  setActiveIndex('');
                } else {
                  setActiveIndex(item.name.value);
                }
              }}
            />
          </Grid>
        </AnimationWrapper>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(CardGrid);
