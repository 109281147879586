import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core/';

const styles = (theme) => ({});

const OutlinedButton = withStyles((theme) => ({
  root: {
    marginTop: '1rem',
    borderColor: '#9aa200',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    color: '#9aa200',
    borderWidth: 2,
    '&:hover': {
      borderColor: '#9aa200',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: '#9aa200',
    },
    '&:focus': {
      boxShadow: '#9aa200',
    },
  },
}))(Button);

const Section5 = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        boxSizing: 'border-box',
        padding: 20,
        flex: 1,
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        color: '#9aa200',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{flex: 1, textAlign: 'center'}}>
        Create a life of limitless potential with an Optimize epigenetic report and free your genes today?
      </div>
      <OutlinedButton variant="outlined">Find a Store</OutlinedButton>
    </div>
  );
};

export default withStyles(styles)(Section5);
