import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PageHeader from '~/components/ui/PageHeader';
import TabSwitch from './Sections/TabSwitch';
import {Box, Container, Grid} from '@material-ui/core';
import FindStoreSearch from './Sections/FindStoreSearch';
import OpenStoreSearch from './Sections/OpenStoreSearch';
import StoreTypeBox from '~/components/ui/StoreTypeBox';
import ImageMap from '~/assets/images/page/findastore/map.png';

const styles = (theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
  searchContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  map: {
    width: '100%',
  },
});

const Tabs = [
  {
    text: 'Find A Store',
  },
  {
    text: 'Open A Store',
  },
];

function FindaStore(props) {
  const {classes} = props;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={classes.root}>
      <PageHeader text="OUR STORES" />
      <Box bgcolor="white">
        <Container maxWidth="lg">
          <Box pb={3}>
            <TabSwitch
              items={Tabs}
              selectedIndex={activeTab}
              onSelectIndex={(index) => {
                setActiveTab(index);
              }}
            />
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Box position="relative" height={1}>
                  <Box position="absolute" className={classes.searchContainer} left={0} right={0} top={0} bottom={0}>
                    {activeTab === 0 ? (
                      <FindStoreSearch style={{height: '100%'}} />
                    ) : (
                      <OpenStoreSearch style={{height: '100%'}} />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <img src={ImageMap} className={classes.map} alt="map" />
              </Grid>
            </Grid>
          </Box>
        </Container>
        {activeTab === 0 && (
          <Box mb={3}>
            <StoreTypeBox />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default withStyles(styles)(FindaStore);
