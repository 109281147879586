import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, CardMedia, Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import Image1 from '~/assets/images/page/lifeIngredients/show1.png';
import Image2 from '~/assets/images/page/lifeIngredients/show2.png';
import Image3 from '~/assets/images/page/lifeIngredients/show3.png';
import Image4 from '~/assets/images/page/lifeIngredients/show4.png';
import Image5 from '~/assets/images/page/lifeIngredients/show5.png';
import Image6 from '~/assets/images/page/lifeIngredients/show6.png';

const styles = (theme) => ({
  root: {},
  image: {
    height: 0,
    paddingTop: '62%',
  },
});

const DataList = [
  {
    title: 'IMMUNE SYSTEM',
    image: Image4,
    path: '/shop-by-health-interest/health-protocols/immune-system',
  },
  {
    title: 'BRAIN HEALTH',
    image: Image3,
    path: '/shop-by-health-interest/health-protocols/brain-health',
  },
  {
    title: 'HEART HEALTH',
    image: Image2,
    path: '/shop-by-health-interest/health-protocols/heart-health',
  },
  {
    title: 'CELLULAR HEALTH',
    path: '/shop-by-health-interest/health-protocols/cellular-health',
    image: Image1,
  },
  {
    title: 'BONE AND CARTILAGE HEALTH',
    image: Image6,
    path: '/shop-by-health-interest/health-protocols/bone-and-cartilage-health',
  },
  {
    title: 'INCREASED HEALTH SPAN',
    image: Image5,
    path: '/shop-by-health-interest/health-protocols/increased-health-span',
  },
];

function ImageGallary(props) {
  const {classes} = props;
  const history = useHistory();

  return (
    <Grid
      container
      style={{
        width: 'calc(100% + 2px)',
        margin: '-1px',
      }}
    >
      {DataList.map((item) => (
        <Grid
          item
          // md={2}
          sm={2}
          xs={6}
          key={item.title}
          style={{
            padding: '1px',
            cursor:'pointer'
          }}
          onClick={()=>history.push(item.path)}
        >
          <Box position="relative">
            <CardMedia className={classes.image} image={item.image} />
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#fff"
              top="0px"
              px="20px"
              bgcolor="rgba(0, 0, 0, 0.5)"
              boxSizing="border-box"
              fontWeight="fontWeightBold"
              fontSize="subtitle2.fontSize"
              textAlign="center"
              width={1}
              height={1}
            >
              {item.title}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(ImageGallary);
