import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Typography, Box} from '@material-ui/core';
import SectionTitle from '~/components/ui/SectionTitle';
import ImageBg from '~/assets/images/page/discover/section5-bg.png';

const DataList = [
  {
    title: 'Nutrient and Toxin profiling',
    desc: 'Map your existing nutritional intake and toxin exposure by using your Metawheel data to populate:',
    list: [
      'Your Original Food and Drink Pyramids of your Health Creator App. Examine the nutritional value of your current eating habits, gain insight into how your current choices are contributing to your current health state. ',
      'Evaluate your general toxin exposure from fast and processed foods, commercial products, digital devices, the environment, home, work place. Toxic thoughts are now also being scientifically linked to ill health.',
    ],
  },
  {
    title: 'Sanctuary Nutrition and Toxin Elimination',
    list: [
      'Engage the Sanctuary Food and Drink Pyramids as a first step guide to better food and drink choices. See one of our Sanctuary nutritionists to further define the best eating choices for you. See one of our Health Professionals to add a Sanctuary Health Protocol to your plan.',
      ' Select from our range of Vision Biospherically Correct natural life style products to introduce pure, toxin free, personal care products into your life.  ',
    ],
  },
  {
    title: 'Maximum Nutrition / Zero Toxins / Healthy Micro biome',
    list: [
      'It’s not just what you eat; it’s also what you put on your face and body to wash and moisture, and what digital, household and environmental toxins you are exposed to.',
      'Our aim is to give you the maximum nutrition possible from whole foods in order for your body to create its own health in an environment of minimal toxicity. ',
      'Most ill-health can be traced back to the combination of toxin overload and nutrient deficiency. Disturbing your gut micro biome and weakening the excellence of the immune system, starving the human body of its natural resilience, disrupting homeostasis, and opening the door to the incubation of disease. ',
      'The Sanctuary focuses on the most nutrient dense, wild harvested, permaculture based, organic, spray free and natural ingredients for all of its food, drink, skin and hair care products. ',
    ],
  },
];

const styles = (theme) => ({
  root: {
    background: `url(${ImageBg})`,
    backgroundSize: 'cover',
    padding: '5%',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '5% 0',
    },
  },
  itemBox: {
    marginTop: '15px',
    color: '#707070',
  },
  detailTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    lineHeight: '1.5',
  },
  detailDesc: {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  dot: {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '5px',
    background: '#8DBF4C',
    marginRight: '5px',
  },
  subItem: {
    display: 'inline',
    fontSize: '0.9rem',
    lineHeight: '1.6',
  },
});

function NutrientToxinSection(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box width="100%" display="flex" justifyContent="center">
          <Box width="80%">
            <SectionTitle text="Nutrient and Toxin profiling" />
            {DataList.map((item) => (
              <div className={classes.itemBox} key={item.title}>
                <Typography variant="body2" className={classes.detailTitle}>
                  {item.title}
                </Typography>
                {item.desc && (
                  <Typography variant="body2" className={classes.detailDesc}>
                    {item.desc}
                  </Typography>
                )}
                {item.list.map((subItem) => (
                  <div className={classes.detailItem} key={subItem}>
                    <span className={classes.dot} />
                    <Typography variant="body2" className={classes.subItem}>
                      {subItem}
                    </Typography>
                  </div>
                ))}
              </div>
            ))}
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default withStyles(styles)(NutrientToxinSection);
