import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Image1 from '~/assets/images/page/inbodyscanning/newmain06Global@3x.png';

const styles = (theme) => ({
  class1: {
    width: '100%',
    backgroundColor: 'rgb(245,246,233)',
    '& > div': {
      display: 'flex',
      maxWidth: 1600,
      padding: '5% 2%',
      margin: '0 auto',
      justifyContent: 'space-between',
      [theme.breakpoints.down(1024)]: {
        flexDirection: 'column',
      },
    },
  },
  class2: {
    flexBasis: '50%',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  class3: {
    flexBasis: '45%',
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    color: '#8c8c8c',
  },
});

const Section2 = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class1}>
      <div>
        <p className={classes.class3}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
          purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
          varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui
          arcu, dapibus eu turpis sed, aliquet tincidunt enim.
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
          purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
          varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui
          arcu, dapibus eu turpis sed, aliquet tincidunt enim.
        </p>
        <div className={classes.class2}>
          <img src={Image1} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Section2);
