import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import scanningImage from '~/assets/images/page/3dscanning/scanning_small.png';
import mobileImage from '~/assets/images/page/3dscanning/3dscanning_mobile.png';

const styles = (theme) => ({
  root: {
    paddingTop: '30px',
  },
  scanningImage: {
    maxHeight: '500px',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  title: {
    fontFamily: 'Arial',
    fontWeight: 'bold',
    margin: '0px 0px 15px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
  heroText: {
    color: 'rgba(240, 240, 238, 0.5)',
    margin: '35px 0px 15px 0px',
    maxWidth: '430px',
  },
  bottomText: {
    fontSize: '12px',
    color: '#ffffff',
    maxWidth: '350px',
    paddingTop: '60px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
  mobileImageWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  mobileImage: {
    maxWidth: '80%',
    paddingLeft: '10px',
    
  }
});

function HeroSectionData(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={5}>
          <img src={scanningImage} className={classes.scanningImage} alt="Scanning" />
        </Grid>
        <Grid item md={7}>
          <Typography variant="h5" className={classes.title}>
            VISUALLY SHOW PROGRESS
          </Typography>
          <Typography variant="body1">Giant stars have a much lower surface gravity</Typography>
          <Typography variant="h4" className={classes.heroText}>
            Diverted and concentrated by drains that create constant flows over the beach.
          </Typography>
          <Grid item container >
            <Grid item md={6}>
                <Typography variant="body1" className={classes.bottomText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
                purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
                varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin
                dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.mobileImageWrapper}>
              <img src={mobileImage} className={classes.mobileImage} alt="Scanning" />
            </Grid>

          </Grid>
          
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(HeroSectionData);
