import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Container, Box, Typography} from '@material-ui/core';
import guideIntro from '~/assets/images/page/healthGuides/guideIntro.png';

const styles = (theme) => ({
  root: {
    color: '#343434',
    margin: '4% auto 2%',
  },
  leftSection: {
    background: '#f2f2f2',
    borderRadius: '8px',
  },
  imageBox: {
    width: '100%',
    height: 400,
    position: 'relative',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  guideTitle: {
    textTransform: 'uppercase',
    fontSize: '20px',
    color: '#595959',
  },
  content: {
    fontSize: '16px',
    color: '#343434',
    fontWeight: '300',
    margin: '2% auto',
    whiteSpace: 'pre-line',
  },
  imgTitle: {
    position: 'absolute',
    top: '40%',
    whiteSpace: 'nowrap',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'lavenderblush',
    fontSize: '24px',
    fontFamily: 'Georgia',
  },
  imgSubtitle: {
    position: 'absolute',
    top: '60%',
    whiteSpace: 'nowrap',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'rgba(255,255,255,1)',
    fontSize: '16px',
    fontFamily: 'Roboto',
  },
});

function HealthProtocolsPhilosophy(props) {
  const {classes} = props;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} className={classes.leftSection}>
          <Box p={3}>
            <Box textAlign="center" mb={1}>
              <Typography variant="h6" className={classes.guideTitle}>
                Health Protocols Philosophy
              </Typography>
            </Box>
            <Typography className={classes.content}>
              In short, our Health Protocols represent essential knowledge, understanding and potential actions to aid
              your health and healing. {'\n'} They are systems of simple yet fundamental daily health habits, combined
              to give you every opportunity to improve your particular situation in the least invasive and most natural
              way.{'\n'} Using natural remedies, nutraceuticals, targeted exercises, therapies, positive psychology,
              nutritional optimisation and specific health habits to benefit you. Each Protocol is geared to one of 6
              primary health concerns, most common to humanity. We further refine the chosen Protocol to your needs, by
              first completing a personal consultation with you to understand the background and context of your health,
              and your existing habits, to then create a custom plan based on your age, ability and situation.{'\n'} Our
              Protocols have been carefully designed to focus on the most proactive and fundamental adaptions and
              actions you may choose to make to naturally fortify, heal, repair, strengthen, and cleanse in order to
              help improve your health.{'\n'} Our six Protocols are catered towards the most prevalent aliments
              occurring globally. They are not medical advice or services (refer to our medical disclaimer), however
              they are comprised of the mostly universally acknowledged fundamentals and principles that have been
              either researched, reported or accepted within health circles to potentially help improve certain health
              conditions.{'\n'} We approach all Protocols in a comprehensive Mind- Body– Biosphere manner to advance the
              whole you; your Self Protecting and Healing Mind-Body System (SPHMBS).{'\n'} Looking at the whole person
              and considering all beneficial actions, co-ordinated to work in unison, can have a powerful effect on
              human health. Establishing a proactive health Protocol by way of a solid daily routine can enable
              rewarding benefits that pay off short and long term. Ultimately leading to what we promote as a
              Self-Advancing Lifestyle (SAL).{'\n'} Our Protocols represent the basic health building blocks which
              should be addressed and engaged as a pre-requisite to maintain optimum health, prevent ill- health and as
              a support to aid healing – whether used alone or conjunction with modern medicine.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(HealthProtocolsPhilosophy);
