import axios from 'axios';
import {EMPTYMEALPLANNER, EMPTYCART} from '../action-types';
import config from 'shared/config/config';
import {axiosPost} from '~/utils/axiosMethods';
const userEndPoint = `${config.apiURL}/user`;

export const signup = (obj) => {
  return () =>
    axiosPost(`${userEndPoint}/createUser`, {
      first_name: obj.firstName,
      last_name: obj.lastName,
      email_address: obj.email,
      password: obj.password,
      more_details: {
        country: '1',
      },
    })
      .then(function (response) {
        return 'Thanks! Account has been created.';
      })
      .catch(function (error) {
        throw JSON.stringify(error.message);
      });
};

export const signin = (obj) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    // dispatch(push('/'));
    const firebase = getFirebase();
    return axiosPost(`${userEndPoint}/loginUser`, {
      email_address: obj.email,
      password: obj.password,
    })
      .then((response) => {
        return firebase.auth().signInWithCustomToken(response.data.idToken);
      })
      .catch((error) => {
        throw JSON.stringify(error.message);
      });
  };
};

export const getShopifyAccessToken = () => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    return firestore
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        return doc.data().customerAccessTokenShopify;
      });
  };
};

// export const resendVerificationEmail = () => {
//   return (dispatch, getState, {getFirebase, getFirestore}) => {
//     const firebase = getFirebase();
//     return firebase
//       .auth()
//       .currentUser.sendEmailVerification({
//         url: 'http://localhost:3000/emailVerification',
//         // handleCodeInApp: 'true',
//       })
//       .then(function () {
//         console.log('email sent');
//       });
//   };
// };

export const logout = () => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({type: EMPTYCART});
        dispatch({type: EMPTYMEALPLANNER});
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const resetPassword = (obj) => {
  return (dispatch, getState, {getFirebase, getFireStore}) => {
    const firebase = getFirebase();
    var emailAddress = firebase.auth().email;
    return firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, obj.currentPassword)
      .then((res) => {
        return firebase
          .auth()
          .currentUser.updatePassword(obj.newPassword)
          .then(function () {
            console.log('success');
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
// export const getProfile = () => {
//   return (dispatch, getState, {getFirebase}) => {
//     const profile = getState().firebase.profile;
//     return console.log(profile);
//     // return console.log(getFirebase().auth().currentUser);
//   };
// };

// export const login = () => {
//   return (dispatch, getState, {getFirebase}) => {
//     const firebase = getFirebase();
//     return firebase
//       .login({
//         email: 'test@test.com',
//         password: 'testest1',
//       })
//       .then((res) => {
//         console.log(res, 'res');
//       });
//   };
// };

// export const sendOtp = (obj) => {
//   return (dispatch) => {
//     dispatch({type: LOADING_STATUS, payload: true});
//     return postFetch(obj, 'sendOtp')
//       .then((res) => {
//         dispatch({type: LOADING_STATUS, payload: false});
//         return res;
//       })
//       .catch((error) => errorHandle(error, dispatch));
//   };
// };

// export const verifyOTP = (obj) => {
//   return (dispatch) => {
//     dispatch({type: LOADING_STATUS, payload: true});
//     return postFetch(obj, 'verifyOtp')
//       .then((res) => {
//         dispatch({type: LOADING_STATUS, payload: false});
//         return res;
//       })
//       .catch((error) => errorHandle(error, dispatch));
//   };
// };

// export const logout = () => {
//   return (dispatch) => {
//     return getSInfo_AccessToken().then((token) => {
//       return getFetch('logout', token).then((res) => {
//         dispatch({type: LOADING_STATUS, payload: false});
//         return res;
//       });
//     });
//   };
// };

// export const login = (obj) => {
//   return (dispatch) => {
//     dispatch({type: LOADING_STATUS, payload: true});
//     return postFetch(obj, 'mlogin')
//       .then((res) => {
//         return setSInfo_AccessToken(res.access_token).then(() => dispatch({type: LOADING_STATUS, payload: false}));
//       })
//       .catch((error) => {
//         if (error.message) {
//           dispatch({type: LOADING_STATUS, payload: false});
//           throw error.message;
//         } else {
//           errorHandle(error, dispatch);
//         }
//       });
//   };
// };

// export const statusLogin = (dispatch, msg) => {
//   dispatch({type: STATUS_LOGIN, payload: msg});
// };

// export const statusSignup = (dispatch, msg) => {
//   dispatch({type: STATUS_SIGNUP, payload: msg});
// };

// export const mResetPwd = (obj, token) => {
//   return (dispatch) => {
//     dispatch({type: LOADING_STATUS, payload: true});
//     return postFetch(obj, 'mresetPwd', token)
//       .then((res) => {
//         dispatch({type: LOADING_STATUS, payload: false});
//         return res;
//       })
//       .catch((error) => errorHandle(error, dispatch));
//   };
// };
