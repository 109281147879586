import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Image2 from '~/assets/images/page/techtesting/wx202010071635432X@3x.png';
import {Link} from 'react-router-dom';
const styles = (theme) => ({
  class1: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    flexDirection: 'column',
    marginTop: 50,
    [theme.breakpoints.down(1024)]: {
      marginTop: 27,
    },
  },
  class2: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    color: 'black',
    backgroundColor: '#f2f2f2',
    color: '#8c8c8c',
    fontFamily: 'Roboto',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& >img': {
      maxWidth: '50%',
      maxHeight: '80%',
      marginTop: '-20%',
      position: 'relative',
      [theme.breakpoints.down(1024)]: {
        marginTop: '-20%',
        maxWidth: '100%',
      },
    },
    '& >div': {
      paddingTop: '3vw',
      paddingRight: '1vw',
      fontSize: '1vw',
      [theme.breakpoints.down(1024)]: {
        padding: ' 0 2vw 3vw 2vw',
        fontSize: '1rem',
      },
    },
  },
  class3: {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down(1024)]: {
      justifyContent: 'flex-start',
    },
    '& a': {
      textDecoration: 'none',
      color: '#8c8c8c',
    },
    '& > div': {
      display: 'flex',
      boxSizing: 'border-box',
      flexBasis: '50%',
      height: '18vw',
      alignItems: 'center',
      [theme.breakpoints.down(1024)]: {
        justifyContent: 'flex-start',
        flexBasis: '100%',
        height: '37vw',
        maxHeight: 234,
        alignItems: 'flex-start',
        padding: '0 3vw',
      },
    },
    '& > div > div': {
      borderLeft: '0.6vw solid #8c8c8c',
      color: '#8c8c8c',
      fontFamily: 'Roboto',
      fontSize: '2vw',
      padding: '1vw 1vw',
      fontWeight: 500,
      [theme.breakpoints.down(1024)]: {
        borderLeft: '0.3em solid #8c8c8c',
        fontSize: '1.4rem',
        padding: '.5em',
        marginLeft: '0.5em',
      },
    },
  },
});

const Section3 = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class1}>
      <div className={classes.class3}>
        <div>
          <div>
            <Link to={'/productandservice/techtesting/inbodyscanning'}>INBODY SCANNING</Link>
          </div>
        </div>
      </div>
      <div className={classes.class2}>
        <img src={Image2} />
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
          purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
          varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.
          {'\n'}
          {'\n'}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
          commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a
          dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros.{' '}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Section3);
