import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddShoppingCartIcon from '~/assets/images/page/lifeforcefood/cartIcon.png';
import BookmarkIcon from '~/assets/images/page/lifeforcefood/bookmarkIcon.png';
import BasketIcon from '~/assets/images/page/lifeforcefood/basketIcon.svg';
import clsx from 'clsx';
import {getPath} from '~/static/routes';

const styles = (theme) => ({
  root: {
    border: 'solid 1px #9aa200',
    height: '100%',
  },
  cardMedia: {
    // height: '300px',
    // [theme.breakpoints.down('sm')]: {
    //   height: '160px',
    // },
  },
  price: {
    color: '#9aa200',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
  },
  addtocart: {
    color: '#9aa200',
    padding: 0,
    width: '100%',
    minWidth: 0,
    height: '100%',
  },
  sortby: {
    textAlign: 'center',
    marginTop: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '1rem',
    padding: '3px',
    fontWeight: '600',
    width: 'max-content',
    border: '1px black dashed',
  },
  class1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
  },
  class2: {
    padding: '10px 10px 0px 10px',
  },
});

function ProductCard(props) {
  const {data, history, classes, sortBy, onClickAddToCart, navigateTo} = props;
  const truncate = (input) => (input.length > 85 ? `${input.substring(0, 85)}...` : input);
  return (
    <Card className={clsx(classes.root, classes.class1)}>
      <CardActionArea
        onClick={() => {
          history.push(navigateTo);
        }}
        className={classes.class1}
      >
        <CardMedia
          component="img"
          alt="product image"
          // height="300"
          image={data.image}
          title="Product image"
          className={classes.cardMedia}
        />
        <CardContent className={clsx(classes.class1, classes.class2)}>
          {data?.text_above_product_title && (
            <Typography style={{color: '#9aa200', fontSize:13, fontFamily: 'Lato', textAlign: 'center'}}>
              {data?.text_above_product_title}
            </Typography>
          )}
          <Typography variant="h6" component="h6" style={{textAlign:'center'}}>
            {data.name}
          </Typography>
          {data?.text_below_product_title && (
            <Typography style={{color: '#9aa200', fontSize:13, fontFamily: 'Lato', textAlign: 'center'}}>
              {data?.text_below_product_title}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary" component="p">
            <div dangerouslySetInnerHTML={{__html: truncate(data.descriptionHtml ?? '')}}></div>
          </Typography>
          {sortBy ? (
            <div className={classes.sortby}>
              {sortBy.key}: {sortBy.value}
            </div>
          ) : null}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container xs={12} style={{margin: 0}} spacing={1}>
          <Grid item xs={6}>
            <Typography className={classes.price}>${data.price}</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Button fullWidth size="small" color="primary" style={{fontSize: 12}} className={classes.addtocart}>
              <img src={BookmarkIcon} style={{height: '1.3rem', marginTop: 2}} />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              size="small"
              color="primary"
              style={{fontSize: 12}}
              className={classes.addtocart}
              onClick={onClickAddToMealPlan}
            >
              <img src={BasketIcon} style={{height: '1.2rem'}} />
            </Button>
          </Grid> */}
          <Grid item xs={6}>
            <Button fullWidth size="small" color="primary" className={classes.addtocart} onClick={onClickAddToCart}>
              <img src={AddShoppingCartIcon} style={{height: '1.4rem'}} />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default withRouter(withStyles(styles)(ProductCard));
