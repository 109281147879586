import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
const styles = (theme) => ({
  root: {
    color: 'black',
    // color: 'white',
    height: 160,
    fontSize: '32px',
    fontWeight: '500',
    marginTop: '56px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      height: 60,
    },
  },
  subtitle: {
    marginTop: 20,
    fontSize: 14,
    fontWeight: '400',
  },
});

function PageHeader(props) {
  const {classes, text, subtitle, style} = props;

  return (
    <Grid container justify="center" alignItems="center" className={classes.root} style={style}>
      <div>
        <div>{text}</div>
        {!!subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </div>
    </Grid>
  );
}

export default withStyles(styles)(PageHeader);
