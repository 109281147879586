import localforage from 'localforage';
import {useEffect, useState} from 'react';
import {useCreateCart, useFetchCart} from 'shared/react-query/hooks';

export const useCheckoutId = () => {
  const [cartId, setCartId] = useState(null);

  const {data: cartData, error} = useFetchCart({id: cartId});

  const createCartMutation = useCreateCart();

  const getData = async (key) => {
    const data = await localforage.getItem(key);
    return data;
  };

  const setData = async (value) => {
    await localforage.setItem('cartId', value);
  };
  useEffect(() => {
    async function fetchData() {
      const storedCartId = await getData('cartId');
      if (storedCartId == null) {
        createCartMutation.mutateAsync().then((data) => {
          setData(data.cartCreate.cart.id);
          setCartId(data.cartCreate.cart.id);
        });
      } else {
        setCartId(storedCartId);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (error || (cartData && !cartData.cart)) {
      createCartMutation.mutateAsync().then((data) => {
        setData(data.cartCreate.cart.id);
        setCartId(data.cartCreate.cart.id);
      });
    }
  }, [error, cartData]);

  return {checkoutId: cartId};
};
