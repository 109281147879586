import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Box} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {},
  rowContainer: {
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  itemNormal: {
    color: 'rgb(39,39,39)',
    background: 'white',
    padding: '3px 8px 6px 8px',
    useSelection: 'none',
  },
  itemClickable: {
    cursor: 'pointer',
    '&:active': {
      background: '#9AA200',
      color: 'white',
    },
  },
  itemActive: {
    color: 'white',
    background: '#9AA200',
  },
});

const Data = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

function SelectionRow(props) {
  const {classes, selectedItem} = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.rowContainer}>
        {Data.map((oneItem) => (
          <Grid
            item
            key={oneItem}
            className={clsx(classes.itemNormal, {
              [classes.itemClickable]: true,
              [classes.itemActive]: selectedItem.toLowerCase() === oneItem.toLowerCase(),
            })}
            onClick={() => {
              window.location.hash = oneItem;
            }}
          >
            <Box fontWeight="fontWeightBold" fontSize="subtitle1.fontSize">
              {oneItem}
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SelectionRow);
