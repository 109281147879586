import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import awardbg from '~/assets/images/page/3dscanning/Fit3d-compare-U2.png';
import trackbg from '~/assets/images/page/3dscanning/overlay-hybrid-double.png';
import posturebg from '~/assets/images/page/3dscanning/Fit3D+Posture+Analysis.png';
import ReadMoreComponent from '~/components/ui/ReadMoreComponent';

const styles = (theme) => ({
  thumbnailWrapper: {
    margin: '60px 0',
  },
});

function ThumbNailCardSection(props) {
  const {classes} = props;
  return (
    <div className={classes.thumbnailWrapper}>
      <Grid container style={{width: '100%', margin: 0}} spacing={1}>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={awardbg} text="3D Avatars & Reports" />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={trackbg} text="Track Weight Loss, Body Comp, & Posture" />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <ReadMoreComponent imageSrc={posturebg} text="Posture & Balance" />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ThumbNailCardSection);
