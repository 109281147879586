import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import Image from '~/assets/images/page/discover/section8-image.png';
import ImageBg from '~/assets/images/page/discover/section7-bg.png';
import Carousel from './Carousel';
import Instruction2Image from '~/assets/images/page/weight-shifter/howto_2.png';
import Instruction3Image from '~/assets/images/page/weight-shifter/howto_3.png';
import Instruction4Image from '~/assets/images/page/weight-shifter/howto_4.png';

const Items = [
  {id: 1, image: Image},
  {id: 2, image: ImageBg},
  {id: 3, image: Instruction2Image},
  {id: 4, image: Instruction3Image},
  {id: 5, image: Instruction4Image},
];

const styles = (theme) => ({
  root: {padding: '30px 6%'},
  title: {
    textAlign: 'center',
    color: '#B5B5B5',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
});

function Index(props) {
  const {classes, items = Items} = props;

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        A Quick look in to the Health Creator App
      </Typography>
      <Carousel items={items} />
    </div>
  );
}

export default withStyles(styles)(Index);
