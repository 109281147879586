import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FactorsSection from './FactorsSection';
import Section3 from './Section3';
import BannerSection from './BannerSection';
import ThumbNailCardSection from './ThumbNailCardSection';
import RelevantProducts from './RelevantProducts';
import BookConsultantCard from './BookConsultantCard';
import ProtocolElementSection from './ProtocolElementSection';
import HealthPsychology from './HealthPsychology';
import ProtocolsCard from './ProtocolsCard';
import HealthDisclaimer from './HealthDisclaimer';

const styles = (theme) => ({
  root: {},
  image: {
    maxWidth: '400px',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  cardContent: {
    height: '100%',
    paddingTop: '50%',
    paddingBottom: '10px',
  },
  cardDesc: {
    fontWeight: 'bold',
  },
  cardButton: {
    marginTop: '10px',
  },
});

function HealthProtocol(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.root}>
      <FactorsSection pageData={pageData} />
      <Section3 pageData={pageData} />
      <BannerSection pageData={pageData} />
      <ProtocolElementSection pageData={pageData} />
      <ThumbNailCardSection pageData={pageData} />
      <RelevantProducts />
      <BookConsultantCard pageData={pageData} />
      <HealthPsychology pageData={pageData} />
      <ProtocolsCard />
      <HealthDisclaimer />
    </div>
  );
}

export default withStyles(styles)(HealthProtocol);
