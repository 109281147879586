import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import ProductInfo from './Sections/ProductInfo';
import Nutritional from './Sections/Nutritional';
import Reviews from './Sections/Reviews';
import OtherProducts from './Sections/OtherProducts';
import {LifeForceSectionDataList} from '~/data';
import LifeForceComponent from '~/components/ui/LifeForceComponent';
import Status from '~/components/ui/Status';
import {statusObj} from '~/utils/commonFunc';
import {makeStyles} from '@material-ui/core/styles';
import OuterContainer from '~/components/ui/OuterContainer';
import {useFetchProduct} from 'shared/react-query/hooks';
import {FoodTabs} from './Sections/FoodTabs';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '100px',

    background: 'rgba(24,24,24,0.5)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
    },
  },
  graySection: {
    padding: '0 10px',
    backgroundColor: '#f6f6f6',
    color: 'black',
  },

  whiteSection: {
    backgroundColor: '#fff',
    paddingTop: '40px',
  },
}));

function ProductDetail(props) {
  const classes = useStyles();
  const location = useLocation();

  const getLastSegment = (pathname) => {
    const pathSegments = pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  const {data: productData, isLoading} = useFetchProduct({
    handle: getLastSegment(location.pathname),
  });

  return (
    <Status statusObj={isLoading ? statusObj('loading') : statusObj('done')}>
      {productData?.productByHandle === null ? <h1 style={{textAlign:'center'}}>Product not found</h1> :(
        <OuterContainer className={classes.graySection}>
          <ProductInfo
            product={productData?.productByHandle}
            productVariants={productData?.productByHandle?.variants?.edges}
          />
          <FoodTabs product={productData?.productByHandle} />
          {/* <MyMeals /> */}
          <Reviews />
          <OtherProducts id={productData?.productByHandle?.id} />
          <LifeForceComponent section={LifeForceSectionDataList[0]} lastOne={false} />
        </OuterContainer>
      )}
    </Status>
  );
}

export default ProductDetail;
