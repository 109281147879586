import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
});

function Volunteer(props) {
  const {classes} = props;

  return <div className={classes.root}>Volunteer</div>;
}

export default withStyles(styles)(Volunteer);
