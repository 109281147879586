import React, {useState} from 'react';
import {withStyles, Box} from '@material-ui/core';
import BaseSearch from './BaseSearch';
import {Link} from 'react-router-dom';
import IcMail from '~/assets/images/page/findastore/ic-mail.png';
import IcLocation from '~/assets/images/page/findastore/ic-location.png';

const styles = (theme) => ({
  root: {
    padding: '20px 0',
  },
  itemIcon: {
    width: '16px',
    marginRight: '5px',
    objectFit: 'contain',
  },
  dialog: {
    zIndex: 1000,
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.5)',
  },
});

const DataList = [
  {
    title: 'Store Franchise Locations Remaining',
    items: [
      {
        name: 'New Lynn',
        email: 'service.newlynn@sanctuary.co.nz',
      },
      {
        name: 'Takapuna',
        email: 'service.newlynn@sanctuary.co.nz',
      },
      {
        name: 'Henderson',
        email: 'service.newlynn@sanctuary.co.nz',
      },
      {
        name: 'Massey',
        email: 'service.newlynn@sanctuary.co.nz',
      },
      {
        name: 'Howick',
        email: 'service.newlynn@sanctuary.co.nz',
      },
    ],
  },
];

function OpenStoreSearch(props) {
  const {classes} = props;
  const [showDialog, setShowDialog] = useState(false);
  return (
    <Box height={1}>
      <BaseSearch
        dataList={DataList}
        doSearch={(searchText, activeIndex) => {
          if (searchText === '') {
            return [];
          }
          return DataList[activeIndex].items.filter((item) => item.name.indexOf(searchText) >= 0);
        }}
        itemRenderFun={(item) => (
          <Box key={item.name} color="#434343" mt="20px">
            <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
              {item.name}
            </Box>
            {[
              {
                icon: IcLocation,
                text: 'Open your store here !',
                onClick: (item) => {
                  setShowDialog(true);
                },
                color: '#9AA200',
              },
              {icon: IcMail, text: item.email},
            ].map((row, index) => (
              <Box
                display="flex"
                mt="10px"
                key={index}
                alignItems="flex-start"
                onClick={row.onClick}
                style={{cursor: row.onClick && 'pointer'}}
              >
                <img src={row.icon} className={classes.itemIcon} alt="address" />
                <Box
                  flex="1"
                  color={row.color}
                  fontSize="body1.fontSize"
                  style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}
                >
                  {row.text}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      ></BaseSearch>
      {showDialog && (
        <Box
          onClick={() => setShowDialog(false)}
          className={classes.dialog}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box bgcolor="white" borderRadius="10px" pt="40px" pl="90px" pr="90px" pb="30px" color="#8C8C8C">
            <Box textAlign="center" fontSize="subtitle1.fontSize">
              Currently, no store in that area.
            </Box>
            <Box
              textAlign="center"
              fontWeight="fontWeightBold"
              color="#9AA200"
              mt="20px"
              fontSize="h5.fontSize"
              style={{cursor: 'pointer'}}
            >
              <Link to="/prosper" style={{textDecoration: 'none', color: '#9AA200'}}>
                Open your store now !
              </Link>
            </Box>
            <Box textAlign="center" fontSize="body1.fontSize" color="#9AA200" mt="20px">
              <Link to="/prosper" style={{textDecoration: 'none', color: '#9AA200'}}>
                {'>> '}Plato Franchise Page
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default withStyles(styles)(OpenStoreSearch);
