import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import SectionTitle from '~/components/ui/SectionTitle';

import Instruction1Image from '~/assets/images/page/weight-shifter/howto_1.png';
import Instruction2Image from '~/assets/images/page/weight-shifter/howto_2.png';
import Instruction3Image from '~/assets/images/page/weight-shifter/howto_3.png';
import Instruction4Image from '~/assets/images/page/weight-shifter/howto_4.png';

const Data = {
  heading: 'How to shift weight',
  subHeadingLine1: 'Combine our Sanctuary Weight shifting philosophy with the app for great results',
  subHeadingLine2: 'Healthy Weight Shift is gaining or lowering weight by shifting body fat to muscle tissue through:',
  bulletPoints: [
    'Eating nutritious Life Force Food',
    'Smart exercise programs like Fusion Exercise',
    'Our high tech Weight Shifter software to give you the knowledge you need to get the results',
  ],
  instructions: [
    {
      reverse: false,
      imageWidthInPercentage: '16',
      imageSrc: Instruction1Image,
      descTitle:
        'Learn to know your calorie needs and calculations and discover if you are living with a calorie deficit or surplus each day - it’s the equation that’s contributing to your current weight and state of health.',
      descText:
        'Your body is constantly burning calories to keep functioning, even the amount of time standing versus sitting each day effects your calorie needs. It’s an equation of how much you take in and how much you expend. In simple terms if you take in too many calories, but are not active enough to burn them, you will have a surplus of calories that will result in fat deposits. This equation may only be out by a small amount on a daily basis, but over a number of years it adds up to a lot of weight loss or gain. If you burn more calories than what you consume you will lose weight- potentially both fat and muscle tissue. The goal is to lose fat tissue only - Weight Shifter helps you do exactly that.',
      descPadingLeft: '2%',
      descPadingRight: '10%',
    },
    {
      reverse: false,
      imageWidthInPercentage: '32',
      imageSrc: Instruction2Image,
      descTitle:
        'Work long term at raising your metabolic rate through smart food and smart exercise and by participating more in life, making it easier for your body to process calories without gaining weight.',
      descText:
        'Your body will expend less calories at a slower rate when you just eat less, in an effort to conserve energy, your body’s metabolism will decrease making it much more difficult for you to lose weight. This means that in order to lose weight you must: A. not only reduce the number of nutritionally empty calories you unknowingly consume, but maintain food quantity through lower calorie, higher nutrition foods (Life Force Food), which helps maintain metabolic rate. B. Then increase the number of calories that you burn through smart exercise and daily activity.',
      descPadingLeft: '2%',
      descPadingRight: '2%',
    },
    {
      reverse: false,
      imageWidthInPercentage: '48',
      imageSrc: Instruction3Image,
      descTitle:
        'Gradually work at lowering your body fat and increasing muscle tissue to achieve greater power to weight ratio.',
      descText:
        ' The more ‘power’ you have compared to your weight will make you feel more dynamic and want to do things! Weight loss alone, without exercise won’t necessarily lead to huge decreases in body fat, if you really want to decrease your body fat percentage you’ve got to eat better, do cardiovascular exercise and especially include some resistance training to build up your lean mass, otherwise about 25% of every pound you lose will come from lean, calorie-burning muscle. Fusion Exercise has been designed with this in mind- to maintain lean muscle. \r\nRemember muscle tissue is slightly heavier than fat tissue. 1 litre of muscle weighs 1.06 kg while 1 litre of fat weighs .9 kg. Your goal should be- to be happy with the way you look.',
      descPadingLeft: '2%',
      descPadingRight: '0',
    },
    {
      reverse: true,
      imageWidthInPercentage: '48',
      imageSrc: Instruction4Image,
      descTitle:
        'Aim to build up your flexibility and the intensity of your activities - as you become fitter and have a higher power to weight ratio everything will become easier to do and more enjoyable.',
      descText:
        'As you are building the intensity of your workouts and activities, you are improving your stamina, strength, cardiovascular system and self confidence. Smart exercise like Fusion will also aid joints and mental clarity. As your body fat drops and your fitness increases your homeostasis will serve as a guardian protector of your health. So try to push yourself when the time feels right and slowly progress yourself up through the Fusion Exercise Classes.',
      descPadingLeft: '0',
      descPadingRight: '2%',
    },
  ],
};

const styles = (theme) => ({
  root: {
    padding: '30px 6%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 3%',
    },
  },
  rowWrapper: {
    delete: 'check at the end if it is of any use',
    display: 'flex',
    flexWrap: 'noWrap',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  index: {
    width: 30,
    height: 40,
    fontFamily: 'Arial',
    fontSize: 33,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: '1.05px',
    textAlign: 'left',
    color: '#2b2a2a',
    borderBottom: 'solid 4px #6f6e6e',
    [theme.breakpoints.down('sm')]: {
      width: 21,
      height: 29,
      fontSize: 23,
    },
  },
  instructionDescTitle: {
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#4e4e4e',
    padding: '0 0 24px 0',
  },
  instructionDescText: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#4e4e4e',
  },
  detailTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    lineHeight: '1.6',
    color: '#4e4e4e',
    paddingLeft: '20px',
  },
  detailDesc: {
    fontSize: '0.8rem',
    lineHeight: '1.6',
    color: '#4e4e4e',
  },
  dot: {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '5px',
    background: '#8DBF4C',
    marginRight: '5px',
  },
  subItem: {
    display: 'inline',
    fontSize: '1rem',
    lineHeight: '1.6',
    color: '#4e4e4e',
    paddingLeft: '6px',
  },
  indexContent: {
    display: 'flex',
    flexWrap: 'noWrap',
    margin: '0px 6%',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
});

function HowToShiftWeight(props) {
  const {classes} = props;
  return (
    <div style={{backgroundColor: 'rgba(239,239,240,0.85)'}} className={classes.root}>
      <div>
        <div style={{paddingLeft: '3.8%'}}>
          <SectionTitle text={Data.heading} />
          <Typography variant="body1" style={{paddingTop: '30px'}} className={classes.detailTitle}>
            {Data.subHeadingLine1}
          </Typography>
          <Typography variant="body1" style={{paddingBottom: '15px'}} className={classes.detailTitle}>
            {Data.subHeadingLine2}
          </Typography>
          {Data.bulletPoints.map((item) => (
            <div key={item}>
              <span className={classes.dot} />
              <Typography variant="body1" className={classes.subItem}>
                {item}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {Data.instructions.map((item, index) => (
        <CommonComponent
          key={index}
          paddingTopBottom={'25px'}
          reverse={item.reverse}
          imageWidthInPercentage={item.imageWidthInPercentage}
          imageSrc={item.imageSrc}
        >
          <div className={classes.indexContent}>
            <div className={classes.index}>{index + 1}.</div>
            <div style={{marginLeft: '30px'}}>
              <div className={classes.instructionDescTitle}>{item.descTitle}</div>
              <div className={classes.instructionDescText}>{item.descText}</div>
            </div>
          </div>
        </CommonComponent>
      ))}
    </div>
  );
}

export default withStyles(styles)(HowToShiftWeight);
