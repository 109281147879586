import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, Container} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Card from '~/components/ui/Card';
import Image1 from '~/assets/images/page/healthProtocols/calloutimage.png';
import Image2 from '~/assets/images/page/register/image2.png';
import Image3 from '~/assets/images/page/register/image3.png';
import Image4 from '~/assets/images/page/register/image4.png';
import Image5 from '~/assets/images/page/register/image5.png';
import Image6 from '~/assets/images/page/register/image6.png';

const styles = (theme) => ({
  root: {
    marginTop: '40px',
  },
  title: {
    textAlign: 'center',
    padding: '35px 0 20px 0',
    color: '#9AA200',
    fontWeight: 'bold',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  cardDesc: {
    fontSize: 15,
    textDecoration: 'underline',
  },
  cardButton: {
    marginTop: '10px',
  },
});

function AllHealthGuides(props) {
  const {classes, imageRowNum = [3, 2], handleClick, showTitle, showButtons} = props;

  const ImageSeries = [
    {
      image: Image1,
      title: 'Health Psychology',
      desc: 'Principle Health Guide and Book available now',
      path: 'https://vfhurbansanctuary.com/guide-landing-page',
    },
    {
      image: Image2,
      title: 'Life Force Food',
      desc: 'Advance Order',
      path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=lifeforcefood',
    },
    {
      image: Image3,
      title: 'Longevity Activities',
      desc: 'Advance Order',
      path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=longevity-activities',
    },
    {
      image: Image4,
      title: 'Physiological Efficiencies',
      desc: 'Advance Order',
      path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=physiological-efficiencies',
    },
    {
      image: Image5,
      title: 'Natural Remedies',
      desc: 'Advance Order',
      path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=natural-remedies',
    },
    {
      image: Image6,
      title: 'Purity',
      desc: 'Advance Order',
      path: 'https://vfhurbansanctuary.com/advance-exercises-guide?open=purity',
    },
  ];

  const CardContent = ({item, classes, handleClick}) => (
    <Grid container direction="column" alignItems="center">
      <Card imageSrc={item.image} ratio="128%">
        <div className={classes.cardContent}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            onClick={handleClick}
            style={{background: 'rgba(255, 255, 255, 0.25)'}}
          >
            <Grid item>
              <Typography variant="body1" align="center" style={{fontSize: 16}}>
                {item.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center" className={classes.cardDesc}>
                {item.desc}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Grid>
  );

  return (
    <>
      {showTitle && (
        <Typography variant="h5" className={classes.title}>
          ALL HEALTH GUIDES
        </Typography>
      )}

      <Grid container spacing={1}>
        {ImageSeries.map((item) => (
          <Grid item sm={2} xs={6} key={item.title}>
            {item.path.startsWith('https://') || item.path.startsWith('http://') ? (
              <a href={item.path} style={{textDecoration: 'none', color: 'white'}}>
                <CardContent item={item} classes={classes} handleClick={handleClick} />
              </a>
            ) : (
              <Link to={item.path} style={{textDecoration: 'none', color: 'white'}}>
                <CardContent item={item} classes={classes} handleClick={handleClick} />
              </Link>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default withStyles(styles)(AllHealthGuides);
