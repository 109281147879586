import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import CommonComponent from './CommonComponent';
import ImageBg from '~/assets/images/page/discover/section6-bg.png';

const styles = (theme) => ({
  root: {},
  desc: {
    textAlign: 'center',
    padding: '5%',
    fontSize: '1rem',
    lineHeight: '1.5',
  },
});

function FoodScienceSection(props) {
  const {classes} = props;

  return (
    <CommonComponent
      title="Food Science Depository"
      imageWidth={55}
      imageRatio="55%"
      imageSrc={ImageBg}
      descMargin="-25%"
      desc={() => (
        <Typography variant="body1" className={classes.desc}>
          Unlock our food science depository and use the best nutritional combinations of all Sanctuary recipes to
          dynamically support performance, healing and anti-aging. Become empowered with greater food knowledge which
          gives you the reasons to eat healthy and to select the meals best for you and your requirements. When we
          understand the why we usually follow through with action making our eating healthy regime so much easier.
          Achieve greater personal vibrancy, increased health prevention, increased sports performance and recovery
          time, achieve easier weight loss and muscle gain, and use food as an aid in combating disease.
        </Typography>
      )}
    />
  );
}

export default withStyles(styles)(FoodScienceSection);
