import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Container} from '@material-ui/core';
import PageHeader from '~/components/ui/PageHeader';
import ParticipateCard from './ParticipateCard';

import data from './data';
import OuterContainer from '../../components/ui/OuterContainer';
const styles = (theme) => ({
  root: {},
  container: {
    background: 'white',
  },
  btn: {
    textAlign: 'center',
    margin: '1em 0',
  },

  viewBtn: {
    height: '54px',
    borderRadius: '8px',
    color: '#9aa200',
    border: 'solid 1px #9aa200',
    fontSize: '20px',
    fontWeight: '300',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
});

function Participate(props) {
  const {classes} = props;

  return (
    <OuterContainer className={classes.container}>
      <ParticipateCard data={data} />
      <div className={classes.btn}>
        <Button className={classes.viewBtn}>VIEW ALL Community Initiatives </Button>
      </div>
    </OuterContainer>
  );
}

export default withStyles(styles)(Participate);
