import React from 'react';
import * as messageKeys from '~/locales';
import intl from 'react-intl-universal';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const useStyles = (fontColor) =>
  makeStyles({
    visitText: {
      textAlign: 'center',
      fontSize: '1.8em',
      fontWeight: 'bold',
      opacity: '0.67',
      color: `${fontColor}`,
      lineHeight: '1.6',
      letterSpacing: '5px',
    },
    workshopBtn: {
      margin: '5em auto 0',
      width: '100px',
      height: '28px',
      border: 'solid 2px #9aa200',
      fontSize: '0.8em',
      lineHeight: '2.2',
      textAlign: 'center',
      letterSpacing: '1.5px',
      '&:hover': {
        backgroundColor: '#9aa200',
      },
    },
    linkStyle: {
      textDecoration: 'none',
      color: 'white',
      fontWeight: 'bold',
    },
  });

function SeeWorkShopBox(props) {
  const classes = useStyles(props.themeColor)();
  return (
    <div>
      <Typography className={classes.visitText}>
        {props.title ? props.title : intl.get(messageKeys.ORGANIZE_SECTIONCONTENT_VISIT)}
      </Typography>
      <div className={classes.workshopBtn}>
        <a href="/#" className={classes.linkStyle}>
          {intl.get(messageKeys.ORGANIZE_SECTIONCONTENT_GO_BUTTON)}
        </a>
      </div>
    </div>
  );
}

export default SeeWorkShopBox;
