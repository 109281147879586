import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import SectionHeader from './SectionHeader';
import ImageRow from './ImageRow';
import {Container} from '@material-ui/core';
import AllHealthGuides from '~/pages/healthGuides/Sections/AllHealthGuides';
import Guides from '~/pages/services/shopbyhealth/Sections/Guides';

const styles = (theme) => ({
  root: {},
  section: {
    marginBottom: '20px',
  },
  sectionLast: {
    marginBottom: '100px',
  },
});

function LifeForceComponent(props) {
  const {classes, section, lastOne = true} = props;
  if (section.title === 'VITALITY AND LONGEVITY HEALTH GUIDES') {
    return <Guides {...props} />;
  }
  return (
    <div
      className={clsx(classes.section, {
        [classes.sectionLast]: lastOne,
      })}
    >
      {section.title && (
        <SectionHeader title={section.title} reverse={section.reverse} desc={() => section.desc(classes)} />
      )}
      <ImageRow {...section} imageCover={(imageItem) => section.imageCover && section.imageCover(imageItem)} />
      {section.bottomDec && section.bottomDec(classes)}
    </div>
  );
}

export default withStyles(styles)(LifeForceComponent);
