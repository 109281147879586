import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
// import BreadcrumbsComponent from '~/components/ui/Breadcrumbs';

import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import healthPsychology from '~/assets/images/page/register/image1.png';
import lifeForceFood from '~/assets/images/page/register/image2.png';
import longevityActivities from '~/assets/images/page/register/image3.png';
import physiologicalEfficiencies from '~/assets/images/page/register/image4.png';
import naturalRemedies from '~/assets/images/page/register/image5.png';
import pureProducts from '~/assets/images/page/register/image6.png';
import {useLocation} from 'react-router';
import Cart from '~/components/layout/Header/Cart';
import {useDispatch, useSelector} from 'react-redux';
import {checkout, getProduct, getProducts, removeFromCart, setBackground} from '~/store/actions';
import Status from '~/components/ui/Status';
import {statusObj} from '~/utils/commonFunc';

const styles = (theme) => ({
  root: {
    // padding: '6% 12% 3%',
    padding: '10% 0% 10% 0%',
    width: '100%',
    // [theme.breakpoints.down('sm')]: {
    //   padding: '0 30px',
    // },
  },
});

// const PageData = [
//   {
//     id: 1,
//     title: 'Health Psychology',
//     subTitle: 'Principle Health Guide and Book available now',
//     cardBg: healthPsychology,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Health Psychology Personal Guide',
//     price: '19.91',
//     priceJournal: '29.91',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'psychology',
//   },
//   {
//     id: 2,
//     title: 'Life Force Food',
//     subTitle: 'Advance Order',
//     cardBg: lifeForceFood,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Life Force Food Personal Guide',
//     price: '12.92',
//     priceJournal: '22.92',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'life',
//   },
//   {
//     id: 3,
//     title: 'Longevity Activities',
//     subTitle: 'Advance Order',
//     cardBg: longevityActivities,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Longevity Activities Personal Guide',
//     price: '19.93',
//     priceJournal: '29.93',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'longevity',
//   },
//   {
//     id: 4,
//     title: 'Physiological Efficiencies',
//     subTitle: 'Advance Order',
//     cardBg: physiologicalEfficiencies,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Physiological Efficiencies Personal Guide',
//     price: '29.94',
//     priceJournal: '39.94',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'physiological',
//   },
//   {
//     id: 5,
//     title: 'Natural Remedies',
//     subTitle: 'Advance Order',
//     cardBg: naturalRemedies,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Natural Remedies',
//     price: '15.95',
//     priceJournal: '25.95',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'natural',
//   },
//   {
//     id: 6,
//     title: 'Purity',
//     subTitle: ' Advance Order',
//     cardBg: pureProducts,
//     cardDesc:
//       'Quisque suscipit ipsum est, eu venenatis leo ornare eget. Ut porta facilisis eletum. Sed condimentum sed massa quis ullamcorper. Donec at scelerisque neque.',
//     guideName: 'Purity Personal Guide',
//     price: '18.96',
//     priceJournal: '28.96',
//     length: 12,
//     language: 'English',
//     Ebook: 'pdf',
//     word: '2305',
//     path: 'pure',
//   },
// ];

const PageData = [
  {
    title: 'Health Psychology',
    cardBg: healthPsychology,
  },
  {
    title: 'Life Force Food',
    cardBg: lifeForceFood,
  },
  {
    title: 'Longevity Activities',
    cardBg: longevityActivities,
  },
  {
    title: 'Physiological Efficiencies',
    cardBg: physiologicalEfficiencies,
  },
  {
    title: 'Natural Remedies',
    cardBg: naturalRemedies,
  },
  {
    title: 'Purity',
    cardBg: pureProducts,
  },
];

const routes = [
  {title: 'Products and Services', to: '/shopbyproduct'},
  {title: 'Vitality and Longevity Health Guides'},
];

function Header(props) {
  // const {classes, pageData, setCartOpen} = props;
  const {classes} = props;
  const location = useLocation();
  const [pageData, setPageData] = useState(null);
  const [cartOpen, setCartOpen] = useState(false);
  const cartArr = useSelector(({cart}) => cart.cart);
  const dispatch = useDispatch();
  const productsArr = useSelector(({products}) => products.productsArr);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState(statusObj('done'));
  const [productVariant, setProductVariant] = useState(null);

  // useEffect(() => {
  //   if (productsArr.length > 0) {
  //     setProducts(productsArr);
  //   } else {
  //     setStatus(statusObj('loading'));
  //     dispatch(getProducts([],'',''))
  //       .then(productsArr => {
  //         setProducts(productsArr);
  //         setStatus(statusObj('done'));
  //   })
  //       .catch(err => {
  //         console.log(err);
  //       })
  //   }
  // }, []);

  // useEffect(() => {
  //   const newData = [];
  //   products && products.filter(prd => prd.type == 'PersonalGuides').map(prd => {
  //     for (let i = 0; i < PageData.length; i++) {
  //       if (prd.attributes.title == PageData[i].title) {
  //         newData.push({
  //           objectID: prd.attributes.id,
  //           shopifyID: prd.shopifyID,
  //           journalName: prd.attributes.journalName,
  //           title: prd.attributes.title,
  //           subTitle: prd.attributes.subTitle,
  //           cardBg: PageData[i].cardBg,
  //           cardDesc: prd.attributes.cardDesc,
  //           shopifyDesc: prd.description,
  //           guideName: prd.attributes.guideName,
  //           price: prd.attributes.price,
  //           priceJournal: prd.attributes.priceJournal,
  //           length: prd.attributes.length,
  //           language: prd.attributes.language,
  //           Ebook: prd.attributes.Ebook,
  //           word: prd.attributes.word,
  //           path: prd.attributes.path,
  //           type: prd.type,
  //           variants: prd.variants
  //         });
  //       }
  //     }
  //   })

  //   newData.forEach((onePage) => {
  //     if (location && location.pathname.includes(onePage.path)) {
  //       setPageData(onePage);
  //       setProductVariant(onePage.variants[0]);
  //     }
  //   });
  // }, [products]);

  return (
    <div className={classes.root}>
      <Status statusObj={status}>
        <Grid container>
          <Grid item md={6} sm={6} xs={12}>
            {pageData && <HeaderLeft pageData={pageData} />}
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            {pageData && (
              <HeaderRight
                pageData={pageData}
                productVariant={productVariant}
                handleProductVariantChange={(value) => setProductVariant(value)}
                setCartOpen={setCartOpen}
              />
            )}
          </Grid>
        </Grid>
      </Status>
    </div>
  );
}

export default withStyles(styles)(Header);
