import React from 'react';
import {withStyles, Box} from '@material-ui/core';
import BaseSearch from './BaseSearch';

import IcMail from '~/assets/images/page/findastore/ic-mail.png';
import IcLocation from '~/assets/images/page/findastore/ic-location.png';

const styles = (theme) => ({
  root: {
    padding: '20px 0',
  },
  itemIcon: {
    width: '16px',
    marginRight: '5px',
    objectFit: 'contain',
  },
});

const items = [
  {
    name: 'Ponsonby',
    address: '2 Buller Street,\nPonsonby\nAuckland 1011',
    email: 'service.ponsonby@sanctuary.co.nz',
  },
  {
    name: 'Newmarket',
    address: '12 Eden Street,\nNewmarket\nAuckland 1023 ',
    email: 'service.newmarket@sanctuary.co.nz',
  },
  {
    name: 'East Tamaki',
    address: '10 Tekoha Rd,\nFlat bush\nAuckland 1011',
    email: 'service.ponsonby@sanctuary.co.nz',
  },
  {
    name: 'Ponsonby-',
    address: '2 Buller Street,\nPonsonby\nAuckland 1011',
    email: 'service.ponsonby@sanctuary.co.nz',
  },
  {
    name: 'Newmarket-',
    address: '12 Eden Street,\nNewmarket\nAuckland 1023 ',
    email: 'service.newmarket@sanctuary.co.nz',
  },
  {
    name: 'East Tamaki-',
    address: '10 Tekoha Rd,\nFlat bush\nAuckland 1011',
    email: 'service.ponsonby@sanctuary.co.nz',
  },
];

const DataList = [
  {
    title: 'All Stores',
    items,
  },
  {
    title: 'Sanctuary Store Express',
    items,
  },
  {
    title: 'Sanctuary Store Express + services',
    items,
  },
  {
    title: 'Sanctuary Store',
    items,
  },
];

function FindStoreSearch(props) {
  const {classes} = props;

  return (
    <BaseSearch
      dataList={DataList}
      doSearch={(searchText, activeIndex) => {
        if (searchText === '') {
          return [];
        }
        return DataList[activeIndex].items.filter((item) => item.name.indexOf(searchText) >= 0);
      }}
      itemRenderFun={(item) => (
        <Box key={item.name} color="#434343" mt="20px">
          <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
            {item.name}
          </Box>
          {[
            {icon: IcLocation, text: item.address},
            {icon: IcMail, text: item.email},
          ].map((row, index) => (
            <Box display="flex" mt="10px" key={index} alignItems="flex-start">
              <img src={row.icon} className={classes.itemIcon} alt="address" />
              <Box flex="1" fontSize="body1.fontSize" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>
                {row.text}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    ></BaseSearch>
  );
}

export default withStyles(styles)(FindStoreSearch);
