import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {flattenedRoutes, routes, routesWithPath} from '~/static/routes';
import Layout from '~/components/layout';
import Logo from '~/assets/images/layout/logoSanctuaryStore.png';
import LoadingIcon from '~/assets/images/layout/loadingGreen.svg';
import {useCheckoutId} from './hooks/uselocalForage';
import {createContext, useState} from 'react';
import ScrollToTop from './components/ui/ScrollToTop';
import {AuthProvider} from 'shared/auth';
import { testShared } from 'shared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  class1: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    background: 'white',
    zIndex: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const CartOpenContext = createContext(null);

const App = () => {
  const {checkoutId} = useCheckoutId();
  const classes = useStyles();
  const [cartOpen, setCartOpen] = useState(false);
  testShared()
  if (checkoutId === null) {
    return (
      <div className={classes.class1}>
        <img src={Logo} style={{width: '80%', maxWidth: 400}} />
        <img src={LoadingIcon} style={{padding: 40}} />
      </div>
    );
  } else {
    return (
      <AuthProvider>
        <CartOpenContext.Provider value={{cartOpen, setCartOpen}}>
          <Layout checkoutId={checkoutId} routes={routes}>
            {/* <ScrollToTop /> */}
            <Switch>
              {flattenedRoutes.map((element) => (
                <Route exact key={element.name} path={element.path} render={element.breadcrumbs} />
              ))}
            </Switch>
            <Switch>
              {flattenedRoutes.map((element) => (
                <Route exact key={element.name} path={element.path} render={element.render} />
              ))}
            </Switch>
          </Layout>
        </CartOpenContext.Provider>
      </AuthProvider>
    );
  }
};

export default App;
