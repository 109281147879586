import React, {useRef} from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {Box, Typography, Grid, IconButton} from '@material-ui/core';
import {myMeals} from '../../lifeforcefood/productMockData';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Slider from 'react-styled-carousel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {useHistory} from 'react-router';
import {getPath} from '~/static/routes';
import {dispatch} from 'd3-dispatch';
import {SELECTEDITEMCAT} from '~/store/action-types';
import {useDispatch} from 'react-redux';
const responsive = [
  {breakPoint: 2011, cardsToShow: 12},
  {breakPoint: 1700, cardsToShow: 9},
  {breakPoint: 1354, cardsToShow: 8}, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4
  {breakPoint: 1024, cardsToShow: 6},
  {breakPoint: 900, cardsToShow: 5},
  // {breakPoint: 445, cardsToShow: 4},
  {breakPoint: 460, cardsToShow: 4},
  {breakPoint: 358, cardsToShow: 3},
  {breakPoint: 320, cardsToShow: 2},
];

// const responsive = [
//   {breakPoint: 2011, cardsToShow: 3},
//   {breakPoint: 1700, cardsToShow: 3},
//   {breakPoint: 1354, cardsToShow: 3}, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4
//   {breakPoint: 1024, cardsToShow: 3},
//   {breakPoint: 900, cardsToShow: 3},
//   // {breakPoint: 445, cardsToShow: 4},
//   {breakPoint: 460, cardsToShow: 2},
//   {breakPoint: 358, cardsToShow: 2},
//   {breakPoint: 320, cardsToShow: 2},
// ];

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#9aa300',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  imageBox: {
    width: 'auto',
    height: 100,
  },
  foodItemscontainer: {
    background: '#f5f5f5',
    // border: '1px red solid',
    padding: 5,
    borderRadius: 10,
    height: '100%',
  },
  cardImageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: 2,
    width: 125,
    height: 125,
    [theme.breakpoints.down('670')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down('320')]: {
      width: 90,
      height: 90,
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    // textAlign: 'right',
    '&>button': {
      padding: 4,
      color: '#ff3300',
    },
  },
  tags: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '80px',
    height: '24px',
    fontSize: '14px',
    lineHeight: '1.6',
    backgroundColor: '#9aa200',
    color: 'white',
    textAlign: 'center',
    borderRadius: '4px',
  },

  icon: {
    color: '#9aa200',
    cursor: 'pointer',
  },

  cardRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: '1',
    margin: '0 30px',
    position: 'relative',
  },
  cardRowItem: {
    display: 'inline-box',
    textAlign: 'center',
    marginRight: '24px',
    width: 'calc(20% - 18px)', //4x + 3y = 100%, x=25% - 0.75y
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      marginRight: '0px',
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '10px',
      width: 'calc(50% - 5px)',
    },
  },
  class2: {
    fontWeight: 'bold',
    fontSize: '1rem',
    padding: 4,
    cursor: 'pointer',
  },
}));

function FoodItemsCarousel(props) {
  const {items, removeOnClick, mealPlanID} = props;
  const classes = useStyles();
  const scrollEle = useRef(null);
  const dispatch = useDispatch();
  const itemCats = [
    {key: 0, name: 'Breakfast', path: `${getPath('Lifeforce Food')}?tag1=breakfast`},
    {key: 1, name: 'Drink', path: getPath('Drinks')},
    {key: 2, name: 'Condiment', path: getPath('Condiments')},
    {key: 3, name: 'Dessert', path: getPath('Desserts')},
    {key: 4, name: 'Lunch', path: `${getPath('Lifeforce Food')}?tag1=lunch`},
    {key: 5, name: 'Drink', path: getPath('Drinks')},
    {key: 6, name: 'Condiment', path: getPath('Condiments')},
    {key: 7, name: 'Dessert', path: getPath('Desserts')},
    {key: 8, name: 'Dinner', path: `${getPath('Lifeforce Food')}?tag1=dinner`},
    {key: 9, name: 'Drink', path: getPath('Drinks')},
    {key: 10, name: 'Condiment', path: getPath('Condiments')},
    {key: 11, name: 'Dessert', path: getPath('Desserts')},
  ];
  let history = useHistory();
  const itemsRender = (items) => {
    let nodeArr = [];
    const plusButtonOnClick = (key, path) => {
      dispatch({type: SELECTEDITEMCAT, payload: key});
      history.push(path);
    };
    itemCats.forEach((e) => {
      const filtered = items.filter((el) => el.itemCat == e.key);
      if (filtered.length === 0) {
        nodeArr.push(
          <div
            className={classes.cardImageContainer}
            style={{
              background: '#d4d4d4',
            }}
          >
            <p
              style={{
                color: 'white',
                background: '#9aa200',
                padding: '4px 4px',
                borderRadius: 4,
                fontSize: '13px',
              }}
              onClick={() => {
                plusButtonOnClick(e.key, e.path);
              }}
            >
              {`${e.name}`}
              <span className={classes.class2}>+</span>
            </p>
          </div>
        );
      } else {
        nodeArr = [
          ...nodeArr,
          ...filtered.map((ele) => (
            <div>
              <div className={classes.cardImageContainer} style={{backgroundImage: `url(${ele.image.transformedSrc})`}}>
                <p
                  style={{color: 'white', background: '#9aa200', padding: '4px 4px', borderRadius: 4, fontSize: '13px'}}
                >
                  <span
                    onClick={() => {
                      removeOnClick(ele.itemID, mealPlanID);
                    }}
                    className={classes.class2}
                  >
                    -
                  </span>
                  {e.name}
                  <span
                    className={classes.class2}
                    onClick={() => {
                      plusButtonOnClick(e.key, e.path);
                    }}
                  >
                    +
                  </span>
                </p>
              </div>
              <p style={{color: '#434343', fontSize: '1rem', marginBottom: '0'}}>
                {ele.product.title}
                <br />
                {ele.price}
              </p>
            </div>
          )),
        ];
      }
    });
    return nodeArr;
  };
  const navItem = (isLeft) => {
    const parent = scrollEle.current;
    let nextIndex = -1;

    [...parent.children].forEach((child, index) => {
      if (isLeft) {
        if (Math.abs(parent.scrollLeft - child.offsetLeft) < 4) {
          if (nextIndex < 0) {
            if (index > 0) {
              nextIndex = index - 1;
            } else {
              nextIndex = parent.children.length - 1;
            }
          }
        }
      } else {
        if (Math.abs(parent.scrollLeft + parent.clientWidth - child.offsetLeft - child.offsetWidth) < 4) {
          if (nextIndex < 0) {
            if (index >= parent.children.length - 1) {
              nextIndex = 0;
            } else {
              nextIndex = index + 1;
            }
          }
        }
      }
    });
    if (nextIndex >= 0) {
      parent.children[nextIndex].scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  };
  return (
    <Slider autoSlide={false} showArrows responsive={responsive}>
      {itemsRender(items)}
    </Slider>
    // </Grid>
  );
}

export default FoodItemsCarousel;
