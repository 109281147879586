import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: '2%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '320px',
    color: 'white',
    padding: '6% 8% 2%',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      padding: '2% 4% 2%',
      margin: '4px 0',
    },
  },
  image: {
    width: '96px',
    height: '96px',
    [theme.breakpoints.down('sm')]: {
      width: '64px',
      height: '64px',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    margin: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  content: {
    fontSize: '1rem',
    marginTop: '10px',
    lineHeight: '1.4',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
});

function ThumbNailCard(props) {
  const {classes, card} = props;
  return (
    <div className={classes.root} style={{background: `url(${card.bgImage})`}}>
      <div>
        <img src={card.icon} className={classes.image} />
      </div>
      <div className={classes.title}>{card.title}</div>
      <div className={classes.content}>{card.content}</div>
    </div>
  );
}

export default withStyles(styles)(ThumbNailCard);
