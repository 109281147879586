import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';
import CartDrawer from '~/components/layout/Header/Cart';
import {useDispatch, useSelector} from 'react-redux';
import {addToCart} from '~/store/actions';
import {openLoginModal} from '~/utils/commonFunc';
import {useHistory, useLocation} from 'react-router';
import pdfFile from '~/assets/pdf/file-sample_150kB.pdf';

const styles = (theme) => ({
  root: {
    paddingLeft: '60px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  topDesc: {
    fontWeight: 'bold',
    color: 'white',
    paddingTop: '10%',
  },
  highLight: {
    color: '#D2DD00',
  },
  cartBox: {
    margin: '30px 0',
    padding: '20px 30px',
    background: 'white',
    color: '#595959',
  },
  cartLeft: {
    borderRight: '1px solid #dfdfdf',
  },
  cartBoxTitle: {
    marginBottom: '25px',
    fontWeight: 'bold',
  },
  categorySelect: {
    position: 'relative',
  },

  selectBtn: {
    minWidth: '120px',
  },

  inputLabel: {width: '50px', backgroundColor: 'white', textAlign: 'center'},

  price: {
    color: '#9AA200',
    marginLeft: '20px',
    fontSize: '28px',
  },
  btnAddCart: {
    height: '50px',
    background: '#9AA200',
    borderRadius: '5px',
    fontSize: '18px',
    lineHeight: '50px',
    textAlign: 'center',
    color: 'white',
    marginTop: '30px',
    width: '90%',
    cursor: 'pointer',
  },
  btnViewCart: {
    height: '50px',
    borderRadius: '5px',
    border: '1px solid #9AA200',
    fontSize: '18px',
    lineHeight: '50px',
    textAlign: 'center',
    color: '#9AA200',
    marginTop: '20px',
    width: '90%',
    cursor: 'pointer',
  },
  cartRight: {
    paddingLeft: '10%',
  },
  infoTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#8C8C8C',
  },
  infoRow: {
    justifyContent: 'space-between',
    color: '#434343',
    fontSize: '14px',
    marginTop: '10px',
  },
  cartBottomDesc: {
    marginTop: '15px',
    color: '#8C8C8C',
  },
  bottomDesc: {
    marginTop: '25px',
    fontSize: '16px',
    color: 'white',
    paddingBottom: '10%',
  },
  recContainer: {
    padding: '40px 30px 20px 30px',
  },
  topIcon: {
    marginLeft: '40px',
    height: '20px',
  },
});

function HeaderRight(props) {
  const {classes, pageData, productVariant, handleProductVariantChange, setCartOpen} = props;
  const [price, setPrice] = useState(pageData.price);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // const productsArr = useSelector(({products}) => products.productsArr);

  const handleMenuItemClick = (menuItem, pgData) => {
    menuItem == 'EBook' ? setPrice(pgData.price) : setPrice(pgData.priceJournal);
  };

  const onClickAddToCart = (data) => {
    // const link = document.createElement('a');
    // link.href = pdfFile;
    // link.download = `${data.guideName.replace(/ /g,'')}.pdf`;
    // link.dispatchEvent(new MouseEvent('click'));

    pageData.price = price;
    dispatch(
      addToCart({
        ...pageData,
        ...{
          variantID: productVariant.id,
          variantPrice: productVariant.price,
          variantTitle: productVariant.title,
          variantImage: productVariant.image,
        },
      })
    ).catch((error) => {
      if (error == 'notLoggedIn') {
        openLoginModal(history, location);
      }
    });
  };

  return (
    <Grid className={classes.root} container direction="column" justify="space-between">
      <Grid item>
        <div>
          <Typography variant="subtitle1" className={classes.topDesc}>
            <span className={classes.highLight}>Vitality and Longevity Series</span> - Living in Vibrant Health to 120
            Years of Age (a series of six journals).
          </Typography>

          <div className={classes.cartBox}>
            <Typography variant="subtitle1" className={classes.cartBoxTitle}>
              {pageData.guideName}
            </Typography>
            <Grid container>
              <Grid item sm={6} xs={12} className={classes.cartLeft}>
                <Grid container alignItems="center" className={classes.priceRow}>
                  <div className={classes.categorySelect}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>
                        Price
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        // defaultValue="EBook"
                        value={productVariant}
                        className={classes.selectBtn}
                        // onChange={(e) => handleMenuItemClick(e.target.value,pageData)}
                        onChange={(e) => handleProductVariantChange(e.target.value)}
                      >
                        {pageData.variants.map((option) => (
                          <MenuItem key={option.id} id={option.id} value={option}>
                            {option.title}
                          </MenuItem>
                        ))}
                        {/* <MenuItem value="EBook">E-Book</MenuItem>
                        <MenuItem value="Journal">Journal</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <div className={classes.price}>${pageData.price}</div> */}
                  <div className={classes.price}>${productVariant.price}</div>
                </Grid>
                <div className={classes.btnAddCart} onClick={() => onClickAddToCart(pageData)}>
                  ADD TO CART
                </div>
                <div className={classes.btnViewCart} onClick={() => setCartOpen(true)}>
                  VIEW CART
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className={classes.cartRight}>
                  <Typography variant="h6" className={classes.infoTitle}>
                    INFORMATION
                  </Typography>
                  <Grid container className={classes.infoRow}>
                    <span>Length:</span>
                    <span>{pageData.length} Pages</span>
                  </Grid>
                  <Grid container className={classes.infoRow}>
                    <span>Language:</span>
                    <span>{pageData.language}</span>
                  </Grid>
                  <Grid container className={classes.infoRow}>
                    <span>Ebook:</span>
                    <span>{pageData.Ebook}</span>
                  </Grid>
                  <Grid container className={classes.infoRow}>
                    <span>Word:</span>
                    <span>{pageData.word}</span>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Typography variant="body1" className={classes.cartBottomDesc}>
              * For Ebook: once you complete your order,we will send you a link for download, the link is valid for
              three days.
            </Typography>
          </div>

          <Typography variant="subtitle1" className={classes.bottomDesc}>
            Take control of your well-being by investing in these concise health guides, developed by the Sanctuary, to
            help provide you vital context to our health systems and protocols. Compliment your well-being knowledge
            through the unique perspectives contained in this Vitality and Longevity series. Vital Guides to greater
            Health Span.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(HeaderRight);
