import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Image1 from '~/assets/images/page/inbodyscanning/1602630867544@3x.png';
import Image2 from '~/assets/images/page/inbodyscanning/wx202010071635432X@3x.png';
import PublicIcon from '@material-ui/icons/Public';
import DescriptionIcon from '@material-ui/icons/Description';
const routes = [
  {title: 'Products and Services', to: '/productandservice'},
  {title: 'Tech Testing', to: '/productandservice/techtesting'},
  {title: 'InBody Scanning', to: '/productandservice/techtesting/inbodyscanning'},
];

const styles = (theme) => ({
  class1: {
    boxSizing: 'border-box',
    backgroundImage: `url(${Image1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    '& > div': {
      backgroundColor: 'rgba(41, 41, 41,30%)',
      backdropFilter: 'blur(2.3px)',
    },
  },
  class8: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0% 1% 2.5% 1%',
    height: '100%',
    color: 'white',
    fontFamily: 'Lato',
    maxWidth: 1600,
    margin: '0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down(1024)]: {
      padding: '2%',
    },
  },
  class2: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '1.9rem',
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
  },
  class4: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  class5: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  class6: {
    flexBasis: '50%',
    minWidth: 310,
    fontFamily: 'Roboto',
    '& > *': {
      maxWidth: 600,
      [theme.breakpoints.down(1024)]: {
        maxWidth: '100%',
      },
    },
    '& > div': {
      display: 'flex',
      boxSizing: 'border-box',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
    '& > div > div': {
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',
    },
    '& > div > div > p': {
      fontFamily: 'Helvetica',
      fontSize: '1rem',
      fontWeight: 'bold',
      padding: '0.5em',
    },
  },
  class7: {
    flexBasis: '40%',
  },
  class9: {
    padding: '5% 6% 0% 6%',
  },
});

const Section1 = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class1}>
      <div>
        <div className={classes.class8}>
          {/* <h1 className={classes.class2}></h1> */}
          <div className={classes.class5}>
            <div className={classes.class7}>
              <img src={Image2} className={classes.class4} />
            </div>
            <div className={classes.class6}>
              <p style={{fontWeight: 'bold', fontSize: '2rem', marginTop: 0}}>The Premium</p>
              <p style={{fontSize: '2.1rem', marginTop: 0}}>Body Composition Analyzer</p>
              <p style={{fontSize: '1.5rem', marginTop: 0}}>
                Understanding the importance of knowing your body fat percentage. and muscle mass Facts we did not know
                before, we now know, through InBody.
              </p>
              <p style={{fontSize: '1.2rem', marginTop: 0}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
                commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies
                diam a dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas
                vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
                commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies
                diam a dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas
                vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
              </p>
              <div>
                <div>
                  <PublicIcon />
                  <p>READ MORE</p>
                </div>
                <div>
                  <DescriptionIcon /> <p>NEWS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <div className={classes.class2}>INBODY SCANNING</div>
        <div></div>
        <img src={Image2} className={classes.class4} />
      </div>
      <div className={classes.class5}>
        <p>The Premium</p>
        <p>Body Composition Analyzer</p>
        <p>
          Understanding the importance of knowing your body fat percentage. and muscle mass Facts we did not know
          before, we now know, through InBody.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
          purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
          varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui
          arcu, dapibus eu turpis sed, aliquet tincidunt enim.{'/n'}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id commodo
          purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies diam a dapibus
          varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare egestas vulputate. Proin dui
          arcu, dapibus eu turpis sed, aliquet tincidunt enim.
        </p>
        <p></p>
      </div> */}
    </div>
  );
};

export default withStyles(styles)(Section1);
