import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Modal, Grid, IconButton, Button, Badge, Popover} from '@material-ui/core';
import {useLocation, useHistory} from 'react-router-dom';
import Submenu1 from './MenuComponents/Submenu1';
import MainMenu from './MenuComponents/MainMenu';
import Logo from '~/assets/images/layout/logoSanctuaryStore.png';
import {MenuIcon} from '~/components/ui/icons';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import AppsIcon from '@material-ui/icons/Apps';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Submenu2 from './MenuComponents/Submenu2';
import Sidemenu from './MenuComponents/Sidemenu';
import Login from './Login';
import Cart from './Cart';
import {openLoginModal, closeLoginModal} from '~/utils/commonFunc';
import {useDispatch, useSelector} from 'react-redux';
import {cartListenerSubscribe, checkout, removeFromCart} from '~/store/actions';
import {getPath} from '~/static/routes';
import {useAssociateCheckout, useFetchCart, useRemoveCartItem, useUpdateCartItemQuantity} from 'shared/react-query/hooks';
import {CartOpenContext} from '~/App';
import AppDrawerPopOver from 'shared/appDrawer';
import {useAuth} from 'shared/auth';

const useStyles = makeStyles((theme) => ({
  class1: {
    margin: 0,
    width: '100%',
  },
  class2: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '1em',
    verticalAlign: 'middle',
    right: '0',
    border: '1px solid #9aa300',
    background: '#9aa300',
    color: 'white',
    padding: '2px',
    borderRadius: '50%',
    zIndex: 1,
    fontSize: '8px',
    fontWeight: 'bold',
    '&:before': {
      content: '""',
      float: 'left',
      width: 'auto',
      paddingBottom: '100%',
    },
  },
}));

const Header = (props) => {
  const {topMenuRoutes, sideMenuRoutes, checkoutId} = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [submenulvl1Obj, setsubmenulvl1Obj] = useState(null);
  const [showFade1, setShowFade1] = useState(false);
  const [submenulvl2Obj, setsubmenulvl2Obj] = useState(null);
  const [showFade2, setShowFade2] = useState(false);
  const [sidemenuOpen, setSidemenuOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (ref) => {
    setAnchorEl(ref.current);
  };

  const appsIconRef = useRef(null);

  const open = Boolean(anchorEl);

  const {cartOpen, setCartOpen} = useContext(CartOpenContext);

  const {data: cartData, isLoading} = useFetchCart({
    id: checkoutId,
  });
  const associateCheckout = useAssociateCheckout();
  const updateCartItemQuantity = useUpdateCartItemQuantity();
  const removeCartItem = useRemoveCartItem();
  const {auth} = useAuth();

  const handleOpen = () => {
    if (!auth?.token?.length) {
      openLoginModal(history, location);
    } else {
      history.push(getPath('Account'));
    }
  };

  const handleClose = () => {
    closeLoginModal(history, location);
    setAnchorEl(null);
  };

  const ModifiedIconButton = withStyles((theme) => ({
    root: {
      padding: 5,
      minWidth: '0px !important',
    },
  }))(Button);

  const ModifiedBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#9aa200',
      color: 'white',
    },
  }))(Badge);

  const matches = useMediaQuery('(max-width:1156px)');
  const logoRender = () => {
    return (
      <Button onClick={() => history.push(getPath('Homepage'))}>
        <img src={Logo} style={{height: '30.8px', width: 'auto'}} />
      </Button>
    );
  };

  return (
    <>
      <div
        style={{position: 'fixed', width: '100%', zIndex: 100, height: 56}}
        onMouseLeave={() => {
          setShowFade1(false);
          setShowFade2(false);
        }}
      >
        <Grid
          container
          justify="space-between"
          style={{borderBottom: '1px solid #c0c0c0', background: 'white'}}
          className={classes.class1}
        >
          <Grid container item xs={matches ? true : 2} alignItems="center" spacing={1} className={classes.class1}>
            <Grid item>
              <ModifiedIconButton
                onClick={() => {
                  setSidemenuOpen(true);
                }}
              >
                <MenuIcon />
              </ModifiedIconButton>
            </Grid>
            {matches ? null : <Grid item>{logoRender()}</Grid>}
          </Grid>
          <Grid
            container
            item
            xs={matches ? true : 8}
            justify="center"
            alignItems="center"
            className={classes.class1}
            style={{maxWidth: 800}}
          >
            {matches ? (
              logoRender()
            ) : (
              <MainMenu
                routes={topMenuRoutes}
                setsubmenulvl1Obj={(element) => setsubmenulvl1Obj(element)}
                setsubmenulvl2Obj={(element) => setsubmenulvl2Obj(element)}
                historyPush={(element) => {
                  history.push(element);
                  setShowFade1(false);
                  setShowFade2(false);
                }}
                setShowFade1={(bool) => {
                  setShowFade1(bool);
                }}
                setShowFade2={(bool) => {
                  setShowFade2(bool);
                }}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={matches ? true : 2}
            justify="flex-end"
            alignItems="center"
            className={classes.class1}
          >
            <Grid item>
              <ModifiedIconButton style={{color: '#8c8c8c'}} onClick={handleOpen}>
                <PersonIcon />
              </ModifiedIconButton>
            </Grid>

            <Grid item style={{position: 'relative'}}>
              <ModifiedIconButton
                style={{color: '#8c8c8c'}}
                onClick={() => {
                  setCartOpen(true);
                }}
              >
                <ModifiedBadge badgeContent={cartData?.cart?.totalQuantity ?? 0}>
                  <ShoppingCartIcon />
                </ModifiedBadge>
              </ModifiedIconButton>
            </Grid>
            <Grid item>
              <div ref={appsIconRef}>
                <ModifiedIconButton style={{color: '#8c8c8c'}} onClick={() => handleClick(appsIconRef)}>
                  <AppsIcon />
                </ModifiedIconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Submenu1
          historyPush={(element) => {
            history.push(element);
            setShowFade1(false);
            setShowFade2(false);
          }}
          setShowFade2={(bool) => {
            setShowFade2(bool);
          }}
          submenulvl1Obj={submenulvl1Obj}
          submenulvl2Obj={submenulvl2Obj}
          showFade={showFade1}
          setsubmenulvl2Obj={(element) => setsubmenulvl2Obj(element)}
        />
        <Submenu2
          submenulvl2Obj={submenulvl2Obj}
          showFade={showFade2}
          historyPush={(element) => {
            history.push(element);
            setShowFade1(false);
            setShowFade2(false);
          }}
        />
        <Sidemenu
          historyPush={(element) => {
            history.push(element);
            setSidemenuOpen(false);
          }}
          routes2={sideMenuRoutes}
          sidemenuOpen={sidemenuOpen}
          setSidemenuOpen={(bool) => {
            setSidemenuOpen(bool);
          }}
        />
        <Cart
          cartOpen={cartOpen}
          totalCost={cartData?.cart?.cost?.totalAmount?.amount ?? ''}
          cartArr={cartData?.cart?.lines?.edges ?? []}
          removeOnClick={(itemID) => {
            removeCartItem.mutate({cartId: checkoutId, lineIds: [itemID]});
          }}
          onItemQuantityChange={(itemID, quantity) => {
            updateCartItemQuantity.mutate({
              cartId: checkoutId,
              lineItems: [
                {
                  id: itemID,
                  quantity: quantity,
                },
              ],
            });
          }}
          setCartOpen={(bool) => {
            setCartOpen(bool);
          }}
          checkoutOnClick={() => {
              window.open(cartData.cart.checkoutUrl, '_blank');
          }}
        />
        <Modal
          open={location.search.includes('?login')}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <Login closeLoginModal={() => closeLoginModal(history, location)} />
        </Modal>
      </div>
      <div style={{height: 51, width: '100%'}} />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{overflow: 'hidden'}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AppDrawerPopOver />
      </Popover>
    </>
  );
};

export default Header;
