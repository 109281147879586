import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import franchiseBg from '~/assets/images/page/prosper/franBg.jpg';

const styles = (theme) => ({
  class1: {
    background: `url(${franchiseBg}) no-repeat fixed`,
    position: 'relative',
    backgroundSize: 'cover',
    zIndex: 1,
    marginTop: '-4em',
    fontSize: '4rem',
    color: 'rgba(255, 255, 255, 0.65)',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(1024)]: {
      marginTop: '-3.2em',
    },
    [theme.breakpoints.down(750)]: {
      marginTop: '-4.5em',
      fontSize: '2rem',
    },
  },
  class2: {
    width: '100%',
    minHeight: '500px',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(41, 41, 41,30%)',
    backdropFilter: 'blur(2.3px)',
  },
});

const BackgroundSection = (props) => {
  const {classes} = props;
  return (
    <div className={classes.class1}>
      <div className={classes.class2}>
        <p>The Sanctuary Store Plato Franchise System</p>
      </div>
    </div>
  );
};

export default withStyles(styles)(BackgroundSection);
