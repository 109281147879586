import React from 'react';
import {IconButton, makeStyles, Typography, withStyles, Grid, TextField, MenuItem} from '@material-ui/core';
import {Button, Drawer} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  class0: {
    width: '100%',
    margin: 0,
    fontFamily: 'Roboto',
  },
  class1: {
    fontSize: '1.5rem',
    color: '#9aa200',
    textAlign: 'center',
    width: '100%',
    fontWeight: '500',
  },
  class2: {color: 'rgb(140, 140, 140)', fontSize: '0.8rem'},
  class3: {
    color: '#9aa200',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  class4: {fontWeight: '500'},
  drawerContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: 10,
    maxHeight: '100%',
    [theme.breakpoints.down(620)]: {
      width: '100%',
    },
    margin: '0 auto',
    fontFamily: 'Lato',
  },
  drawerHeading: {
    fontSize: '2rem',
    color: '#9aa200',
  },
  divider: {
    border: 'solid 1px #dfdfdf',
  },
  cartItem: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down(620)]: {
      flexDirection: 'column',
    },
  },
  productInfo: {
    display: 'flex',
    [theme.breakpoints.down(620)]: {
      marginTop: 10,
    },
  },
  checkoutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(620)]: {
      justifyContent: 'center',
    },
  },
  class5: {
    fontSize: '0.9rem',
    color: '#9aa200',
    fontWeight: 'bold',
  },
  class6: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '2px',
      textAlign: 'center',
      border: '1px solid black',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
}));
const GreenButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#9AA200',
    '&:hover': {
      backgroundColor: '#747a00',
    },
  },
}))(Button);

const CartItem = (props) => {
  const {item, removeOnClick, onItemQuantityChange} = props;
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={1} className={classes.class0}>
      <Grid container item xs={12} spacing={1} className={classes.class0}>
        <Grid item xs={4}>
          <img
            src={item?.node?.merchandise?.image?.originalSrc}
            style={{aspectRatio: '0.614', maxWidth: '100%', objectFit: 'cover', maxHeight: 70}}
            alt="icon"
          />
        </Grid>
        <Grid item xs container style={{height: '100%'}} alignItems="space-between" className={classes.class0}>
          <Grid item xs={12}>
            <Typography className={classes.class4}>{item.node.merchandise.product.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.class2}>{item.node.merchandise.title}</Typography>
          </Grid>
          <Grid container item xs={12} className={classes.class0} justify="space-between" alignItems="center">
            <Grid item xs style={{textAlign: 'left'}}>
              <TextField
                id="select"
                variant="outlined"
                value={item.node.quantity}
                select
                className={classes.class6}
                onChange={(e) => onItemQuantityChange(item.node.id, e.target.value)}
              >
                {Array.from({length: item.node.merchandise.quantityAvailable}, (_, i) => (
                  <MenuItem value={i + 1} key={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs style={{textAlign: 'center'}}>
              <Typography className={classes.class3}>${item.node.cost.totalAmount.amount}</Typography>
            </Grid>
            <Grid item xs style={{textAlign: 'right'}}>
              <IconButton
                onClick={() => {
                  removeOnClick(item.node.id);
                }}
                style={{padding: 3, color: '#d73921'}}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.divider}></div>
      </Grid>
    </Grid>
  );
};

const CartDrawer = (props) => {
  const {cartOpen, setCartOpen, cartArr, checkoutOnClick, removeOnClick, totalCost, onItemQuantityChange} = props;
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)} PaperProps={{style: {width: 276}}}>
      <Grid container direction="column" spacing={2} style={{height: '100%'}} className={classes.class0}>
        <Grid container item className={classes.class0}>
          <Grid item xs={12}>
            <Typography className={classes.class1}>My Shopping Cart</Typography>
          </Grid>
          <Grid item container xs={12} className={classes.class0}>
            <Grid item xs className={classes.divider}></Grid>
          </Grid>
        </Grid>
        <Grid item container xs className={classes.class0} style={{overflowY: 'scroll', display: 'block'}}>
          {cartArr.length !== 0
            ? cartArr.map((e, index) => (
                <CartItem
                  key={index}
                  item={e}
                  removeOnClick={removeOnClick}
                  onItemQuantityChange={onItemQuantityChange}
                />
              ))
            : null}
        </Grid>
        <Grid id="footer" item container spacing={2} alignItems="center" className={classes.class0} style={{marginBottom:"calc(20px + env(safe-area-inset-bottom))"}}>
          <Grid item xs={6}>
            <Typography className={classes.class5}>Total: ${totalCost}</Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            {cartArr.length !== 0 && (
              <GreenButton
                onClick={() => {
                  checkoutOnClick();
                }}
              >
                CHECK OUT
              </GreenButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default CartDrawer;
