export default [
  {
    title: 'Natural Beauty',
    name: 'Natural Beauty',
    text:
      '',
  },
  {
    title: 'Increased Vitality',
    name: 'Increased Vitality',
    text:
      '',
  },
  {
    title: 'Esteem and Appearance',
    name: 'Esteem and Appearance',
    text:
      '',
  },
  {
    title: 'Improved Health',
    name: 'Improved Health',
    text:
      '',
  },
  {
    title: 'Enhanced physique',
    name: 'Enhanced physique',
    text:
      '',
  },
  {
    title: 'Advanced Learning',
    name: 'Health Psychology Guide and Book',
    text:
      'The book is very detailed and comprehensive, but explains material in a lay-person and easy to understand manner. Exceptional, well researched and presented material. I took out what was important to me and what I enjoyed most was the feeling of advancement. Ivan Middlemost',
  },
  {
    title: 'Life Balance and Connection',
    name: 'Life Balance and Connection',
    text:
      '',
  },
  {
    title: 'Empowerment',
    name: 'Empowerment',
    text:
      '',
  },
  {
    title: 'Exquisite taste',
    name: 'Exquisite taste',
    text:
      'Greater Purpose Consciously shop online or at our Sanctuary Stores, personally advance and grow through our Urban Sanctuaryservices, enjoy the bliss and serenity of our Master Sanctuary, engage with other conscious creatures on ourHigher Consciousness Network and ultimately prosper through ‘right livihood’ with our Plato Franchise Systemopportunity.',
  },
  {
    title: 'Clarity of Mind',
    name: 'Clarity of Mind',
    text:
      '',
  },
  {
    title: 'Greater Happiness',
    name: 'Greater Happiness',
    text:
      '',
  },
];
