import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';

import BorderRadiusButton from '~/components/ui/BorderRadiusButton';

import IconOwn from '~/assets/images/page/discover/section11-icon-own.png';
import IconMissing from '~/assets/images/page/discover/section11-icon-missing.png';
import OuterContainer from '../OuterContainer';

const DataList = [
  {
    isPopular: false,
    mainColor: '#871DBF',
    title: 'Explore',
    desc: 'Duis porta, ligula rhoncus euismod pretium, nisi tellus eleifend odio, luctus viverra sem dolor id sem.',
    price: ['$0', '/Free'],
    ownList: [
      'Precise Nutritional Analysis',
      'Access our Food Science depository',
      'Evaluate your Cellular Purity',
      'Metawheel App Integration ',
      'Quick purchase link for all products',
      'Discover your impact up on the health of our planet',
    ],
    missingList: [
      'Personalised guidance',
      'Hair Test',
      'Fat reduction program',
      'Introductory detoxification',
      'Weight Shifter App Integration',
      'Accurate Biological age and longevity analysis',
      'Prodromal mapping for pro-active prevention',
      'Identify optimum Sanctuary Health Protocols',
      'Personal Optimum Nutritional Design.',
    ],
  },
  {
    isPopular: true,
    mainColor: '#8DBF4C',
    title: 'Premium',
    desc: 'Maecenas a venenatis enim, quis porttitor magna. Etiam nec rhoncus neque. Sed quis ultrices eros.',
    price: ['$150', '/Year'],
    ownList: [
      'Precise Nutritional Analysis',
      'Access our Food Science depository',
      'Evaluate your Cellular Purity',
      'Metawheel App Integration ',
      'Quick purchase link for all products',
      'Discover your impact up on the health of our planet',
      'Personalised guidance',
      'Hair Test',
      'Fat reduction program',
      'Introductory detoxification',
      'Weight Shifter App Integration',
    ],
    missingList: [
      'Accurate Biological age and longevity analysis',
      'Prodromal mapping for pro-active prevention',
      'Identify optimum Sanctuary Health Protocols',
      'Personal Optimum Nutritional Design.',
    ],
    footer: 'Add Hair test $120.00 ',
  },
  {
    isPopular: false,
    mainColor: '#D4C623',
    title: 'Precision',
    desc:
      'Duis ut dolor consectetur, mollis lorem et, mattis est. Nullam mattis mauris quis massa consectetur dapibus. ',
    price: ['* Price on Request'],
    ownList: [
      'Precise Nutritional Analysis',
      'Access our Food Science depository',
      'Evaluate your Cellular Purity',
      'Metawheel App Integration ',
      'Quick purchase link for all products',
      'Discover your impact up on the health of our planet',
      'Personalised guidance',
      'Hair Test',
      'Fat reduction program',
      'Introductory detoxification',
      'Weight Shifter App Integration',
      'Accurate Biological age and longevity analysis',
      'Prodromal mapping for pro-active prevention',
      'Identify optimum Sanctuary Health Protocols',
      'Personal Optimum Nutritional Design.',
    ],
    note: '* Price depends on the tests required',
    footer: 'Click here for detailed Precision Process',
  },
];

const Radius = '5px';
const styles = (theme) => ({
  root: {padding: '30px 6%'},
  title: {
    textAlign: 'center',
    color: '#595959',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  itemRoot: {
    height: '100%',
  },
  itemPopular: {
    color: 'white',
    textAlign: 'center',
    height: '40px',
    lineHeight: '40px',
    fontWeight: 'bold',
    borderTopLeftRadius: Radius,
    borderTopRightRadius: Radius,
  },
  itemContainer: {
    padding: '50px 30px 15px 30px',
    borderRadius: Radius,
    border: '2px solid',
  },
});

function createColumn({itemData, classes}) {
  return (
    <div className={classes.itemRoot}>
      <Typography
        variant="subtitle1"
        className={classes.itemPopular}
        style={{
          backgroundColor: itemData.mainColor,
          visibility: itemData.isPopular ? 'visible' : 'hidden',
        }}
      >
        MOST POPULAR
      </Typography>
      <Grid
        container
        direction="column"
        style={{
          height: 'calc(100% - 40px)',
          borderColor: itemData.mainColor,
          borderTopLeftRadius: itemData.isPopular ? 0 : Radius,
          borderTopRightRadius: itemData.isPopular ? 0 : Radius,
        }}
        className={classes.itemContainer}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
            marginLeft: '10px',
            color: itemData.mainColor,
          }}
        >
          {itemData.title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: '#595959',
            fontSize: '0.8rem',
            marginTop: '10px',
          }}
        >
          {itemData.desc}
        </Typography>
        <Typography
          variant="h4"
          style={{
            color: '#030303',
            fontWeight: 'bold',
            fontFamily: 'Georgia',
            marginTop: '20px',
            alignSelf: 'center',
            height: '60px',
            fontSize: itemData.price.length === 1 ? '1rem' : '2.125rem',
          }}
        >
          {itemData.price[0]}
          {itemData.price.length === 2 && (
            <span
              style={{
                fontSize: '1rem',
              }}
            >
              {itemData.price[1]}
            </span>
          )}
        </Typography>
        <BorderRadiusButton text="GET NOW" style={{alignSelf: 'center'}} hollow={!itemData.isPopular} />
        <div
          style={{
            borderTop: '1px dashed #DEDEDE',
            margin: '20px -30px 10px -30px',
          }}
        />
        {createList(IconOwn, itemData.ownList)}
        {createList(IconMissing, itemData.missingList)}
        <Typography variant="body1" style={{fontStyle: 'italic', fontSize: '0.8rem', color: '#707070'}}>
          {itemData.note}
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontSize: '0.8rem',
            marginTop: '5px',
            alignSelf: 'center',
            color: '#8DBF4C',
          }}
        >
          {itemData.footer}
        </Typography>
      </Grid>
    </div>
  );
}

function createList(icon, dataList) {
  return (
    dataList && (
      <div style={{marginTop: '10px'}}>
        {dataList.map((item, index) => (
          <Grid
            container
            key={index}
            style={{
              color: '#707070',
              marginTop: '5px',
            }}
          >
            <img src={icon} alt="Icon" width={15} height={15} />
            <Typography variant="body1" style={{fontSize: '0.8rem', flex: 1, marginLeft: '8px'}}>
              {item}
            </Typography>
          </Grid>
        ))}
      </div>
    )
  );
}

function SelectPlanSection(props) {
  const {classes} = props;
  return (
    <OuterContainer>
      <Typography variant="h5" className={classes.title}>
        Select your plan
      </Typography>
      <Grid container spacing={4} style={{width: '100%', margin: 0}}>
        {DataList.map((itemData, index) => (
          <Grid key={index} item md sm xs={12}>
            {createColumn({
              itemData,
              classes,
            })}
          </Grid>
        ))}
      </Grid>
    </OuterContainer>
  );
}

export default withStyles(styles)(SelectPlanSection);
