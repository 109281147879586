import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import BMPCalIcon from '~/assets/images/page/weight-shifter/BMP_cal_icon.png';
import BodyFatIcon from '~/assets/images/page/weight-shifter/body_fat_icon.png';
import CalorieCalIcon from '~/assets/images/page/weight-shifter/Calorie_cal_icon.png';
import ProteinIcon from '~/assets/images/page/weight-shifter/protein_icon.png';
import ResultIcon from '~/assets/images/page/weight-shifter/result_icon.png';
import WaterIcon from '~/assets/images/page/weight-shifter/water_icon.png';

const styles = (theme) => ({
  sectionWrapper: {
    margin: '6% 6% 2%',
    background: '#fff',
    boxShadow: '1px 1px 10px 0px rgba(0,0,0,0.75)',
    borderRadius: '10px',
    paddingBottom: '35px',
  },
  utiliseMainHeading: {
    fontSize: '16px',
    textAlign: 'center',
    color: '#8dbf4c',
    padding: '35px 0 20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  root: {
    flexGrow: 0,
  },
  itemList: {
    flexDirection: 'column',
  },
  utiliseImage: {
    textAlign: 'center',
    marginBottom: 12,
    minHeight: 90,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 6,
    },
  },
  image: {
    width: 76,
    height: 90,
  },
  utiliseHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#707070',
    textAlign: 'center',
    height: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      height: 24,
    },
  },
  utiliseDesc: {
    fontSize: '14px',
    color: '#707070',
    textAlign: 'center',
    padding: '7px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 10px',
      fontSize: 11,
    },
  },
});

const microAppUtilise = [
  {
    icon: BodyFatIcon,
    heading: 'Body Fat Calculator',
    description: 'Recording and projecting body fat',
  },
  {
    icon: CalorieCalIcon,
    heading: 'Calorie&Time Calculator ',
    description: 'Calculating daily calorie & time options to achieve your goal',
  },
  {
    icon: BMPCalIcon,
    heading: 'BMR Calculator',
    description: 'Calculate your BMR and daily burn',
  },
  {
    icon: WaterIcon,
    heading: 'Water chart',
    description: 'Know the optimum water requires to protein levels',
  },
  {
    icon: ProteinIcon,
    heading: 'Protein & Calorie composition charts',
    description: 'Calculate your necessary fuel to loss fat and or gain muscle',
  },
  {
    icon: ResultIcon,
    heading: 'Results charting ',
    description: 'Monitor  actual results and chart  projected results throughout your program.',
  },
];

function MicroApps(props) {
  const {classes} = props;

  return (
    <div className={classes.sectionWrapper}>
      <Typography className={classes.utiliseMainHeading}>
        Utilise our intelligent micro apps to enable the overall picture necessary for physique transformation:{' '}
      </Typography>
      <Grid container className={classes.root} spacing={1}>
        {microAppUtilise.map((item, index) => (
          <Grid item key={index} className={classes.itemList} md={2} sm={4} xs={6}>
            <div className={classes.utiliseImage}>
              <img src={item.icon} alt={item.heading} className={classes.image} />
            </div>
            <div className={classes.utiliseHeading}>{item.heading}</div>
            <div className={classes.utiliseDesc}>{item.description}</div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(MicroApps);
