import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import hairTestingImage from '~/assets/images/page/hairTesting/hairTesting_small.png';
import cellWellBeingImage from '~/assets/images/page/hairTesting/cellWellBeing.png';
import IconPlayVideo from '~/assets/images/page/discover/section1-video-play.png';

const styles = (theme) => ({
  root: {
    paddingTop: '30px',
    '& div': {
      //border:'1px solid red',
    },
  },
  leftContent: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  scanningImageDiv: {
    maxWidth: '325px',
    position: 'relative',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      margin: 'auto',
      width: '80%',
    },
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
  textBottomRight: {
    position: 'absolute',
    bottom: '12px',
    right: '26px',
    fontFamily: 'Bahnschrift',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    lineHeight: '1.25',
    letterSpacing: '1.28px',
    textAlign: 'right',
    color: '#9aa200',
  },
  rightContent: {
    width: '580px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  steps: {
    paddingLeft: '30px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
    },
    '&>div': {
      width: '33%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
  },
  stepsSquare: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
    },
  },
  stepNumber: {
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    paddingBottom: '5px',
    marginTop: '-65px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '1rem',
      fontFamily: 'Bahnschrift',
      lineHeight: '2',
      letterSpacing: '0.96px',
      color: '#ffffff',
    },
  },
  stepTitle: {
    fontFamily: 'Bahnschrift',
    lineHeight: '1',
    letterSpacing: '0.96px',
    color: '#ffffff',
    paddingTop: '15px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      fontSize: '0.75rem',
    },
  },
  stepDescription: {
    padding: '10px 5px 0px 5px',
    fontFamily: 'Arial',
    fontSize: '0.6rem',
    lineHeight: '1',
    letterSpacing: '0.12px',
    color: '#595959',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px',
      fontSize: '0.5rem',
    },
  },
  cellWellBeingImage: {
    paddingLeft: '30px',
    marginTop: '50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
    '&>div:nth-child(1)': {
      width: '150px',
      [theme.breakpoints.down('md')]: {
        width: '30%',
        alignSelf: 'center',
      },
      '& img': {
        width: '100%',
        [theme.breakpoints.down('md')]: {
          width: '80%',
        },
      },
    },
    '&>div:nth-child(2)': {
      marginLeft: '30px',
      width: '365px',
      [theme.breakpoints.down('md')]: {
        width: '70%',
        marginLeft: '0px',
        paddingLeft: '5px',
      },
      '& p': {
        fontFamily: 'Arial',
        fontSize: '0.75rem',
        lineHeight: '1.67',
        color: '#ffffff',
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.65rem',
        },
      },
    },
  },
  text: {
    paddingTop: '50px',
    fontFamily: 'Helvetica',
    fontSize: '2.2rem',
    fontWeight: 'bold',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  readMore: {
    padding: '20px 20px 0px 20px',
    fontFamily: 'Helvetica',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '1.25',
    color: '#ffffff',
  },
  videoIcon: {
    height: '15px',
    margin: '2px 10px 0 0',
    verticalAlign: 'text-bottom',
  },
});

const data = {
  steps: [
    {
      backgroundColor: '#e6c7f7',
      titleBackgroundColor: '#7e489c',
      title: 'Lorem Sedes',
      description: 'Sed ornare egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt',
    },
    {
      backgroundColor: '#e4e893',
      titleBackgroundColor: '#9aa200',
      title: 'Lorem Sedes',
      description: 'Sed ornare egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt',
    },
    {
      backgroundColor: '#c0daeb',
      titleBackgroundColor: '#659bbf',
      title: 'Lorem Sedes',
      description: 'Sed ornare egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt',
    },
  ],
};
function HeroSectionData(props) {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={5} className={classes.leftContent}>
          <div className={classes.scanningImageDiv}>
            <img src={hairTestingImage} className={classes.scanningImage} alt="Scanning" />
            <div className={classes.textBottomRight}>Hair Testing</div>
          </div>
        </Grid>
        <Grid item md={7}>
          <div className={classes.rightContent}>
            <Grid item container className={classes.steps}>
              {data.steps.map((item, index) => (
                <Grid item container>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    item
                    className={classes.stepsSquare}
                    style={{backgroundColor: item.backgroundColor}}
                  >
                    <Grid
                      container
                      justify="center"
                      alignItems="flex-end"
                      item
                      className={classes.stepNumber}
                      style={{backgroundColor: item.titleBackgroundColor}}
                    >
                      <Grid item>Step {index + 1}</Grid>
                    </Grid>
                    <Typography variant="subtitle1" className={classes.stepTitle}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" className={classes.stepDescription}>
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item container className={classes.cellWellBeingImage}>
              <Grid item>
                <img src={cellWellBeingImage} alt="cell well being" />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat feugiat pulvinar. Duis id
                  commodo purus. Vestibulum iaculis nunc vitae libero hendrerit, a finibus leo venenatis. Sed ultricies
                  diam a dapibus varius. Sed a scelerisque dui. Donec sed iaculis est, ut gravida eros. Sed ornare
                  egestas vulputate. Proin dui arcu, dapibus eu turpis sed, aliquet tincidunt enim.
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item container>
              <Typography className={classes.text}>Tiny thing makes a big difference</Typography>
              <Grid item container justify="center">
                <Typography className={classes.readMore}>
                  <img className={classes.videoIcon} src={IconPlayVideo} alt="icon play"></img>READMORE
                </Typography>
                <Typography className={classes.readMore}>
                  <img className={classes.videoIcon} src={IconPlayVideo} alt="icon play"></img>NEWS
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(HeroSectionData);
