import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
  factorsWrapper: {
    zIndex: '2',
    position: 'relative',
    backgroundColor: 'rgba(245, 245, 233)',
  },
  root: {
    zIndex: '2',
    padding: '50px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },

  title: {
    opacity: '0.8',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  leftText: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.15',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#595959',
    padding: '8px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '4px 0px',
    },
  },
  imageWrapper: {
    textAlign: 'center',
  },
  immuneResponseImage: {
    backgroundColor: '#ffffff',
    margin: '0px auto',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '10px auto',
    },
  },
});

function FactorsSection(props) {
  const {classes, pageData} = props;

  return (
    <div className={classes.factorsWrapper}>
      <Container>
        <div className={classes.root}>
          <Typography variant="h5" className={classes.title}>
            {pageData.factor.factorTitle}
          </Typography>
          <Grid container>
            <Grid item md={6}>
              {pageData.factor.factorContents.map((content, index) => (
                <Typography variant="body1" className={classes.leftText} key={index}>
                  {'- '}
                  {content}
                </Typography>
              ))}
            </Grid>
            <Grid item md={6} className={classes.imageWrapper}>
              <img src={pageData.factor.factorsImage} className={classes.immuneResponseImage} alt="Our Scanner" />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withStyles(styles)(FactorsSection);
