export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const ACTION_SET_BACKGROUND = 'ACTION_SET_BACKGROUND';

export const GETPRODUCTS = 'GETPRODUCTS';

export const CART = 'CART';

export const EMPTYCART = 'EMPTYCART';

export const MEALPLANSARR = 'MEALPLANSARR';

export const SELECTEDMEALPLAN = 'SELECTEDMEALPLAN';

export const EMPTYMEALPLANNER = 'EMPTYMEALPLANNER';

export const SELECTEDITEMCAT = 'SELECTEDITEMCAT';

export const SET_USERNAMEANDPHONENUMBER = 'SET_USERNAMEANDPHONENUMBER';
