import React from 'react';
import {withStyles} from '@material-ui/core';
import {Grid, Typography} from '@material-ui/core';
const styles = (theme) => ({
  content: {
    position: 'relative',
    padding: '5% 12%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '3% 4%',
    },
  },
  bookNowTitle:{
    color: '#9aa200',
    fontFamily: 'Roboto',
    fontSize: '25px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: '4px',
    textTransform: 'uppercase',
    margin: '0px auto 40px',
    textAlign: 'center',

  },
  steps: {
    paddingLeft: '30px',
    paddingBottom: '40px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingBottom: '20px',
    },
    '&>div': {
      width: '33%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
  },
  stepsSquare: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    overflow: 'hidden',
    textAlign: 'center',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
      paddingBottom: '20px',
    },
  },
  stepNumber: {
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    paddingBottom: '5px',
    marginTop: '-65px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '1rem',
      fontFamily: 'Bahnschrift',
      lineHeight: '2',
      letterSpacing: '0.96px',
      color: '#ffffff',
    },
  },
  stepTitle: {
    fontFamily: 'Bahnschrift',
    lineHeight: '1',
    letterSpacing: '0.96px',
    color: '#ffffff',
    paddingTop: '15px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      fontSize: '0.75rem',
    },
  },
  stepDescription: {
    padding: '10px 15px 0px 15px',
    fontFamily: 'Lato',
    fontSize: '0.7rem',
    lineHeight: '1.2',
    letterSpacing: '0.12px',
    color: '#595959',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px',
      fontSize: '0.5rem',
    },
  },
    bookNowText: {
    backgroundColor: '#f6f6f6',
    padding: '40px',
    margin: '0px auto',
    marginBottom: '20px',
    width: '90%',
    [theme.breakpoints.down('xs')]: {
        padding: '20px',
      },
    },
  scanningTips: {
      color: '#595959',
      fontSize: '12px',
      padding: '2px 0px',
  },

});
const data = {
    steps: [
      {
        backgroundColor: '#e6c7f7',
        titleBackgroundColor: '#7e489c',
       
        description: 'Use our online booking calendar below (or phone 021-026-71849 for appointment outside these available times).',
      },
      {
        backgroundColor: '#e4e893',
        titleBackgroundColor: '#9aa200',
        description: 'Register on-line with Fit3D www.xxxxxx.com          ',
      },
      {
        backgroundColor: '#c0daeb',
        titleBackgroundColor: '#659bbf',
      
        description: 'Download the Fit3D App to your phone',
      },
    ],
  };


function BookNowSection(props) {
  const {classes} = props;

  return (
    <>
      <div className={classes.content}>
        <Grid container>
            <Grid item md={12}>
                <Grid item>
                    <h2 className={classes.bookNowTitle}>Book it Now!</h2>
                </Grid>
                <Grid item container className={classes.steps}>
                {data.steps.map((item, index) => (
                    <Grid item container key={index}>
                        
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        className={classes.stepsSquare}
                        style={{backgroundColor: item.backgroundColor}}
                    >
                        <Grid
                        container
                        justify="center"
                        alignItems="flex-end"
                        item
                        className={classes.stepNumber}
                        style={{backgroundColor: item.titleBackgroundColor}}
                        >
                        <Grid item>Step {index + 1}</Grid>
                        </Grid>
                        
                        <Typography variant="body1" className={classes.stepDescription}>
                        {item.description}
                        </Typography>
                    </Grid>
                    </Grid>
                ))}
                </Grid>
                <Grid item container >
              
                <Grid item className={classes.bookNowText}>
                    <Typography variant="body1" className={classes.scanningTips}>
                        To ensure proper measurements, and therefore accurate body composition, body shape rating, and other anthropometric measurements, when you come for your appointment please ensure that you:
                    </Typography>
                    <Typography variant="body1" className={classes.scanningTips}>
                        <b>1. Wear minimal and form-fitting</b> clothing (e.g. G-string and bra for women and tight/small underwear for men). 
                        Please note that things like different bra styles from one scan to the next CAN affect measurements.  ​(Our scanner allows self-scanning, and therefore your scan will be taken in private).  
                    </Typography>
                    <Typography variant="body1" className={classes.scanningTips}>
                        <b>2. Ensure that hair is completely tied up above the neckline.</b> Hair must be tied in a bun (not a pony tail).  Long beards, must also be tied above the neckline.
                    </Typography>
                </Grid>

                </Grid>
         
            </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(BookNowSection);
